import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import Chip from '@mui/material/Chip';

const FloatingInputField = (props) => {
  const theme = useTheme();
  const [userRole, setUserRole] = useState(props.currentUserRole);
  const [userRoleData, setUserRoleData] = useState(props.userRoleData);
  const [firstName, setFirstName] = useState(props.firstName || '');
  const [lastName, setLastName] = useState(props.lastName || '');
  const [email, setEmail] = useState(props.email || '');
  const [userName, setUserName] = useState(props.userName || '');
  const [password, setPassword] = useState('');
  const [locationsData, setLocations] = useState(props.locationData);
  const [locationsSelected, setLocationsSelected] = useState([]);
  const [locationsSelectedId, setLocationsSelectedId] = useState([]);
  const [showLocationsField, setShowLocationsField] = useState(false);
  const [driverRequiredFields, setDriverRequiredFields] = useState(false);
  const [touched, setTouched] = useState(true);
  const [firstNameTouched, setFirstNameTouched] = useState(false);
  const [lastNameTouched, setLastNameTouched] = useState(false);
  const [emailTouched, setEmailTouched] = useState(false);
  const [usernameTouched, setUsernameTouched] = useState(false);
  const [passwordTouched, setPasswordTouched] = useState(false);
  const [locationsTouched, setLocationsTouched] = useState(false);

  const names = [];
  const WIDTH_FIELDS = '300px';
  const emailRegex = new RegExp(/\S+@\S+\.\S+/);

  const setNames = () => {
    let temp = [];
    for (let i in locationsData) {
      temp.push(locationsData[i].locationName);
    }
    names.push(...temp);
  };
  setNames();

  let error1 = (firstName === "");
  let error2 = (lastName === "");
  let error3 = (!emailRegex.test(email));
  let error4 = (userName === "");
  let error5 = (password === "");
  let error6 = locationsSelected.length < 1;

  useEffect(() => {
    if (!userRole) {
      setTouched(true);
      return;
    }
    if (userRole !== 'Driver' && showLocationsField && (error1 || error2 || error3 || error4 || error5 || error6)) {
      setTouched(true);
    } else if (userRole !== 'Driver' && !showLocationsField && (error1 || error2 || error3 || error4 || error5)) {
      setTouched(true);
    } else if (userRole === 'Driver' && ((error1 || error2 || error4 || error6))) {
      setTouched(true);
    } else {
      setTouched(false);
    }
  }, [userName, firstName, lastName, email, userName, password, locationsSelected, userRole, showLocationsField]);

  useEffect(() => {
    let temp = [];
      for (let i in locationsSelected) {
        let selectedName = locationsSelected[i];
        for (let j in locationsData) {
          if (selectedName === locationsData[j].locationName) {
            temp.push(locationsData[j].locationId);
          }
        }
      }
      setLocationsSelectedId(temp);
  }, [locationsSelected]);

  useEffect(() =>{
    let temp =[]
    if (locationsSelected.length<1 && props.locations) {
      for (let i in props.locations) {
        temp.push(props.locations[i].locationName);
      }
      setLocationsSelected(temp)
    }
  },[])

  useEffect(() => {
    const locationVisibleRoles = ['LocationAdmin', 'LocationReadOnly', 'Maintenance', 'Driver'];

    (locationVisibleRoles.includes(userRole)) ? setShowLocationsField(true) : setShowLocationsField(false);
    (userRole === 'Driver') ? setDriverRequiredFields(true) : setDriverRequiredFields(false);
  }, [userRole]);

  const handleFirstNameChange = (event) => {
    setFirstNameTouched(true);
    setFirstName(event.target.value);
  };

  const handleLastName = (event) => {
    setLastNameTouched(true);
    setLastName(event.target.value);
  };

  const handleEmail = (event) => {
    setEmailTouched(true);
    setEmail(event.target.value);
  };

  const handleUserName = (event) => {
    setUsernameTouched(true);
    setUserName(event.target.value);
  };

  const handlePassword = (event) => {
    setPasswordTouched(true);
    setPassword(event.target.value);
  };

  const submitDetails = () => {
    const newDataItem = {};
    newDataItem['userRole'] = userRole;
    newDataItem['firstName'] = firstName;
    newDataItem['lastName'] = lastName;
    newDataItem['email'] = email;
    newDataItem['userName'] = userName;
    newDataItem['locations'] = locationsSelectedId;
    newDataItem['password'] = password;
    props.onValueChange(newDataItem)
  }

  const handleChangeLocations = (event) => {
    setLocationsTouched(true);
    const {
      target: { value },
    } = event;
    setLocationsSelected(value);
  };

  const getStyles = (name, personName, theme) => {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250
      },
    },
  };

  return (
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { marginTop: '20px', width: WIDTH_FIELDS, boxShadow: 'none' },
      }}
      noValidate
    >
      {userRoleData && <FormControl sx={{ width: WIDTH_FIELDS }}>
        <InputLabel id="demo-simple-select-helper-label">User Role*</InputLabel>
        <Select
          labelId="customer-name-label"
          id="customer-name"
          value={userRole || ''}
          onChange={(e) => setUserRole(e.target.value)}
        >
          {userRoleData.map((item, index) => (
            <MenuItem key={index} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>}
      <TextField
        id="outlined-name"
        label="First Name*"
        value={firstName || ''}
        onChange={handleFirstNameChange}
        error={firstNameTouched && error1}
        helperText={"Hint: Enter first name"}
        style={{ backgroundColor: '#fff' }}
      />
      <TextField
        id="outlined-name"
        label="Last Name*"
        value={lastName || ''}
        onChange={handleLastName}
        error={lastNameTouched && error2}
        helperText={"Hint: Enter last name"}
        style={{ backgroundColor: '#fff' }}
      />
      <TextField
        id="outlined-name"
        label={driverRequiredFields ? "Email" : "Email*"}
        value={email || ''}
        onChange={handleEmail}
        error={driverRequiredFields ? false : (emailTouched && error3)}
        helperText={"Hint: Enter email here"}
        style={{ backgroundColor: '#fff' }}
      />
      <TextField
        id="outlined-name"
        label="Username*"
        value={userName || ''}
        onChange={handleUserName}
        error={usernameTouched && error4}
        helperText={"Hint: Enter username here"}
        style={{ backgroundColor: '#fff' }}
      />
      <TextField
        id="outlined-name"
        label={driverRequiredFields ? "Password" : "Password*"}
        value={password || ''}
        onChange={handlePassword}
        error={driverRequiredFields ? false : (passwordTouched && error5)}
        helperText={props.passwordHint}
        style={{ backgroundColor: '#fff' }}
      />
      {showLocationsField && <FormControl sx={{ width: WIDTH_FIELDS, marginTop: '10px' }}>
        <InputLabel id="demo-multiple-chip-label">Locations*</InputLabel>
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          value={locationsSelected}
          onChange={handleChangeLocations}
          error={setLocationsTouched && error6}
          input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}>
          {names.map((item) => (
            <MenuItem
              key={item}
              value={item}
              style={getStyles(item, locationsSelected, theme)}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>}
      <div style={{ marginLeft: '70%', marginTop: '20px' }}>
          <Button variant="contained" disabled={touched}
            onClick={submitDetails}
          >Save</Button>
        </div>
    </Box>
  );
};

export default FloatingInputField;