import React from 'react';
import * as LiftActions from '../../../store/lift/lift.actions'
import moment from 'moment'
import DuoLiftMap from '../DuoLiftMap/DuoLiftMap.js'
import { connect } from 'react-redux'


class DuoLiftDetailsPortal extends React.Component {

   interval = null;
   is_mounted = true;
   refreshDataRetrieved = false;
  
   componentDidMount() {
    this.interval = setInterval(this.selectDevice, 60000)
    this.refreshDataRetrieved = false
  }

  componentWillUnmount() {
    clearInterval(this.interval)
    this.is_mounted = false
    this.refreshDataRetrieved = false
  }

  selectDevice = () => {
    if (this.props.selectedDevice !== null) {
      let currentTime = moment(this.props.selectedDevice.clientLastUpdated)
      let end = moment(this.props.selectedDevice.lastHeartbeat +'Z'); 
      let duration = moment.duration(currentTime.diff(end));
      let connected = duration.asMinutes() <= 5;
      if (connected) {
        const deivceID = this.props.selectedDevice.liftID
        const loggedInUserIDToken = localStorage.getItem("authToken");
        this.refreshDataRetrieved = true
        this.props.updateRefreshDataFlag(true)
        this.props.updateStopSelectedFlag(false)
        this.props.updateAlertSelectedFlag(false)
        // this.props.fetchDeviceDetails(deivceID, loggedInUserIDToken);
        if (this.props.userSelectionAlerts !== null) {
          var startDate = ""
          var endDate = ""
          this.props.updateUserAlertSelection(this.props.userSelectionAlerts)
          if (this.props.userSelectionAlerts === 1) {
             startDate = this.getCurrentDate()
     
             } else if (this.props.userSelectionAlerts === 7) {
                 startDate = this.getBeforeDate(this.props.userSelectionAlerts)
                 endDate = this.getCurrentDate()
     
             } else if (this.props.userSelectionAlerts === 30) {
                 startDate = this.getBeforeDate(this.props.userSelectionAlerts)
                 endDate = this.getCurrentDate()
     
             } else if (this.props.userSelectionAlerts === 365) {
                 startDate = this.getBeforeDate(this.props.userSelectionAlerts)
                 endDate = this.getCurrentDate()
             }
          this.props.fetchAlertPareto(loggedInUserIDToken, this.props.selectedLocationListData.locationId, startDate, endDate);
       } else {
          this.props.updateUserAlertSelection(30)
          this.props.fetchAlertPareto(loggedInUserIDToken, this.props.selectedLocationListData.locationId, this.getThirtyDaysBeforeDate(), this.getCurrentDate());
       }
        this.props.fetchStopBiometrics(deivceID, this.getCurrentDate(), loggedInUserIDToken)
        if (this.props.mapDateItemsValue.start !== undefined && this.props.mapDateItemsValue.start !== null) {
          let newStartDate = this.getUserSelectedDate(this.props.mapDateItemsValue.start)
          this.props.fetchDeviceDetails(deivceID, loggedInUserIDToken, newStartDate);
          this.props.fetchDeviceAlertTrail(deivceID, newStartDate, loggedInUserIDToken);
          this.props.fetchDeviceAlertHistory(deivceID, newStartDate, loggedInUserIDToken);
          this.props.fetchDeviceBreadcrumbHistory(deivceID, newStartDate, loggedInUserIDToken);
          this.props.fetchDeviceStopHistory(deivceID, newStartDate, loggedInUserIDToken);
        } else {
          this.props.fetchDeviceDetails(deivceID, loggedInUserIDToken, this.getCurrentDate());
          this.props.fetchDeviceAlertTrail(deivceID, this.getCurrentDate(), loggedInUserIDToken);
          this.props.fetchDeviceAlertHistory(deivceID, this.getCurrentDate(), loggedInUserIDToken);
          this.props.fetchDeviceBreadcrumbHistory(deivceID,  this.getCurrentDate(), loggedInUserIDToken);
          this.props.fetchDeviceStopHistory(deivceID, this.getCurrentDate(), loggedInUserIDToken);
        }
      } else {
        this.refreshDataRetrieved = false
        this.props.updateRefreshDataFlag(false)
        this.props.updateStopSelectedFlag(false)
        this.props.updateAlertSelectedFlag(false)
      }
    }
  }

  getBeforeDate(daysBack) {
    let newDate = new Date()
    newDate.setDate(newDate.getDate() - daysBack);
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    let day = newDate.getDate();
    let requireDate = year + '-' + month + '-' + day;
    return requireDate
  }

  getUserSelectedDate(selectedValue) {
    let newDate = selectedValue
    if (newDate != null) {
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        let day = newDate.getDate();
        let requireDate = year + '-' + month + '-' + day;
        console.log(requireDate)
        return requireDate
    } else {
        return null
    }
 }

 getCurrentDate() {
  let newDate = new Date()
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();
  let day = newDate.getDate();
  let requireDate = year + '-' + month + '-' + day;
  return requireDate
}
  
  getThirtyDaysBeforeDate() {
    let newDate = new Date()
    newDate.setDate(newDate.getDate() - 30);
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    let day = newDate.getDate();
    let requireDate = year + '-' + month + '-' + day;
    return requireDate
  }
  render() {

    let device = this.props.selectedDevice;
    let deviceList = this.props.devices;
    if (device == null)
      return null;
    if (this.is_mounted && device != null) {
      return (
        <div>
          <DuoLiftMap deviceList={deviceList} latitude={device.lat} longitude={device.long} alerts={this.props.selectedAlertTrail} breadcrumbs={this.props.selectedDeviceBreadcrumbs} stops={this.props.selectedDeviceStopHistory} refreshDataRequested={this.props.refreshDataRequested}></DuoLiftMap>
        </div>
      )
    }
  }
}

const mapStateToProps = (state) => {
  return {
    selectedDevice: state.selectedDevice,
    devices: state.devices,
    refreshDataRequested: state.refreshDataRequested,
    mapDateItemsValue: state.mapDateItemsValue,
    selectedDeviceAlertHistory: state.selectedDeviceAlertHistory,
    selectedDeviceBreadcrumbs: state.selectedDeviceLocationBreadcrumbs,
    selectedDeviceStopHistory: state.selectedDeviceStopHistory,
    selectedAlertTrail: state.selectedAlertTrail,
    geocoder: state.geocoder,
    selectedLocationListData: state.selectedLocationListData,
    userSelectionAlerts: state.fleetAlertSelection,
  }
}

const mapDispatchToProps = (dispatch) =>{
  return {
     updateRefreshDataFlag: (refreshDataRequested) => dispatch(LiftActions.UPDATE_REFRESH_DATA_REQUESTED_FLAG(refreshDataRequested)),
     updateStopSelectedFlag: (stopSelectedFlag) => dispatch(LiftActions.UPDATE_USER_SELECTED_STOP_DASHBOARD_FLAG(stopSelectedFlag)),
     updateAlertSelectedFlag: (alertSelectedFlag) => dispatch(LiftActions.UPDATE_USER_SELECTED_ALERT_DASHBOARD_FLAG(alertSelectedFlag)),
     fetchDeviceDetails: (id, userToken, startDate) => dispatch(LiftActions.LOAD_LIFT_DETAIL(id, userToken, startDate)),
     fetchDeviceAlertHistory: (id,startDate, userToken) => dispatch(LiftActions.LOAD_LIFT_ALERT_HISTORY(id, startDate, userToken)),
     fetchDeviceBreadcrumbHistory: (id, startDate, userToken) => dispatch(LiftActions.LOAD_LIFT_BREADCRUMB_HISTORY(id, startDate, userToken)),
     fetchDeviceStopHistory: (id, startDate, userToken) => dispatch(LiftActions.LOAD_LIFT_STOPS_HISTORY_TYPE(id, startDate, userToken)),
     fetchDeviceAlertTrail: (id, startDate, userToken) => dispatch(LiftActions.LOAD_LIFT_ALERT_TRAIL_TYPE(id, startDate, userToken)),
     fetchAlertPareto: (userToken, locationid, startdate, enddate) => dispatch(LiftActions.LOAD_ALERT_PARETO(userToken, locationid, startdate, enddate)),
     fetchStopBiometrics: (id, date, userToken) => dispatch(LiftActions.LOAD_LIFT_STOP_BIOMETRICS(id, date, userToken)),
     updateUserAlertSelection: (alertType) => dispatch(LiftActions.UPDATE_FLEET_COMPARISION_SELCTION(alertType)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DuoLiftDetailsPortal);
