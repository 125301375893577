import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import DashboardPortal from "./components/DashboardPortal/DashboardPortal";
import { I18n, Auth, Hub } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import { Box, Button } from "@mui/material";
import RehrigLogo from "./assets/rehrig_logo.svg";
import '@aws-amplify/ui-react/styles.css';
import "./styles.scss";
import NotificationService from './components/tools/NotificationService/NotificationService'

const App = () => {
  const [user, setUser] = useState(null);
  const [customState, setCustomState] = useState(null);

  useEffect(() => {
    const unsubscribe = Hub.listen("auth", ({ payload: { event, data } }) => {
      console.log("data",data)
      switch (event) {
        case "signIn":
          const loggedInUserIDToken = data.signInUserSession?.accessToken.jwtToken;
          const loggedInUserID = data.attributes?.[`custom:central_user_id`];
          const customer_role_id = data.attributes?.[`custom:central_role_id`];
          const userName = data.attributes?.preferred_username;
          localStorage.setItem('authToken', loggedInUserIDToken);
          localStorage.setItem('userRoleId', customer_role_id);
          localStorage.setItem('userName', userName);
          localStorage.setItem('loggedInUserID',loggedInUserID)
          setUser(data);
          break;
        case "signOut":
          setUser(null);
          break;
        case "customOAuthState":
          setCustomState(data);
      }
    });

    Auth.currentAuthenticatedUser()
      .then(currentUser => setUser(currentUser))
      .catch((response) => console.log('Not signed in'));
    return unsubscribe;
  }, []);

  return (
    <div className={!user ? 'loginPage' : ""}>
      <Authenticator slot="sign-in" hideSignUp components={components} formFields={formFields}>
        <BrowserRouter>
          {user && (
            <Switch>
              <Route exact path="/" component={DashboardPortal} />
              <Route exact path="/dashboard" component={DashboardPortal} />
            </Switch>
          )}
        </BrowserRouter>
        <NotificationService />
      </Authenticator>
      {!user ?
        <div className='sso-cont'>
          <Button className='SSO-button' onClick={() => Auth.federatedSignIn({ provider: "rpcidentityprovider" })}>Rehrig Employee? for SSO link Click Here</Button>
        </div>
        : ""
      }
    </div>
  );
};

const formFields = {
  signIn: {
    username: {
      labelHidden: false,
      placeholder: "Username",
      isRequired: true,
      label: "Username",
    },
    password: {
      labelHidden: false,
      placeholder: "Password",
      isRequired: true,
      label: "Password",
    },
  },
};

const components = {
  Header() {
    return (
      <Box
        sx={{
          background: "#44B1D0",
          display: "grid",
          placeContent: "center",
          padding: "40px"
        }}
      >
        <img alt="Rehrig" src={RehrigLogo} />
      </Box>
    );
  },
};

I18n.putVocabulariesForLanguage("en", {
  "Sign In": "Login", // Tab header
  "Sign in": "Login", // Button label
  "Signing in": "Signing In",
  "Forgot your password?": "Forgot Password?",
});

export default App;