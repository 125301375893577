import React from 'react';
import { Box, Paper, Pagination, TableContainer, TablePagination, Typography } from "@mui/material";
import Table from "../Table/Table"
import { connect } from 'react-redux'
import {USER_PROFILE_TABLE_HEAD, CUSTOMER_DETAILS_TABLE_HEAD, LOCATION_DETAILS_TABLE_HEAD} from "../../data/tableHeadData.js"

const UserProfile = () => { 
    return (
        <div style={{ width: "100%" }}>
            <Typography sx={styles.heading}>User Profile</Typography>
            <Table
                tableHeadItems={USER_PROFILE_TABLE_HEAD}
                tableBodyData={""}
                tableName="userProfile"
            />
            <Typography sx={styles.heading}>Customer Details</Typography>
            <Table
                tableHeadItems={CUSTOMER_DETAILS_TABLE_HEAD}
                tableBodyData={""}
                tableName="customerDetails"
            />
            <Typography sx={styles.heading}>Location Details</Typography>
            <Table
                tableHeadItems={LOCATION_DETAILS_TABLE_HEAD}
                tableBodyData={""}
                tableName="locationDeatails"
            />
        </div>
    )
}

export const styles = {
    heading: {
        color: "#303030",
        fontSize: "24px",
        fontWeight: 700,
    }
}

export default connect(null, null)(UserProfile);
