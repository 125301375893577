import React from 'react'
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux'
import * as LiftActions from '../../store/lift/lift.actions'
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PieChartIcon from '@mui/icons-material/PieChart';
import GroupIcon from '@mui/icons-material/Group';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';

class DashboardNavMenu extends React.Component {

  state = {
    selectedBoard: this.props.selectedBoard,
    showPopup: this.props.showNavMenu,
  }

  handleSelectedBoardChange = (event) => {
    let test = this.state.showPopup;
    test = !test;
    this.setState ({
      selectedBoard: event.target.innerText,
      showPopup: test,
    });
    this.props.updateNavMenuType(test);
    this.props.updateSelectedBoardType(event.target.innerText);
  }

  render() {
    this.state.selectedBoard = this.props.selectedBoard; 
    this.state.showPopup = this.props.showNavMenu;
    let isCustomerFleetUser = this.props.isCustomerFleetUser
    if (this.state.showPopup) {
      var nonparent = document.getElementById(this.props.anchor);

      let popOverMenuForUser;

      if (isCustomerFleetUser) {
        popOverMenuForUser = 
        <div>
        <div>
          <Link className="k-button k-button-flat" to="/Dashboard" style={{ display: "table" , color: "darkblue"}}>
          {/* onClick={this.props.onclick} */}
            <div className="serviceIndicatorFlex">
              <PieChartIcon />
              <span className="serviceIndicatorText" onClick={this.handleSelectedBoardChange}>Fleet Comparison</span>
            </div>
          </Link>
          <hr style={{ marginRight: '10%', marginBlockStart: '0px' }} />
        </div>
        </div>

      } else {
        popOverMenuForUser =   <div>
        <div>
          <Link className="k-button k-button-flat" to="/Dashboard" style={{ display: "table", color: "darkblue" }}>
          {/* onClick={this.props.onclick} */}
            <div className="serviceIndicatorFlex">
             <VisibilityIcon />
              <span className="serviceIndicatorText" onClick={this.handleSelectedBoardChange}>Lift Details</span>

            </div>
          </Link>
          <hr style={{ marginRight: '10%', marginBlockStart: '0px' }} />
          <Link className="k-button k-button-flat" to="/Dashboard" style={{ display: "table" , color: "darkblue"}}>
          {/* onClick={this.props.onclick} */}
            <div className="serviceIndicatorFlex">
              <PieChartIcon />
              <span className="serviceIndicatorText" onClick={this.handleSelectedBoardChange}>Fleet Comparison</span>
            </div>
          </Link>
          <hr style={{ marginRight: '10%', marginBlockStart: '0px' }} />
          {/* <Link className="k-button k-button-flat" to="/Dashboard" style={{ display: "table" }}> */}
          {/* onClick={this.props.onclick} */}
            {/* <div className="serviceIndicatorFlex"> */}
              {/* <span style={{ fontSize: '24px' }} className="k-icon k-i-saturation"></span> */}
              {/* <span className="serviceIndicatorText" onClick={this.handleSelectedBoardChange}>Stop Analytics</span> */}
            {/* </div> */}
          {/* </Link> */}
          {/* <hr style={{ marginRight: '10%', marginBlockStart: '0px' }} /> */}
          <Link className="k-button k-button-flat" to="/Dashboard" style={{ display: "table", color: "darkblue" }}>
          {/* onClick={this.props.onclick} */}
            <div className="serviceIndicatorFlex">
              <GroupIcon />
              <span className="serviceIndicatorText" onClick={this.handleSelectedBoardChange}>Customer Management</span>
            </div>
          </Link>
          <hr style={{ marginRight: '10%', marginBlockStart: '0px' }} />
          <Link className="k-button k-button-flat" to="/Dashboard" style={{ display: "table" , color: "darkblue"}}>
          {/* onClick={this.props.onclick} */}
            <div className="serviceIndicatorFlex">
            <SupervisedUserCircleIcon />
              <span className="serviceIndicatorText" onClick={this.handleSelectedBoardChange}>User Management</span>
            </div>
          </Link>
          <hr style={{ marginRight: '10%', marginBlockStart: '0px' }} />
          <Link className="k-button k-button-flat" to="/Dashboard" style={{ display: "table" , color: "darkblue"}}>
          {/* onClick={this.props.onclick} */}
            <div className="serviceIndicatorFlex">
              <LocalShippingIcon />
              <span className="serviceIndicatorText" onClick={this.handleSelectedBoardChange}>Lift Management</span>
            </div>
          </Link>
          <hr style={{ marginRight: '10%', marginBlockStart: '0px' }} />
        </div>
      </div>
      }

      return (<>
            <Modal
              open={this.state.showPopup}
              onClose={this.handleSelectedBoardChange}
               aria-labelledby="parent-modal-title"
              aria-describedby="parent-modal-description">
      <div className="navMenu" style={{ top: nonparent.offsetTop - 25, left: nonparent.offsetLeft + 35 }}>
          <div className="modularDivScale">
            <div style={{ width: '100%', display: "flex", justifyContent: "end" }}>
            <Button className="navButton" startIcon={<CloseIcon />} variant={this.state.alertButtonVariant} size="large" style={{justifyContent: "end"}} onClick={this.handleSelectedBoardChange}>
            </Button>
            </div>
          </div>
          <div className="duolift-device-detail-card-body">
           {popOverMenuForUser} 
          </div>
        </div>
      </Modal>
     </>
      )
    }
    return null;

  }
}

const mapStateToProps = (state) => {
  return {
    isCustomerFleetUser: state.isCustomerFleetUser,
    selectedBoard: state.selectedBoard,
    showNavMenu: state.showNavMenu,
  }
}

const mapDispatchToProps = (dispatch) =>{
  return {
     updateSelectedBoardType: (selectedType) => dispatch(LiftActions.LOAD_DASHBOARD_TYPE_UPDATE(selectedType)),
     updateNavMenuType: (showNavMenu) => dispatch(LiftActions.LOAD_NAVMENU_TYPE_UPDATE(showNavMenu))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DashboardNavMenu));

