import * as React from "react";
import Button from '@mui/material/Button';

export const MyCommandCell = (props) => {

  return ( 
      <Button  variant="contained" onClick={() => props.onClick(props)}>
        {props.name}
      </Button>
  );
};

export default MyCommandCell;