import HomeIcon from "../assets/homeIcon.svg";
import UserIcon from "../assets/userIcon.svg";
import ToolIcon from "../assets/toolIcon.svg";
import ManageAccount from "../assets/manage_accounts.svg";
import MapCenter from "../assets/explore.svg";
import Reports from "../assets/report.svg";
import Analytics from "../assets/analytics.svg";
import ImportAssets from "../assets/import_export.svg";
import Support from "../assets/support.svg";
import Notification from "../assets/notification.svg";
import LiftMgmt from "../assets/lift_mgmt.svg"

export const NavLinks = [
    {
        icon: HomeIcon,
        link: "/Dashboard",
        text: "Asset Management IOT",
    },
    // {
    //     icon: UserIcon,
    //     link: "/Dashboard",
    //     text: "User Mgmt",
    // },
    // {
    //     icon: LiftMgmt,
    //     link: "/Dashboard",
    //     text: "Lift Mgmt",
    // },
    // {
    //     icon: ToolIcon,
    //     link: "/Dashboard",
    //     text: "Hardware Mgmt",
    // },
    {
        icon: ToolIcon,
        link: "/Dashboard",
        text: "Lost Assets Mgmt",
    }
];

export const AMNavLinks = [
    {
        icon: HomeIcon,
        link: "/Dashboard",
        text: "Asset Management IOT",
    },
    // {
    //     icon: UserIcon,
    //     link: "/Dashboard",
    //     text: "User Mgmt",
    // },
    // {
    //     icon: ToolIcon,
    //     link: "/Dashboard",
    //     text: "Hardware Mgmt",
    // },
    {
        icon: ToolIcon,
        link: "/Dashboard",
        text: "Lost Assets Mgmt",
    }
];

export const DuoNavLinks = [
    {
        icon: HomeIcon,
        link: "/Dashboard",
        text: "Delivery IOT",
    },
    // {
    //     icon: UserIcon,
    //     link: "/Dashboard",
    //     text: "User Mgmt",
    // },
    // {
    //     icon: LiftMgmt,
    //     link: "/Dashboard",
    //     text: "Lift Mgmt",
    // },
];



export const NavLinkSubItems = [
    // {
    //     icon: ManageAccount,
    //     link: "/Dashboard",
    //     text: "Manage Account",
    // },
    {
        icon: MapCenter,
        link: "/Dashboard",
        text: "Map Center",
    },
    {
        icon: Reports,
        link: "/Dashboard",
        text: "Reports",
    },
    {
        icon: Analytics,
        link: "/Dashboard",
        text: "Analytics",
    },
    // {
    //     icon: ImportAssets,
    //     link: "/Dashboard",
    //     text: "Import Assets",
    // },
    // {
    //     icon: Notification,
    //     link: "/Dashboard",
    //     text: "Notifications",
    // },
    // {
    //     icon: Support,
    //     link: "/Dashboard",
    //     text: "Support",
    // },
];

export const AMNavLinkSubItems = [
    // {
    //     icon: ManageAccount,
    //     link: "/Dashboard",
    //     text: "Manage Account",
    // },
    {
        icon: Reports,
        link: "/Dashboard",
        text: "Reports",
    },
    // {
    //     icon: Analytics,
    //     link: "/Dashboard",
    //     text: "Analytics",
    // },
    // {
    //     icon: ImportAssets,
    //     link: "/Dashboard",
    //     text: "Import Assets",
    // },
    // {
    //     icon: Notification,
    //     link: "/Dashboard",
    //     text: "Notifications",
    // },
    // {
    //     icon: Support,
    //     link: "/Dashboard",
    //     text: "Support",
    // },
];

export const DuoNavLinkSubItems = [
    {
        icon: MapCenter,
        link: "/Dashboard",
        text: "Map Center",
    },
    {
        icon: Analytics,
        link: "/Dashboard",
        text: "Analytics",
    },
    // {
    //     icon: ImportAssets,
    //     link: "/Dashboard",
    //     text: "Import Assets",
    // },
    // {
    //     icon: Notification,
    //     link: "/Dashboard",
    //     text: "Notifications",
    // },
    // {
    //     icon: Support,
    //     link: "/Dashboard",
    //     text: "Support",
    // },
];

export const ReportSubItems = [
    // {
    //     icon: ToolIcon,
    //     link: "/Dashboard",
    //     text: "Asset Status Details",
    // },
    // {
    //     icon: ToolIcon,
    //     link: "/Dashboard",
    //     text: "Float Volume",
    // },
    {
        icon: ToolIcon,
        link: "/Dashboard",
        text: "Lost Assets",
    },
    {
        icon: ToolIcon,
        link: "/Dashboard",
        text: "Store Location Assets",
    }
]