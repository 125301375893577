import React from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';

const FleetComparisionPieChartTable = (props) => {
  let liftData = props.liftData
  const showBoxForColor = (title) => {
    if (title != undefined) {
      if (title == "Lifts no maintenance required") {
        return <div className="box" style={{ backgroundColor: "#3B9C57", marginTop: "15px" }}></div>
      } else if (title == "Lifts with upcoming maintenance") {
        return <div className="box" style={{ backgroundColor: "#F19902", marginTop: "15px" }}></div>
      } else if (title == "Lifts with maintenance past due") {
        return <div className="box" style={{ backgroundColor: "#DB2D2E", marginTop: "15px" }}></div>
      }
    }
  }
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 300 }} aria-label="simple table">
        <TableBody>
          {liftData.map((lift) => (
            <TableRow
              key={lift.title}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="right">{showBoxForColor(lift.title)}</TableCell>

              <TableCell component="th" scope="row">
                {lift.title}
              </TableCell>
              <TableCell align="right">{lift.value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

}

export default FleetComparisionPieChartTable;