import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import DuoLiftStatusTab from '../DuoLiftRightPanelCard/DuoLiftStatusTab/DuoLiftStatusTab.js'
import DuoLiftAlertsTab from '../DuoLiftRightPanelCard/DuoLiftAlertsTab/DuoLiftAlertsTab.js'
import DuoLiftStopsTab from '../DuoLiftRightPanelCard/DuoLiftStopsTab/DuoLiftStopsTab.js'
import DuoLiftServiceItemModal from '../Cards/DuoLiftServiceItems/DuoLiftServiceItemModal/DuoLiftServiceItemModal.js'
import * as LiftActions from '../../../store/lift/lift.actions';
import WifiIcon from '@mui/icons-material/Wifi';
import WifiOffIcon from '@mui/icons-material/WifiOff';
class DuoLiftRightPanelCard extends React.Component {
    state = {
        currentLocation: "Unable to find geocoded address",
        currentLatitude: null,
        currentLongitude: null,
        showStatusTab: true,
        showAlertsTab: false,
        showStopTab: false,
        statusButtonVariant: "contained",
        alertButtonVariant: "",
        stopsButtonVariant: "",
        collapsePanel: false,
    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }
    SelectBatteryImage(batteryLevel) {
        if (batteryLevel === 100)
            return "fa-solid fa-battery-full fa-lg";
        else if (batteryLevel >= 65 && batteryLevel < 100)
            return "fa-solid fa-battery-three-quarters fa-lg";
        else if (batteryLevel >= 35 && batteryLevel < 65)
            return "fa-solid fa-battery-half fa-lg";
        else if (this.batteryLevel > 0 && batteryLevel < 35)
            return "fa-solid fa-battery-quarter fa-lg";
        else
            return "fa-solid fa-battery-empty fa-lg";

    }
    SelectServiceImage(serviceNumber) {
        if (serviceNumber === 1) {
            return "fa-solid fa-wrench";
        } else if (serviceNumber === 2) {
            return "fa-solid fa-wrench";
        }
    }

    ServiceStatusText(serviceNumber) {
        if (serviceNumber === 1) {
            return 'UPCOMING SERVICE REQUIRED';
        } else if (serviceNumber === 2) {
            return 'SERVICE PAST DUE';
        }
    }

    serviceItemBackgroundColor(serviceNumber) {
        if (serviceNumber === 1) {
            return '#FB9700';
        } else if (serviceNumber === 2) {
            return '#DB2D2D';
        }
    }

    setConnectedImage = (clientLastUpdated, lastHeartbeat) => {
        let currentTime = moment(clientLastUpdated)
        let end = moment(lastHeartbeat +'Z'); 
        let duration = moment.duration(currentTime.diff(end));
        let connected = duration.asMinutes() <= 5;
        return connected;
    }

    setAlertImage(alertCount) {
        if (alertCount != null) {
            if (alertCount > 0) {
                return "fa-regular fa-triangle-exclamation fa-lg"
            } else {
                return null
            }
        } else {
            return null
        }
    }

    handleStatusTapped = () => {
        this.setState({
            showStatusTab: true,
            showAlertsTab: false,
            showStopTab: false,
            statusButtonVariant: "contained",
            alertButtonVariant: "",
            stopsButtonVariant: "",
        })
    }

    handleAlertsTapped = () => {
        this.setState({
            showStatusTab: false,
            showAlertsTab: true,
            showStopTab: false,
            statusButtonVariant: "",
            alertButtonVariant: "contained",
            stopsButtonVariant: "",
        })
    }

    handleStopsTapped = () => {
        this.setState({
            showStatusTab: false,
            showAlertsTab: false,
            showStopTab: true,
            statusButtonVariant: "",
            alertButtonVariant: "",
            stopsButtonVariant: "contained",
        })
    }

    hadleExpandPanel = () => {
        this.setState({
            collapsePanel: !this.state.collapsePanel,
        })
        this.props.updateRightPanelCollapse(!this.state.collapsePanel)
    }

    showLiftPopupWithData = (event) => {
        // this.setState({
        //   popUpData: event
        // }) 
        let default_state = "Unable to find geocoded address"
        if (this.props.geocoder != null) {
            if ((event.lat == null
                || event.long == null)
                && this.state.currentLocation !== default_state) {
                this.setState({ currentLocation: default_state });

            } else {
                if (this.state.currentLatitude !== event.lat || this.state.currentLongitude !== event.long) {
                    this.props.geocoder.geocode({ location: { lat: event.lat !== null ? parseFloat(event.lat) : 50.25, lng: event.long !== null ? parseFloat(event.long): 50.25 } }, (results, status) => {
                        if (status === "OK") {
                            this.setState({ currentLocation: results[0].formatted_address, currentLatitude: event.lat, currentLongitude: event.long });
                        }
                    })
                }
            }
        }
    }

    render() {
        let selectedDevice = this.props.selectedDevice
        let selectedLiftLocation = this.props.selectedLiftLocation
        let stopBio = this.props.selectedLiftStopBiometrics
        let alertInfo = this.props.selectedLiftAlerts
        let stopsData = null;
        let alertData = null;
        if (alertInfo != null) {
            alertData = alertInfo
        } else {
            alertData = null
        }
        if (stopBio != null) {
            stopsData = stopBio
        } else {
            stopsData = null
        }

        if (selectedDevice !== undefined) {
            let connectedStatusImage = this.setConnectedImage(Date.now(), selectedDevice.lastHeartbeat)
            let batteryImage = this.SelectBatteryImage(selectedDevice.batteryPercent)
            let serviceImage = this.SelectServiceImage(selectedDevice.maintState)
            let serviceStatus = this.ServiceStatusText(selectedDevice.maintState)
            let alertImage = this.setAlertImage(selectedDevice.alertCount)
            this.showLiftPopupWithData(selectedDevice)
            let serviceItemBackgroundColor = this.serviceItemBackgroundColor(selectedDevice.maintState)
            if (this.state.collapsePanel) {
                return (
                    <div style={{ width: "70px", height: "50px" }}>
                        <div>
                            <IconButton onClick={this.hadleExpandPanel} aria-label="delete" size="large">
                                <KeyboardDoubleArrowLeftIcon />
                            </IconButton>
                            <Typography gutterBottom variant="subtitle1" component="h2" className="app-name-font">
                                LIFT
                            </Typography>
                            <Typography gutterBottom variant="subtitle1" component="h2" className="app-name-font">
                                DETAILS
                            </Typography>
                        </div>
                    </div>
                );
            } else {
                return (
                    <div style={{ width: "30%", height: "800px" }}>
                        <div>
                            <IconButton onClick={this.hadleExpandPanel} aria-label="delete" size="large">
                                <KeyboardDoubleArrowRightIcon />
                            </IconButton>
                        </div>
                        <Card sx={{ marginLeft: 0, marginRight: "0px", marginTop: "0px", height: "800px", width: "100%", overflow: "auto" }}>
                            <CardContent>
                                <div className="duoLiftRightPanelTopSection">
                                    {selectedDevice.routeName ?
                                        <div className="dashboard-card-header">
                                            <div style={{ fontSize: '16px', fontWeight: 'bold' }}>ROUTE: {selectedDevice.routeName}</div>
                                        </div> : null}
                                    <div className="dashboard-card-header">
                                        <div>
                                            <span style={{ fontSize: '16px', fontWeight: selectedDevice.routeName ? 'normal' : 'bold' }}>DUO LIFT: {selectedDevice.liftID}</span>
                                        </div>
                                        {connectedStatusImage ? <WifiIcon style={{ color: "green", marginTop: "10px", marginRight: "20px" }} />
                                            : <WifiOffIcon style={{ color: "gray", marginTop: "10px", marginRight: "20px" }} />}
                                    </div>
                                    <div className="liftCustomerNameFlex"> {selectedDevice.customerName} </div>
                                    {/* <div className="serviceIndicatorFlexForPanel" style={{ backgroundColor: "gray" }}>
                                        <span className="serviceIndicatorText">Service Coming Soon</span>
                                    </div> */}
                                    {/* {serviceImage ? 
                        <div className="serviceIndicatorFlexForPanel" style={{backgroundColor: serviceItemBackgroundColor}}>
                        {serviceImage ? <i className={serviceImage} style={{marginLeft: "10px"}}></i> : null} 
                        <span className="serviceIndicatorText" style={{color: 'white'}}>{serviceStatus}</span>
                         </div> : null} */}
                                    <div className="liftLocationFlexForRightPanel"> {this.state.currentLocation} </div>
                                    <div className="duoLiftRightPanelTabHeader">
                                        <Button variant={this.state.statusButtonVariant} size="small" style={{ marginRight: "10px" }} onClick={this.handleStatusTapped}>
                                            STATUS
                                        </Button>
                                        <Button variant={this.state.alertButtonVariant} size="small" style={{ marginRight: "10px" }} onClick={this.handleAlertsTapped}>
                                            ALERTS
                                        </Button>
                                        <Button variant={this.state.stopsButtonVariant} size="small" onClick={this.handleStopsTapped}>
                                            STOPS
                                        </Button>
                                    </div>
                                    <hr style={{ backgroundColor: "black", height: 2, width: "100%", marginTop: "0px" }}></hr>
                                    <div>
                                        {this.state.showStatusTab &&
                                            <DuoLiftStatusTab selectedLift={selectedDevice} selectedLiftLocation={selectedLiftLocation}></DuoLiftStatusTab>
                                        }
                                        {this.state.showAlertsTab &&
                                            <DuoLiftAlertsTab selectedLift={selectedDevice} selectedLiftAlerts={alertData}></DuoLiftAlertsTab>
                                        }
                                        {this.state.showStopTab &&
                                            <DuoLiftStopsTab selectedLift={selectedDevice} selectedLiftStopBio={stopsData}></DuoLiftStopsTab>
                                        }
                                    </div>
                                    <DuoLiftServiceItemModal selectedDevice={selectedDevice} maintenanceTasks={selectedDevice.maintType}></DuoLiftServiceItemModal>
                                </div>
                            </CardContent>
                            {/* <CardContent>
                        <Typography variant="body2" color="text.secondary">
                        This impressive paella is a perfect party dish and a fun meal to cook
                        together with your guests. Add 1 cup of frozen peas along with the mussels,
                        if you like.
                        </Typography>
                    </CardContent> */}
                        </Card>
                    </div>
                );
            }
        } else {
            return null
        }
    }
}

const mapStateToProps = (state) => {
    return {
        selectedDevice: state.selectedDevice,
        selectedLiftLocation: state.selectedLiftLocation,
        liftList: state.devices,
        geocoder: state.geocoder,
        selectedLiftStopBiometrics: state.selectedLiftStopBiometrics,
        selectedLiftAlerts: state.selectedDeviceAlertHistory,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        updateRightPanelCollapse: (selection) => dispatch(LiftActions.UPDATE_RIGHT_PANEL_EXPAND_AND_COLLAPSE(selection))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DuoLiftRightPanelCard);