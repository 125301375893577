import React, { useEffect, useState, setState } from 'react';
import { useIdleTimer } from "react-idle-timer";
import { Auth } from "aws-amplify";
import {fetchUserAccessLevel} from "../../services/duo-lift.service"
import { Box, Button, CssBaseline, Typography } from '@mui/material';
import DashboardHeader from '../DashboardHeader/DashboardHeader.js'
import DuoLiftList from '../LiftList/DuoLiftList/DuoLiftList.js'
import DuoLiftDetailsPortal from '../LiftDetailsPortal/DuoLiftDetailsPortal/DuoLiftDetailsPortal.js'
import FleetComparisionPortal from '../FleetComparisionPortal/FleetComparisionRightPanel/FleetComparisionPortal'
import { connect } from 'react-redux'
import * as ActionTypes from '../../store/lift/lift.actiontypes'
import * as LiftActions from '../../store/lift/lift.actions'
import IconButton from '@mui/material/IconButton';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import { initialState } from '../../store/lift/lift.reducer.js';
import DashboardNavMenu from '../DashboardNavMenu/DashboardNavMenu.js';
import UserManagementPortal from '../UserManagement/UserManagementPortal/UserManagementPortal.js'
import LiftManagementPortal from '../LiftManagement/LiftManagementPortal/LiftManagementPortal.js'
import HardwareManagementPage from '../HardwareManagement/HardwareManagementPage.js';
import LostScrapManagementPage from '../LostScrapManagement/LostScrapManagementPage.js';
import UserProfile from "../UserProfile/UserProfile.js";
import AssetDetailsPage from '../Reports/AssetDetails/AssetDetailsPage.js';
import StoreDetailsPage from '../Reports/StoreDetails/StoreDetailsPage.js';
import DashboardPage from '../Dashboard/DashboardPage.js';
import { grey } from '@mui/material/colors';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import SideNav from "../SideNav/SideNav.js";
import LostScrapDetailsPage from '../Reports/LostScrap/LostScrapDetailsPage.js';
import { useAuthenticator } from '@aws-amplify/ui-react';
import ShowModal from "../Modal/Modal";

const DashboardPortal = (props) => {
  var isUserLoggedIn = props.isUserLoggedIn;
  let isCustomerFleetUser = props.isCustomerFleetUser
  var userIDToken;
  var selectedBoardType;
  var customerId;
  var locationId;
  var timeoutValue = props.userTokens != undefined && props.userTokens.ExpiresIn;
  var isMapLoaded = props.isMapLoaded;
  var isError = props.isError;
  var resetLogout = props.resetLogout
  const [seconds, setSeconds] = useState(0);
  const [showNavMenu, showNav] = useState(false);
  const [showDashBoard, setShowDashBoard] = useState(true);
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(true);
  const [showUserMnagementPortal, setShowUserMnagementPortal] = useState(false);
  const [showLiftManagementPortal, setShowLiftManagementPortal] = useState(false);
  const [showHardwareManagementPortal, setShowHardwareManagementPortal] = useState(false);
  const [showLostScrapManagementPortal, setShowLostScrapManagementPortal] = useState(false);
  const [showUserProfile, setShowUserProfile] = useState(false);
  const [showAssetDetails, setShowAssetDetails] = useState(false);
  const [showStoreDetailsPage, setShowStoreDetailsPage] = useState(false);
  const [showLostScrapDetails, setShowLostScrapDetails] = useState(false);
  const [showDashBoardPage, setShowDashBoardPage] = useState(false);
  const [showSupportPage, setShowSupportPage] = useState(false);
  const [collapsePanel, setCollapsePanel] = useState(true);
  const [showFleetComparisionPortal, setShowFleetComparisionPortal] = useState(false);
  const [isUserNotValid, setIsUserNotValid] = useState(false);
  const { user } = useAuthenticator((context) => [context.user]);
  const [alertSelection, setAlertSelection] = useState(props.fleetAlertSelection);

  const onLogOut = () => {
    localStorage.clear();
    Auth.signOut()
      .then(() => {
        localStorage.clear();
        window.location.replace('/');
      })
      .catch((err) => {
        console.log("Error on Logout:", err.message);
      });
  }

  const collapasePanel = (value) => {
    setCollapsePanel(value)
  }

  useIdleTimer({
    timeout: 1000 * 60 * 60,
    onIdle: onLogOut,
    debounce: 1000,
  });

  const getCustomerLocationListAndUserRole = async() => {
    const session = await Auth.currentSession();
    const loggedInUserIDToken = session.accessToken.jwtToken;
    localStorage.setItem('authToken', loggedInUserIDToken);
    if(loggedInUserIDToken){
      props.fetchCustomersAndLocations(loggedInUserIDToken);
    }
  }

  const checkCentralUserAuth = async (loggedInUserID) => {
    const session = await Auth.currentSession();
    const loggedInUserIDToken = session.accessToken.jwtToken;
    localStorage.setItem('authToken', loggedInUserIDToken);
    let userData = await fetchUserAccessLevel(loggedInUserIDToken, loggedInUserID);
    let roleName = userData?.Data.role_name;
    let userName = userData?.Data.username;
    const applicationAccess = userData?.Data.applications;
    localStorage.setItem('userRole',roleName);
    localStorage.setItem('userName',userName);
    localStorage.setItem("applicationIds",applicationAccess)
    if(roleName === "RpcAdmin" || roleName === "RpcReadOnly"){
      setIsUserNotValid(false)
    }else if(applicationAccess.includes(4) || applicationAccess.includes(6)){
      setIsUserNotValid(false)
    }else{
      setIsUserNotValid(true)
      setTimeout(() => {
        localStorage.clear();
        Auth.signOut()
          .then(() => {
            window.location.reload();
          })
          .catch((err) => {
            console.log("Error on Logout:", err.message);
          });
          setIsUserNotValid(false);
      },3000);
    }
  }

  useEffect(() => {
      getCustomerLocationListAndUserRole();
  },[])

  useEffect(()=>{
    let loggedInUserID = user.attributes[`custom:central_user_id`];
    checkCentralUserAuth(loggedInUserID);
  },[user])

  useEffect(() => {
    if (props.selectedBoard !== null && props.selectedBoard !== undefined) {
      selectedBoardType = props.selectedBoard
    } else {
      if (props?.selectedCustomerListData) {
        const appIds = localStorage.getItem("applicationIds");
        const userRoleName = localStorage.getItem("userRole");
        if (props.selectedCustomerListData.duo_iot_enabled === true && props.selectedCustomerListData.am_enabled === true &&   (userRoleName === "RpcAdmin" || userRoleName === "RpcReadOnly")) {
          localStorage.setItem('selectedBoard', 'Asset Management IOT');
          props.updateSelectedBoardType('Asset Management IOT');
        }else if(props.selectedCustomerListData.duo_iot_enabled === true && props.selectedCustomerListData.am_enabled === true &&  appIds?.includes(4) && appIds?.includes(6)) {
          localStorage.setItem('selectedBoard', 'Asset Management IOT');
          props.updateSelectedBoardType('Asset Management IOT');
        }else if (props.selectedCustomerListData.duo_iot_enabled === true && (appIds?.includes(4) || userRoleName === "RpcAdmin" || userRoleName === "RpcReadOnly")) {
          localStorage.setItem('selectedBoard', 'Map Center');
          props.updateSelectedBoardType('Map Center');
        } else if (props.selectedCustomerListData.am_enabled === true && (appIds?.includes(6) || userRoleName === "RpcAdmin" || userRoleName === "RpcReadOnly")) {
          localStorage.setItem('selectedBoard', 'Asset Management IOT');
          props.updateSelectedBoardType('Asset Management IOT');
        }
      }
    }
    if (props.selectedLiftCustomer != null && props.selectedLiftCustomer != undefined) {
      customerId = props.selectedLiftCustomer.customerId
    } else {
      customerId = null
    }

    if (props.selectedLiftLocation != null && props.selectedLiftLocation != undefined) {
      locationId = props.selectedLiftLocation.locationId

    } else {
      locationId = null
    }
    if (props.userTokens != undefined) {
      userIDToken = props.userTokens.IdToken
    } else {
      userIDToken = localStorage.getItem("authToken");
    }
    getUserDashBoardType()
  }, [props]);

  useEffect(() => {
    if (selectedBoardType ===  'Map Center' || selectedBoardType ===  'Analytics') {
      if (props.selectedCustomerListData != null && props.selectedLocationListData != null) {
        props.updateUserAlertSelection(30);
        setAlertSelection(30)
        fetchDevices(props.selectedCustomerListData.customerId, props.selectedLocationListData.locationId)
      }
    }
  }, [props.selectedCustomerListData, props.selectedLocationListData, props.selectedBoard])
  
  useEffect(() => {
    if(isError === " User session expired. Login again to continue."){
      Auth.signOut();
      localStorage.clear();
      window.location.replace('/');
    }
    console.log("isError....",isError)
    if (isMapLoaded || isError) {
      setShowLoadingIndicator(false);
    }
  }, [isMapLoaded, isError]);

  useEffect(()=>{
   if(props?.selectedCustomer){
    setIsUserNotValid(true)
      setTimeout(() => {
        localStorage.clear();
        Auth.signOut()
          .then(() => {
            window.location.reload();
          })
          .catch((err) => {
            console.log("Error on Logout:", err.message);
          });
          setIsUserNotValid(false);
      },3000);
   }
  },[props?.selectedCustomer])

  const fetchDevices = (customer, location) => {
    setShowLoadingIndicator(true);
    props.fetchDevices(userIDToken, customer, location, getInitialLiftInfo, hideLoadingIndicator);
  }

  const hideLoadingIndicator = () => {
    setShowLoadingIndicator(false);
  }

  const getInitialLiftInfo = (liftID) => {
    setShowLoadingIndicator(false);
    let newDateValues = { start: null}
    if (liftID !== undefined, liftID !== null) {

      if (props.mapDateItemsValue.start !== undefined && props.mapDateItemsValue.start !== null) {
        let newStartDate = getUserSelectedDate(props.mapDateItemsValue.start)
        props.fetchDeviceDetails(liftID, userIDToken, newStartDate);
        props.fetchDeviceAlertTrail(liftID, newStartDate, userIDToken);
        props.fetchDeviceAlertHistory(liftID, newStartDate, userIDToken);
        props.fetchDeviceBreadcrumbHistory(liftID,  newStartDate, userIDToken);
        props.fetchDeviceStopHistory(liftID, newStartDate, userIDToken);
        props.fetchStopBiometrics(liftID, newStartDate, userIDToken);

     } else {
      props.fetchDeviceDetails(liftID, userIDToken, getCurrentDate());
      props.fetchDeviceAlertTrail(liftID, getCurrentDate(), userIDToken);
      props.fetchDeviceAlertHistory(liftID, getCurrentDate(), userIDToken);
      props.fetchDeviceBreadcrumbHistory(liftID,  getCurrentDate(), userIDToken);
      props.fetchDeviceStopHistory(liftID, getCurrentDate(), userIDToken);
      props.fetchStopBiometrics(liftID, getCurrentDate(), userIDToken);
     }
      props.updateRefreshDataFlag(false)
      props.updateStopSelectedFlag(false)
      props.updateAlertSelectedFlag(false)
      if (alertSelection !== null) {
        var startDate = ""
        var endDate = ""
        props.updateUserAlertSelection(alertSelection)
        if (alertSelection === 1) {
           startDate = getCurrentDate()
   
           } else if (alertSelection === 7) {
               startDate = getBeforeDate(alertSelection)
               endDate = getCurrentDate()
   
           } else if (alertSelection === 30) {
               startDate = getBeforeDate(alertSelection)
               endDate = getCurrentDate()
   
           } else if (alertSelection === 365) {
               startDate = getBeforeDate(alertSelection)
               endDate = getCurrentDate()
           }
        props.fetchAlertPareto(userIDToken, props.selectedLocationListData?.locationId, startDate, endDate);
     } else {
        props.updateUserAlertSelection(30)
        props.fetchAlertPareto(userIDToken, props.selectedLocationListData?.locationId, getThirtyDaysBeforeDate(), getCurrentDate());
     }
    }
  }

  const getUserSelectedDate = (selectedValue) => {
    let newDate = selectedValue
    if (newDate != null) {
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        let day = newDate.getDate();
        let requireDate = year + '-' + month + '-' + day;
        console.log(requireDate)
        return requireDate
    } else {
        return null
    }
 }

  const getCurrentDate = () => {
    let newDate = new Date()
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    let day = newDate.getDate();
    let requireDate = year + '-' + month + '-' + day;
    return requireDate
  }

  const getBeforeDate = (daysBack) => {
    let newDate = new Date()
    newDate.setDate(newDate.getDate() - daysBack);
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    let day = newDate.getDate();
    let requireDate = year + '-' + month + '-' + day;
    return requireDate
  }

  const getThirtyDaysBeforeDate = () =>{
    let newDate = new Date()
    newDate.setDate(newDate.getDate() - 30);
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    let day = newDate.getDate();
    let requireDate = year + '-' + month + '-' + day;
    return requireDate
  }

  const getUserDashBoardType = () => {
    if (selectedBoardType === 'Map Center') {
      setShowDashBoard(true)
      setShowUserMnagementPortal(false)
      setShowLiftManagementPortal(false)
      setShowUserProfile(false)
      setShowAssetDetails(false)
      setShowStoreDetailsPage(false)
      setShowLostScrapDetails(false)
      setShowDashBoardPage(false)
      setShowSupportPage(false)
      setShowHardwareManagementPortal(false)
      setShowLostScrapManagementPortal(false)
      setShowFleetComparisionPortal(false)
    } else if (selectedBoardType === 'User Mgmt') {
      setShowDashBoard(false)
      setShowUserMnagementPortal(true)
      setShowLiftManagementPortal(false)
      setShowUserProfile(false)
      setShowAssetDetails(false)
      setShowLostScrapDetails(false)
      setShowStoreDetailsPage(false)
      setShowDashBoardPage(false)
      setShowSupportPage(false)
      setShowHardwareManagementPortal(false)
      setShowLostScrapManagementPortal(false)
      setShowFleetComparisionPortal(false)
    } else if (selectedBoardType === 'Lift Mgmt') {
      setShowDashBoard(false)
      setShowUserMnagementPortal(false)
      setShowLiftManagementPortal(true)
      setShowUserProfile(false)
      setShowAssetDetails(false)
      setShowLostScrapDetails(false)
      setShowStoreDetailsPage(false)
      setShowDashBoardPage(false)
      setShowSupportPage(false)
      setShowHardwareManagementPortal(false)
      setShowLostScrapManagementPortal(false)
      setShowFleetComparisionPortal(false)
    } else if (selectedBoardType === 'Hardware Mgmt') {
      setShowDashBoard(false)
      setShowUserMnagementPortal(false)
      setShowLiftManagementPortal(false)
      setShowStoreDetailsPage(false)
      setShowUserProfile(false)
      setShowAssetDetails(false)
      setShowLostScrapDetails(false)
      setShowDashBoardPage(false)
      setShowSupportPage(false)
      setShowHardwareManagementPortal(true)
      setShowLostScrapManagementPortal(false)
      setShowFleetComparisionPortal(false)
    } else if (selectedBoardType === 'Lost Assets Mgmt') {
      setShowDashBoard(false)
      setShowUserMnagementPortal(false)
      setShowLiftManagementPortal(false)
      setShowUserProfile(false)
      setShowAssetDetails(false)
      setShowLostScrapDetails(false)
      setShowStoreDetailsPage(false)
      setShowDashBoardPage(false)
      setShowSupportPage(false)
      setShowHardwareManagementPortal(false)
      setShowLostScrapManagementPortal(true)
      setShowFleetComparisionPortal(false)
    }
    else if (selectedBoardType === 'User Profile') {
      setShowDashBoard(false)
      setShowUserMnagementPortal(false)
      setShowLiftManagementPortal(false)
      setShowUserProfile(true)
      setShowAssetDetails(false)
      setShowLostScrapDetails(false)
      setShowStoreDetailsPage(false)
      setShowDashBoardPage(false)
      setShowSupportPage(false)
      setShowHardwareManagementPortal(false)
      setShowLostScrapManagementPortal(false)
      setShowFleetComparisionPortal(false)
    } else if (selectedBoardType === 'Asset Status Details') {
      setShowDashBoard(false)
      setShowUserMnagementPortal(false)
      setShowLiftManagementPortal(false)
      setShowUserProfile(false)
      setShowAssetDetails(true)
      setShowStoreDetailsPage(false)
      setShowLostScrapDetails(false)
      setShowDashBoardPage(false)
      setShowSupportPage(false)
      setShowHardwareManagementPortal(false)
      setShowLostScrapManagementPortal(false)
      setShowFleetComparisionPortal(false)
    } else if (selectedBoardType === 'Lost Assets') {
      setShowDashBoard(false)
      setShowUserMnagementPortal(false)
      setShowLiftManagementPortal(false)
      setShowUserProfile(false)
      setShowAssetDetails(false)
      setShowLostScrapDetails(true)
      setShowStoreDetailsPage(false)
      setShowDashBoardPage(false)
      setShowSupportPage(false)
      setShowHardwareManagementPortal(false)
      setShowLostScrapManagementPortal(false)
      setShowFleetComparisionPortal(false)
    } else if (selectedBoardType === 'Asset Management IOT') {
      setShowDashBoard(false)
      setShowUserMnagementPortal(false)
      setShowLiftManagementPortal(false)
      setShowStoreDetailsPage(false)
      setShowUserProfile(false)
      setShowAssetDetails(false)
      setShowLostScrapDetails(false)
      setShowDashBoardPage(true)
      setShowSupportPage(false)
      setShowHardwareManagementPortal(false)
      setShowLostScrapManagementPortal(false)
      setShowFleetComparisionPortal(false)
    } else if (selectedBoardType === 'Support') {
      setShowDashBoard(false)
      setShowUserMnagementPortal(false)
      setShowLiftManagementPortal(false)
      setShowStoreDetailsPage(false)
      setShowUserProfile(false)
      setShowAssetDetails(false)
      setShowLostScrapDetails(false)
      setShowDashBoardPage(false)
      setShowSupportPage(true)
      setShowHardwareManagementPortal(false)
      setShowLostScrapManagementPortal(false)
      setShowFleetComparisionPortal(false)
    } else if (selectedBoardType === 'Store Location Assets') {
      setShowDashBoard(false)
      setShowUserMnagementPortal(false)
      setShowLiftManagementPortal(false)
      setShowStoreDetailsPage(true)
      setShowUserProfile(false)
      setShowAssetDetails(false)
      setShowLostScrapDetails(false)
      setShowDashBoardPage(false)
      setShowSupportPage(false)
      setShowHardwareManagementPortal(false)
      setShowLostScrapManagementPortal(false)
      setShowFleetComparisionPortal(false)
    } else if (selectedBoardType === 'Delivery IOT') {
      setShowDashBoard(false)
      setShowUserMnagementPortal(false)
      setShowLiftManagementPortal(false)
      setShowStoreDetailsPage(false)
      setShowUserProfile(false)
      setShowAssetDetails(false)
      setShowLostScrapDetails(false)
      setShowDashBoardPage(true)
      setShowSupportPage(false)
      setShowHardwareManagementPortal(false)
      setShowLostScrapManagementPortal(false)
      setShowFleetComparisionPortal(false)
    }else if (selectedBoardType === 'Analytics') {
      setShowDashBoard(false)
      setShowUserMnagementPortal(false)
      setShowLiftManagementPortal(false)
      setShowStoreDetailsPage(false)
      setShowUserProfile(false)
      setShowAssetDetails(false)
      setShowLostScrapDetails(false)
      setShowDashBoardPage(false)
      setShowSupportPage(false)
      setShowHardwareManagementPortal(false)
      setShowLostScrapManagementPortal(false)
      setShowFleetComparisionPortal(true)
    }
  }

  let adminScreenSelected;

  let mainScreenSelected;

  let showAllPages = true

  let showDuoIOTPages = false

  let showAMPages = false

  if (props.selectedCustomerListData != null, props.selectedCustomerListData != undefined) {
    const appIds = localStorage.getItem("applicationIds");
    const userRoleName = localStorage.getItem("userRole");
    if (props.selectedCustomerListData.duo_iot_enabled === true && props.selectedCustomerListData.am_enabled === true &&   (userRoleName === "RpcAdmin" || userRoleName === "RpcReadOnly")) {
      showAllPages = true
      showDuoIOTPages = false
      showAMPages = false
    }else if(props.selectedCustomerListData.duo_iot_enabled === true && props.selectedCustomerListData.am_enabled === true &&  appIds?.includes(4) && appIds?.includes(6)) {
      showAllPages = true
      showDuoIOTPages = false
      showAMPages = false
    }else if (props.selectedCustomerListData.duo_iot_enabled === true && (appIds?.includes(4) || userRoleName === "RpcAdmin" || userRoleName === "RpcReadOnly")) {
      showAllPages = false
      showDuoIOTPages = true
      showAMPages = false
    } else if (props.selectedCustomerListData.am_enabled === true && (appIds?.includes(6) || userRoleName === "RpcAdmin" || userRoleName === "RpcReadOnly")) {
      showAllPages = false
      showDuoIOTPages = false
      showAMPages = true
    }
  }

  mainScreenSelected = <div className="dashboard">
    <div className="dashboard-right-panel" >
      <Backdrop
            sx={{ color: '#fff', zIndex: 1000 }}
            open={showLoadingIndicator}
          >
            <CircularProgress color="inherit" />
      </Backdrop>
      {showDashBoard ?
        <DuoLiftDetailsPortal></DuoLiftDetailsPortal> : null
      }
    </div>
  </div>

  if (showUserMnagementPortal) {
    adminScreenSelected =
      <div className="dashboardAdminPanel">
        <UserManagementPortal></UserManagementPortal>
      </div>
  } else if (showLiftManagementPortal) {
    adminScreenSelected =
      <div className="dashboardAdminPanel">
        <LiftManagementPortal></LiftManagementPortal>
      </div>
  } else if (showHardwareManagementPortal) {
    adminScreenSelected =
      <div className="dashboardAdminPanel">
        <HardwareManagementPage></HardwareManagementPage>
      </div>
  } else if (showLostScrapManagementPortal) {
    adminScreenSelected =
      <div className="dashboardAdminPanel">
        <LostScrapManagementPage></LostScrapManagementPage>
      </div>
  } else if (showUserProfile) {
    adminScreenSelected =
      <div className="dashboardAdminPanel">
        <UserProfile></UserProfile>
      </div>
  } else if (showAssetDetails) {
    adminScreenSelected =
      <div className="dashboardAdminPanel">
        <AssetDetailsPage />
      </div>
  } else if (showLostScrapDetails) {
    adminScreenSelected =
      <div className="dashboardAdminPanel">
        <LostScrapDetailsPage />
      </div>
  } else if (showDashBoardPage) {
    adminScreenSelected =
      <div className="dashboardAdminPanel">
        <DashboardPage />
      </div>
  } else if (showStoreDetailsPage) {
    adminScreenSelected =
    <div className="dashboardAdminPanel">
        <StoreDetailsPage />
      </div>
  } else if (showFleetComparisionPortal) {
    adminScreenSelected =
    <div className="dashboardAdminPanel">
        <FleetComparisionPortal />
      </div>
  }

  return (
    <Box
      sx={{
        display: "flex",
        minWidth: "768px",
        background: "#f3f3f3",
        minHeight: "100vh",
      }}
    >
      <CssBaseline />
      <SideNav customerLocationList={props.customerLocationList}
        selectedCustomerListData={props.selectedCustomerListData}
        selectedLocationListData={props.selectedLocationListData}
        collapasePanel={collapasePanel}
        showAllPages={showAllPages}
        showDuoIOTPages={showDuoIOTPages}
        showAMPages={showAMPages} />
      <Box
        sx={{
          padding: "31px 39px 73px 21px",
          width: collapsePanel ? "85%" : "100%",
          marginLeft: collapsePanel ? "250px" : "50px",
        }}
      >
        <DashboardHeader></DashboardHeader>
        {isUserNotValid ?
          <div style={{ backgroundColor: "transparent !important" }}>
            <ShowModal />
          </div>
          :
          showUserMnagementPortal || showLiftManagementPortal || showHardwareManagementPortal || showLostScrapManagementPortal ||
            showUserProfile || showAssetDetails || showLostScrapDetails || showDashBoardPage || showStoreDetailsPage || showFleetComparisionPortal ? (adminScreenSelected) : (mainScreenSelected)
        }
      </Box>
    </Box>
  )
  // DashboardPortal.propTypes = {};

  // DashboardPortal.defaultProps = {};
}


const mapStateToProps = (state) => {
  return {
    userID: state.userDetails !== null && state.userDetails.userID,
    userDetails: state.userDetails,
    isCustomerFleetUser: state.isCustomerFleetUser,
    selectedBoard: state.selectedBoard,
    userTokens: state.userTokens,
    isUserLoggedIn: state.userLoggedIn,
    isMapLoaded: state.geocoder,
    isError: state.error,
    selectedLiftCustomer: state.selectedLiftCustomer,
    selectedLiftLocation: state.selectedLiftLocation,
    resetLogout: state.resetLogout,
    usersForLocations: state.usersForLocations,
    selectedLocationForCustomer: state.selectedLocationForCustomer,
    selectedUsersForLocation: state.selectedUsersForLocation,
    isReadOnlyUser: state.isReadOnlyUser,
    customerLocationList: state.customerLocationList,
    selectedCustomerListData: state.selectedCustomerListData,
    selectedLocationListData: state.selectedLocationListData,
    fleetAlertSelection: state.fleetAlertSelection,
    mapDateItemsValue: state.mapDateItemsValue,
    selectedCustomer: state.selectedCustomer
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchDevices: (userToken, customerId, locationId, getInitialLiftInfo, hideLoadingIndicator) => dispatch(LiftActions.LOAD_LIFT_STOP_ALERT_DATA(userToken, customerId, locationId, getInitialLiftInfo, hideLoadingIndicator)),
    updateSelectedBoardType: (selectedType) => dispatch(LiftActions.LOAD_DASHBOARD_TYPE_UPDATE(selectedType)),
    fetchCustomersAndLocations: (userToken) => dispatch(LiftActions.LOAD_CUSTOMERS_AND_LOCATIONS(userToken)),
    fetchDeviceDetails: (id, userToken, startDate) => dispatch(LiftActions.LOAD_LIFT_DETAIL(id, userToken, startDate)),
    fetchDeviceAlertHistory: (id, startDate, userToken) => dispatch(LiftActions.LOAD_LIFT_ALERT_HISTORY(id, startDate, userToken)),
    fetchDeviceBreadcrumbHistory: (id, startDate, userToken) => dispatch(LiftActions.LOAD_LIFT_BREADCRUMB_HISTORY(id, startDate, userToken)),
    fetchDeviceStopHistory: (id, startDate, userToken) => dispatch(LiftActions.LOAD_LIFT_STOPS_HISTORY_TYPE(id, startDate, userToken)),
    fetchDeviceAlertTrail: (id, startDate, userToken) => dispatch(LiftActions.LOAD_LIFT_ALERT_TRAIL_TYPE(id, startDate, userToken)),
    fetchAlertPareto: (userToken, locationId, startdate, enddate) => dispatch(LiftActions.LOAD_ALERT_PARETO(userToken, locationId, startdate, enddate)),
    fetchStopBiometrics: (id, date, userToken) => dispatch(LiftActions.LOAD_LIFT_STOP_BIOMETRICS(id, date, userToken)),
    updateUserAlertSelection: (alertType) => dispatch(LiftActions.UPDATE_FLEET_COMPARISION_SELCTION(alertType)),
    fetchUserRole: (userToken, roleId) => dispatch(LiftActions.FETCH_USER_ROLE_REQUEST_TYPE(userToken, roleId)),
    updateRefreshDataFlag: (refreshDataRequested) => dispatch(LiftActions.UPDATE_REFRESH_DATA_REQUESTED_FLAG(refreshDataRequested)),
    updateStopSelectedFlag: (stopSelectedFlag) => dispatch(LiftActions.UPDATE_USER_SELECTED_STOP_DASHBOARD_FLAG(stopSelectedFlag)),
    updateAlertSelectedFlag: (alertSelectedFlag) => dispatch(LiftActions.UPDATE_USER_SELECTED_ALERT_DASHBOARD_FLAG(alertSelectedFlag))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(DashboardPortal);