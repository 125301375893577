import React from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";

const FleetComparisionChartDataTable = (props) => {
  const liftAlertData = props.liftAlertData
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 300, borderBottom: "none" }} aria-label="simple table" options={{ exportButton: true }}>
        <TableBody sx={{ borderBottom: "none" }}>
          {liftAlertData.map((alert) => (
            <TableRow
              key={alert.alertDesc}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="left">{alert.alertID}</TableCell>
              <TableCell component="th" scope="row">
                {alert.alertDesc}
              </TableCell>
              <TableCell align="right">{alert.alertCount}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default FleetComparisionChartDataTable;