import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';

const LiftFloatingInputFields = (props) => {
  const [customersData, setCustomersData] = useState(props.customerLocationList);
  const [selectedCustomer, setSelectedCustomer] = useState(props.selectedCustomer.customerName);
  const [locationsData, setLocationsData] = useState(props.selectedCustomerListData.locations);
  const [selectedLocation, setSelectedLocation] = useState(props.selectedLocation.locationName);
  const [selectedLocationId, setSelectedLocationId] = useState(props.selectedLocation.locationID);
  const [defaultDriver, setDefaultDriver] = useState(props.selectedDriver.name);
  const [selectedDriver, setSelectedDriver] = useState(props.selectedDriver);
  const [driversInfo, setDriversInfo] = useState(props.selectedUnassignedDrivers);
  const [truckName, setTruckName] = useState(props.selectedTruckName);
  const [routeName, setRouteName] = useState(props.selectedRouteName);
  const WIDTH_FIELDS = '350px';

  const customerChanged = (event) => {
    if (event) {
      for (let i in customersData){
        if(customersData[i].customerName === event){
          setLocationsData(customersData[i].locations);
        }
      }
      setSelectedCustomer(event);
      setSelectedLocation(null);
      setDefaultDriver(null);
      setDriversInfo([]);
    }
  }
  const locationChanged = (event, dropdownChange) => {
    if (event != undefined, props != undefined) {
      const newDefaultDriver = {}
      var newDriversInfo = [];
      var newDriversInfo = props.selectedDriver ? [props.selectedDriver] : [];
      props.unassignedDrivers.map(customer => {
        if (selectedCustomer === customer.customerName) {
          customer.locations.map(location => {
            if (event === location.locationName) {
              setSelectedLocationId(location.locationId);
              if (location.drivers.length > 0) {
                location.drivers.map(driver => {
                  newDriversInfo.push({
                    name: driver.firstName + driver.lastName,
                    userId: driver.userId,
                  })
                  newDefaultDriver['name']= driver.firstName + driver.lastName;
                  newDefaultDriver['userId'] = driver.userId;

                })
              } else {
                newDefaultDriver['name'] = ""
                newDefaultDriver['userId'] = 0
                newDriversInfo = []
              }
            }
          })
        }
      })
      setSelectedLocation(event);
      setDriversInfo(newDriversInfo);
      // if (dropdownChange) {
      //   setDefaultDriver(newDefaultDriver.name);
      // }
    }
  }
  useEffect(() => {
    let flag = selectedDriver.name ? false : true;
    locationChanged(selectedLocation, flag);
  }, []);
  
  const driverChanged = (event) => {
    let newDefaultDriver={};
    driversInfo.map(driver => {
      if (event === driver.name) {
        newDefaultDriver['name']= driver.name;
        newDefaultDriver['userId'] = driver.userId;
      }
    })
    setDefaultDriver(event);
    setSelectedDriver(newDefaultDriver);
  }

  const truckChanged = (event) => {
    setTruckName(event.target.value);
  }

  const routeChanged = (event) => {
    setRouteName(event.target.value);
  }

  const submitDetails = () => {
    const newDataItem = {};
    newDataItem['customer'] = selectedCustomer;
    newDataItem['location'] = selectedLocationId;
    newDataItem['driver'] = selectedDriver.userId;
    newDataItem['truck'] = truckName;
    newDataItem['route'] = routeName;
    props.onValueChange(newDataItem);
  }

  return (
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { marginTop: '20px', width: WIDTH_FIELDS, boxShadow: 'none' },
      }}
      noValidate
    >
      {customersData && <FormControl sx={{ width: WIDTH_FIELDS, marginBottom: '20px' }}>
        <InputLabel id="demo-simple-select-helper-label">Assign Customer*</InputLabel>
        <Select
          labelId="customer-name-label"
          id="customer-name"
          value={selectedCustomer || ''}
          onChange={(e) => customerChanged(e.target.value)}
        >
          {customersData.map((item) => (
            <MenuItem key={item.customerId} value={item.customerName}>
              {item.customerName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>}

      {locationsData && <FormControl sx={{ width: WIDTH_FIELDS, marginBottom: '20px' }}>
        <InputLabel id="demo-simple-select-helper-label">Assign Location*</InputLabel>
        <Select
          labelId="location-name-label"
          id="location-name"
          value={selectedLocation || ''}
          onChange={(e) => locationChanged(e.target.value, 'true')}
        >
          {locationsData && locationsData.map((item, index) => (
            <MenuItem key={index} value={item.locationName}>
              {item.locationName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>}

      {driversInfo.length > 0 ? <FormControl sx={{ width: WIDTH_FIELDS }}>
        <InputLabel id="demo-simple-select-helper-label">Assign Driver</InputLabel>
        <Select
          labelId="location-name-label"
          id="location-name"
          value={defaultDriver != null ? defaultDriver : ''}
          onChange={(e) => driverChanged(e.target.value)}
        >
          {driversInfo.map((item, index) => (
            <MenuItem key={index} value={item.name}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl> :
        <FormControl sx={{ width: WIDTH_FIELDS }}>
          <InputLabel id="demo-simple-select-helper-label">Assign Driver</InputLabel>
          <Select
            labelId="location-name-label"
            id="location-name"
            value={defaultDriver != null ? defaultDriver : ''}>
            <MenuItem value={"empty"}>
              No data found
            </MenuItem>
          </Select>
        </FormControl>
      }
      <TextField
        id="outlined-name"
        label="Route"
        value={routeName != null ? routeName : ''}
        onChange={routeChanged}
        helperText={"Hint: Enter last name"}
        style={{ backgroundColor: '#fff' }}
      />
      <TextField
        id="outlined-name"
        label="Truck"
        value={truckName  != null ? truckName : ''}
        helperText={"Hint: Enter email here"}
        onChange={truckChanged}
        style={{ backgroundColor: '#fff' }}
      />
      <div style={{ marginLeft: '70%', marginTop: '20px' }}>
        <Button variant="contained" disabled={false}
          onClick={submitDetails}
        >Save</Button>
      </div>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    customerLocationList: state.customerLocationList,
    selectedCustomerListData: state.selectedCustomerListData,
    selectedLocationListData: state.selectedLocationListData
  }
}

export default connect(mapStateToProps, null)(LiftFloatingInputFields);
