import React from 'react'
import * as LiftActions from '../../../../../store/lift/lift.actions'
import {connect} from 'react-redux'
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';


class DuoLiftServiceItemModal extends React.Component {

    clearTaskButtonTapped = (task) => {
      console.log(this.props.serviceItem)
      console.log(this.props.maintenanceTasks)
      console.log(this.props.selectedDevice)
      var itemSheduledIds = []
      // , id, , date, keyonTime, userId, taskId
      if (this.props.maintenanceTasks != null) {
        if (this.props.maintenanceTasks.length > 0) {
            this.props.maintenanceTasks.forEach(mainTask => {
                if (mainTask.maintTypeID !== null) {
                    if (mainTask.tasks !== undefined && mainTask.tasks.length > 0) {
                        mainTask.tasks.forEach(task => {
                          if (this.props.serviceItem.maintTaskID === task.maintTaskID) {
                            itemSheduledIds.push(mainTask.maintSchedID)
                          }
                        })
                    } 
                  }
                })
              }
            }
        const loggedInUserIDToken = localStorage.getItem("authToken");
        const deviceId = this.props.selectedDevice.liftID
        const clearedDate = this.getUserClearedDate()
        const liftKeyonTime = this.props.selectedDevice.keyOnHours
        const taskId = this.props.serviceItem.maintTaskID
        const userId = localStorage.getItem("userID");
        this.props.clearServiceItem(loggedInUserIDToken, deviceId, itemSheduledIds, clearedDate, liftKeyonTime, userId, taskId, this.getUpdatedDeviceDetails)
    }

    getUpdatedDeviceDetails = () => {
      if (this.props.mapDateItemsValue.start !== undefined) {
        let newStartDate = this.getUserSelectedDate(this.props.mapDateItemsValue.start)
        const deivceID = this.props.selectedDevice.liftID
        const loggedInUserIDToken = localStorage.getItem("authToken");
        if (this.props.serviceItems != undefined) {
          if (this.props.serviceItems.length === 1) {
            this.fetchDevices()
          }
        }
        this.props.fetchDeviceDetails(deivceID, loggedInUserIDToken, newStartDate);
      } else {
        const deivceID = this.props.selectedDevice.liftID
        const loggedInUserIDToken = localStorage.getItem("authToken");
        if (this.props.serviceItems != undefined) {
          if (this.props.serviceItems.length === 1) {
            this.fetchDevices()
          }
        }
        this.props.fetchDeviceDetails(deivceID, loggedInUserIDToken, this.getCurrentDate());
      }
    }

   fetchDevices() {
    const loggedInUserIDToken = localStorage.getItem("authToken");
    const customer = this.props.selectedLiftCustomer.customerId
    const location = this.props.selectedLiftLocation.locationId
    this.props.fetchDevices(loggedInUserIDToken, customer, location);
  }

    getUserSelectedDate(selectedValue) {
      let newDate = selectedValue
      if (newDate != null) {
          let month = newDate.getMonth() + 1;
          let year = newDate.getFullYear();
          let day = newDate.getDate();
          let requireDate = year + '-' + month + '-' + day;
          console.log(requireDate)
          return requireDate
      } else {
          return null
      }
   }

   getCurrentDate() {
    let newDate = new Date()
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    let day = newDate.getDate();
    let requireDate = year + '-' + month + '-' + day;
    return requireDate
 }
    
    getUserClearedDate() {
        let newDate = new Date()
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        let day = newDate.getDate();
        let requireDate = month + '/' + day + '/' + year;
        console.log(requireDate)
        return requireDate
    } 

    render(){
        let serviceItemDesc = this.props.serviceItem
        let role = localStorage.getItem("userRole")
        var isReadOnly = false
        if (role === "CustomerReadOnly" || role === "LocationReadOnly") {
          isReadOnly = true
        } else {
          isReadOnly = false
        }
        if(this.props.showModal) {
      
          return(
            <div>
            <Dialog onClose={this.props.updateDuoLiftServiceItemModal.bind(this)} className="dialog-container" open={this.props.showModal} maxWidth={"md"}>
            <DialogTitle textAlign="center">Service Item</DialogTitle>
            <div className="modalForStatusChange">
            <p style={{width: "100%", marginLeft: "5px", marginRight: "5px"}}> {serviceItemDesc.maintTaskName}</p>  
            {!isReadOnly &&
              <div className = "statusModalSaveButton">
                 <Button variant="contained" onClick={this.clearTaskButtonTapped} > Clear Task </Button>
             </div>
            }              
            </div>
            </Dialog>
            </div>
         )
        } else {
          return null;
        } 
       }
}

const mapStateToProps = (state) => {
    return {
      showModal: state.showServiceItemModal,
      serviceItem: state.serviceItem,
      serviceItems: state.serviceItems,
      mapDateItemsValue: state.mapDateItemsValue,
      selectedLiftCustomer: state.selectedLiftCustomer,
      selectedLiftLocation: state.selectedLiftLocation,
    }
  }

const mapDispatchToProps = (dispatch) =>{
    return {
      fetchDeviceDetails: (id, userToken, startDate) => dispatch(LiftActions.LOAD_LIFT_DETAIL(id, userToken, startDate)),
      updateDuoLiftServiceItemModal: () => dispatch(LiftActions.LIFT_SERVICE_MODAL_UPDATE()),
      clearServiceItem: (userToken, id, sheduledIDs, date, keyonTime, userId, taskId, updateResults) => dispatch(LiftActions.LIFT_CLEAR_SERVICE_TASK(userToken, id, sheduledIDs, date, keyonTime, userId, taskId, updateResults)),
      fetchDevices: (userToken, customerId, locationId) => dispatch(LiftActions.LOAD_LIFT_SUMMARY(userToken, customerId, locationId))
    }
  }

export default  connect(mapStateToProps, mapDispatchToProps)(DuoLiftServiceItemModal);

