import React from 'react'
import * as LiftActions from '../../../store/lift/lift.actions'
import {connect} from 'react-redux'
import LiftFloatingInputFields from '../../tools/LiftFloatingInputFields2/LiftFloatingInputFields.js';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

class LiftManagementEditModal extends React.Component {

    state = {
        liftState: true,
        liftStatusChanged: false
    }

    hideModal = (event) => {
        if (event != undefined) {
          if (event === "Edit Lift") {
            this.props.hideLiftModal("Edit Lift", false)
          } else {
            this.props.hideLiftModal("Assign Lift", false)
          }
        }
      }

    userTextEntered = (event) => {
        const loggedInUserIDToken = localStorage.getItem("authToken");
        let liftStatus;
        if (this.state.liftStatusChanged) {
            liftStatus = this.state.liftState
        } else {
            liftStatus = this.props.selectedLiftToEdit.active
        }
        let liftID = this.props.selectedLiftToEdit.liftId
        let locationId = event.location
        let driverID = event.driver
        let routeName = event.route
        let truckName = event.truck
        this.props.updateLiftDetails(loggedInUserIDToken, liftID, locationId, driverID, routeName, truckName, liftStatus, this.getLiftsForLocations, this.getUpdatedUsersAndLocation, this.getDriversForLifts)
    
    }

    getLiftsForLocations = () => {
        const loggedInUserIDToken = localStorage.getItem("authToken");
        const customerId = this.props.selectedCustomerListData && this.props.selectedCustomerListData.customerId;
        const locationId = this.props.selectedCustomerListData && this.props.selectedLocationListData.locationId;
        this.props.fetchLiftsForLocation(loggedInUserIDToken,customerId,locationId)
    }


    getUpdatedUsersAndLocation = () => {
        const loggedInUserIDToken = localStorage.getItem("authToken");
        this.props.fetchUsersAndLocation(loggedInUserIDToken)
      }

    
    getDriversForLifts = () => {
        const loggedInUserIDToken = localStorage.getItem("authToken");
        this.props.fetchDriversForLifts(loggedInUserIDToken)
    }
  

    handleCheckBoxChange = (event) => {
        console.log(event.target.checked)
        this.setState({
            liftState: event.target.checked,
            liftStatusChanged: true
        })
    }

    render() {
        let liftSerialName;
        let customerNameSelected = {};
        let customersInfo = [];
        let locationNameSelected = {};
        let locationsInfo = [];
        let unassignedDriverInfo = [];
        let selectedLocationDrivers = [];
        let truckName;
        let routeName;
        let driverName = {};

        if (this.props.selectedLiftToEdit != undefined) {
            liftSerialName = this.props.selectedLiftToEdit.liftId;
            truckName = this.props.selectedLiftToEdit.truck
            routeName = this.props.selectedLiftToEdit.route
            if (this.props.selectedLiftToEdit.firstName != null, this.props.selectedLiftToEdit.lastName != null, this.props.selectedLiftToEdit.firstName != undefined, this.props.selectedLiftToEdit.lastName != undefined) {
                driverName['name'] = this.props.selectedLiftToEdit.firstName + this.props.selectedLiftToEdit.lastName
                driverName['userId'] = this.props.selectedLiftToEdit.userId
            } else {
                driverName['name'] = null
                driverName['userId'] = null
            }
        }
        if (this.props.customerInfo != undefined) {
            if (this.props.customerInfo.length > 0) {
                this.props.customerInfo.map(customer => {
                    customersInfo.push({
                        customerName: customer.customerName,
                        customerID: customer.customerId,
                        locations: customer.locations,
                    })
                })
            }
        } else {
            customersInfo = []
        }

        if (this.props.selectedCustomerInfo != undefined) {
            customerNameSelected['customerName'] = this.props.selectedCustomerInfo.customerName
            customerNameSelected['customerID'] = this.props.selectedCustomerInfo.customerId
        } else {
            customerNameSelected = null
        }
        
        if (this.props.selectedCustomerInfo != undefined && this.props.customerInfo != undefined) {
                if (this.props.customerInfo.length > 0){
                    this.props.customerInfo.map(customer => {
                        if (this.props.selectedCustomerInfo.customerName === customer.customerName) {
                            customer.locations.map(location => {
                                locationsInfo.push({
                                    locationName: location.locationName,
                                    locationID: location.locationId,
                                })
                            })
                        }
                    })
                }
        } else {
            locationsInfo = []
        }

        if (this.props.selectedLocattionInfo != undefined) {
            locationNameSelected['locationName'] = this.props.selectedLocattionInfo.locationName
            locationNameSelected['locationID'] = this.props.selectedLocattionInfo.locationId
        } else {
            locationNameSelected = null
        }

        unassignedDriverInfo = this.props.unassignedDrivers

        if (unassignedDriverInfo != undefined, customerNameSelected != undefined, locationNameSelected != undefined) {
            if  (unassignedDriverInfo != null) {
                unassignedDriverInfo.map(customer => {
                    if (customer.customerName === customerNameSelected.customerName) {
                        customer.locations.map( location => {
                            if (location.locationName === locationNameSelected.locationName) {
                                if (location.drivers != undefined && location.drivers.length > 0) {
                                    location.drivers.map( driver => {
                                        selectedLocationDrivers.push({
                                            name: driver.firstName + driver.lastName,
                                            userId: driver.userId,
                                        })
                                    })
                                } else {
                                    selectedLocationDrivers = []
                                }
                            } 
                        })
                    }
                })
            }
        }


        let modalTitle
        if (this.props.liftEditModalType != undefined) {
            modalTitle = this.props.liftEditModalType
        }
        if (this.props.showModal) {
            return(
                <div>
                    <Dialog open={this.props.showModal} onClose={this.hideModal.bind(this, this.props.liftEditModalType)}
                        PaperProps={{ sx: { width: '500px', minHeight: "600px" } }}>
                        <DialogTitle>{modalTitle}</DialogTitle>
                        <DialogContent>
                            <div className="userModalTopUserRole" style={{fontWeight: "bold"}}>
                                <span style={{ marginLeft: "5px" }}>LIFT SR#</span>
                                <span style={{ fontSize: "15px", marginLeft: "20px"}}>{liftSerialName}</span>
                            </div>
                            <div className="userModalTopSelection">
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox checked={this.state.liftState} onChange={this.handleCheckBoxChange} />} label="Active Lift" />
                                </FormGroup>
                            </div>
                            <div className="userModalFloatingLabelsSection">
                                <LiftFloatingInputFields id={this.props.showModalType} saveButtonStatus={this.state.liftStatusChanged} showLocationDropDown={false} selectedCustomer={customerNameSelected} customers={customersInfo} selectedLocation={locationNameSelected} locations={locationsInfo} unassignedDrivers={unassignedDriverInfo} selectedUnassignedDrivers={selectedLocationDrivers} selectedDriver={driverName} selectedTruckName={truckName} selectedRouteName={routeName} onValueChange={this.userTextEntered}>
                                </LiftFloatingInputFields>
                            </div>
                        </DialogContent>
                    </Dialog>
                </div>
            )
        } else {
            return null
        }
    }
}

const mapStateToProps = (state) => {
    return {
      showModal: state.showLiftEditModal,
      liftEditModalType: state.liftEditModalType,
      customerInfo: state.liftsForLocations,
      selectedCustomerInfo: state.selectedLocationsForLifts,
      selectedLocattionInfo: state.selectedLiftsForLocation,
      selectedLiftToEdit: state.selectedLiftToEdit,
      unassignedDrivers: state.liftDriversInfo,
      selectedCustomerListData: state.selectedCustomerListData,
      selectedLocationListData: state.selectedLocationListData
    }
  }

const mapDispatchToProps = (dispatch) =>{
    return {
        hideLiftModal: (type, show) => dispatch(LiftActions.LIFTS_EDIT_MODAL_UPDATE(type, show)),
        updateLiftDetails: (userToken, liftId, locationId, driverId, routeName, truckName, liftStatus, updateLiftDetails, updateLocationDetails, getDriversForLifts) => dispatch(LiftActions.LIFTS_UPDATE_REQUEST_TYPE(userToken, liftId, locationId, driverId, routeName, truckName, liftStatus, updateLiftDetails, updateLocationDetails, getDriversForLifts)),
        fetchLiftsForLocation: (userToken,customerId,locationId) => dispatch(LiftActions.LOCATIONS_LIFTS_REQUEST_TYPE(userToken,customerId,locationId)),
        fetchUsersAndLocation: (userToken) => dispatch(LiftActions.LIFT_USERS_LOCATIONS_REQUEST_TYPE(userToken)),
        fetchDriversForLifts: (userToken) => dispatch(LiftActions.LIFTS_DRIVERS_REQUEST_TYPE(userToken, "", ""))
    }
  }

export default connect(mapStateToProps, mapDispatchToProps) (LiftManagementEditModal);
