export const styles = {
  paper: {
    width: "100%",
    mb: 2,
    position: "relative",
    borderRadius: "4px",
    boxShadow: "0px 4px 8px rgba(16, 24, 64, 0.16)",
    overflowX: "auto",
  },
  tableFooter: {
    // border: "1px solid black",
    background: "#FAFBFF",
    display: "flex",
    alignItems: "center",
    position: "relative",
    flexDirection: "row-reverse",
    borderBottomLeftRadius: "4px",
    borderBottomRightRadius: "4px",
    padding: "6px",
  },
  tableRow: {
    color: "#696F8C",
    fontSize: "12px",
    padding: "23px 24px",
    // border: "2px solid white",
  },
  tableRowAction: {
    color: "#3b62a1",
    fontSize: "12px",
    fontWeight: "600",
    cursor: "pointer",
  },
  edit: {
    color: "#3b62a1",
    fontSize: "12px",
    fontWeight: "600",
    cursor: "pointer",
    // border: "2px solid white",
  },
  delete: {
    color: "#F54F46",
    fontSize: "12px",
    fontWeight: "600",
    cursor: "pointer",
    // border: "2px solid white",
  },
  tablePagination: {
    ".MuiTablePagination-select": {
      border: "1px solid #969BB4",
      borderRadius: "5px",
      background: "#fff",
    },
    ".MuiTablePagination-displayedRows": {
      display: "none",
    },
    ".MuiTablePagination-selectLabel": {
      color: "#3F4344",
    },
    background: "#FAFBFF",
    width: "350px",
    marginRight: "100px",
  },
  pagination: {
    "& .MuiPaginationItem-root": {
      "&.Mui-selected": {
        color: "white",
        background: "#3b62a1",
      },
      "&.hover": {
        color: "white",
        background: "#3b62a1",
      },
    },
    position: "absolute",
    right: 10,
    zIndex: 10,
    background: "#FAFBFF",
  },
  blocker: {
    position: "absolute",
    right: 40,
    zIndex: 8,
    background: "#FAFBFF",
    // border: "1px solid black",
    height: "25px",
    width: "200px",
  },
};
