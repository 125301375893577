import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { connect } from 'react-redux';
import * as LiftActions from '../../store/lift/lift.actions';
import { Box, Drawer, Divider, IconButton, Typography, MenuItem, Select, FormControl, Grid } from '@mui/material';
import RehrigLogo from '../../assets/rehrig_logo.svg';
import CompanyLogo from "../../assets/CompanyLogo.png";
import { NavLinks, NavLinkSubItems, ReportSubItems,AMNavLinks, DuoNavLinks, AMNavLinkSubItems, DuoNavLinkSubItems } from "../../data/sideNavData";
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

const SideNav = (props) => {
  const [collapsePanel, setCollapsePanel] = useState(false);
  const [selectedBoard, setSelectedBoard] = useState(props.selectedBoard);

  const handleCustomerChange = (event) => {
   // let selectedBoardValue = props.selectedBoard;
    props.updateSelectedCustomer(event.target.value)
    let mapDateItemsValue = { start: null}
    props.updateDatesSelectedForMap(mapDateItemsValue)
    if(event.target.value.duo_iot_enabled === true && event.target.value.am_enabled === false){
      props.updateSelectedBoardType('Map Center')
    }else{
      props.updateSelectedBoardType('Asset Management IOT')
    }
  }

  const handleLocationChange = (event) => {
    props.updateSelectedLocation(event.target.value)
  }

  let customersData = props.customerLocationList || null;
  let selectedCustomer = props.selectedCustomerListData
  let selectedLocation = props.selectedLocationListData


  const handleSelectedBoardChange = (text) => {
    setSelectedBoard(text)
    props.updateSelectedBoardType(text);
  }

  const handleExpandPanel = () => {
    setCollapsePanel(!collapsePanel);
    props.collapasePanel(collapsePanel)
  }

  return <>
    {collapsePanel ?
      <Drawer sx={styles.collapseSideNav} variant="permanent" anchor="left">
        <IconButton
          onClick={handleExpandPanel}
          style={{backgroundColor:"#3b62a1"}}
          size="large">
          <ArrowRightIcon fontSize="large" style={{ color: "white", marginTop: "25px", marginLeft: "5px" }} />
        </IconButton>
      </Drawer>
      :
      <Drawer sx={styles.sideNav} variant="permanent" anchor="left">
        <Grid container>
          <Grid item xs={9}>
            <Box>
              <img src={RehrigLogo} alt="Rehrig" style={{ height: "80px", width: "80px" }} />
            </Box>
          </Grid>
          <Grid item xs={3}>
            <IconButton
              onClick={handleExpandPanel}
              style={{backgroundColor:"#3b62a1"}}
              size="large">
              <ArrowLeftIcon fontSize="large" style={{ color: "white", marginTop: "25px", marginLeft: "5px" }} />
            </IconButton>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={3}>
            <Box>
              <img src={CompanyLogo} alt="Customer"/>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h4" color="white">A.M.I</Typography>
          </Grid>
        </Grid>

        <Divider sx={{ my: 0.5, borderColor: "#38D2EA" }} />

        <Box style={{ marginTop: "1rem", marginRight: "20px" }}>
          <Typography color="white">Customer</Typography>
          <FormControl fullWidth size={"small"}
            sx={{
              borderRadius: "4px",
              background: "#fff",
              boxShadow: "0px 4px 8px rgba(16, 24, 64, 0.16)",
              "& .MuiInputBase-root.Mui-disabled": {
                background: "#E0E2EA",
                color: "#A6A6A6",
              },
            }}
          >
            <Select
              labelId="customer-name-label"
              id="customer-name"
              value={selectedCustomer && selectedCustomer || ''}
              onChange={handleCustomerChange}
            >
              {customersData && customersData.map((item) => (
                <MenuItem key={item.customerId} value={item}>
                  {item.customerName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <Box style={{ marginTop: "1rem", marginRight: "20px" }}>
          <Typography color="white">Location</Typography>
          <FormControl fullWidth size={"small"}
            sx={{
              borderRadius: "4px",
              background: "#fff",
              boxShadow: "0px 4px 8px rgba(16, 24, 64, 0.16)",
              "& .MuiInputBase-root.Mui-disabled": {
                background: "#E0E2EA",
                color: "#A6A6A6",
              },
            }}
          >
            <Select
              labelId="customer-name-label"
              id="location-name"
              value={selectedLocation || ''}
              onChange={handleLocationChange}
            >
              {selectedCustomer && selectedCustomer.locations.map((item) => (
                <MenuItem key={item.locationId} value={item}>
                  {item.locationName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box sx={styles.routesContainer}>
          {props.showAllPages == true && (
            NavLinks.map(({ icon, link, text }, index) => {
              return (
                <Box key={index}>
                  <NavLink
                    onClick={() => handleSelectedBoardChange(text)}
                    style={props.selectedBoard === text ? styles.navLinkActive : styles.navLink}
                    to={link}
                  >
                    <img src={icon} alt={""} style={{ marginRight: "10px" }} />
                    <Typography
                      sx={{
                        fontWeight: props.selectedBoard === text ? 600 : 400,
                      }}
                    >
                      {text}
                    </Typography>
                  </NavLink>
                  {text === "Asset Management IOT" && (
                    NavLinkSubItems.map(({ icon, link, text }, index) => {
                      return (
                        <Box style={{ marginLeft: "20px" }} key={index}>
                          <NavLink
                            onClick={() => text !== "Reports" ? handleSelectedBoardChange(text) : ""}
                            style={props.selectedBoard === text ? styles.navLinkActive : styles.navLink}
                            to={link}
                          >
                            <img src={icon} alt={""} style={{ marginRight: "10px", fill: "#ed6866" }} />
                            <Typography
                              sx={{
                                fontWeight: props.selectedBoard === text ? 600 : 400,
                              }}
                            >
                              {text}
                            </Typography>
                          </NavLink>
                          {text === "Reports" && (
                            ReportSubItems.map(({ icon, link, text }, index) => {
                              return (
                                <Box style={{ marginLeft: "20px" }} key={index}>
                                  <NavLink
                                    onClick={() => handleSelectedBoardChange(text)}
                                    style={props.selectedBoard === text ? styles.navLinkActive : styles.navLink}
                                    to={link}
                                  >
                                    <img src={icon} alt={""} style={{ marginRight: "10px" }} />
                                    <Typography
                                      sx={{
                                        fontWeight: props.selectedBoard === text ? 600 : 400,
                                      }}
                                    >
                                      {text}
                                    </Typography>
                                  </NavLink>
                                </Box>
                              )
                            })
                          )}
                        </Box>
                      )
                    })
                  )}

                  {index === 0 && (
                    <Typography
                      sx={{
                        margin: "20px 0px 10px 0px",
                      }}
                    >
                      Settings
                    </Typography>
                  )}
                </Box>
              );
            })
          )
          }
          {props.showDuoIOTPages == true && (
              DuoNavLinks.map(({ icon, link, text }, index) => {
                return (
                  <Box key={index}>
                    <NavLink
                      onClick={() => text !== "Delivery IOT" ? handleSelectedBoardChange(text) : ""}
                      style={props.selectedBoard === text ? styles.navLinkActive : styles.navLink}
                      to={link}
                    >
                      <img src={icon} alt={""} style={{ marginRight: "10px" }} />
                      <Typography
                        sx={{
                          fontWeight: props.selectedBoard === text ? 600 : 400,
                        }}
                      >
                        {text}
                      </Typography>
                    </NavLink>
                    {text === "Delivery IOT" && (
                      DuoNavLinkSubItems.map(({ icon, link, text }, index) => {
                        return (
                          <Box style={{ marginLeft: "20px" }} key={index}>
                            <NavLink
                              onClick={() => handleSelectedBoardChange(text)}
                              style={props.selectedBoard === text ? styles.navLinkActive : styles.navLink}
                              to={link}
                            >
                              <img src={icon} alt={""} style={{ marginRight: "10px", fill: "#ed6866" }} />
                              <Typography
                                sx={{
                                  fontWeight: props.selectedBoard === text ? 600 : 400,
                                }}
                              >
                                {text}
                              </Typography>
                            </NavLink>
                            {/* {text === "Reports" && (
                              ReportSubItems.map(({ icon, link, text }, index) => {
                                return (
                                  <Box style={{ marginLeft: "20px" }} key={index}>
                                    <NavLink
                                      onClick={() => handleSelectedBoardChange(text)}
                                      style={props.selectedBoard === text ? styles.navLinkActive : styles.navLink}
                                      to={link}
                                    >
                                      <img src={icon} alt={""} style={{ marginRight: "10px" }} />
                                      <Typography
                                        sx={{
                                          fontWeight: props.selectedBoard === text ? 600 : 400,
                                        }}
                                      >
                                        {text}
                                      </Typography>
                                    </NavLink>
                                  </Box>
                                )
                              })
                            )} */}
                          </Box>
                        )
                      })
                    )}

                    {/* {index === 0 && (
                      <Typography
                        sx={{
                          margin: "20px 0px 10px 0px",
                        }}
                      >
                        Settings
                      </Typography>
                    )} */}
                  </Box>
                );
              })
          )
          }
          {props.showAMPages == true && (
              AMNavLinks.map(({ icon, link, text }, index) => {
                return (
                  <Box key={index}>
                    <NavLink
                      onClick={() => handleSelectedBoardChange(text)}
                      style={props.selectedBoard === text ? styles.navLinkActive : styles.navLink}
                      to={link}
                    >
                      <img src={icon} alt={""} style={{ marginRight: "10px" }} />
                      <Typography
                        sx={{
                          fontWeight: props.selectedBoard === text ? 600 : 400,
                        }}
                      >
                        {text}
                      </Typography>
                    </NavLink>
                    {text === "Asset Management IOT" && (
                      AMNavLinkSubItems.map(({ icon, link, text }, index) => {
                        return (
                          <Box style={{ marginLeft: "20px" }} key={index}>
                            <NavLink
                              onClick={() => text !== "Reports" ? handleSelectedBoardChange(text) : ""}
                              style={props.selectedBoard === text ? styles.navLinkActive : styles.navLink}
                              to={link}
                            >
                              <img src={icon} alt={""} style={{ marginRight: "10px", fill: "#ed6866" }} />
                              <Typography
                                sx={{
                                  fontWeight: props.selectedBoard === text ? 600 : 400,
                                }}
                              >
                                {text}
                              </Typography>
                            </NavLink>
                            {text === "Reports" && (
                              ReportSubItems.map(({ icon, link, text }, index) => {
                                return (
                                  <Box style={{ marginLeft: "20px" }} key={index}>
                                    <NavLink
                                      onClick={() => handleSelectedBoardChange(text)}
                                      style={props.selectedBoard === text ? styles.navLinkActive : styles.navLink}
                                      to={link}
                                    >
                                      <img src={icon} alt={""} style={{ marginRight: "10px" }} />
                                      <Typography
                                        sx={{
                                          fontWeight: props.selectedBoard === text ? 600 : 400,
                                        }}
                                      >
                                        {text}
                                      </Typography>
                                    </NavLink>
                                  </Box>
                                )
                              })
                            )}
                          </Box>
                        )
                      })
                    )}

                    {index === 0 && (
                      <Typography
                        sx={{
                          margin: "20px 0px 10px 0px",
                        }}
                      >
                        Settings
                      </Typography>
                    )}
                  </Box>
                );
              })
          )
          }
        </Box>
      </Drawer>
    }
  </>;
};

const styles = {
  sideNav: {
    width: "250px",
    height: "100vh",
    flexShrink: 0,
    position: "fixed",
    zIndex: "100",
    "& .MuiDrawer-paper": {
      width: "255px",
      boxSizing: "border-box",
      background: "#3b62a1",
      padding: "20px 0px 20px 20px",
      position: "relative",
    },
  },
  logoText: {
    fontSize: "18px",
    color: "#fff",
    fontWeight: 700,
    marginTop: "5px",
  },
  routesContainer: {
    marginTop: "40px",
    color: "#D8DAE5",
  },
  navLink: {
    display: "flex",
    alignItems: "center",
    margin: "7px 0px 8px 0px",
    color: "#D8DAE5",
    textDecoration: "none",
  },
  navLinkActive: {
    fontWeight: 600,
    display: "flex",
    alignItems: "center",
    margin: "7px 0px 8px 0px",
    color: "#fff",
    textDecoration: "none",
    borderBottom: `2px solid #38D2EA`,
  },
  collapseSideNav: {
    width: "50px",
    height: "100vh",
    flexShrink: 0,
    position: "fixed",
    zIndex: "100",
    "& .MuiDrawer-paper": {
      width: "50px",
      boxSizing: "border-box",
      background: "#3b62a1",
      position: "relative",
    },
  },
};

const mapStateToProps = (state) => {
  return {
    selectedBoard: state.selectedBoard,
    showNavMenu: state.showNavMenu,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateSelectedCustomer: (customerInfo) => dispatch(LiftActions.CUSTOMER_UPDATE(customerInfo)),
    updateSelectedLocation: (locationInfo) => dispatch(LiftActions.LOCATION_UPDATE(locationInfo)),
    updateSelectedBoardType: (selectedType) => dispatch(LiftActions.LOAD_DASHBOARD_TYPE_UPDATE(selectedType)),
    updateNavMenuType: (showNavMenu) => dispatch(LiftActions.LOAD_NAVMENU_TYPE_UPDATE(showNavMenu)),
    updateDatesSelectedForMap: (selectedDates) => dispatch(LiftActions.UPDATE_USER_SELECTED_MAP_DATES(selectedDates)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SideNav);
