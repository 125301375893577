import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { connect } from 'react-redux'
import * as LiftActions from '../../../store/lift/lift.actions'
import { Box, Button, CircularProgress, Grid, InputAdornment, TextField, Typography, Stack } from '@mui/material';
import SearchIcon from "../../../assets/searchIcon.svg";

const LiftManagementBottomPanel = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [tableData, setTableData] = useState(props.selectedLiftsForLocation?.lifts);
    const [pageSize, setPageSize] = useState(10);

    const handleChange = (value) => {
        props.updateSearchInput(value);
    }

    const handleEditLift = (data, editButtonArea) => {
        if (editButtonArea === "Edit Lift") {
            props.showEditLiftModal("Edit Lift", true, data)
        } else {
            props.showEditLiftModal("Assign Lift", true, data)
        }
    }

    const handleUnassignLift = (event) => {
        let locationID;
        if (props.selectedCustomerListData !== undefined, props.selectedCustomerListData.locations !== undefined) {
            if (props.selectedCustomerListData.locations.length > 0) {
                props.selectedCustomerListData.locations.map(location => {
                    if (location.locationName === 'Unassigned') {
                        locationID = location.locationId
                    }
                })
            }
        }
        const loggedInUserIDToken = localStorage.getItem("authToken");
        let liftStatus = event.active
        let liftID = event.liftId
        let driverID = 0
        let routeName = ""
        let truckName = ""
        props.updateLiftDetails(loggedInUserIDToken, liftID, locationID, driverID, routeName, truckName, liftStatus, getLiftsForLocations, getUpdatedUsersAndLocation)
    }

    const getLiftsForLocations = () => {
        const customerId = props.selectedCustomerListData && props.selectedCustomerListData.customerId;
        const locationId = props.selectedCustomerListData && props.selectedLocationListData.locationId;
        const loggedInUserIDToken = localStorage.getItem("authToken");
        props.fetchLiftsForLocation(loggedInUserIDToken, customerId, locationId)
    }

    const getUpdatedUsersAndLocation = () => {
        const loggedInUserIDToken = localStorage.getItem("authToken");
        props.fetchUsersAndLocation(loggedInUserIDToken)
    }

    let assignLiftButton = "Assign Lift"
    let editLiftButton = "Edit Lift"
    let editLiftStatusButton = "Unassign"

    let selectedLiftsForLocation = props.selectedLiftsForLocation
    let editButtonArea;
    let unassignButtonArea;
    var editButtonAreaTitle = "";
    var editButtonWidth = "100px";
    var unassignButtonWidth = "140px";

    const runSearchQuery = (searchInput) => {
        const filteredLiftData = props.selectedLiftsForLocation?.lifts.filter((row) => {
            return (
                (row.liftId && row.liftId.toUpperCase().includes(searchInput.toUpperCase())) ||
                (row.firstName && row.firstName.toUpperCase().includes(searchInput.toUpperCase())) ||
                (row.lastName && row.lastName.toUpperCase().includes(searchInput.toUpperCase())) ||
                (row.route && row.route.toUpperCase().includes(searchInput.toUpperCase())) ||
                (row.truck && row.truck.toUpperCase().includes(searchInput.toUpperCase())) ||
                (row.statusDesc && row.statusDesc.toUpperCase().includes(searchInput.toUpperCase())) 
            );
        });
        setTableData(filteredLiftData)
    };

    useEffect(() => {
        if (props.searchInput) {
            runSearchQuery(props.searchInput);
        } else if (props.selectedLiftsForLocation) {
            setTableData(props.selectedLiftsForLocation?.lifts);
        }
    }, [props.searchInput, props.selectedLiftsForLocation]);

    useEffect(() => {
        if (tableData) {
            setIsLoading(false)
        }
    }, [tableData])

    if (selectedLiftsForLocation != undefined) {
        if (selectedLiftsForLocation.displayStatus && !props.isReadOnly) {
            if (selectedLiftsForLocation.locationName === 'Unassigned') {
                editButtonArea = assignLiftButton
                unassignButtonArea = null
                editButtonAreaTitle = "Assign Lift"
            } else {
                editButtonArea = editLiftButton
                unassignButtonArea = editLiftStatusButton
                editButtonAreaTitle = "Edit Lift"
            }
            if (selectedLiftsForLocation.lifts.length > 0) {
                let lifts = selectedLiftsForLocation.lifts
                lifts.map(liftInfo => {
                    if (liftInfo.active === true) {
                        liftInfo['statusDesc'] = "Active"
                    } else {
                        liftInfo['statusDesc'] = "InActive"
                    }
                    if (liftInfo.firstName === null) {
                        liftInfo['displayFirstName'] = "N/A"
                    } else {
                        liftInfo['displayFirstName'] = liftInfo.firstName
                    }
                    if (liftInfo.lastName === null) {
                        liftInfo['displayLastName'] = "N/A"
                    } else {
                        liftInfo['displayLastName'] = liftInfo.lastName 
                    }
                    if (liftInfo.route === null) {
                        liftInfo['displayRouteName'] = "N/A"
                    } else {
                        liftInfo['displayRouteName'] = liftInfo.route 
                    }
                    if (liftInfo.truck === null) {
                        liftInfo['displayTruckName'] = "N/A"
                    } else {
                        liftInfo['displayTruckName'] = liftInfo.truck
                    }
                })
            }
        } else {
            editButtonArea = null
            unassignButtonArea = null
            editButtonAreaTitle = ""
            editButtonWidth = "0px"
            unassignButtonWidth = "0px"
        }
    }

    const columns = [
        {
            headerName: "Lift SR #",
            field: "liftId",
            flex: 1
        },
        {
            headerName: "First Name",
            field: "displayFirstName",
            flex: 1
        },
        {
            headerName: "Last Name",
            field: "displayLastName",
            flex: 1
        },
        {
            headerName: "Route",
            field: "displayRouteName",
            flex: 1
        },
        {
            headerName: "Truck",
            field: "displayTruckName",
            flex: 1
        },
        {
            headerName: "Status",
            field: "statusDesc",
            flex: 1
        },
        {
            headerName: "Edit Lift",
            field: "editButtonArea",
            flex: 1,
            renderCell: ({ row }) =>
                <Button sx={styles.edit} onClick={() => handleEditLift(row, editButtonArea)}>
                    {editButtonArea}
                </Button>,
        },
        {
            headerName: "Edit Lift",
            field: "unassignButtonArea",
            flex: 1,
            renderCell: ({ row }) =>
                <Button sx={styles.edit} onClick={() => handleUnassignLift(row)}>
                    {unassignButtonArea}
                </Button>,
        }
    ];

    return (
        <div style={{ width: "100%" }}>
            <Typography sx={styles.heading}>Lift Management</Typography>
            {isLoading ?
                <Stack alignItems="center">
                    <CircularProgress color="inherit" />
                </Stack> :
                <>
                    <Grid container>
                        <Grid item xs={3}>
                            <Box sx={{ width: "100%" }} mt={4} mb={4}>
                                <TextField
                                    value={props?.searchInput}
                                    onChange={(e) => {
                                        handleChange(e.target.value);
                                    }}
                                    autoComplete="off"
                                    sx={{
                                        borderRadius: "4px",
                                        input: {
                                            color: "#033F53",
                                        },
                                        width: "100%",
                                        background: "#fff",
                                        boxShadow: "0px 4px 8px rgba(16, 24, 64, 0.16)",
                                        "& .MuiInputBase-root.Mui-disabled": {
                                            background: "#E0E2EA",
                                            color: "#A6A6A6",
                                        },
                                    }}
                                    size={"small"}
                                    placeholder="Search"
                                    id="outlined-start-adornment"
                                    InputProps={{
                                        startAdornment:
                                            <InputAdornment position="start">
                                                <img src={SearchIcon} alt="" />
                                            </InputAdornment>
                                    }}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                    {tableData && (
                        <DataGrid
                            rows={tableData}
                            columns={columns}
                            getRowId={(row) => row.liftId}
                            disableColumnMenu
                            autoHeight={true}
                            pageSize={pageSize}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            rowsPerPageOptions={[10, 20, 30, 40, 50]}
                            disableSelectionOnClick
                            hideFooterPagination={tableData.length > 0 ? false: true}
                            sx={{
                                ".MuiDataGrid-columnHeaders": {
                                    backgroundColor: "#FAFBFF",
                                },
                                '.MuiDataGrid-columnSeparator': {
                                    display: 'none',
                                },
                                '.MuiDataGrid-cell:focus': {
                                    outline: "none !important",
                                }
                            }}
                        />
                    )}
                </>
            }
        </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        showEditLiftModal: (type, show, userData) => dispatch(LiftActions.LIFTS_EDIT_MODAL_UPDATE(type, show, userData)),
        updateLiftDetails: (userToken, liftId, locationId, driverId, routeName, truckName, liftStatus, updateLiftDetails, updateLocationDetails) => dispatch(LiftActions.LIFTS_UPDATE_REQUEST_TYPE(userToken, liftId, locationId, driverId, routeName, truckName, liftStatus, updateLiftDetails, updateLocationDetails)),
        fetchLiftsForLocation: (userToken, customerId, locationId) => dispatch(LiftActions.LOCATIONS_LIFTS_REQUEST_TYPE(userToken, customerId, locationId)),
        fetchUsersAndLocation: (userToken) => dispatch(LiftActions.LIFT_USERS_LOCATIONS_REQUEST_TYPE(userToken)),
        updateSearchInput: (searchInput) => dispatch(LiftActions.SEARCH_INPUT_UPDATE(searchInput))
    }
}

const mapStateToProps = (state) => {
    return {
        selectedLocationForCustomerLifts: state.selectedLocationsForLifts,
        selectedLiftsForLocation: state.selectedLiftsForLocation,
        selectedCustomerListData: state.selectedCustomerListData,
        selectedLocationListData: state.selectedLocationListData,
        searchInput: state.searchInput
    }
}

export const styles = {
    heading: {
        color: "#303030",
        fontSize: "24px",
        fontWeight: 700,
    },
    edit: {
        color: "#3b62a1",
        fontSize: "12px",
        fontWeight: "600",
        cursor: "pointer",
        textTransform: 'none'
    },
}

export default connect(mapStateToProps, mapDispatchToProps)(LiftManagementBottomPanel);
