import React, { Children, useState, useContext } from "react";
import { useLocation } from "react-router-dom";

import { Checkbox, TableCell, TableHead, TableRow } from "@mui/material";


const AppTableHead = ({ tableHeadItems }) => {
  const style = {
    color: "#696F8C",
    fontWeight: 400,
    fontSize: 12,
    padding: "23px 24px",
  };
  const styleCheckBox = {
    // color: COLORS.neutraln700,
    fontWeight: 400,
    fontSize: 12,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const tableHeader = tableHeadItems.columns;

  return (
    <>
      <TableHead sx={{ background: "#FAFBFF" }}>
        <TableRow>
          {
            tableHeader && tableHeader.map(({ column }, index) => {
              return (
                <TableCell sx={style} key={index}>
                  {column} 
                </TableCell>
              );
            })
          }
        </TableRow>
      </TableHead>
    </>
  );
};
export default AppTableHead;
