import React from 'react'
import * as LiftActions from '../../../store/lift/lift.actions'
import {connect} from 'react-redux'
import Checkbox from '@mui/material/Checkbox';
import UserFloatingInputLabels from '../../tools/UserFloatingInputLabels2/UserFLoatingInputLabels.js';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
class UserManagementAddEditModal extends React.Component {

    state = {
      showLocations: true,
      userRoleSelected: null,
      userActive: null,
      userStatusChanged: false,
    }

     rpcAdminUserRoles = [ 
      "RpcAdmin",
      "CustomerAdmin",
      "CustomerReadOnly",
      "LocationAdmin",
      "LocationReadOnly",
      "FleetComparisonUser",
      "Maintenance",
      "Driver",
    ]

    customerAdminUserRoles = [
      "CustomerAdmin",
      "CustomerReadOnly",
      "LocationAdmin",
      "LocationReadOnly",
      "FleetComparisonUser",
      "Maintenance",
      "Driver",
    ]

    locationAdminUserRoles = [
      "LocationAdmin",
      "LocationReadOnly",
      "Maintenance",
      "Driver",
    ]

    hideModal = (event) => {
        if (event != undefined) {
          this.setState({'userStatusChanged': false});
          if (event === "Add User") {
            this.props.hideUserAddModal("Add User", false)
          } else if (event === "Edit User"){
            this.props.hideUserEditModal("Edit User", false);
          } 
        }
      }

    userTextEntered = (event) => {
        let requestType
        let authToken
        var userId = ""
        let userName
        let firstName
        let lastName
        let email
        let active
        let customerId
        let password
        let role
        var locations = []
      
        authToken = localStorage.getItem("authToken");
        if (this.props.selectedLocationForCustomer != undefined) {
          customerId = this.props.selectedLocationForCustomer.customerName
        }
        if (this.props.showModalType === "Edit User") {
          requestType = "Edit User"
          if (this.props.userData != undefined) {
            userId = this.props.userData.userId
            if (this.state.userStatusChanged) {
              active = this.state.userActive
            } else {
              active = this.props.userData.active
            }
          }
          userName = event.userName
          firstName = event.firstName
          lastName = event.lastName
          email = event.email
          if (event.password != undefined) {
            password = event.password
          }
          role = event.userRole
          if (event.userRole !== "RpcAdmin" && event.userRole !== "CustomerAdmin" && event.userRole !== "CustomerReadOnly" && event.userRole !== "FleetComparisonUser") {
            if (event.locations && event.locations.length > 0) {
              locations=[...event.locations];
            }
          }
        } else {
          requestType = "Add User"
          active = this.state.userActive
          userName = event.userName
          firstName = event.firstName
          lastName = event.lastName
          email = event.email
          if (event.password != undefined) {
            password = event.password
          }
          role = event.userRole
          if (event.userRole !== "RpcAdmin" && event.userRole !== "CustomerAdmin" && event.userRole !== "CustomerReadOnly") {
            if (event.locations && event.locations.length > 0) {
              event.locations.map(location => {
                locations=[...event.locations];
              })
            }
          }
        }
        this.props.editUserDetails(requestType, authToken, userId, userName, firstName, lastName, email, active, customerId, password, role, locations, this.getUsersForLocations, this.getDriversForLifts) 
    }

    getUsersForLocations = () => {
      const loggedInUserIDToken = localStorage.getItem("authToken");
      this.props.fetchUsersForLocation(loggedInUserIDToken)
    }

    getDriversForLifts = () => {
    const loggedInUserIDToken = localStorage.getItem("authToken");
    this.props.fetchDriversForLifts(loggedInUserIDToken)
    }

    handleCheckBoxChange = (event) => {
      this.setState({
        userActive: event.target.checked,
        userStatusChanged: true
      })
    }

    render() {
      let userRoleError = this.props.showUserModalRoleError
      let userRoleErrorText;
      if (userRoleError) {
        userRoleErrorText = 
          <span style={{marginLeft: "20pxpx"}}>Select User Role to continue</span>
      } else {
        userRoleErrorText = null
      }
      let userRole;
      let userRolesData;
      let firstName;
      let lastName;
      let email;
      let userName;
      let password;
      let userActive = false
      let modalTitle;
      let userLocations = []
      let customerLocations = []
      let showLocationsArea = false
      let isPasswordMandatory = true
      let isEmailMandatory = true
      let passwordHint = ""

      let role = localStorage.getItem('userRole')

      if (role != undefined) {
        if (role === "RpcAdmin") {
          userRolesData = this.rpcAdminUserRoles
        } else if (role === "CustomerAdmin") {
          userRolesData = this.customerAdminUserRoles
        } else if (role === "LocationAdmin") {
          userRolesData = this.locationAdminUserRoles
        } 
      }

      if (this.props.showModalType === "Edit User") {
        modalTitle = "Edit User"
        passwordHint = "Enter password here to change"
        if (this.props.userData != undefined) {
          let userInfo = this.props.userData
          userRole = userInfo.role
          firstName = userInfo.firstName
          lastName = userInfo.lastName
          email = userInfo.email
          userName = userInfo.userName
          userActive = userInfo.active
          isPasswordMandatory = false
          if (userRole !== "RpcAdmin" && userRole !== "CustomerAdmin" && userRole !== "CustomerReadOnly" && userRole !== "FleetComparisonUser") {
            if (userRole === "Driver") {

              isEmailMandatory = false
            } else {
              isEmailMandatory = true
            }
            showLocationsArea = true
          }
          if (userInfo.locations != undefined) {
            userInfo.locations.map(location => {
              if (location.locationName != undefined) {
                userLocations.push
                ({
                  locationName: location.locationName,
                  locationId: location.locationId,
                })
              }
          })
          }
          if (this.props.selectedLocationForCustomer != undefined) {
            this.props.selectedLocationForCustomer.locations.map(location => {
              if (location.locationName != undefined) {
                customerLocations.push
                ({
                  locationName: location.locationName,
                  locationId: location.locationId,
                })
              }
          })
          }
        }
      } else {
        modalTitle = "Add User"
        isPasswordMandatory = true
        isEmailMandatory = true
        passwordHint = "Hint: Enter password here"

        if (this.props.selectedLocationForCustomer != undefined) {
          this.props.selectedLocationForCustomer.locations.map(location => {
            if (location.locationName != undefined) {
              customerLocations.push
              ({
                locationName: location.locationName,
                locationId: location.locationId,
              })
            }
        })
        }
      }

      if (this.props.showModal) {
        return (
          <div>
            <Dialog open={this.props.showModal} onClose={this.hideModal.bind(this, this.props.showModalType)}
              PaperProps={{ sx: { width: '500px', minHeight: "650px" } }}>
              <DialogTitle>{modalTitle}</DialogTitle>
              <DialogContent>
                <div className="userModalTopSelection">
                  <FormGroup>
                    <FormControlLabel control={<Checkbox checked={this.state.userStatusChanged ? this.state.userActive : userActive} onChange={this.handleCheckBoxChange} />} label="Active Customer" />
                  </FormGroup>
                </div>
                <UserFloatingInputLabels id={this.props.showModalType} currentUserRole={userRole} userRoleData={userRolesData} firstName={firstName} lastName={lastName} email={email} userName={userName} locations={userLocations} locationData={customerLocations} showLocationsArea={showLocationsArea} isPasswordMandatory={isPasswordMandatory} isEmailMandatory={isEmailMandatory} passwordHint={passwordHint} saveButtonStatus={this.state.userStatusChanged} onValueChange={this.userTextEntered} >
                </UserFloatingInputLabels>
              </DialogContent>
            </Dialog>
          </div>
        )
      } else {
        return null
      }
    }

}

const mapStateToProps = (state) => {
    return {
      showModal: state.showUserModal,
      showModalType : state.showModalType,
      userData: state.selectedUserToEdit,
      selectedLocationForCustomer: state.selectedLocationForCustomer,
      showUserModalRoleError: state.showUserModalRoleError,
    }
  }

  const mapDispatchToProps = (dispatch) =>{
    return {
      hideUserAddModal: (type, show) => dispatch(LiftActions.USERS_ADD_MODAL_UPDATE(type, show)),
      hideUserEditModal: (type, show) => dispatch(LiftActions.USERS_EDIT_MODAL_UPDATE(type, show)),
      userModalRoleErrorUpdate: (show) => dispatch(LiftActions.SHOW_ERROR_USER_ROLE_UPDATE(show)),
      editUserDetails:(requestType, authToken, userId, username, firstname, lastname, email, active, customerId, password, role, locations, getUpdatedUsers, getUpdatedDrivers) => dispatch(LiftActions.LOCATION_USERS_UPDATE_REQUEST_TYPE(requestType, authToken, userId, username, firstname, lastname, email, active, customerId, password, role, locations, getUpdatedUsers, getUpdatedDrivers)),
      fetchDriversForLifts: (userToken) => dispatch(LiftActions.LIFTS_DRIVERS_REQUEST_TYPE(userToken, "", "")),
      fetchUsersForLocation: (userToken) => dispatch(LiftActions.LOCATIONS_USERS_REQUEST_TYPE(userToken)),
    }
  }

export default connect(mapStateToProps, mapDispatchToProps) (UserManagementAddEditModal);
