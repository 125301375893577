import * as ActionTypes from './lift.actiontypes'

export const LIFT_DASHBOARD_LOGIN_REQUEST = (userName, password, navigateToDashboard, showLoadingIndicator) => {
    return {
        type: ActionTypes.LIFT_DASHBOARD_LOGIN_REQUEST_TYPE,
        payload: {userName, password, navigateToDashboard, showLoadingIndicator}
    }
}

export const LIFT_DASHBOARD_LOGIN_REQUEST_SUCCESS = (data) => {
    return {
        type: ActionTypes.LIFT_DASHBOARD_LOGIN_SUCCESS_TYPE,
        payload: data
    }
}

export const LIFT_DASHBOARD_LOGIN_REQUEST_FAILURE = (error) => {
    return {
        type: ActionTypes.LIFT_DASHBOARD_LOGIN_FAILURE_TYPE,
        payload: error
    }
}
export const VALIDATE_AUTHENTICATION_REQUEST = (userName, password) => {
    return {
        type: ActionTypes.VALIDATE_AUTHENTICATION_REQUEST_TYPE,
        payload: {userName, password}
    }
}
export const VALIDATE_AUTHENTICATION_REQUEST_SUCCESS = (data) => {
    return {
        type: ActionTypes.VALIDATE_AUTHENTICATION_REQUEST_SUCCESS_TYPE,
        payload: data
    }
}
export const VALIDATE_AUTHENTICATION_REQUEST_FAILURE = (error) => {
    return {
        type: ActionTypes.VALIDATE_AUTHENTICATION_REQUEST_FAILURE_TYPE,
        payload: error
    }
}
export const LOAD_CUSTOMERS_AND_LOCATIONS = (userToken) => {
    return {
    type: ActionTypes.LOAD_CUSTOMERS_AND_LOCATIONS,
    payload: {userToken}
    }
}

export const LOAD_CUSTOMERS_AND_LOCATIONS_SUCCESS_TYPE = (data) => {
    return {
    type: ActionTypes.LOAD_CUSTOMERS_AND_LOCATIONS_SUCCESS_TYPE,
    payload: data
    }
}
export const LOAD_CUSTOMERS_AND_LOCATIONS_FAILURE_TYPE = (error) => {
    return {
    type: ActionTypes.LOAD_CUSTOMERS_AND_LOCATIONS_FAILURE_TYPE,
    error: error
    }
}

export const UPDATE_SELECTED_LIFT_CUSTOMERS = (selectedCustomer, locationData) => {
    return {
    type: ActionTypes.UPDATE_SELECTED_LIFT_CUSTOMERS,
    payload: {selectedCustomer, locationData}
    }
}

export const UPDATE_SELECTED_LIFT_LOCATIONS = (selectedLocation) => {
    return {
    type: ActionTypes.UPDATE_SELECTED_LIFT_LOCATIONS,
    payload: selectedLocation
    }
}


export const LOAD_LIFT_SUMMARY = (userToken, customerId, locationId) => {
    return {
    type: ActionTypes.LOAD_LIFT_SUMMARY_TYPE,
    payload: {userToken, customerId, locationId}
    }
}

export const LOAD_LIFT_STOP_ALERT_DATA = (userToken, customerId, locationId, fetchDeviceDetails, hideLoadingIndicator) => {
    return {
    type: ActionTypes.LOAD_LIFT_STOP_ALERT_DATA,
    payload: {userToken, customerId, locationId, fetchDeviceDetails, hideLoadingIndicator}
    }
}

export const LOAD_LIFT_MAP_GEOCODER = (data) => {
    return {
    type: ActionTypes.LIFT_MAP_GEOCODER,
    payload: data
    }
}
export const RESET_ALERT_DETAIL_MODAL_STATE = () => {
    return {
    type: ActionTypes.LIFT_ALERT_DETAIL_MODAL,
    payload: null
    }
}
export const LOAD_LIFT_SUMMARY_SUCCESS = (data) => {
    return {
    type: ActionTypes.LOAD_LIFT_SUMMARY_SUCCESS_TYPE,
    payload: data
    }
}
export const LOAD_LIFT_SUMMARY_FAILURE = (error) => {
    return {
    type: ActionTypes.LOAD_LIFT_SUMMARY_FAILURE_TYPE,
    error: error
    }
}
    export const LOAD_LIFT_DETAIL = (id, userToken, startDate) => {
        return {
        type: ActionTypes.LOAD_LIFT_DETAIL_TYPE,
        payload: {id: id, userToken: userToken, startDate: startDate}
        }
    }
    export const LOAD_LIFT_DETAIL_SUCCESS = (data) => {
        return {
        type: ActionTypes.LOAD_LIFT_DETAIL_SUCCESS_TYPE,
        payload: data
        }
    }
    export const LOAD_LIFT_DETAIL_FAILURE = (error) => {
        return {
        type: ActionTypes.LOAD_LIFT_DETAIL_FAILURE_TYPE,
        error: error
        }
    }
    export const LOAD_LIFT_ALERT_HISTORY = (id, startDate, userToken) => {
        return {
        type: ActionTypes.LOAD_LIFT_ALERT_HISTORY_TYPE,
        payload: {id: id, startDate: startDate, userToken: userToken}
        }
    }
    export const LOAD_LIFT_ALERT_HISTORY_SUCCESS = (data) => {
        return {
        type: ActionTypes.LOAD_LIFT_ALERT_HISTORY_SUCCESS_TYPE,
        payload: data
        }
    }
    export const LOAD_LIFT_ALERT_HISTORY_FAILURE = (error) => {
        return {
        type: ActionTypes.LOAD_LIFT_ALERT_HISTORY_FAILURE_TYPE,
        error: error
        }
    }

    export const LOAD_LIFT_ALERT_DETAIL_HISTORY = (id,alert,userToken) => {
        return {
        type: ActionTypes.LOAD_LIFT_ALERT_DETAIL_HISTORY_TYPE,
        payload: {id: id, alert:alert, userToken: userToken}
        }
    }

    export const LOAD_LIFT_ALERT_DETAIL_HISTORY_SUCCESS = (data, alert) => {
        return {
        type: ActionTypes.LOAD_LIFT_ALERT_DETAIL_HISTORY_SUCCESS_TYPE,
        payload: data,
        alert: alert
        }
    }
    export const LOAD_LIFT_ALERT_DETAIL_HISTORY_FAILURE = (error) => {
        return {
        type: ActionTypes.LOAD_LIFT_ALERT_DETAIL_HISTORY_FAILURE_TYPE,
        error: error
        }
    }

    export const LOAD_LIFT_ALERT_TRAIL_TYPE = (id, startDate, userToken, getupdatedResults) => {
        return {
            type: ActionTypes.LOAD_LIFT_ALERT_TRAIL_TYPE,
            payload: {id: id, startDate: startDate, userToken: userToken, getupdatedResults: getupdatedResults}
            } 
    }

    export const LOAD_LIFT_ALERT_TRAIL_SUCCESS_TYPE = (data) => {
        return {
            type: ActionTypes.LOAD_LIFT_ALERT_TRAIL_SUCCESS_TYPE,
            payload: data
            } 
    }

    export const LOAD_LIFT_ALERT_TRAIL_FAILURE_TYPE = (error) => {
        return {
            type: ActionTypes.LOAD_LIFT_ALERT_TRAIL_FAILURE_TYPE,
            payload: error
            } 
    }

    export const LOAD_LIFT_BREADCRUMB_HISTORY = (id, startDate, userToken) => {
        return {
        type: ActionTypes.LOAD_LIFT_BREADCRUMB_HISTORY_TYPE,
        payload: {id: id, startDate: startDate, userToken: userToken}
        }
    }


    export const LOAD_LIFT_BREADCRUMB_HISTORY_SUCCESS = (data) => {
        return {
        type: ActionTypes.LOAD_LIFT_BREADCRUMB_HISTORY_SUCCESS_TYPE,
        payload: data
        }
    }

    export const LOAD_LIFT_BREADCRUMB_HISTORY_FAILURE = (error) => {
        return {
        type: ActionTypes.LOAD_LIFT_BREADCRUMB_HISTORY_FAILURE_TYPE,
        error: error
        }
    }

    export const LOAD_LIFT_STOPS_HISTORY_TYPE = (id, startDate, userToken, getupdatedResults) => {
        return {
            type: ActionTypes.LOAD_LIFT_STOPS_HISTORY_TYPE,
            payload: {id: id, startDate: startDate,userToken: userToken, getupdatedResults: getupdatedResults}
        }
    }

    export const LOAD_LIFT_STOPS_HISTORY_SUCCESS = (data) => {
        return {
        type: ActionTypes.LOAD_LIFT_STOPS_HISTORY_SUCCESS_TYPE,
        payload: data
        }
    }

    export const LOAD_LIFT_STOPS_HISTORY_FAILURE = (error) => {
        return {
        type: ActionTypes.LOAD_LIFT_STOPS_HISTORY_FAILURE_TYPE,
        error: error
        }
    }

    export const LOAD_LIFT_MAP_TYPE_UPDATE = (selectedMapType) => {
        return {
        type: ActionTypes.LOAD_LIFT_MAP_TYPE_UPDATE,
        payload: selectedMapType
        }
    }

    export const LOAD_DASHBOARD_TYPE_UPDATE = (selectedDashboardType) => {
        return {
            type: ActionTypes.LOAD_DASHBOARD_TYPE_UPDATE,
            payload: selectedDashboardType
        }
    }

    export const SEARCH_INPUT_UPDATE = (searchInput) => {
        return {
            type: ActionTypes.SEARCH_INPUT_UPDATE,
            payload: searchInput
        }
    }

    export const LIFT_SERVICE_MODAL = (serviceItem, serviceItems, showModal) => {
        return {
            type: ActionTypes.LIFT_SERVICE_MODAL,
            payload: {serviceItem, serviceItems, showModal}
        }
    }

    export const LIFT_SERVICE_MODAL_UPDATE = () => {
        return {
            type: ActionTypes.LIFT_SERVICE_MODAL_UPDATE,
        }
    }

    export const LOAD_NAVMENU_TYPE_UPDATE = (showNavMenu) => {
        return {
            type: ActionTypes.LOAD_NAVMENU_TYPE_UPDATE,
            payload: showNavMenu
        }
    }

    export const LOAD_SIGNOUT_POPUP_TYPE_UPDATE = (showSignOut) => {
        return {
            type: ActionTypes.LOAD_SIGNOUT_POPUP_TYPE_UPDATE,
            payload: showSignOut
        }
    }

    export const SIGNOUT_USER = (showSignOut) => {
        return {
            type: ActionTypes.SIGNOUT_USER,
            payload: showSignOut
        }
    }

    export const UPDATE_REFRESH_DATA_REQUESTED_FLAG = (refreshRequested) => {
        return {
            type: ActionTypes.UPDATE_REFRESH_DATA_REQUESTED_FLAG,
            payload: refreshRequested
        }
    }

    export const UPDATE_USER_SELECTED_MAP_DATES = (datesSelected) => {
        return {
            type: ActionTypes.UPDATE_USER_SELECTED_MAP_DATES,
            payload: datesSelected
        }
    }

    export const LOAD_ALERT_PARETO = (userToken, locationid, startdate, enddate) => {
        return {
            type: ActionTypes.LOAD_ALERT_PARETO,
            payload: {userToken, locationid, startdate, enddate},
        }
    }

    export const LOAD_ALERT_PARETO_SUCCESS = (data) => {
        return {
            type: ActionTypes.LOAD_ALERT_PARETO_SUCCESS,
            payload: data
         } 
    }

    export const LOAD_ALERT_PARETO_FAILURE = (error) => {
        return {
            type: ActionTypes.LOAD_ALERT_PARETO_FAILURE,
            error: error
         } 
    }

    export const UPDATE_FLEET_COMPARISION_SELCTION = (alertType) => {
        return {
            type: ActionTypes.UPDATE_FLEET_COMPARISION_SELCTION,
            payload: alertType
         } 
    }

    export const UPDATE_RIGHT_PANEL_EXPAND_AND_COLLAPSE = (selection) => {
        return {
            type: ActionTypes.UPDATE_RIGHT_PANEL_EXPAND_AND_COLLAPSE,
            payload: selection
         } 
    }

    export const LOAD_LIFT_ALERT_PARETO = (alertID, userToken) => {
        return {
            type: ActionTypes.LOAD_LIFT_ALERT_PARETO,
            payload: {alertID, userToken},
        }
    }

    export const LOAD_LIFT_ALERT_PARETO_SUCCESS = (data) => {
        return {
            type: ActionTypes.LOAD_LIFT_ALERT_PARETO_SUCCESS,
            payload: data
         } 
    }

    export const LOAD_LIFT_ALERT_PARETO_FAILURE = (error) => {
        return {
            type: ActionTypes.LOAD_LIFT_ALERT_PARETO_FAILURE,
            error: error
         } 
    }

    export const RESET_LOAD_LIFT_ALERT_PARETO_MODAL = () => {
        return {
        type: ActionTypes.RESET_LOAD_LIFT_ALERT_PARETO_MODAL,
        payload: null
        }
    }

    export const LOAD_LIFT_MAINTENANCE_MODAL = (statusCode, userToken) => {
        return {
            type: ActionTypes.LOAD_LIFT_MAINTENANCE_MODAL,
            payload: {statusCode, userToken}
         }
    }

    export const LOAD_LIFT_MAINTENANCE_MODAL_SUCCESS = (data) => {
        return {
            type: ActionTypes.LOAD_LIFT_MAINTENANCE_MODAL_SUCCESS,
            payload: data
         } 
    }

    export const LOAD_LIFT_MAINTENANCE_MODAL_FAILURE = (error) => {
        return {
            type: ActionTypes.LOAD_LIFT_MAINTENANCE_MODAL_FAILURE,
            error: error
         } 
    }

    export const UPDATE_LOAD_LIFT_MAINTENANCE_MODAL = () => {
        return {
            type: ActionTypes.UPDATE_LOAD_LIFT_MAINTENANCE_MODAL,
            payload: null
         } 
    }

    export const LOAD_LIFT_STOP_BIOMETRICS = (id, date, userToken) => {
        return {
            type: ActionTypes.LOAD_LIFT_STOP_BIOMETRICS,
            payload: {id, date, userToken}
        }
    }

    export const LOAD_LIFT_STOP_BIOMETRICS_SUCCESS = (data) => {
        return {
            type: ActionTypes.LOAD_LIFT_STOP_BIOMETRICS_SUCCESS,
            payload: data
         } 
    }

    export const LOAD_LIFT_STOP_BIOMETRICS_FAILURE = (error) => {
        return {
            type: ActionTypes.LOAD_LIFT_STOP_BIOMETRICS_FAILURE,
            error: error
         } 
    }

    export const LIFT_USERS_LOCATIONS_REQUEST_TYPE = (userToken) => {
        return {
            type: ActionTypes.LIFT_USERS_LOCATIONS_REQUEST_TYPE,
            payload: {userToken}
        }
    }

    export const LIFT_USERS_LOCATIONS_SUCCESS_TYPE = (data) => {
        return {
            type: ActionTypes.LIFT_USERS_LOCATIONS_SUCCESS_TYPE,
            payload: data
         } 
    }

    export const LIFT_USERS_LOCATIONS_ERROR_TYPE = (data) => {
        return {
            type: ActionTypes.LIFT_USERS_LOCATIONS_ERROR_TYPE,
            payload: data
         } 
    }

    export const LIFT_CUSTOMER_SELECTION_TYPE_UPDATE = (customerSelected) => {
        return {
            type: ActionTypes.LIFT_CUSTOMER_SELECTION_TYPE_UPDATE,
            payload: customerSelected
        }
    }

    export const LIFT_ADD_EDIT_CUSTOMER_MODAL_UPDATE = (type, show) => {
        return {
            type: ActionTypes.LIFT_ADD_EDIT_CUSTOMER_MODAL_UPDATE,
            payload: {type, show}
        }
    }

    export const LIFT_ADD_EDIT_LOCATION_MODAL_UPDATE = (type, location, show) => {
        return {
            type: ActionTypes.LIFT_ADD_EDIT_LOCATION_MODAL_UPDATE,
            payload: {type, location, show}
        }
    }

    export const CUSTOMER_ADD_REQUEST_TYPE = (userToken, customerName, status, callUpdatedResults) => {
        return {
            type: ActionTypes.CUSTOMER_ADD_REQUEST_TYPE,
            payload: {userToken, customerName, status, callUpdatedResults}
        }
    }

    export const CUSTOMER_EDIT_REQUEST_TYPE = (userToken, customerID, customername, status, callUpdatedResults, callUpdatedUsers, callUpdatedLifts) => {
        return {
            type: ActionTypes.CUSTOMER_EDIT_REQUEST_TYPE,
            payload: {userToken, customerID, customername, status, callUpdatedResults, callUpdatedUsers, callUpdatedLifts}
        }
    }

    export const CUSTOMER_ADD_EDIT_SUCCESS_TYPE = (data) => {
        return {
            type: ActionTypes.CUSTOMER_ADD_EDIT_SUCCESS_TYPE,
            payload: data
         } 
    }

    export const CUSTOMER_ADD_EDIT_ERROR_TYPE = (error) => {
        return {
            type: ActionTypes.CUSTOMER_ADD_EDIT_ERROR_TYPE,
            error: error
         } 
    }

    export const LOCATION_ADD_REQUEST_TYPE = (userToken, locationName, customerName, latitude, longitude, formRadius, status, showMaintanance, callUpdatedResults) => {
        return {
            type: ActionTypes.LOCATION_ADD_REQUEST_TYPE,
            payload: {userToken, locationName, customerName, latitude, longitude, formRadius, status, showMaintanance, callUpdatedResults}

        }
    }

    export const LOCATION_EDIT_REQUEST_TYPE = (userToken, locationId, newName, latitude, longitude, formRadius, status, showMaintanance, callUpdatedResults, callUpdatedUsers, callUpdatedLifts) => {
        return {
            type: ActionTypes.LOCATION_EDIT_REQUEST_TYPE,
            payload: {userToken, locationId, newName, latitude, longitude, formRadius, status, showMaintanance, callUpdatedResults, callUpdatedUsers, callUpdatedLifts}

        }
    }

    export const LOCATION_ADD_EDIT_SUCCESS_TYPE = (data) => {
        return {
            type: ActionTypes.LOCATION_ADD_EDIT_SUCCESS_TYPE,
            payload: data
         } 
    }

    export const LOCATION_ADD_EDIT_ERROR_TYPE = (error) => {
        return {
            type: ActionTypes.LOCATION_ADD_EDIT_ERROR_TYPE,
            error: error
         } 
    }

    export const LOCATIONS_USERS_REQUEST_TYPE =(userToken) => {
        return {
            type: ActionTypes.LOCATIONS_USERS_REQUEST_TYPE,
            payload: {userToken}
        }
    }

    export const LOCATIONS_USERS_SUCCESS_TYPE =(data) => {
        return {
            type: ActionTypes.LOCATIONS_USERS_SUCCESS_TYPE,
            payload: data
        }
    }

    export const LOCATIONS_USERS_ERROR_TYPE =(error) => {
        return {
            type: ActionTypes.LOCATIONS_USERS_ERROR_TYPE,
            payload: error
        }
    }

    export const LOCATIONS_USERS_CUSTOMER_UPDATE = (customerInfo) => {
        return {
            type: ActionTypes.LOCATIONS_USERS_CUSTOMER_UPDATE,
            payload: customerInfo 
        }
    }

    export const LOCATIONS_USER_LOCATION_UPDATE = (locationInfo) => {
        return {
            type: ActionTypes.LOCATIONS_USER_LOCATION_UPDATE,
            payload: locationInfo 
        }
    }

    export const CUSTOMER_UPDATE = (customerInfo) => {
        return {
            type: ActionTypes.CUSTOMER_UPDATE,
            payload: customerInfo 
        }
    }

    export const LOCATION_UPDATE = (locationInfo) => {
        return {
            type: ActionTypes.LOCATION_UPDATE,
            payload: locationInfo 
        }
    }


    export const USERS_ADD_MODAL_UPDATE = (type, show) => {
        return {
            type: ActionTypes.USERS_ADD_MODAL_UPDATE,
            payload: {type, show}
        }
    }

    export const USERS_EDIT_MODAL_UPDATE = (type, show, userData) => {
        return {
            type: ActionTypes.USERS_EDIT_MODAL_UPDATE,
            payload: {type, show, userData}
        }
    }

    export const LOCATION_USERS_UPDATE_REQUEST_TYPE = (requestType, authToken, userId, username, firstname, lastname, email, active, customerId, password, role, locations, getUpdatedUsers, getUpdatedDrivers) => {
        return {
            type: ActionTypes.LOCATION_USERS_UPDATE_REQUEST_TYPE,
            payload: {requestType, authToken, userId, username, firstname, lastname, email, active, customerId, password, role, locations, getUpdatedUsers, getUpdatedDrivers}
        }
    }

    export const LOCATION_USERS_UPDATE_SUCCESS_TYPE = (data) => {
        return {
            type: ActionTypes.LOCATION_USERS_UPDATE_SUCCESS_TYPE,
            payload: data
        }
    }

    export const LOCATION_USERS_UPDATE_FAILURE_TYPE = (error) => {
        return {
            type: ActionTypes.LOCATION_USERS_UPDATE_FAILURE_TYPE,
            payload: error
        }
    }

    export const SHOW_ERROR_USER_ROLE_UPDATE = (show) => {
        return {
            type: ActionTypes.SHOW_ERROR_USER_ROLE_UPDATE,
            payload: {show}
        }
    }

    export const LOCATIONS_LIFTS_CUSTOMER_UPDATE = (customerInfo) => {
        return {
            type: ActionTypes.LOCATIONS_LIFTS_CUSTOMER_UPDATE,
            payload: customerInfo 
        }
    }

    export const LOCATIONS_LIFTS_LOCATION_UPDATE = (locationInfo) => {
        return {
            type: ActionTypes.LOCATIONS_LIFTS_LOCATION_UPDATE,
            payload: locationInfo 
        }
    }

    export const LIFTS_EDIT_MODAL_UPDATE = (type, show, liftData) => {
        return {
            type: ActionTypes.LIFTS_EDIT_MODAL_UPDATE,
            payload: {type, show, liftData}
        }
    }

    export const LOCATIONS_LIFTS_REQUEST_TYPE =(userToken,customerId,locationId) => {
        return {
            type: ActionTypes.LOCATIONS_LIFTS_REQUEST_TYPE,
            payload: {userToken,customerId,locationId}
        }
    }

    export const LOCATIONS_LIFTS_SUCCESS_TYPE =(data) => {
        return {
            type: ActionTypes.LOCATIONS_LIFTS_SUCCESS_TYPE,
            payload: data
        }
    }

    export const LOCATIONS_LIFTS_ERROR_TYPE =(error) => {
        return {
            type: ActionTypes.LOCATIONS_LIFTS_ERROR_TYPE,
            payload: error
        }
    }

    export const LIFTS_UPDATE_REQUEST_TYPE = (userToken, liftId, locationId, driverId, routeName, truckName, liftStatus, updateLiftDetails, updateLocationDetails, getDriversForLifts) => {
        return {
            type: ActionTypes.LIFTS_UPDATE_REQUEST_TYPE,
            payload: {userToken, liftId, locationId, driverId, routeName, truckName, liftStatus, updateLiftDetails, updateLocationDetails, getDriversForLifts}
        }
    }

    export const LIFTS_UPDATE_REQUEST_SUCCESS_TYPE =(data) => {
        return {
            type: ActionTypes.LIFTS_UPDATE_REQUEST_SUCCESS_TYPE,
            payload: data
        }
    }

    export const LIFTS_UPDATE_REQUEST_ERROR_TYPE =(error) => {
        return {
            type: ActionTypes.LIFTS_UPDATE_REQUEST_ERROR_TYPE,
            payload: error
        }
    }

    export const LIFTS_DRIVERS_REQUEST_TYPE = (userToken, locationId, customerId) => {
        return {
            type: ActionTypes.LIFTS_DRIVERS_REQUEST_TYPE,
            payload: {userToken, locationId, customerId}
        }
    }

    export const LIFTS_DRIVERS_REQUEST_SUCCESS_TYPE =(data) => {
        return {
            type: ActionTypes.LIFTS_DRIVERS_REQUEST_SUCCESS_TYPE,
            payload: data
        }
    }

    export const LIFTS_DRIVERS_REQUEST_ERROR_TYPE =(error) => {
        return {
            type: ActionTypes.LIFTS_UPDATE_REQUEST_ERROR_TYPE,
            payload: error
        }
    }

    export const LIFT_CLEAR_SERVICE_TASK = (userToken, id, sheduledIDs, date, keyonTime, userId, taskId, updateResults) => {
        return {
            type: ActionTypes.LIFT_CLEAR_SERVICE_TASK,
            payload: {userToken, id, sheduledIDs, date, keyonTime, userId, taskId, updateResults}
        }
    }

    export const LIFT_CLEAR_SERVICE_TASK_SUCCESS =(data) => {
        return {
            type: ActionTypes.LIFT_CLEAR_SERVICE_TASK_SUCCESS,
            payload: data
        }
    }

    export const LIFT_CLEAR_SERVICE_TASK_FAILURE =(error) => {
        return {
            type: ActionTypes.LIFT_CLEAR_SERVICE_TASK_FAILURE,
            payload: error
        }
    }

    export const UPDATE_USER_SELECTED_STOP_DASHBOARD = (selectedStop) => {
        return {
            type: ActionTypes.UPDATE_USER_SELECTED_STOP_DASHBOARD,
            payload: selectedStop
        }
    }

    export const UPDATE_USER_SELECTED_STOP_DASHBOARD_FLAG = (selectedStopStatus) => {
        return {
            type: ActionTypes.UPDATE_USER_SELECTED_STOP_DASHBOARD_FLAG,
            payload: selectedStopStatus
        }
    }

    export const UPDATE_USER_SELECTED_ALERT_DASHBOARD = (selectedStop) => {
        return {
            type: ActionTypes.UPDATE_USER_SELECTED_ALERT_DASHBOARD,
            payload: selectedStop
        }
    }

    export const UPDATE_USER_SELECTED_ALERT_DASHBOARD_FLAG = (selectedStopStatus) => {
        return {
            type: ActionTypes.UPDATE_USER_SELECTED_ALERT_DASHBOARD_FLAG,
            payload: selectedStopStatus
        }
    }

    export const USER_FLEET_REPORT_REQUEST_TYPE = (authToken, locationid, startdate, enddate) => {
        return {
            type: ActionTypes.USER_FLEET_REPORT_REQUEST_TYPE,
            payload: {authToken, locationid, startdate, enddate}
        }
    }

    export const USER_FLEET_REPORT_REQUEST_SUCCESS_TYPE =(data) => {
        return {
            type: ActionTypes.USER_FLEET_REPORT_REQUEST_SUCCESS_TYPE,
            payload: data
        }
    }

    export const USER_FLEET_REPORT_REQUEST_ERROR_TYPE =(error) => {
        return {
            type: ActionTypes.USER_FLEET_REPORT_REQUEST_ERROR_TYPE,
            payload: error
        }
    }

    export const USER_FLEET_REPORT_MODAL_UPDATE = () => {
        return {
            type: ActionTypes.USER_FLEET_REPORT_MODAL_UPDATE,
            payload: null
        }
    }

    export const DEFAULT_ERROR_MESSAGE = () => {
        return {
            type: ActionTypes.DEFAULT_ERROR_MESSAGE,
            payload: null
        }
    }
//For asset details page    

export const ASSET_DETAILS_REQUEST_TYPE =(userToken,customerId,locationId) => {
    return {
        type: ActionTypes.ASSET_DETAILS_REQUEST_TYPE,
        payload: {userToken,customerId,locationId}
    }
}

export const ASSET_DETAILS_SUCCESS_TYPE =(data) => {
    return {
        type: ActionTypes.ASSET_DETAILS_SUCCESS_TYPE,
        payload: data
    }
}

export const ASSET_DETAILS_ERROR_TYPE =(error) => {
    return {
        type: ActionTypes.ASSET_DETAILS_ERROR_TYPE,
        payload: error
    }
}

//Total Assets in float section 

export const TOTAL_ASSETS_REQUEST_TYPE =(userToken,customerId,locationId) => {
    return {
        type: ActionTypes.TOTAL_ASSETS_REQUEST_TYPE,
        payload: {userToken,customerId,locationId}
    }
}

export const TOTAL_ASSETS_SUCCESS_TYPE =(data) => {
    return {
        type: ActionTypes.TOTAL_ASSETS_SUCCESS_TYPE,
        payload: data
    }
}

export const TOTAL_ASSETS_ERROR_TYPE =(error) => {
    return {
        type: ActionTypes.TOTAL_ASSETS_ERROR_TYPE,
        payload: error
    }
}

//Lost Scrap section 

export const LOST_SCRAP_REQUEST_TYPE =(userToken,customerId,locationId) => {
    return {
        type: ActionTypes.LOST_SCRAP_REQUEST_TYPE,
        payload: {userToken,customerId,locationId}
    }
}

export const LOST_SCRAP_SUCCESS_TYPE =(data) => {
    return {
        type: ActionTypes.LOST_SCRAP_SUCCESS_TYPE,
        payload: data
    }
}

export const LOST_SCRAP_ERROR_TYPE =(error) => {
    return {
        type: ActionTypes.LOST_SCRAP_ERROR_TYPE,
        payload: error
    }
}

//Store details section
export const STORE_DETAILS_REQUEST_TYPE =(userToken,customerId,locationId,dashboardFlag) => {
    return {
        type: ActionTypes.STORE_DETAILS_REQUEST_TYPE,
        payload: {userToken,customerId,locationId,dashboardFlag}
    }
}

export const STORE_DETAILS_SUCCESS_TYPE =(data) => {
    return {
        type: ActionTypes.STORE_DETAILS_SUCCESS_TYPE,
        payload: data
    }
}

export const STORE_DETAILS_ERROR_TYPE =(error) => {
    return {
        type: ActionTypes.STORE_DETAILS_ERROR_TYPE,
        payload: error
    }
}


//Dwell & turn time 

export const DWELL_AND_TURN_TIME_REQUEST_TYPE =(userToken,customerId,locationId) => {
    return {
        type: ActionTypes.DWELL_AND_TURN_TIME_REQUEST_TYPE,
        payload: {userToken,customerId,locationId}
    }
}

export const DWELL_AND_TURN_TIME_SUCCESS_TYPE =(data) => {
    return {
        type: ActionTypes.DWELL_AND_TURN_TIME_SUCCESS_TYPE,
        payload: data
    }
}

export const DWELL_AND_TURN_TIME_ERROR_TYPE =(error) => {
    return {
        type: ActionTypes.DWELL_AND_TURN_TIME_ERROR_TYPE,
        payload: error
    }
}


// Average Dwell & turn time 

export const AVG_DWELL_AND_TURN_TIME_REQUEST_TYPE =(userToken,customerId,locationId) => {
    return {
        type: ActionTypes.AVG_DWELL_AND_TURN_TIME_REQUEST_TYPE,
        payload: {userToken,customerId,locationId}
    }
}

export const AVG_DWELL_AND_TURN_TIME_SUCCESS_TYPE =(data) => {
    return {
        type: ActionTypes.AVG_DWELL_AND_TURN_TIME_SUCCESS_TYPE,
        payload: data
    }
}

export const AVG_DWELL_AND_TURN_TIME_ERROR_TYPE =(error) => {
    return {
        type: ActionTypes.AVG_DWELL_AND_TURN_TIME_ERROR_TYPE,
        payload: error
    }
}

//for hardware management 
export const HARDWARE_DETAILS_REQUEST_TYPE =(userToken,customerId,locationId) => {
    return {
        type: ActionTypes.HARDWARE_DETAILS_REQUEST_TYPE,
        payload: {userToken,customerId,locationId}
    }
}

export const HARDWARE_DETAILS_SUCCESS_TYPE =(data) => {
    return {
        type: ActionTypes.HARDWARE_DETAILS_SUCCESS_TYPE,
        payload: data
    }
}

export const HARDWARE_DETAILS_ERROR_TYPE =(error) => {
    return {
        type: ActionTypes.HARDWARE_DETAILS_ERROR_TYPE,
        payload: error
    }
}

export const LOST_SCRAP_ADD_REQUEST_TYPE =(userToken,customerId,locationId,lostScrapValue, lostScrapUnit) => {
    return {
        type: ActionTypes.LOST_SCRAP_ADD_REQUEST_TYPE,
        payload: {userToken,customerId,locationId,lostScrapValue,lostScrapUnit}
    }
}

export const LOSTSCRAP_ADD_SUCCESS_TYPE =(data) => {
    return {
        type: ActionTypes.LOSTSCRAP_ADD_SUCCESS_TYPE,
        payload: data
    }
}

export const LOSTSCRAP_ADD_ERROR_TYPE =(error) => {
    return {
        type: ActionTypes.LOSTSCRAP_ADD_ERROR_TYPE,
        payload: error
    }
}

export const FETCH_USER_ROLE_REQUEST_TYPE =(userToken,roleId) => {
    return {
        type: ActionTypes.FETCH_USER_ROLE_REQUEST_TYPE,
        payload: {userToken,roleId}
    }
}

export const FETCH_USER_ROLE_SUCCESS_TYPE =(data) => {
    return {
        type: ActionTypes.FETCH_USER_ROLE_SUCCESS_TYPE,
        payload: data
    }
}

export const FETCH_USER_ROLE_ERROR_TYPE =(error) => {
    return {
        type: ActionTypes.FETCH_USER_ROLE_ERROR_TYPE,
        payload: error
    }
}