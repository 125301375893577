import React, { useEffect, useState } from "react";
import { DataGrid } from '@mui/x-data-grid';
import { CsvBuilder } from 'filefy';
import { Box, Button, CircularProgress, Grid, InputAdornment, TableSortLabel, Typography, TextField, Stack } from '@mui/material';
import { connect } from 'react-redux'
import * as LiftActions from '../../../store/lift/lift.actions'
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { makeStyles, withStyles } from '@mui/styles';
import SearchIcon from "../../../assets/searchIcon.svg";
import FileDownloadIcon from '@mui/icons-material/FileDownload';

const NEWTableHead = withStyles((theme) => ({
  root: {
    backgroundColor: "#FAFBFF"
  }
}))(TableHead);

const Row = (props) => {
  const { row, labelId } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        <TableCell id={labelId}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          {row.store_name ? row.store_name : 'N/A'}
        </TableCell>
        <TableCell>{row.store_address ? row.store_address : 'N/A'}</TableCell>
        <TableCell>{row.rfid_count}</TableCell>
        <TableCell>{row.avg_dwell_time_days ? row.avg_dwell_time_days : '-'}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>RFID</TableCell>
                    <TableCell>Dwell Time</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.rfids.map((rfid) => (
                    <TableRow key={rfid.rfid} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell component="th" scope="row">
                        {rfid.rfid ? rfid.rfid : 'N/A'}
                      </TableCell>
                      <TableCell>{rfid.dwell_time_days ? rfid.dwell_time_days : '-'}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const StoreDetailsPage = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [tableData, setTableData] = useState(props.storeDetails);
  const [showdataView, setShowdataView] = useState(false);
  const [nodataView, setNodataView] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [open, setOpen] = React.useState(false);
  const customerId = props.selectedCustomerListData && props.selectedCustomerListData.customerId;
  const locationId = props.selectedCustomerListData && props.selectedLocationListData.locationId;
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("avg_dwell_time_days");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const fetchStoreDetails = () => {
    const loggedInUserIDToken = localStorage.getItem("authToken");
    if(props.selectedBoard === "Asset Management IOT"){
      props.fetchStoreDetails(loggedInUserIDToken, customerId, locationId,true);
    }else{
      props.fetchStoreDetails(loggedInUserIDToken, customerId, locationId,false);
    }
  }

  const runSearchQuery = (searchInput) => {
    const filteredLostScrapData = props?.storeDetails.filter((row) => {
      return (
        (row.store_name && row.store_name.toUpperCase().includes(searchInput.toUpperCase())) ||
        (row.store_address && row.store_address.toUpperCase().includes(searchInput.toUpperCase())) ||
        (row.rfid_count && String(row.rfid_count).includes(searchInput)) ||
        (row.avg_dwell_time_days && String(row.avg_dwell_time_days).includes(searchInput))
      );
    });
    setTableData(filteredLostScrapData)
  };


  const handleChange = (value) => {
    props.updateSearchInput(value);
  }

  const columns = [
    { field: "store_name", headerName: "Store Name" },
    { field: "store_address", headerName: "Store Address" },
    { field: "rfid_count", headerName: "Total Pallets" },
    { field: "avg_dwell_time_days", headerName: "Avg Dwell Time(Days)" }
  ];

  const handleStoreLocationDownload = () => {
    const currTs = Date.now();
    const data = tableData.map(rowData =>
      columns.map(columnDef => rowData[columnDef.field]),
    );
    const builder = new CsvBuilder(`storeLocationDetails_${currTs}.csv`);
    builder
      .setColumns(columns.map(columnDef => columnDef.headerName))
      .addRows(data)
      .exportFile();
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  useEffect(() => {
    if (props.searchInput) {
      runSearchQuery(props.searchInput);
    }
    else if (props.storeDetails) {
      let storeDetails = props.storeDetails
      storeDetails.map(storeDetail => {
        if (storeDetail.rfids && storeDetail.rfids.length > 0) {
          storeDetail['rfid_count'] = storeDetail.rfids.length
        } else {
          storeDetail['rfid_count'] = 0
        }
      })
      if (storeDetails.length > 0) {
        if (props.selectedBoard === "Asset Management IOT"){
          const sortedStoreDetails = storeDetails.sort((a, b) => (a.avg_dwell_time_days < b.avg_dwell_time_days) ? 1 : -1);
          let filteredStoreDetailsData = [];
          sortedStoreDetails.filter(item => {
            if (item.rfids.length > 0) {
              filteredStoreDetailsData.push(item)
            }
          })
          setTableData(filteredStoreDetailsData.slice(0, 10));
        }
        else
          setTableData(storeDetails);
        setShowdataView(true)
        setNodataView(false)
      } else {
        setTableData(storeDetails);
        setShowdataView(false)
        setNodataView(true)
      }
    }
  }, [props.searchInput, props.storeDetails]);

  useEffect(() => {
    if (customerId && locationId) {
      fetchStoreDetails();
    }
  }, [props.selectedCustomerListData, props.selectedLocationListData]);

  useEffect(() => {
    if (tableData) {
      setIsLoading(false)
    }
  }, [tableData]);

  return (
    <div style={{ width: "100%" }}>
      {props.selectedBoard === "Asset Management IOT" ?
        <Typography sx={styles.dashboardHeading}>Top 10 Stores by Dwell Time</Typography> :
        <Typography sx={styles.heading}>Store Location Assets</Typography>
      }
      {isLoading ?
        <Stack alignItems="center">
          <CircularProgress color="inherit" />
        </Stack> :
        <>
          {props.selectedBoard === "Store Location Assets" &&
            <Grid container>
              <Grid item xs={3}>
                <Box sx={{ width: "100%" }} mt={4} mb={4}>
                  <TextField
                    value={props?.searchInput}
                    onChange={(e) => {
                      handleChange(e.target.value);
                    }}
                    autoComplete="off"
                    sx={{
                      borderRadius: "4px",
                      input: {
                        color: "#033F53",
                      },
                      width: "100%",
                      background: "#fff",
                      boxShadow: "0px 4px 8px rgba(16, 24, 64, 0.16)",
                      "& .MuiInputBase-root.Mui-disabled": {
                        background: "#E0E2EA",
                        color: "#A6A6A6",
                      },
                    }}
                    size={"small"}
                    placeholder="Search"
                    id="outlined-start-adornment"
                    InputProps={{
                      startAdornment:
                        <InputAdornment position="start">
                          <img src={SearchIcon} alt="" />
                        </InputAdornment>
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={9}>
                <Box mt={4} mb={4} display={"flex"} justifyContent={"end"}>
                  <Button
                    onClick={handleStoreLocationDownload}
                    startIcon={<FileDownloadIcon />}
                    variant="contained"
                    style={{
                      backgroundColor: "#3b62a1"
                    }}
                  >
                    Export Data
                  </Button>
                </Box>
              </Grid>
            </Grid>
          }
          {showdataView && (
            <TableContainer component={Paper}>
              <Table aria-label="collapsible table">
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={tableData.length}
                />
                {tableData.length > 0 ?
                  <TableBody>
                    {stableSort(tableData, getComparator(order, orderBy)).map(
                      (row, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <Row key={row.store_name} row={row} labelId={labelId} />
                        )
                      })}
                  </TableBody> :
                  <TableBody>
                    <TableRow>
                      <TableCell scope="row" align="center" colSpan={6}>
                        No Data
                      </TableCell>
                    </TableRow>
                  </TableBody>
                }
              </Table>
            </TableContainer>
          )}
          {nodataView && (
            <TableContainer component={Paper}>
              <Table aria-label="collapsible table">
                <NEWTableHead>
                  <TableRow>
                    <TableCell>Store Name</TableCell>
                    <TableCell>Store Address</TableCell>
                    <TableCell align="right">Total Pallets</TableCell>
                    <TableCell align="right">Avg Dwell Time(Days)</TableCell>
                  </TableRow>
                </NEWTableHead>
                <TableBody>
                  <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                    <TableCell scope="row" align="center" colSpan={5}>
                      No Data
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </>
      }
    </div>
  );
};

const headCells = [
  { id: "", numeric: false, label: "" },
  { id: "store_name", numeric: false, label: "Store Name" },
  { id: "store_address", numeric: false, label: "Store Address" },
  { id: "rfid_count", numeric: true, label: "Total Pallets" },
  { id: "avg_dwell_time_days", numeric: true, label: "Avg Dwell Time(Days)" },
];


function EnhancedTableHead(props) {
  const {
    order,
    orderBy,
    onRequestSort
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span style={{
                  border: 0,
                  clip: "rect(0 0 0 0)",
                  height: 1,
                  margin: -1,
                  overflow: "hidden",
                  padding: 0,
                  position: "absolute",
                  top: 20,
                  width: 1
                }}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export const styles = {
  heading: {
    color: "#303030",
    fontSize: "24px",
    fontWeight: 700,
  },
  dashboardHeading: {
    color: "#303030",
    fontSize: "18px",
    fontWeight: 600,
    marginTop: "20px",
    marginBottom: "20px"
  }
}

const mapStateToProps = (state) => {
  return {
    isReadOnlyUser: state.isReadOnlyUser,
    selectedCustomerListData: state.selectedCustomerListData,
    selectedLocationListData: state.selectedLocationListData,
    storeDetails: state.storeDetails,
    searchInput: state.searchInput,
    selectedBoard: state.selectedBoard,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchStoreDetails: (userToken, customerId, locationId, dashboardFlag) => dispatch(LiftActions.STORE_DETAILS_REQUEST_TYPE(userToken, customerId, locationId, dashboardFlag)),
    updateSearchInput: (searchInput) => dispatch(LiftActions.SEARCH_INPUT_UPDATE(searchInput))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StoreDetailsPage);