import React from "react";
import TotalAssetsInFloat from "./TotalAssetsInFloat/TotalAssetsInFloat";
import StoreDetailsPage from "../Reports/StoreDetails/StoreDetailsPage";

const DashboardPage = () => {
    return (
        <div style={{ width: "100%" }}>
            <TotalAssetsInFloat/>
            <StoreDetailsPage/>
        </div>
    );
};

export const styles = {
    heading: {
        color: "#303030",
        fontSize: "24px",
        fontWeight: 700,
    }
}

export default DashboardPage;