import axios from "axios";

let devbaseURL = "https://deliveryiotdevapi.rehrig.com/";
let prodbaseURL = "https://deliveryiotapi.rehrigpacific.com/"
let qabaseURL = "https://deliveryiotqaapi.rehrig.com/"
let userURL = window.location.href;

const getBaseURL = () => {
    if (userURL.includes("deliveryiot.rehrigpacific") || userURL.includes("d18dalmzdtol92.cloudfront")) {
        return prodbaseURL
    } else if (userURL.includes("deliveryiot-dev.rehrig")|| userURL.includes("localhost")) {
        return devbaseURL
    } else if (userURL.includes("deliveryiot-qa.rehrig")) {
        return qabaseURL
    } else {
        return devbaseURL
    }
}

//URL's for vor API 
let vordevbaseURL = "https://vordevapi.rehrig.com/";
let vorqabaseURL = "https://vorqaapi.rehrig.com/";
let vorprodbaseURL = "https://vorapi.rehrigpacific.com/";

const getVorAPIURL = () => {
    if (userURL.includes("deliveryiot.rehrigpacific") || userURL.includes("d18dalmzdtol92.cloudfront")) {
        return vorprodbaseURL
    } else if (userURL.includes("deliveryiot-dev.rehrig")|| userURL.includes("localhost")) {
        return vordevbaseURL
    } else if (userURL.includes("deliveryiot-qa.rehrig")) {
        return vorqabaseURL
    } else {
        return vordevbaseURL
    }
}

// const getVorAPIURL = () => {
//     if (process.env.NODE_ENV === "development") {
//         return vordevbaseURL;
//     } else if (process.env.NODE_ENV === "qa") {
//         return vorqabaseURL;
//     } else if (process.env.NODE_ENV === "prod") {
//         return vorprodbaseURL;
//     }
// }

const baseURL = getBaseURL();

const vorAPIURL = getVorAPIURL();

export const loginUser = (encodedParams) => {
    const body = {};
    return axios.post(`${baseURL}auth/signin`, body, 
        {
            headers: {
                "Authorization": "Basic " + encodedParams,
            }
        })
}

export const getCustomersAndLocations = async(authToken) => {
    return axios.get(`${baseURL}web/locationv2`,
        {
            headers: {
                "Authorization": authToken
            }
        })
}

export const getLiftDevices = (authToken, customerId, locationId) => {

    return axios.get(`${baseURL}web/liftlistv2`,
        {
            params: {
                // type: "liftlistv2",
                customer_id: customerId,
                location_id: locationId
            },
            headers: {
                "Authorization": authToken
            }
        })

}
export const getLiftDeviceDetail = (liftId, authToken, startDate) => {
    return axios.get(`${baseURL}web/dashboard`,
        {
            params: {
                // type: "dashboard",
                liftid: liftId,
                startdate: startDate,
            },
            headers: {
                "Authorization": authToken
            }
        })

}
export const getDeviceAlertHistory = (liftId, startDate, authToken) => {
    let params;
    if (startDate == "") {
        params = {
            // type: "alertsummary",
            liftid: liftId,
        }
    } else if (startDate != "") {
        params = {
            // type: "alertsummary",
            liftid: liftId,
            startdate: startDate,
        }
    } 

    return axios.get(`${baseURL}web/alertsummary`,
        {
            params: params,
            headers: {
                "Authorization": authToken
            }
        })

}
export const getDeviceAlertDetailHistory = (liftId, code, authToken) => {
    return axios.get(`${baseURL}web/alertdetail`,
        {
            params: {
                // type: "alertdetail",
                liftid: liftId,
                alertcode: code
            }, 
            headers: {
                "Authorization": authToken
            }
        })

}

export const getDeviceAlertTrail = (liftId, startDate, authToken) => {
    let params;
    if (startDate == "" || startDate == null) {
        params = {
            // type: "alerttrail",
            liftid: liftId,
        }
    } else if (startDate != "") {
        params = {
            // type: "alerttrail",
            liftid: liftId,
            startdate: startDate,
        }
    } 

    return axios.get(`${baseURL}web/alerttrail`,
        {
            params: params,
            headers: {
                "Authorization": authToken
            }
        })

}
export const getDeviceLocationBreadcrumbs = (liftId, startDate, authToken) => {
    let params;
    if (startDate == "" || startDate == null) {
        params = {
            // type: "breadcrumbs",
            liftid: liftId,
        }
    } else if (startDate != "") {
        params = {
            // type: "breadcrumbs",
            liftid: liftId,
            startdate: startDate,
        }
    } 
    return axios.get(`${baseURL}web/breadcrumbs`,
        {
            params: params,
            headers: {
                "Authorization": authToken
            }
        })

}

export const getDeviceStopDetails = (liftId, startDate, authToken) => {
    let params;
    if (startDate == "" || startDate == null ) {
        params = {
            // type: "stopget",
            liftid: liftId,
        }
    } else if (startDate != "") {
        params = {
            // type: "stopget",
            liftid: liftId,
            startdate: startDate,
        }
    } 
    
    return axios.get(`${baseURL}web/stopget`,
    {
        params: params,
        headers: {
            "Authorization": authToken
        }
    })
}

export const clearServiceItems = (authToken, id, sheduledIDs, date, keyonTime, userId, taskId) => {

    const addParams = JSON.stringify({
        // "type": "liftmaintpost",
        "liftID": id,
        "scheduleIDs": sheduledIDs,
        "dateComplete": date,
        "keyonHours": keyonTime,
        "notes": "",
        "personnelID": userId,
        "taskID": taskId,
        "completed": 1,
        });
    return axios.post(`${baseURL}web/liftmaintpost`, addParams,
    {
        headers: {
            "Authorization": authToken
        },
    })
}

export const getAlertPareto = (authToken, locationid, startdate, enddate) => {
    let params;
    if (enddate == "" || enddate == null ) {
        params = {
            // type: "alertpareto",
            locationid: locationid,
            startdate: startdate,
        }
    } else {
        params = {
            // type: "alertpareto",
            locationid: locationid,
            startdate: startdate,
            enddate: enddate,
        }
    } 
    return axios.get(`${baseURL}web/alertpareto`,
    {
        headers: {
            "Authorization": authToken
        },
        params: params
    })
}

export const getLiftAlertPareto = (alertID, authToken) => {
    return axios.get(`${baseURL}web/alertparetolifts`,
    {
        headers: {
            "Authorization": authToken
        },
        params: {
            alertid: alertID,
            // type: "alertparetolifts"
        }
    })
}

export const getLiftMaintenanceList = (statusCode, authToken) => {
    return axios.get(`${baseURL}web/maintstatusliftlist`,
    {
        headers: {
            "Authorization": authToken
        },
        params: {
            statuscode: statusCode,
            // type: "maintstatusliftlist"
        }
    })
}

export const getLiftStopBiometrics = (id, date, authToken) => {
    return axios.get(`${baseURL}web/stopbio`,
    {
        headers: {
            "Authorization": authToken
        },
        params: {
            // type: "stopbio",
            liftid: id,
            startdate: date
        }
    })
}

export const getAlertDetailFleet = (authToken, locationid, startdate, enddate) => {
    let params;
    if (enddate == "" || enddate == null ) {
        params = {
            locationid: locationid,
            startdate: startdate,
        }
    } else {
        params = {
            locationid: locationid,
            startdate: startdate,
            enddate: enddate,
        }
    } 
    return axios.get(`${baseURL}web/alertdetailfleet`,
    {
        headers: {
            "Authorization": authToken
        },
        params: params
    })
}

export const getUsersAndLocations = (authToken) => {
    return axios.get(`${baseURL}admin/location`,
    {
        headers: {
            "Authorization": authToken,
        },
    }) 
}

export const addNewUser = (authToken, customerName, status) => {
    const params = JSON.stringify({
        "customerName": customerName,
        "active": status
        });
    return axios.post(`${baseURL}admin/customer`, params,
    {
        headers: {
            "Authorization": authToken,
        },
    }) 
}

export const editUser = (authToken, customerID, customerName, status) => {
    const params = JSON.stringify({
        "customerId": customerID,
        "customerName": customerName,
        "active": status,
        });
    return axios.put(`${baseURL}admin/customer`, params,
    {
        headers: {
            "Authorization": authToken,
        },
    }) 
}

export const addNewLocation = (authToken, locationName, customerName, latitude, longitude, formRadius, status, showMaintanance)  => {
    var newLong = Number(longitude);
    var newLat = Number(latitude);
    var newFormRadius = Number(formRadius)
    const params = JSON.stringify({
        "locationName": locationName,
        "customerName": customerName,
        "lat": newLat,
        "long": newLong,
        "fence_radius_meters": newFormRadius,
        "active": status,
        "maintenanceDetails": showMaintanance,
        });
    return axios.post(`${baseURL}admin/locationv2`, params,
    {
        headers: {
            "Authorization": authToken,
        },       
    }) 
}

export const editLocation = (authToken, locationId, locationName, latitude, longitude, formRadius, status, showMaintanance) => {
    var newLong = Number(longitude);
    var newLat = Number(latitude);
    var newFormRadius = Number(formRadius)

    const params = JSON.stringify({
        "locationId": locationId,
        "locationName": locationName,
        "lat": newLat,
        "long": newLong,
        "fence_radius_meters": newFormRadius,
        "active": status,  
        "maintenanceDetails": showMaintanance, 
        });
    return axios.put(`${baseURL}admin/locationv2`, params,
    {
        headers: {
            "Authorization": authToken,
        },
    }) 
}

export const getUsersData = (authToken) => {
    return axios.get(`${baseURL}admin/users`,
    {
        headers: {
            "Authorization": authToken,
        },
    }) 
}

export const getLiftData = (authToken,customerId,locationId) => {
    return axios.get(`${baseURL}admin/lifts`,
    {
        headers: {
            "Authorization": authToken,
        },
        params: {
            locationId: locationId,
            customerId: customerId,
        }
    }) 
}

// {
//     "liftId": 6,
//     "locationId": 18,
//     "driverId": 9,
//     "routeName":"test",
//     "truckName":"test2"
// }

export const getDriversInfo = (authToken, locationId, customerId) => {
    return axios.get(`${baseURL}admin/drivers`,
    {
        headers: {
            "Authorization": authToken,
        },
        params: {
            locationId: locationId,
            customerId: customerId,
        }
    }) 
}

export const editLiftDetails = (authToken, liftId, locationId, driverId, routeName, truckName, liftStatus) => {
    let newDriverID
    let newTruckName
    let newRouteName
    if (driverId === 0 || driverId === null) {
        newDriverID = null
    } else {
        newDriverID = driverId
    }
    if (truckName === "" || truckName === "N/A") {
        newTruckName = null
    } else {
        newTruckName = truckName
    }

    if (routeName === "" || routeName === "N/A") {
        newRouteName = null
    } else {
        newRouteName = routeName
    }
    const params = JSON.stringify({
        "liftId": liftId,
        "locationId": locationId,
        "driverId": newDriverID,
        "routeName": newRouteName,
        "truckName": newTruckName,
        "active": liftStatus,
        });
    return axios.put(`${baseURL}admin/lifts`, params,
    {
        headers: {
            "Authorization": authToken,
        },
    }) 
}

export const editUserDetails = (requestType, authToken, userId, username, firstname, lastname, email, active, customerId, password, role, locations) => {

    const editParams = JSON.stringify({
        "user_id": userId,
        "username": username,
        "firstname": firstname,
        "lastname": lastname,
        "email": email,
        "active": active,
        "notes": "",
        "customerId": customerId,
        "password": password,
        "role": role,
        "locations": locations,
        });

    const addParams = JSON.stringify({
        "username": username,
        "firstname": firstname,
        "lastname": lastname,
        "email": email,
        "active": active,
        "notes": "",
        "customerId": customerId,
        "password": password,
        "role": role,
        "locations": locations,
        });

    let params

    if (requestType === "Add User") {
        params = addParams
        return axios.post(`${baseURL}admin/users`, params,
        {
            headers: {
                "Authorization": authToken,
            },
        })
    } else {
        params = editParams
        return axios.put(`${baseURL}admin/users`, params,
        {
            headers: {
                "Authorization": authToken,
            },
        })
    }
}

export const getAssetDetails = (authToken,customerId,locationId) => {
    return axios.get(`${baseURL}web/asset_details`,
    {
        headers: {
            "Authorization": authToken,
        },
        params: {
            location_id: locationId,
            customer_id: customerId,
        }
    }) 
}

export const getTotalAssets = async(authToken,customerId,locationId) => {
    return axios.get(`${baseURL}web/total-assets-kpi`,
    {
        headers: {
            "Authorization": authToken,
        },
        params: {
            location_id: locationId,
            customer_id: customerId,
        }
    }) 
}

export const getLostScrap = async(authToken,customerId,locationId) => {
    return axios.get(`${baseURL}web/asset_lost_scrap_kpi`,
    {
        headers: {
            "Authorization": authToken,
        },
        params: {
            location_id: locationId,
            customer_id: customerId,
        }
    }) 
}

export const getDwellAndTurnTime = async(authToken,customerId,locationId) => {
    return axios.get(`${baseURL}web/asset_dwell_and_turn_time_count_kpi`,
    {
        headers: {
            "Authorization": authToken,
        },
        params: {
            location_id: locationId,
            customer_id: customerId,
        }
    }) 
}

export const getAvgDwellAndTurnTime = async(authToken,customerId,locationId) => {
    return axios.get(`${baseURL}web/get_asset_avg_dwell_and_turn_time_kpi`,
    {
        headers: {
            "Authorization": authToken,
        },
        params: {
            location_id: locationId,
            customer_id: customerId,
        }
    }) 
}

export const getHardwareDetails = (authToken,customerId,locationId) => {
    return axios.get(`${baseURL}web/hardware_details`,
    {
        headers: {
            "Authorization": authToken,
        },
        params: {
            location_id: locationId,
            customer_id: customerId,
        }
    }) 
}

export const addLostScrap = async(authToken, customerId, locationId, lostScrapValue, lostScrapUnit) => {
    const params = JSON.stringify({
        customer_id: customerId,
        ...(locationId && { location_id: locationId }),
        lost_scrap_threshold_unit: lostScrapUnit,
        lost_scrap_threshold_value: lostScrapValue,
    })
    return axios.post(`${baseURL}web/asset_lost_scrap_kpi`, params,
        {
            headers: {
                "Authorization": authToken,
            },
        })
}

export const storeDetails = (authToken, customerId, locationId, dashboardFlag) => {
    return axios.get(`${baseURL}web/asset_details_by_store`,
        {
            headers: {
                "Authorization": authToken,
            },
            params: {
                location_id: locationId,
                customer_id: customerId,
                isDashboard: dashboardFlag
            }
        })
}

export const fetchUserRoleName = (authToken, roleId) => {
    return axios.get(`${vorAPIURL}Central/role/${roleId}`,
        {
            headers: {
                "Authorization": authToken,
            }
        })
}

export const fetchUserAccessLevel = async (authToken, userId) => {
    return axios.get(`${vorAPIURL}Central/user/${userId}`,
        {
            headers: {
                "Authorization": authToken,
            }
        }).then(response => {
            return response.data
        })
        .catch(error => {
            return error
        });
}