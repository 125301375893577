import React from 'react'
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import * as LiftActions from '../../../store/lift/lift.actions'
import { connect } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Box, Drawer, Divider, IconButton, Typography, MenuItem, Select, Grid, ListItemText, OutlinedInput, InputLabel } from '@mui/material';


class DuoLiftMapItems extends React.Component {

    defaultValue = true;

    todaysDate = new Date();
    tomorrowDate = new Date();
    newDate = new Date();
    previousDate = this.newDate.setDate(this.newDate.getDate() - 180)
    state = {
        dateItemsValue: { start: this.todaysDate, end: this.tomorrowDate},
        selectedMapType: this.props.selectedMapType,
        selectedDevice: null,
        currentLocation: "Unable to find geocoded address",
        currentLatitude: null,
        currentLongitude: null
    };
    newDateValues = { start: null}

    componentDidMount() {
        this.tomorrowDate.setDate(this.todaysDate.getDate() + 1);
    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state,callback)=>{
            return;
        };
    }

  componentDidUpdate(prevPros, prevState) {
    let default_state = "Unable to find geocoded address" 
    if(this.props.geocoder != null) {    
      if((this.props.latitude== null
         || this.props.longitude == null)
       && prevState.currentLocation !== default_state){
        this.setState({ currentLocation: default_state});

      } else {
        if (this.state.currentLatitude !== this.props.latitude || this.state.currentLongitude !== this.props.longitude) {
            this.props.geocoder.geocode({ location: { lat: this.props.latitude !== null ? parseFloat(this.props.latitude): 50.25, lng: this.props.longitude !== null ? parseFloat(this.props.longitude) : 50.25 } }, (results, status) => {
                if(status === "OK") {
                    this.setState({ currentLocation: results[0].formatted_address, currentLatitude: this.props.latitude, currentLongitude: this.props.longitude});
                    }         
                 })
        } 
        }
        }
    }

    handleMapItemsChange = (event) => {
        const loggedInUserIDToken = localStorage.getItem("authToken");
        let newArray = [...this.state.selectedMapType]
        this.props.updateSelectedMapType(event.target.value) 
        if (!this.state.selectedMapType.includes('Alerts') && event.target.value.includes('Alerts')) {
            this.props.fetchDeviceAlertTrail(this.props.selectedDevice.liftID, this.getUserSelectedDate(this.state.dateItemsValue.start), loggedInUserIDToken, this.getUpdatedDeviceDetails)

            // if (event.target.checked) {

            //     this.state.selectedMapType = this.state.selectedMapType.concat(event.currentTarget.defaultValue)
            //     this.props.updateSelectedMapType(this.state.selectedMapType)
            //     this.props.fetchDeviceAlertTrail(this.props.selectedDevice.liftID, this.getUserSelectedDate(this.state.dateItemsValue.start), loggedInUserIDToken, this.getUpdatedDeviceDetails)
            //     this.setState ({
            //         selectedMapType: this.state.selectedMapType,
            //     })                  
            // } else {
            //     let newIndex = newArray.indexOf(event.currentTarget.defaultValue)
            //     let removedElement = newArray.splice(newIndex, 1)
            //     console.log(removedElement)
            //     this.props.updateSelectedMapType(newArray)

            //     this.setState ({
            //         selectedMapType: newArray,
            //     })                
            // }
        } 
        if (!this.state.selectedMapType.includes('Stops') && event.target.value.includes('Stops')) {
            this.props.fetchDeviceStopDetails(this.props.selectedDevice.liftID, this.getUserSelectedDate(this.state.dateItemsValue.start), loggedInUserIDToken, this.getUpdatedDeviceDetails)

            // if (event.target.checked) {
            //     this.state.selectedMapType = this.state.selectedMapType.concat(event.currentTarget.defaultValue)
            //     this.props.updateSelectedMapType(this.state.selectedMapType)
            //     this.props.fetchDeviceStopDetails(this.props.selectedDevice.liftID, this.getUserSelectedDate(this.state.dateItemsValue.start), loggedInUserIDToken, this.getUpdatedDeviceDetails)
            //     this.setState ({
            //         selectedMapType: this.state.selectedMapType,
            //     })                  
            // } else {
            //     let newIndex = newArray.indexOf(event.currentTarget.defaultValue)
            //     let removedElement = newArray.splice(newIndex, 1)
            //     console.log(removedElement)
            //     this.props.updateSelectedMapType(newArray)

            //     this.setState ({
            //         selectedMapType: newArray,
            //     })                 
            // }
        }  
        if  (!this.state.selectedMapType.includes('Breadcrumbs') && event.target.value.includes('Breadcrumbs')) {
            this.props.fetchDeviceBreadcrumbHistory(this.props.selectedDevice.liftID, this.getUserSelectedDate(this.state.dateItemsValue.start), loggedInUserIDToken)

            // if (event.target.checked) {
            //     this.state.selectedMapType = this.state.selectedMapType.concat(event.currentTarget.defaultValue)
            //     this.props.updateSelectedMapType(this.state.selectedMapType)
            //     this.props.fetchDeviceBreadcrumbHistory(this.props.selectedDevice.liftID, this.getUserSelectedDate(this.state.dateItemsValue.start), loggedInUserIDToken)
            //     this.setState ({
            //         selectedMapType: this.state.selectedMapType,
            //     })  
            // } else {
            //     let newIndex = newArray.indexOf(event.currentTarget.defaultValue)
            //     let removedElement = newArray.splice(newIndex, 1)
            //     console.log(removedElement) 
            //     this.props.updateSelectedMapType(newArray)

            //     this.setState ({
            //         selectedMapType: newArray,
            //      })             
            // }
        }
        this.state.selectedMapType = event.target.value

        this.setState ({
            selectedMapType: this.state.selectedMapType,
        }) 
    }

    getNextDayDate(selectedDate) {
        var nextDay = new Date(selectedDate)
        nextDay.setDate(nextDay.getDate() + 1);
        return nextDay
    }

    handleDatesChange = (event) => {
        let selectedDate = event
        this.newDateValues.start = selectedDate
        this.setState ({
            dateItemsValue: this.newDateValues,
        });
        let selectedDevice = this.props.selectedDevice
        const loggedInUserIDToken = localStorage.getItem("authToken");
        if (selectedDate != null) {
            this.props.updateDatesSelectedForMap(this.newDateValues)
            let selectedMaptypes = this.state.selectedMapType
            if (selectedMaptypes.length > 0) {
                selectedMaptypes.map(mapType => {
                    if (mapType == 'Alerts') {
                        this.props.fetchDeviceAlertTrail(selectedDevice.liftID, this.getUserSelectedDate(selectedDate), loggedInUserIDToken, this.getUpdatedDeviceDetails)
                    } else if (mapType == 'Stops') {
                        this.props.fetchDeviceStopDetails(selectedDevice.liftID, this.getUserSelectedDate(selectedDate), loggedInUserIDToken, this.getUpdatedDeviceDetails)
                    } else if (mapType == 'Breadcrumbs') {
                        this.props.fetchDeviceBreadcrumbHistory(selectedDevice.liftID, this.getUserSelectedDate(selectedDate), loggedInUserIDToken)
                    }
                })
            } else {
                this.getUpdatedDeviceDetails()
            }
           
        }
    }

    getUpdatedDeviceDetails = () => {
        if (this.state.dateItemsValue.start !== undefined) {
          let newStartDate = this.getUserSelectedDate(this.newDateValues.start)
          const deivceID = this.props.selectedDevice.liftID
          const loggedInUserIDToken = localStorage.getItem("authToken");

          let selectedMaptypes = this.state.selectedMapType
          if (selectedMaptypes.length > 0) {
            selectedMaptypes.map(mapType => {
                if (mapType == 'Alerts') {
                    this.props.fetchDeviceDetails(deivceID, loggedInUserIDToken, newStartDate);
                    this.props.fetchDeviceAlertHistory(deivceID, newStartDate, loggedInUserIDToken)
                } else if (mapType == 'Stops') {
                    this.props.fetchDeviceDetails(deivceID, loggedInUserIDToken, newStartDate);
                }
            })
          } else {
            this.props.fetchDeviceDetails(deivceID, loggedInUserIDToken, newStartDate);
            this.props.fetchDeviceAlertHistory(deivceID, newStartDate, loggedInUserIDToken)
          }
        } else {
          const deivceID = this.props.selectedDevice.liftID
          const loggedInUserIDToken = localStorage.getItem("authToken");
          let selectedMaptypes = this.state.selectedMapType
          if (selectedMaptypes.length > 0) {
            selectedMaptypes.map(mapType => {
                if (mapType == 'Alerts') {
                    this.props.fetchDeviceDetails(deivceID, loggedInUserIDToken, this.getCurrentDate());
                    this.props.fetchDeviceAlertHistory(deivceID, this.getCurrentDate(), loggedInUserIDToken)
                } else if (mapType == 'Stops') {
                    this.props.fetchDeviceDetails(deivceID, loggedInUserIDToken, this.getCurrentDate());
                }
            })
          } else {
            this.props.fetchDeviceDetails(deivceID, loggedInUserIDToken, this.getCurrentDate());
            this.props.fetchDeviceAlertHistory(deivceID, this.getCurrentDate(), loggedInUserIDToken)
          }
        }
      }


    useStyles = makeStyles(() =>
        createStyles({
        dateBorderBox: {
            outline: "none",
            border: "2px solid black",
            color: "white",
            backgroundColor: "#00B0F0",
            },
        })
    );

      getCurrentDate() {
        let newDate = new Date()
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        let day = newDate.getDate();
        let requireDate = year + '-' + month + '-' + day;
        return requireDate
     }
     
    checkIfTheBoxIsSelected = (event) => {
        let selectedMaptypes = this.state.selectedMapType
        return selectedMaptypes.some(item => item === event);
    }

    handleLiftChange = (event) => {
        // props.updateSelectedCustomer(event.target.value)
        const loggedInUserIDToken = localStorage.getItem("authToken");
        let liftId = event.target.value
        // this.setState({ selectedDeviceId: liftId})
        // this.props.updateStopSelectedFlag(false)
        // this.props.updateAlertSelectedFlag(false)
     
        // this.props.updateSelectedMapType(["Alerts"])
        if (this.props.mapDateItemsValue.start !== undefined && this.props.mapDateItemsValue.start !== null) {
           let newStartDate = this.getUserSelectedDate(this.props.mapDateItemsValue.start)
             this.props.fetchDeviceDetails(liftId, loggedInUserIDToken, newStartDate);
     
           this.props.fetchDeviceAlertHistory(liftId, newStartDate, loggedInUserIDToken);
           this.props.fetchDeviceBreadcrumbHistory(liftId, newStartDate, loggedInUserIDToken);
           this.props.fetchDeviceStopDetails(liftId, newStartDate, loggedInUserIDToken);
           this.props.fetchDeviceAlertTrail(liftId, newStartDate, loggedInUserIDToken);
           this.props.fetchStopBiometrics(liftId, newStartDate, loggedInUserIDToken)

        } else {
           this.props.fetchDeviceDetails(liftId, loggedInUserIDToken, this.getCurrentDate());
           this.props.fetchDeviceAlertHistory(liftId, this.getCurrentDate(), loggedInUserIDToken);
           this.props.fetchDeviceBreadcrumbHistory(liftId, this.getCurrentDate(), loggedInUserIDToken);
           this.props.fetchDeviceStopDetails(liftId, this.getCurrentDate(), loggedInUserIDToken);
           this.props.fetchDeviceAlertTrail(liftId, this.getCurrentDate(), loggedInUserIDToken);
           this.props.fetchStopBiometrics(liftId, this.getCurrentDate(), loggedInUserIDToken)
        }
        this.props.updateRefreshDataFlag(false)
        this.props.updateStopSelectedFlag(false)
        this.props.updateAlertSelectedFlag(false)
        // if (this.props.selectedLiftCustomer != undefined, this.props.selectedLiftCustomer != null, this.props.selectedLiftLocation != undefined, this.props.selectedLiftLocation != null) {
        //    if (this.props.selectedLiftCustomer.customerId !== undefined, this.props.selectedLiftCustomer.customerId !== null, this.props.selectedLiftLocation.locationId !== undefined, this.props.selectedLiftLocation.locationId !== null) {
        //       this.props.fetchDevices(loggedInUserIDToken, this.props.selectedLiftCustomer.customerId, this.props.selectedLiftLocation.locationId)
        //    }
        // }
    }

    getUserSelectedDate(selectedValue) {
        let newDate = selectedValue
        if (newDate != null) {
            let month = newDate.getMonth() + 1;
            let year = newDate.getFullYear();
            let day = newDate.getDate();
            let requireDate = year + '-' + month + '-' + day;
            console.log(requireDate)
            return requireDate
        } else {
            return null
        }
     }

    render() {

        var startDate
        var previousDates
        let deviceList = this.props.devices;
        let mapUserList = ['Alerts', 'Stops', 'Breadcrumbs']
        if (this.state.dateItemsValue != undefined) {
            startDate = this.state.dateItemsValue.start
        }
        let selectedLift = this.props.selectedDevice
        return (
            <div className="mapItemsView">
                    <div className="mapAlertsSelectionView">
                    <Box style={{marginRight: "20px", width: "170px" }}>
                    <Typography color="white">Lift</Typography>
                     <FormControl fullWidth size={"small"}
                        sx={{
                            borderRadius: "4px",
                            background: "#fff",
                            boxShadow: "0px 4px 8px rgba(16, 24, 64, 0.16)",
                            "& .MuiInputBase-root.Mui-disabled": {
                            background: "#E0E2EA",
                            color: "#A6A6A6",
                            },
                        }}
                        >
                        <Select
                        labelId="lift-name-label"
                        id="lift-name"
                        value={selectedLift.liftID || ''}
                        onChange={(newValue) => {
                            this.handleLiftChange(newValue)
                        }}
                         >
                        {deviceList && deviceList.map((lift) => (
                             <MenuItem key={lift.liftID} value={lift.liftID}>
                             {lift.liftID}
                             </MenuItem>
                         ))}
                    </Select>
                    </FormControl>
                </Box>
                <div className="datesView" >
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                    value={startDate}
                    onChange={(newValue) => {
                        this.handleDatesChange(newValue)
                    }}
                    minDate={this.previousDate}
                    renderInput={(params) => <TextField {...params}
                        size="small"
                        sx={{ borderRadius: "4px", backgroundColor: "#00B0F0" }} />}
                    />
                </LocalizationProvider> 
                </div>
                <Box style={{ marginLeft: "20px", marginRight: "20px", marginTop: "1.6em",  width: "250px" }}>
                     <FormControl fullWidth size={"small"}
                        sx={{
                            borderRadius: "4px",
                            background: "#fff",
                            boxShadow: "0px 4px 8px rgba(16, 24, 64, 0.16)",
                            "& .MuiInputBase-root.Mui-disabled": {
                            background: "#E0E2EA",
                            color: "#A6A6A6",
                            },
                        }}
                        >
                        <InputLabel id="demo-multiple-checkbox-label">Map Selections</InputLabel>
                        <Select
                        labelId="map-options-label"
                        id="map-options"
                        value={this.state.selectedMapType}
                        multiple
                        input={<OutlinedInput label="Map Selections" />}
                        onChange={this.handleMapItemsChange}
                        renderValue={(selected) => selected.join(', ')}
                         >
                        {mapUserList.map((mapSelection) => (
                             <MenuItem key={mapSelection} value={mapSelection}>
                             <Checkbox checked={this.state.selectedMapType.indexOf(mapSelection) > -1} />
                             <ListItemText primary={mapSelection} />
                             </MenuItem>
                        ))}
                    
                    </Select>
                    </FormControl>
                </Box>
                </div>

                {/* <div className="mapAlertsSelectionView">
                    <div className="mapAlertsCheckboxes"> 
                    <FormControl component="fieldset">
                        <FormGroup aria-label="position" row>
                            <div className="mapAlertCardView">
                                <FormControlLabel
                                    value="Alerts"
                                    control={
                                    <Checkbox 
                                    label="Alerts"
                                    onChange = {this.handleMapItemsChange}
                                    value = "Alerts"
                                    checked = {this.checkIfTheBoxIsSelected('Alerts')}
                                    style={{color:"white", marginLeft:"1em"}}
                                    />
                                 }
                                label={<span style={{ fontSize: '0.9rem' }}>{"ALERTS"}</span>}
                                labelPlacement="end"
                                />
                            </div>
                            <div className="mapAlertCardView">
                                <FormControlLabel
                                    value="Stops"
                                    control={
                                        <Checkbox 
                                        label="Stops"
                                        onChange = {this.handleMapItemsChange}
                                        value = "Stops"
                                        checked = {this.checkIfTheBoxIsSelected('Stops')}
                                        style={{color:"white", marginLeft:"1em", fontsize:"0.9rem"}}
                                    />
                                }
                                label={<span style={{ fontSize: '0.9rem' }}>{"STOPS"}</span>}
                                labelPlacement="end"
                            />
                            </div>
                            <div className="mapAlertCardView">
                                <FormControlLabel
                                    value="Breadcrumbs"
                                    control={
                                        <Checkbox 
                                            label= "Breadcrumbs" 
                                            onChange = {this.handleMapItemsChange}
                                            value =  "Breadcrumbs"
                                            checked = {this.checkIfTheBoxIsSelected('Breadcrumbs')}
                                            style={{color:"white", marginLeft:"1em", fontsize:"0.9rem"}}
                                        />
                                    }
                                    label={<span style={{ fontSize: '0.9rem' }}>{"BREADCRUMBS"}</span>}
                                    labelPlacement="end"
                                />
                            </div>
                        </FormGroup>
                 </FormControl>
                    </div>
                </div> */}
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
      selectedMapType: state.selectedMapType,
      selectedDevice: state.selectedDevice,
      devices: state.devices,
      mapDateItemsValue: state.mapDateItemsValue,
    }
  }
const mapDispatchToProps = (dispatch) =>{
    return {
       fetchDeviceDetails: (id, userToken, startDate) => dispatch(LiftActions.LOAD_LIFT_DETAIL(id, userToken, startDate)),
       updateSelectedMapType: (selectedType) => dispatch(LiftActions.LOAD_LIFT_MAP_TYPE_UPDATE(selectedType)),
       updateDatesSelectedForMap: (selectedDates) => dispatch(LiftActions.UPDATE_USER_SELECTED_MAP_DATES(selectedDates)),
       fetchDeviceAlertHistory: (id, startDate, userToken) => dispatch(LiftActions.LOAD_LIFT_ALERT_HISTORY(id, startDate, userToken)),
       fetchDeviceBreadcrumbHistory: (id, startDate, userToken) => dispatch(LiftActions.LOAD_LIFT_BREADCRUMB_HISTORY(id, startDate, userToken)),
       fetchStopBiometrics: (id, date, userToken) => dispatch(LiftActions.LOAD_LIFT_STOP_BIOMETRICS(id, date, userToken)),
       fetchDeviceAlertTrail: (id, startDate, userToken, getupdatedResults) => dispatch(LiftActions.LOAD_LIFT_ALERT_TRAIL_TYPE(id, startDate, userToken, getupdatedResults)),
       fetchDeviceStopDetails: (id, startDate, userToken, getupdatedResults) => dispatch(LiftActions.LOAD_LIFT_STOPS_HISTORY_TYPE(id, startDate, userToken, getupdatedResults)),
       updateRefreshDataFlag: (refreshDataRequested) => dispatch(LiftActions.UPDATE_REFRESH_DATA_REQUESTED_FLAG(refreshDataRequested)),
       updateStopSelectedFlag: (stopSelectedFlag) => dispatch(LiftActions.UPDATE_USER_SELECTED_STOP_DASHBOARD_FLAG(stopSelectedFlag)),
       updateAlertSelectedFlag: (alertSelectedFlag) => dispatch(LiftActions.UPDATE_USER_SELECTED_ALERT_DASHBOARD_FLAG(alertSelectedFlag)),
    }
  }

export default connect(mapStateToProps, mapDispatchToProps) (DuoLiftMapItems);