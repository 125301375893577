import * as ActionTypes from './lift.actiontypes';
import moment from 'moment'

export const initialState = {
    userDetails: null,
    authCheck:null,
    authCheckError:'',
    isRPCAdmin: false,
    isReadOnlyUser: false,
    isAdminUser: false,
    isCustomerFleetUser: false,
    userTokens: null,
    userLoggedIn: false,
    userDetailsError: null,
    liftCustomersAndLocations: [],
    selectedLiftCustomer: null,
    selectedLiftLocation: null,
    selectedCustomerLocationData: null,
    devices: [],
    sortedDevices: {onlineMainLifts: [], onlineLifts: [], offLineLifts: []},
    alertPareto: [],
    liftAlertPareto: [],
    liftMaintenanceModal: [],
    showLiftAlertParetoModal: false,
    showLiftMaintenanceModal: false,
    showServiceItemModal: false,
    serviceItem: null,
    serviceItems: [],
    selectedMapType: [],
    selectedDevice: null,
    selectedBoard: null,
    mapDateItemsValue: { start: null},
    refreshDataRequested: false,
    showNavMenu: false,
    selectedDeviceAlertHistory: null,
    geocoder: null,
    selectedDeviceAlertDetailHistory: null,
    selectedDeviceLocationBreadcrumbs: [],
    selectedDeviceStopHistory: [],
    selectedAlertTrail: [],
    selectedStopPallets: [],
    selectedLiftStopBiometrics: null,
    showAlertDetailHistoryModal: false,
    selectedAlertHistory: null,
    error: null,
    loadingIndicatorStatus: false,
    showSignoutPopup: false,
    userLocations: [],
    selectedCustomer: null,
    showCustomerModal: false,
    showLocationModal: false,
    showModalType: null,
    selectedLocationToEdit: null,
    usersForLocations: [],
    selectedLocationForCustomer: null,
    selectedUsersForLocation: null,
    showUserModal: false,
    selectedUserToEdit: null,
    showUserModalRoleError: false,
    liftsForLocations: [],
    selectedLocationsForLifts: null,
    selectedLiftsForLocation: null,
    showLiftEditModal: false,
    liftEditModalType: null,
    selectedLiftToEdit: null,
    liftDriversInfo: null,
    fleetAlertSelection: 30, 
    collapseRightPanel: false,
    userSelectedStopDashboardFlag: false,
    userSelectedStopDashboard: null,
    userSelectedAlertDashboardFlag: false,
    userSelectedAlertDashboard: null,
    userFleetComparisionReportDetails: null,
    showFleetComparisionTable: false,
    resetLogout: false,
    customerLocationList: [],
    selectedCustomerListData: null,
    selectedLocationListData: null,
    assetDetailsData:[],
    totalAssets: null,
    lostScrap: null,
    dwellAndTurnTime: null,
    avgDwellAndTurnTime: null,
    searchInput: "",
    hardwareDetailsData:[],
    successMessage: null,
    storeDetails: [],
    selectedCustomer: false,
}

const reducer = (state = initialState, action) => {
    const newState = { ...state };
    switch (action.type) {
        case ActionTypes.LIFT_MAP_GEOCODER:
            newState.geocoder = action.payload;
            break;
        case ActionTypes.LOAD_CUSTOMERS_AND_LOCATIONS_SUCCESS_TYPE:
            let customerLocationListData = action.payload.data
            let activeCustomersList = customerLocationListData.filter((customerInfo) => customerInfo.customerActive !== false);
            let activeCustomerAndLocationsList = activeCustomersList.map((customerInfo) => {
                customerInfo.locations = customerInfo.locations.filter((locationInfo) => locationInfo.locationActive !== false)
                return customerInfo;
            });
            newState.customerLocationList = activeCustomerAndLocationsList;
            console.log("activeCustomerAndLocationsList",activeCustomerAndLocationsList)
            if(activeCustomerAndLocationsList.length > 0){
                newState.selectedCustomerListData = activeCustomerAndLocationsList[0];
                newState.selectedLocationListData = activeCustomerAndLocationsList[0].locations[0];
            }else{
                newState.selectedCustomer = true;
            }
            newState.resetLogout = true
            break;
       
        case ActionTypes.LOAD_CUSTOMERS_AND_LOCATIONS_FAILURE_TYPE:
            newState.error = errorDescription(action.error)
            newState.resetLogout = true
            break;
        case ActionTypes.UPDATE_SELECTED_LIFT_CUSTOMERS:
            newState.selectedLiftCustomer = action.payload.selectedCustomer
            newState.selectedLiftLocation = null
            newState.selectedCustomerLocationData = action.payload.locationData
            newState.resetLogout = true
            break;
        case ActionTypes.UPDATE_SELECTED_LIFT_LOCATIONS:
            newState.selectedLiftLocation = action.payload
            newState.resetLogout = true
            break;
        case ActionTypes.LOAD_LIFT_SUMMARY_SUCCESS_TYPE:
            let dev = action.payload.data;
            let onlineMainLifts = []
            let onlineLifts = []
            let offLineLifts = []
            if(dev.length !== 0) {
                dev.forEach((item) => {
                    item.clientLastUpdated = Date.now()
                    let currentTime = moment(item.clientLastUpdated)
                    let end = moment(item.lastHeartbeat +'Z'); 
                    let duration = moment.duration(currentTime.diff(end));
                    let connected = duration.asMinutes() <= 5;
                    if (connected) {
                        if (item.maintState === 1 || item.maintState === 2) {
                            onlineMainLifts.push(item)
                        } else {
                            onlineLifts.push(item)
                        }
                    } else {
                        offLineLifts.push(item)
                    }
                })
                newState.sortedDevices.onlineMainLifts = onlineMainLifts
                newState.sortedDevices.onlineLifts = onlineLifts
                newState.sortedDevices.offLineLifts = offLineLifts
                newState.devices = dev
                newState.error = null
            } else {
                newState.sortedDevices.onlineMainLifts = []
                newState.sortedDevices.onlineLifts = []
                newState.sortedDevices.offLineLifts = []
                newState.devices = []
                newState.alertPareto = []
                newState.selectedDevice = null
                // localStorage.removeItem('authToken')
                newState.error = "Lift details not available for the user"
            }
            newState.resetLogout = true
            break;
        case ActionTypes.LOAD_LIFT_SUMMARY_FAILURE_TYPE:
           // localStorage.removeItem('authToken')
            newState.error = errorDescription(action.error)
            break;
        case ActionTypes.LIFT_ALERT_DETAIL_MODAL:
            newState.showAlertDetailHistoryModal = false;
            newState.resetLogout = true
            break;
        case ActionTypes.LOAD_LIFT_MAP_TYPE_UPDATE:
            newState.selectedMapType = action.payload;
            // if (action.payload.length === 0) {
            //     newState.selectedDeviceAlertHistory = []
            //     newState.selectedAlertTrail = []
            //     // initialState.selectedDevice.stops = []
            // }
            break;
        case ActionTypes.LOAD_DASHBOARD_TYPE_UPDATE:
            newState.selectedBoard = action.payload;
            localStorage.setItem('selectedBoard', action.payload)
            newState.resetLogout = true
            break;
        case ActionTypes.LOAD_NAVMENU_TYPE_UPDATE:
            newState.showNavMenu = action.payload;
            newState.resetLogout = true
            break;
        case ActionTypes.LOAD_SIGNOUT_POPUP_TYPE_UPDATE:
            newState.showSignoutPopup = action.payload;
            newState.resetLogout = true
            break;
        case ActionTypes.SIGNOUT_USER:
            newState.showSignoutPopup = action.payload.showSignOut;
            newState.devices = []
            newState.userDetails = null
            newState.userDetailsError = null
            newState.selectedDevice = null
            newState.selectedDeviceAlertHistory = null
            newState.geocoder = null
            newState.selectedDeviceAlertDetailHistory = null
            newState.selectedDeviceLocationBreadcrumbs = []
            newState.showAlertDetailHistoryModal = null
            newState.selectedAlertHistory = null
            newState.error = null
            newState.alertPareto= []
            newState.liftAlertPareto= []
            newState.liftMaintenanceModal= []
            newState.showLiftAlertParetoModal= false
            newState.showLiftMaintenanceModal = false
            newState.userTokens = null
            newState.userLoggedIn = false
            newState.showLiftAlertParetoModal = false
            newState.showLiftMaintenanceModal = false
            newState.selectedMapType = []
            newState.selectedBoard = null
            newState.userLocations = []
            newState.selectedCustomer = null
            newState.usersForLocations = []
            newState.customerLocationList = []
            newState.selectedCustomerListData = null
            newState.selectedLocationListData = null
            newState.selectedLocationForCustomer = null
            newState.selectedUsersForLocation = null
            newState.liftsForLocations = []
            newState.selectedLocationsForLifts = null
            newState.selectedLiftsForLocation = null
            newState.isRPCAdmin = false
            newState.isAdminUser = false
            newState.isReadOnlyUser = false
            newState.isCustomerFleetUser = false
            newState.showLiftEditModal = false
            newState.refreshDataRequested = false
            newState.assetDetailsData = []
            newState.totalAssets = null
            newState.lostScrap = null
            newState.dwellAndTurnTime = null
            newState.avgDwellAndTurnTime = null
            newState.searchInput = ""
            newState.hardwareDetailsData = []
            newState.successMessage = null
            localStorage.clear();
            newState.resetLogout = true
            break;
        case ActionTypes.UPDATE_USER_SELECTED_MAP_DATES:
            newState.mapDateItemsValue = action.payload;
            break;
        case ActionTypes.UPDATE_REFRESH_DATA_REQUESTED_FLAG:
            newState.refreshDataRequested = action.payload;
            break;
        case ActionTypes.LOAD_LIFT_ALERT_TRAIL_SUCCESS_TYPE:
            newState.selectedAlertTrail = action.payload.data
            break;
        case ActionTypes.LOAD_LIFT_ALERT_TRAIL_FAILURE_TYPE:
            newState.error = errorDescription(action.error)
            break;
        case ActionTypes.LOAD_LIFT_BREADCRUMB_HISTORY_SUCCESS_TYPE:
            newState.selectedDeviceLocationBreadcrumbs = action.payload.data
            break;
        case ActionTypes.LOAD_LIFT_BREADCRUMB_HISTORY_FAILURE_TYPE:
            newState.error = errorDescription(action.error)
            break;
        case ActionTypes.LOAD_LIFT_STOPS_HISTORY_SUCCESS_TYPE:
                newState.selectedDeviceStopHistory = action.payload.data
                break;
        case ActionTypes.LOAD_LIFT_STOPS_HISTORY_FAILURE_TYPE:
                newState.error = errorDescription(action.error)
                break;
        case ActionTypes.LOAD_LIFT_DETAIL_SUCCESS_TYPE:
            if (action.payload.data != null) {
                let dev = newState.devices.splice(0)
                dev.forEach((item) => {
                    if (item.liftID === action.payload.data.liftID) {
                        item.alertCount = action.payload.data.alertCount;
                        item.lastHeartbeat = action.payload.data.lastHeartbeat
                        item.batteryPercent = action.payload.data.batteryPercent
                        item.ambientTemp = action.payload.data.ambientTemp
                        item.clientLastUpdated = Date.now()
                        item.stops = action.payload.data.stops
                    }
                })
                newState.devices = dev
            }
            if (action.payload.data.stops !== undefined, action.payload.data.stops !== null) {
                let newRFID = []
                action.payload.data.stops.forEach((stop) => {
                    if (stop.rfid_count !== 0) {
                        stop.rfid.forEach((tag) => {
                            newRFID.push(tag)
                        })
                    }
                })
                newState.selectedStopPallets = newRFID
            }
            newState.selectedDevice = action.payload.data
            let role = localStorage.getItem("userRole")
            if (role === "RpcAdmin") {
                newState.isRPCAdmin = true
            } else if (role === "CustomerReadOnly", role === "LocationReadOnly") {
                newState.isReadOnlyUser = true
            } else if (role === "FleetComparisonUser") {
                newState.isCustomerFleetUser = true
            } 
            if (role === "RpcAdmin" || role === "CustomerAdmin" || role === "LocationAdmin") {
                newState.isAdminUser = true
            }
            // newState.selectedMapType = ['Alerts'];
            // newState.selectedBoard = 'Lift Details'
            break;
        case ActionTypes.LOAD_LIFT_DETAIL_FAILURE_TYPE:
            newState.showAlertDetailHistoryModal = false;
            // localStorage.removeItem('authToken')
            newState.error = errorDescription(action.error)
            break;
        case ActionTypes.LOAD_LIFT_ALERT_HISTORY_SUCCESS_TYPE:
            newState.selectedDeviceAlertHistory = action.payload.data
            break;
        case ActionTypes.LOAD_LIFT_ALERT_HISTORY_FAILURE_TYPE:
            newState.error = errorDescription(action.error)
            break;
        case ActionTypes.LOAD_LIFT_ALERT_DETAIL_HISTORY_SUCCESS_TYPE:
            newState.selectedDeviceAlertDetailHistory = action.payload.data
            newState.showAlertDetailHistoryModal = true;
            newState.selectedAlertHistory = action.alert;
            newState.resetLogout = true
            break;
        case ActionTypes.LOAD_LIFT_ALERT_DETAIL_HISTORY_FAILURE_TYPE:
            newState.error = errorDescription(action.error)
            break;
        case ActionTypes.LOAD_ALERT_PARETO_SUCCESS:
            newState.alertPareto = action.payload.data
            newState.resetLogout = true
            break;
        case ActionTypes.LOAD_ALERT_PARETO_FAILURE:
            newState.error = errorDescription(action.error)
            break;
        case ActionTypes.LOAD_LIFT_ALERT_PARETO_SUCCESS:
            newState.liftAlertPareto = action.payload.data
            newState.showLiftAlertParetoModal = true
            newState.resetLogout = true
            break;
        case ActionTypes.UPDATE_FLEET_COMPARISION_SELCTION:
            newState.fleetAlertSelection = action.payload
            newState.resetLogout = true
            break;
        case ActionTypes.LOAD_LIFT_ALERT_PARETO_FAILURE:
            newState.error = errorDescription(action.error)
            break;
        case ActionTypes.RESET_LOAD_LIFT_ALERT_PARETO_MODAL:
            newState.showLiftAlertParetoModal = false
            break;
        case ActionTypes.LOAD_LIFT_MAINTENANCE_MODAL_SUCCESS:
            newState.liftMaintenanceModal = action.payload.data
            newState.showLiftMaintenanceModal = true
            newState.resetLogout = true
            break;
        case ActionTypes.LOAD_LIFT_MAINTENANCE_MODAL_FAILURE:
            newState.error = errorDescription(action.error)
            break;
        case ActionTypes.UPDATE_LOAD_LIFT_MAINTENANCE_MODAL:
            newState.showLiftMaintenanceModal = false
            newState.resetLogout = true
            break;
        case ActionTypes.LOAD_LIFT_STOP_BIOMETRICS_SUCCESS:
            newState.selectedLiftStopBiometrics = action.payload.data
            break;
        case ActionTypes.LOAD_LIFT_STOP_BIOMETRICS_FAILURE:
            newState.error = errorDescription(action.error)
            break;
        case ActionTypes.LIFT_DASHBOARD_LOGIN_REQUEST_TYPE:
            console.log('User login requesting')
            newState.devices = []
            newState.userDetails = null
            newState.userDetailsError = null
            newState.selectedDevice = null
            newState.selectedDeviceAlertHistory = null
            newState.geocoder = null
            newState.selectedDeviceAlertDetailHistory = null
            newState.selectedDeviceLocationBreadcrumbs = []
            newState.showAlertDetailHistoryModal = null
            newState.selectedAlertHistory = null
            newState.error = null
            newState.alertPareto= []
            newState.liftAlertPareto= []
            newState.liftMaintenanceModal= []
            newState.showLiftAlertParetoModal= false
            newState.showLiftMaintenanceModal = false
            newState.selectedLiftCustomer = null
            newState.selectedLiftLocation = null
            newState.showSignoutPopup = false
            newState.showModalType = null
            newState.showCustomerModal = false
            newState.showLocationModal = false
            newState.userLocations = []
            newState.selectedCustomer = null
            newState.usersForLocations = []
            newState.customerLocationList = []
            newState.selectedCustomerListData = null
            newState.selectedLocationListData = null
            newState.selectedLocationForCustomer = null
            newState.selectedUsersForLocation = null
            newState.liftsForLocations = []
            newState.selectedLocationsForLifts = null
            newState.selectedLiftsForLocation = null
            newState.isRPCAdmin = false
            newState.isReadOnlyUser = false
            newState.isAdminUser = false
            newState.isCustomerFleetUser = false
            newState.showLiftEditModal = false
            newState.refreshDataRequested = false
            newState.loadingIndicatorStatus = true
            newState.assetDetailsData = []
            newState.totalAssets = null
            newState.lostScrap = null
            newState.dwellAndTurnTime = null
            newState.avgDwellAndTurnTime = null
            newState.hardwareDetailsData = []
            localStorage.removeItem('selectedBoard');
            newState.resetLogout = true
            break;
        case ActionTypes.LIFT_DASHBOARD_LOGIN_SUCCESS_TYPE:
            const userdata = action.payload.data
            if(userdata.length !== 0){
                let attributes = action.payload.data.attributes
                if (attributes.role !== "Maintenance" && attributes.role !== "Driver") {
                    newState.userDetails = action.payload.data.attributes
                    newState.userDetailsError = null
                    newState.userTokens = action.payload.data.tokens
                    localStorage.setItem('userID', action.payload.data.attributes.userID)
                    localStorage.setItem('userName', action.payload.data.attributes.userName)
                    localStorage.setItem('authToken', action.payload.data.tokens.IdToken)
                    newState.userLoggedIn = true
                    localStorage.setItem('userRole', attributes.role)
                    if (attributes.role === "RpcAdmin") {
                        newState.isRPCAdmin = true
                    }  else if (attributes.role === "CustomerReadOnly" || attributes.role === "LocationReadOnly") {
                        newState.isReadOnlyUser = true
                    } else if (attributes.role === "FleetComparisonUser") {
                        newState.isCustomerFleetUser = true
                    } 
                    if (attributes.role === "RpcAdmin" || attributes.role === "CustomerAdmin" || attributes.role === "LocationAdmin") {
                        newState.isAdminUser = true
                    }
                    if (attributes.role === "FleetComparisonUser") {
                        newState.selectedBoard = 'Fleet Comparison'
                        localStorage.setItem('selectedBoard', 'Fleet Comparison')
                    } else {
                        newState.selectedBoard = 'Asset Management IOT'
                        localStorage.setItem('selectedBoard', 'Asset Management IOT')
                    }
                } else {
                    newState.userDetailsError = 'Access denied to the user'
                }
            } else{ 
                newState.userDetailsError = 'Incorrect User Name or Password entered'
            }
            newState.loadingIndicatorStatus = false
            newState.resetLogout = true
            break;
        case ActionTypes.VALIDATE_AUTHENTICATION_REQUEST_TYPE:
            break;
        case ActionTypes.VALIDATE_AUTHENTICATION_REQUEST_SUCCESS_TYPE:
            newState.authCheck = true;
            newState.authCheckError=''
            newState.resetLogout = true
            break;
        case ActionTypes.VALIDATE_AUTHENTICATION_REQUEST_FAILURE_TYPE:
            newState.authCheckError = action.payload.response.data;
            newState.resetLogout = true
            break;
        case ActionTypes.LIFT_DASHBOARD_LOGIN_FAILURE_TYPE:
            if (action.payload.response === undefined){
                newState.error = action.payload
            } else if (action.payload.response.data == 'Incorrect username or password.') {
                newState.userDetailsError = 'Incorrect User Name or Password entered'
            } else if (action.payload.response.data == 'Invalid username or password.') {
                newState.userDetailsError = 'Invalid username or password.'
            } else if (action.payload.response.data == "Access currently denied to this User") {
                newState.userDetailsError = 'Access currently denied to this User.'
            } else if (action.payload.response.data == 'Incorrect username and password.') {
                newState.userDetailsError = 'Incorrect User Name and Password entered'
            }
            newState.loadingIndicatorStatus = false
            break;
        case ActionTypes.LIFT_USERS_LOCATIONS_SUCCESS_TYPE:

            let customerLocationData = action.payload.data
            let allLocationInfo = []
            let showAllCustomerData = {}
            let userStoredRole = localStorage.getItem("userRole")
            if (customerLocationData != undefined) {
                if (customerLocationData.length > 0) {
                    customerLocationData.map(customer => {
                        if (customer.locations != undefined) {
                            if (customer.locations.length > 0) {
                                customer.locations.map(location => {
                                    if (location.locationName === 'Unassigned') {
                                        customer.locations.push(customer.locations.splice(customer.locations.indexOf(location), 1)[0]);
                                    }
                                    if (userStoredRole === "RpcAdmin") {
                                        allLocationInfo.push(location)
                                    }
                                })
                            }
                        }
                    })

                }
            }
                if (userStoredRole === "RpcAdmin") {
                    showAllCustomerData['customerId'] = 0
                    showAllCustomerData['customerName'] = 'ShowAll'
                    showAllCustomerData['customerActive'] = true
                    showAllCustomerData['locations'] = allLocationInfo
                    customerLocationData.push(showAllCustomerData)
                }

                if (userStoredRole === "FleetComparisonUser") {
                    newState.isCustomerFleetUser = true
                }
                newState.userLocations = customerLocationData
                if (action.payload.data.length > 0) {
                    if (state.selectedCustomer != undefined) {
                         action.payload.data.map(customer =>{
                            if (customer.customerId === state.selectedCustomer.customerId) {
                                newState.selectedCustomer = customer
                            }
                        })
                    } else {
                        newState.selectedCustomer = customerLocationData[0]
                    }
                }
                newState.resetLogout = true
            break;  
        case ActionTypes.LIFT_USERS_LOCATIONS_ERROR_TYPE:
            newState.error = errorDescription(action.payload)
            break;
        case ActionTypes.LIFT_CUSTOMER_SELECTION_TYPE_UPDATE:
            newState.selectedCustomer = action.payload
            newState.resetLogout = true
            break;
        case ActionTypes.LIFT_ADD_EDIT_CUSTOMER_MODAL_UPDATE:
            newState.showModalType = action.payload.type
            newState.showCustomerModal = action.payload.show
            newState.resetLogout = true
            break;
        case ActionTypes.LIFT_ADD_EDIT_LOCATION_MODAL_UPDATE:
            newState.showModalType = action.payload.type
            newState.showLocationModal = action.payload.show
            newState.selectedLocationToEdit = action.payload.location
            newState.resetLogout = true
            break;
        case ActionTypes.CUSTOMER_ADD_EDIT_SUCCESS_TYPE:
            newState.showCustomerModal = false
            newState.authCheck = false
            newState.authCheckError =''
            newState.resetLogout = true
            break;
        case ActionTypes.CUSTOMER_ADD_EDIT_ERROR_TYPE:
            newState.error = errorDescription(action.payload)
            
            break;
        case ActionTypes.LOCATION_ADD_EDIT_SUCCESS_TYPE:
            newState.showLocationModal = false
            newState.resetLogout = true
            break;
        case ActionTypes.LOCATION_ADD_EDIT_ERROR_TYPE:
            newState.error = errorDescription(action.payload)
            break;
        case ActionTypes.LOCATIONS_USERS_SUCCESS_TYPE:
            let locationUserData = action.payload.data
            let allCustomerLocationInfo = []
            let allUserInfo = []
            let showAllLocationInfo = {}
            let showAllUserInfo = {}
            let userLoggedRole = localStorage.getItem("userRole")
            if (locationUserData != undefined) {
                if (locationUserData.length > 0) {
                    locationUserData.map(customer => {
                        if (customer.locations != undefined) {
                            if (customer.locations.length > 0) {
                                customer.locations.map(location => {
                                    if (location.locationName === 'Unassigned') {
                                        customer.locations.push(customer.locations.splice(customer.locations.indexOf(location), 1)[0]);
                                    }
                                    if (userLoggedRole === 'RpcAdmin') {
                                        allCustomerLocationInfo.push(location)
                                        if (location.users != undefined) {
                                            if (location.users.length > 0) {
                                                location.users.map(user => {
                                                    allUserInfo.push(user)
                                                })
                                            }
                                        }
                                        
                                    }
                                })
                            }
                        }
                    })
                }
            }
            if (userLoggedRole === "RpcAdmin") {
                newState.isRPCAdmin = true
            }  else if (userLoggedRole === "CustomerReadOnly" || userLoggedRole === "LocationReadOnly") {
                newState.isReadOnlyUser = true
            } else if (userLoggedRole === "FleetComparisonUser") {
                newState.isCustomerFleetUser = true
            } 
            
            if (userLoggedRole === "RpcAdmin" || userLoggedRole === "CustomerAdmin" || userLoggedRole === "LocationAdmin") {
                newState.isAdminUser = true
            }
            if (userLoggedRole === 'RpcAdmin') {
                showAllLocationInfo['locationId'] = 0
                showAllLocationInfo['locationName'] = 'ShowAll'
                showAllLocationInfo['locationActive'] = true
                showAllLocationInfo['displayStatus'] = true
                showAllLocationInfo['users'] = allUserInfo
                allCustomerLocationInfo.push(showAllLocationInfo)
                showAllUserInfo['customerId'] = 0
                showAllUserInfo['customerName'] = 'ShowAll'
                showAllUserInfo['customerActive'] = true
                showAllUserInfo['locations'] = allCustomerLocationInfo
                locationUserData.push(showAllUserInfo)             
            }
            newState.usersForLocations = locationUserData
            if (action.payload.data.length > 0) {
                if (state.selectedLocationForCustomer !== undefined) {
                     action.payload.data.map(customer =>{
                        if (customer.customerId === state.selectedLocationForCustomer.customerId) {
                            newState.selectedLocationForCustomer = customer
                            if (customer.locations.length > 0) {
                                customer.locations.map(location => {
                                    if (location.locationId === state.selectedUsersForLocation.locationId) {
                                        newState.selectedUsersForLocation = location
                                    }
                                })
                            }
                        }
                    })
                } else {
                    newState.selectedLocationForCustomer = locationUserData[0]
                    newState.selectedUsersForLocation = locationUserData[0].locations[0]
                }
            }
            newState.resetLogout = true
            break;
        case ActionTypes.LOCATIONS_USERS_ERROR_TYPE:
            newState.error = errorDescription(action.payload)
            break;
        case ActionTypes.LOCATIONS_USERS_CUSTOMER_UPDATE:
            newState.selectedLocationForCustomer = action.payload
            newState.selectedUsersForLocation = null
            newState.resetLogout = true
            break;
        case ActionTypes.LOCATIONS_USER_LOCATION_UPDATE:
            newState.selectedUsersForLocation = action.payload
            newState.resetLogout = true
            break;
        case ActionTypes.CUSTOMER_UPDATE:
            newState.selectedCustomerListData = action.payload
            newState.selectedLocationListData = action.payload.locations[0]
            newState.resetLogout = true
            break;
        case ActionTypes.LOCATION_UPDATE:
            newState.selectedLocationListData = action.payload
            newState.resetLogout = true
            break;
        case ActionTypes.USERS_ADD_MODAL_UPDATE:
            newState.showModalType = action.payload.type
            newState.showUserModal = action.payload.show
            newState.resetLogout = true
            break;
        case ActionTypes.USERS_EDIT_MODAL_UPDATE:
            newState.showModalType = action.payload.type
            newState.showUserModal = action.payload.show
            newState.selectedUserToEdit = action.payload.userData
            newState.resetLogout = true
            break;
        case ActionTypes.SHOW_ERROR_USER_ROLE_UPDATE:
            newState.showUserModalRoleError = action.payload.show
            newState.resetLogout = true
            break;
        case ActionTypes.LOCATIONS_LIFTS_CUSTOMER_UPDATE:
            newState.selectedLocationsForLifts = action.payload
            newState.resetLogout = true
            newState.selectedLiftsForLocation = null
            break;
        case ActionTypes.LOCATIONS_LIFTS_LOCATION_UPDATE:
            newState.selectedLiftsForLocation = action.payload
            newState.resetLogout = true
            break;
        case ActionTypes.LIFTS_EDIT_MODAL_UPDATE:
            newState.showLiftEditModal = action.payload.show
            newState.liftEditModalType = action.payload.type
            newState.selectedLiftToEdit = action.payload.liftData
            newState.resetLogout = true
            break;
        case ActionTypes.LOCATIONS_LIFTS_SUCCESS_TYPE:
            let customersData = action.payload.data
            newState.liftsForLocations = customersData;
            newState.selectedLocationsForLifts = action.payload.data[0]
            newState.selectedLiftsForLocation = action.payload.data[0].locations[0]
            newState.resetLogout = true
            break;
        case ActionTypes.LOCATIONS_LIFTS_ERROR_TYPE:
            newState.error = errorDescription(action.payload)
            break;
        case ActionTypes.LIFTS_DRIVERS_REQUEST_SUCCESS_TYPE:
            newState.liftDriversInfo = action.payload.data
            newState.resetLogout = true
            break;
        case ActionTypes.LIFTS_DRIVERS_REQUEST_ERROR_TYPE:
            newState.error = errorDescription(action.payload)
            break;
        case ActionTypes.LIFTS_UPDATE_REQUEST_SUCCESS_TYPE:
            newState.showLiftEditModal = false
            newState.resetLogout = true
            break;
        case ActionTypes.LIFTS_UPDATE_REQUEST_ERROR_TYPE:
            newState.error = errorDescription(action.payload)
            break;
        case ActionTypes.LOCATION_USERS_UPDATE_SUCCESS_TYPE:
            newState.showUserModal = false
            newState.resetLogout = true
            break;
        case ActionTypes.LOCATION_USERS_UPDATE_FAILURE_TYPE:
            newState.error = errorDescription(action.payload)
            break;
        case ActionTypes.LIFT_SERVICE_MODAL:
            newState.showServiceItemModal = action.payload.showModal
            newState.serviceItem = action.payload.serviceItem
            newState.serviceItems = action.payload.serviceItems
            newState.resetLogout = true
            break;
        case ActionTypes.LIFT_SERVICE_MODAL_UPDATE:
            newState.showServiceItemModal = false
            newState.resetLogout = true
            break
        case ActionTypes.LIFT_CLEAR_SERVICE_TASK_SUCCESS:
            newState.showServiceItemModal = false
            newState.resetLogout = true
            break
        case ActionTypes.LIFT_CLEAR_SERVICE_TASK_SUCCESS:
            newState.error = errorDescription(action.payload)
            break  
        case ActionTypes.UPDATE_RIGHT_PANEL_EXPAND_AND_COLLAPSE:
            newState.collapseRightPanel = action.payload
            newState.resetLogout = true
            break
        case ActionTypes.UPDATE_USER_SELECTED_STOP_DASHBOARD:
            newState.userSelectedStopDashboard = action.payload
            newState.resetLogout = true
            break
        case ActionTypes.UPDATE_USER_SELECTED_STOP_DASHBOARD_FLAG:
            newState.userSelectedStopDashboardFlag = action.payload
            newState.resetLogout = true
            break
        case ActionTypes.UPDATE_USER_SELECTED_ALERT_DASHBOARD:
            newState.userSelectedAlertDashboard = action.payload
            newState.resetLogout = true
            break
        case ActionTypes.UPDATE_USER_SELECTED_ALERT_DASHBOARD_FLAG:
            newState.userSelectedAlertDashboardFlag = action.payload
            newState.resetLogout = true
            break
        case ActionTypes.USER_FLEET_REPORT_REQUEST_SUCCESS_TYPE:
            newState.showFleetComparisionTable = true
            newState.userFleetComparisionReportDetails = action.payload.data
            newState.resetLogout = true
            break
        case ActionTypes.USER_FLEET_REPORT_REQUEST_ERROR_TYPE:
            newState.error = errorDescription(action.payload)
            newState.resetLogout = true
            break
        case ActionTypes.USER_FLEET_REPORT_MODAL_UPDATE:
            newState.showFleetComparisionTable = false
            newState.resetLogout = true
            break;
        case ActionTypes.DEFAULT_ERROR_MESSAGE:
            newState.error = null
            break;
        case ActionTypes.ASSET_DETAILS_SUCCESS_TYPE:
            const assetData = action.payload.data;
            newState.assetDetailsData = assetData;
            newState.resetLogout = true
            break;
        case ActionTypes.ASSET_DETAILS_ERROR_TYPE:
            newState.error = errorDescription(action.payload)
            break;
        case ActionTypes.TOTAL_ASSETS_SUCCESS_TYPE:
            const totalAssets = action.payload.data;
            newState.totalAssets = totalAssets;
            newState.resetLogout = true
            break;
        case ActionTypes.TOTAL_ASSETS_ERROR_TYPE:
            newState.error = errorDescription(action.payload)
            break;
        case ActionTypes.LOST_SCRAP_SUCCESS_TYPE:
            const lostScrap = action.payload.data;
            newState.lostScrap = lostScrap;
            newState.resetLogout = true
            break;
        case ActionTypes.LOST_SCRAP_ERROR_TYPE:
            newState.error = errorDescription(action.payload)
            break;
        case ActionTypes.STORE_DETAILS_SUCCESS_TYPE:
            newState.storeDetails = action.payload.data;
            break;
        case ActionTypes.STORE_DETAILS_ERROR_TYPE:
            newState.error = errorDescription(action.payload)
            console.log("error...", newState.error);
            break;
        case ActionTypes.DWELL_AND_TURN_TIME_SUCCESS_TYPE:
            const dwellAndTurnTime = action.payload.data;
            newState.dwellAndTurnTime = dwellAndTurnTime;
            newState.resetLogout = true
            break;
        case ActionTypes.DWELL_AND_TURN_TIME_ERROR_TYPE:
            newState.error = errorDescription(action.payload)
            break;
        case ActionTypes.AVG_DWELL_AND_TURN_TIME_SUCCESS_TYPE:
            const avgDwellAndTurnTime = action.payload.data;
            newState.avgDwellAndTurnTime = avgDwellAndTurnTime;
            newState.resetLogout = true
            break;
        case ActionTypes.AVG_DWELL_AND_TURN_TIME_ERROR_TYPE:
            newState.error = errorDescription(action.payload)
            break;
        case ActionTypes.HARDWARE_DETAILS_SUCCESS_TYPE:
            const hardwareData = action.payload.data;
            newState.hardwareDetailsData = hardwareData;
            newState.resetLogout = true
            break;
        case ActionTypes.HARDWARE_DETAILS_ERROR_TYPE:
            newState.error = errorDescription(action.payload)
            break;
        case ActionTypes.LOSTSCRAP_ADD_SUCCESS_TYPE:
            const lostScarpData = action.payload.data;
            //newState.lostScrap = lostScarpData;
            newState.successMessage = "Lost/Scrap Successfully Configured";
            newState.resetLogout = true
            break;
        case ActionTypes.LOSTSCRAP_ADD_ERROR_TYPE:
            newState.error = errorDescription(action.payload)
            console.log("error...", newState.error);
            break;
        case ActionTypes.SEARCH_INPUT_UPDATE:
            newState.searchInput = action.payload;
            newState.resetLogout = true
            break;
        default:
            break;
    }
    return newState;
}

const errorDescription = (error) => {
    if (error != undefined && error.response != null) {
        if (error.response.data != null, error.response.data != undefined) {
            if (error.response.data.includes(':')) {
                let requiredError = error.response.data.split(":")
                if (requiredError.length > 0) {
                    return requiredError[1].replace('}', '')
                } else {
                 return error.response.data
                }
            } else {
                return error.response.data
            }
        }
    } else {
        return error
    }
}
export default reducer;