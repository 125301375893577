import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { connect } from 'react-redux';
import * as LiftActions from '../../../store/lift/lift.actions';
import KPICard from "../KPICard/KPICard";
import DcIcon from "../../../assets/dcIcon.svg";
import LocationIcon from "../../../assets/locationIcon.svg";
import TrashIcon from "../../../assets/trashIcon.svg";

const TotalAssetsInFloat = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const customerId = props.selectedCustomerListData?.customerId;
  const locationId = props.selectedLocationListData?.locationId;
  const selectedCustomer = props.selectedCustomerListData?.customerName;

  let totalAssetsData = props?.totalAssets;
  let totalAssetsAtDC
  let totalAssetsAtStore
  if (totalAssetsData) {
    totalAssetsAtDC = totalAssetsData.total_assets_in_dc;
    totalAssetsAtStore = totalAssetsData.total_assets_in_customer_location;
  }
  let lostScrapData = props?.lostScrap;
  let companyTitleValue;
  let locationTitleValue;
  if (lostScrapData) {
    companyTitleValue = `${selectedCustomer} Lost/Scrap >= ${lostScrapData?.cus_lost_scrap_threshold_value} ${lostScrapData?.cus_lost_scrap_threshold_unit}`;
    // locationTitleValue = `Location(s) Lost/Scrap >= ${lostScrapData?.loc_lost_scrap_threshold_value} ${lostScrapData?.loc_lost_scrap_threshold_unit}`;
    locationTitleValue = `Total Pallets Lost`
  }

  const getTotalAssetsAndLostScrap = () => {
    const loggedInUserIDToken = localStorage.getItem("authToken");
    props.fetchTotalAssets(loggedInUserIDToken, customerId, locationId);
    props.fetchLostScrap(loggedInUserIDToken, customerId, locationId);
  }

  const handleRedirect = (text) => {
    props.updateSelectedBoardType(text);
  }

  useEffect(() => {
    if (customerId && locationId) {
      getTotalAssetsAndLostScrap();
    }
  }, [props.selectedCustomerListData, props.selectedLocationListData]);

  useEffect(() => {
    if (totalAssetsData) {
      setIsLoading(false)
    }
  }, [totalAssetsData]);

  useEffect(() => {
    if (lostScrapData) {
      setIsLoading(false)
    }
  }, [lostScrapData]);

  return (
    <Box>
      <Typography sx={styles.heading}>Total Assets in Float (as of Today)</Typography>
      <Grid container spacing={2} sx={{ marginTop: "10px" }}>
        <Grid item xs={12} lg={4}>
          <KPICard
            icon={DcIcon}
            title={"Total Pallets at DC"}
            data={isLoading ? <CircularProgress color="inherit" /> : totalAssetsAtDC}
            tooltip={true}
            tooltipText={"Summary pallet count currently at the distribution center"}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <Box style={{ cursor: "pointer" }} onClick={() => handleRedirect("Store Location Assets")} >
            <KPICard
              icon={LocationIcon}
              title={"Total Pallets at Stores"}
              data={isLoading ? <CircularProgress color="inherit" /> : totalAssetsAtStore}
              tooltip={true}
              tooltipText={"Summary pallet count currently outside the distribution center i.e., at a store location"}
            />
          </Box>
        </Grid>
        {/* Lost scrap for just customer */}
        {/* <Grid item xs={6} lg={3}>
          {isLoading ?
            <KPICard
              icon={TrashIcon}
              title={"Location(s) Lost/Scrap"}
              data={"--"}
              background={"#E0E2EA"}
            /> :
            <KPICard
              icon={TrashIcon}
              title={companyTitleValue}
              data={isLoading ? <CircularProgress color="inherit" /> : lostScrapData?.cus_scrap_kpi_count}
            />
          }
        </Grid> */}
        {/* Lost scrap for location(s) */}
        <Grid item xs={12} lg={4}>
          {isLoading ?
            <KPICard
              icon={TrashIcon}
              title={"Total Pallets Lost"}
              data={"--"}
              background={"#E0E2EA"}
            /> :
            <Box style={{ cursor: "pointer" }} onClick={() => handleRedirect("Lost Assets")}>
              <KPICard
                icon={TrashIcon}
                title={locationTitleValue}
                data={isLoading ? <CircularProgress color="inherit" /> : lostScrapData?.loc_scrap_kpi_count}
                tooltip={true}
                tooltipText={"Summary pallet count currently outside the distribution center which meets the configured threshold set under “Lost Assets Mgmt”"}
              />
            </Box>
          }
        </Grid>
      </Grid>
    </Box>
  )
}

export const styles = {
  heading: {
    color: "#303030",
    fontSize: "18px",
    fontWeight: 600,
  }
}

const mapStateToProps = (state) => {
  return {
    isReadOnlyUser: state.isReadOnlyUser,
    selectedCustomerListData: state.selectedCustomerListData,
    selectedLocationListData: state.selectedLocationListData,
    totalAssets: state.totalAssets,
    lostScrap: state.lostScrap
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchTotalAssets: (userToken, customerId, locationId) => dispatch(LiftActions.TOTAL_ASSETS_REQUEST_TYPE(userToken, customerId, locationId)),
    fetchLostScrap: (userToken, customerId, locationId) => dispatch(LiftActions.LOST_SCRAP_REQUEST_TYPE(userToken, customerId, locationId)),
    updateSelectedBoardType: (selectedType) => dispatch(LiftActions.LOAD_DASHBOARD_TYPE_UPDATE(selectedType))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TotalAssetsInFloat);