import React from 'react';
import Button from '@mui/material/Button';
import { connect } from 'react-redux';
import * as LiftActions from '../../../../../store/lift/lift.actions'


class DuoLiftServiceItems extends React.Component {
    state = { showAllServiceItems: false }
    componentDidUpdate (prevProps, prevState) {

        if (prevState.showAllServiceItems === true){      
            this.setState({ showAllServiceItems: false});
        }
   
       
    }
    render() {
        let serviceItems = this.GetRenderServiceView();

        let serviceTask = this.props.serviceTask
        let serviceView;
        if (serviceItems.length > 0)
            serviceView = <ul style={{ paddingBottom: '1em' }} >
                {
                     <li className="flexContainer" style={{ marginBottom: '.5em', width: '100%' }} key={serviceTask.maintTaskName.maintTaskID}>
                     <div className="cardRow">
                         <a className="maintenanceLink" onClick={() => this.openInNewTab()} href={"javascript:;"}>{serviceTask.maintTaskName}</a>
                         <Button shape={"rectangle"}themecolor={"tertiary"} fillmode= {"solid"} onClick={this.ShowAllServiceItems.bind(this)} style={{right:'5px', left: '10px'}}> Clear Task</Button>
                     </div>
                    </li>
                    // serviceItems.map(item => (
                    //     <li className="flexContainer" style={{ marginBottom: '.5em', width: '100%' }} key={item.partID}>
                    //         <div className="cardRow">
                    //             <a className="maintenanceLink" onClick={() => this.openInNewTab(item.partLink)} href={"javascript:;"}>{item.partName}</a>
                    //             <Button shape={"rectangle"}themeColor={"tertiary"} fillMode= {"solid"} onClick={this.ShowAllServiceItems.bind(this)} style={{right:'5px', left: '10px'}}> Clear Task</Button>
                    //         </div>
                    //     </li>
                    // ))
                }
            </ul>
        // else
        //     serviceView = <span className="service-no-alerts">There are no parts to service at this time </span>

        return (
         
            <div>
                     <Button variant="contained" onClick={this.showDialogBoxForServiceItems} key={serviceTask.maintTaskID} style={{marginBottom: "5px"}}>{serviceTask.short_description} - Click here to view</Button>

                    {/* <Button fillmode= {'link'} themecolor={"primary"} style={{marginBottom: "10px", marginTop: "10px"}} onClick={this.showDialogBoxForServiceItems}>
                    Task{serviceTask.maintTaskID} - Click here to view
                    </Button> */}
                    {/* {this.GetServiceButton()} */}
                    
                    {/* {serviceView} */}
            </div>



        );
    }

    openInNewTab(url) {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    showDialogBoxForServiceItems = (event) => {
        this.props.showDialogForServiceItem(this.props.serviceTask, this.props.serviceItems, true)
    }

    GetServiceButton(){
        if(this.props.serviceTask && this.props.serviceTask.parts && this.props.serviceTask.parts.length > 0){
           return  <Button onClick={this.ShowAllServiceItems.bind(this)} style={{right:'5px', bottom:'5px'}} icon={this.state.showAllServiceItems? "chevron-down": "chevron-right"} look="flat"></Button>

        }
    }
    GetRenderServiceView() {
        // return []
        if (this.state.showAllServiceItems)
            return this.props.serviceTask.parts;
        else
            return [];
    }
    
    ShowAllServiceItems() {
        this.setState({ showAllServiceItems: !this.state.showAllServiceItems });
    }
}

const mapDispatchToProps = (dispatch) =>{
    return {
       showDialogForServiceItem: (serviceItem, serviceItems, showModal) => dispatch(LiftActions.LIFT_SERVICE_MODAL(serviceItem, serviceItems, showModal)),
    }
  }

export default connect(null, mapDispatchToProps) (DuoLiftServiceItems);
