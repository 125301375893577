import * as LiftActions from './lift.actions';
import * as ActionTypes from './lift.actiontypes'
import * as LiftService from '../../services/duo-lift.service'
import { takeEvery, call, put } from 'redux-saga/effects'

export function* loginUser(action) {
   let userParams = action.payload.userName + ":" + action.payload.password
   let encodedParams = btoa(userParams)
   try {
      const data = yield call(LiftService.loginUser, encodedParams)
      yield put(LiftActions.LIFT_DASHBOARD_LOGIN_REQUEST_SUCCESS(data))
      if (data.data.length !== 0 && data.data.attributes != undefined) {
         let userRole = data.data.attributes.role
         if (userRole !== "Maintenance" && userRole !== "Driver") {
            action.payload.navigateToDashboard()
         }
      }
   } catch (error) {
      console.log(error)
      yield put(LiftActions.LIFT_DASHBOARD_LOGIN_REQUEST_FAILURE(error))
   }
}

export function* authCheck(action) {
   let userParams = action.payload.userName + ":" + action.payload.password
   let encodedParams = btoa(userParams);
   try {
      const data = yield call(LiftService.loginUser, encodedParams);
      console.log(data);
      yield put(LiftActions.VALIDATE_AUTHENTICATION_REQUEST_SUCCESS(data))
   } catch (error) {
      console.log(error)
      yield put(LiftActions.VALIDATE_AUTHENTICATION_REQUEST_FAILURE(error));
   }
}

export function* fetchCustomersAndLocations(action) {
   try {
      const data = yield call(LiftService.getCustomersAndLocations, action.payload.userToken)
      yield put(LiftActions.LOAD_CUSTOMERS_AND_LOCATIONS_SUCCESS_TYPE(data))
   } catch (error) {
      console.log(error)
      yield put(LiftActions.LOAD_CUSTOMERS_AND_LOCATIONS_FAILURE_TYPE(error))
   }
} 

export function* fetchLiftDevices(action) {
   try {
      const data = yield call(LiftService.getLiftDevices, action.payload.userToken, action.payload.customerId, action.payload.locationId)

      yield put(LiftActions.LOAD_LIFT_SUMMARY_SUCCESS(data))

   } catch (error) {
      console.log(error)
      yield put(LiftActions.LOAD_LIFT_SUMMARY_FAILURE(error))
   }

}

export function* fetchLiftDevicesAndData(action) {
   try {
      const data = yield call(LiftService.getLiftDevices, action.payload.userToken, action.payload.customerId, action.payload.locationId)

      yield put(LiftActions.LOAD_LIFT_SUMMARY_SUCCESS(data))
      if (data.status === 200) {
         let dev = data.data;
         action.payload.hideLoadingIndicator()
         if (dev.length > 0) {
            action.payload.fetchDeviceDetails(dev[0].liftID)
        } else {
         let error = Error('Lift details not available for user')
         yield put(LiftActions.LOAD_LIFT_SUMMARY_FAILURE(error))
        }
      }
   } catch (error) {
      console.log(error)
      yield put(LiftActions.LOAD_LIFT_SUMMARY_FAILURE(error))
   }

}

export function* fetchLiftDeviceDetails(action) {
   try {
      const data = yield call(LiftService.getLiftDeviceDetail, action.payload.id, action.payload.userToken, action.payload.startDate)
      yield put(LiftActions.LOAD_LIFT_DETAIL_SUCCESS(data))

   } catch (error) {
      console.log(error)
      yield put(LiftActions.LOAD_LIFT_DETAIL_FAILURE(error))

   }

}
export function* fetchLiftDeviceAlertHistory(action) {
   try {
      const data = yield call(LiftService.getDeviceAlertHistory, action.payload.id, action.payload.startDate, action.payload.userToken)
      yield put(LiftActions.LOAD_LIFT_ALERT_HISTORY_SUCCESS(data))

   } catch (error) {
      yield put(LiftActions.LOAD_LIFT_ALERT_HISTORY_FAILURE(error))

   }

}

export function* fecthLiftDeviceAlertTrail(action) {
   try {
      const data = yield call(LiftService.getDeviceAlertTrail, action.payload.id, action.payload.startDate, action.payload.userToken)
      yield put(LiftActions.LOAD_LIFT_ALERT_TRAIL_SUCCESS_TYPE(data))
      if (data.data.length >= 0 && action.payload.getupdatedResults != undefined) {
         action.payload.getupdatedResults()
      }

   } catch (error) {
      yield put(LiftActions.LOAD_LIFT_ALERT_TRAIL_FAILURE_TYPE(error))

   }
}

export function* fetchLiftBreadcrumbHistory(action) {
   try {
      const data = yield call(LiftService.getDeviceLocationBreadcrumbs, action.payload.id,action.payload.startDate, action.payload.userToken)
      yield put(LiftActions.LOAD_LIFT_BREADCRUMB_HISTORY_SUCCESS(data))

   } catch (error) {
      yield put(LiftActions.LOAD_LIFT_BREADCRUMB_HISTORY_FAILURE(error))

   }

}

export function* fetchLiftStopsHistory(action) {
   try {
      const data = yield call(LiftService.getDeviceStopDetails, action.payload.id, action.payload.startDate, action.payload.userToken)
      yield put(LiftActions.LOAD_LIFT_STOPS_HISTORY_SUCCESS(data))
      if (data.data.length >= 0 && action.payload.getupdatedResults != undefined) {
         action.payload.getupdatedResults()
      }

   } catch (error) {
      yield put(LiftActions.LOAD_LIFT_STOPS_HISTORY_FAILURE(error))

   }
}


export function* fetchLiftDeviceAlertDetailHistory(action) {
   try {
      const data = yield call(LiftService.getDeviceAlertDetailHistory, action.payload.id, action.payload.alert.alertID, action.payload.userToken)
      yield put(LiftActions.LOAD_LIFT_ALERT_DETAIL_HISTORY_SUCCESS(data, action.payload.alert))

   } catch (error) {
      yield put(LiftActions.LOAD_LIFT_ALERT_DETAIL_HISTORY_FAILURE(error))

   }

}

export function* fetchAlertPareto(action) {
   try {
      const data = yield call(LiftService.getAlertPareto, action.payload.userToken, action.payload.locationid, action.payload.startdate, action.payload.enddate)
      yield put(LiftActions.LOAD_ALERT_PARETO_SUCCESS(data))

   } catch (error) {
      yield put(LiftActions.LOAD_ALERT_PARETO_FAILURE(error))

   }
}

export function* fetchLiftAlertPareto(action) {
   try {
      const data = yield call(LiftService.getLiftAlertPareto, action.payload.alertID, action.payload.userToken)
      yield put(LiftActions.LOAD_LIFT_ALERT_PARETO_SUCCESS(data))

   } catch (error) {
      yield put(LiftActions.LOAD_LIFT_ALERT_PARETO_FAILURE(error))

   }
}

export function* fetchLiftMaintenanceDetails(action) {
   try {
      const data = yield call(LiftService.getLiftMaintenanceList, action.payload.statusCode, action.payload.userToken)
      yield put(LiftActions.LOAD_LIFT_MAINTENANCE_MODAL_SUCCESS(data))

   } catch (error) {
      yield put(LiftActions.LOAD_LIFT_MAINTENANCE_MODAL_FAILURE(error))

   }
}

export function* fetchLiftStopBiometrics(action) {
   try {
      const data = yield call(LiftService.getLiftStopBiometrics, action.payload.id, action.payload.date, action.payload.userToken)
      yield put(LiftActions.LOAD_LIFT_STOP_BIOMETRICS_SUCCESS(data))

   } catch (error) {
      yield put(LiftActions.LOAD_LIFT_STOP_BIOMETRICS_FAILURE(error))

   }
}

export function* fetchUsersAndLocations(action) {
   try {
      const data = yield call(LiftService.getUsersAndLocations, action.payload.userToken)
      yield put(LiftActions.LIFT_USERS_LOCATIONS_SUCCESS_TYPE(data))

   } catch (error) {
      yield put(LiftActions.LIFT_USERS_LOCATIONS_ERROR_TYPE(error))

   }
}

export function* addNewUser(action) {
   try {
      const data = yield call(LiftService.addNewUser, action.payload.userToken, action.payload.customerName, action.payload.status)
      yield put(LiftActions.CUSTOMER_ADD_EDIT_SUCCESS_TYPE(data))
      if (data.data === 'Success') {
         action.payload.callUpdatedResults()
      }
   } catch (error) {
      yield put(LiftActions.CUSTOMER_ADD_EDIT_ERROR_TYPE(error))
   }
}

export function* editUser(action) {
   try {
      const data = yield call(LiftService.editUser, action.payload.userToken, action.payload.customerID, action.payload.customername, action.payload.status)
      yield put(LiftActions.CUSTOMER_ADD_EDIT_SUCCESS_TYPE(data))
      if (data.data === 'Success') {
         action.payload.callUpdatedResults()
         action.payload.callUpdatedUsers()
         action.payload.callUpdatedLifts()
      }
   } catch (error) {
      yield put(LiftActions.CUSTOMER_ADD_EDIT_ERROR_TYPE(error))
   }
}

export function* addNewLocation(action) {
   try {
      const data = yield call(LiftService.addNewLocation, action.payload.userToken, action.payload.locationName, action.payload.customerName, action.payload.latitude, action.payload.longitude,action.payload.formRadius, action.payload.status, action.payload.showMaintanance)
      yield put(LiftActions.LOCATION_ADD_EDIT_SUCCESS_TYPE(data))
      if (data.data === 'Success') {
         action.payload.callUpdatedResults()
      }
   } catch (error) {
      yield put(LiftActions.LOCATION_ADD_EDIT_ERROR_TYPE(error))
   }
}

export function* editLocation(action) {
   try {
      const data = yield call(LiftService.editLocation, action.payload.userToken, action.payload.locationId, action.payload.newName, action.payload.latitude, action.payload.longitude, action.payload.formRadius, action.payload.status, action.payload.showMaintanance)
      yield put(LiftActions.LOCATION_ADD_EDIT_SUCCESS_TYPE(data))
      if (data.data === 'Success') {
         action.payload.callUpdatedResults()
         action.payload.callUpdatedUsers()
         action.payload.callUpdatedLifts()
      }
   } catch (error) {
      yield put(LiftActions.LOCATION_ADD_EDIT_ERROR_TYPE(error))
   }
}

export function* fetchUsersForLocations(action) {
   try {
      const data = yield call(LiftService.getUsersData, action.payload.userToken)
      yield put(LiftActions.LOCATIONS_USERS_SUCCESS_TYPE(data))

   } catch (error) {
      yield put(LiftActions.LOCATIONS_USERS_ERROR_TYPE(error))

   }
}

export function* fetchLiftsForLocations(action) {
   try {
      const data = yield call(LiftService.getLiftData, action.payload.userToken,action.payload.customerId, action.payload.locationId)
      yield put(LiftActions.LOCATIONS_LIFTS_SUCCESS_TYPE(data))

   } catch (error) {
      yield put(LiftActions.LOCATIONS_LIFTS_ERROR_TYPE(error))

   }
}

export function* getDiversInfo(action) {
   try {
      const data = yield call(LiftService.getDriversInfo, action.payload.userToken, action.payload.locationId, action.payload.customerId)
      yield put(LiftActions.LIFTS_DRIVERS_REQUEST_SUCCESS_TYPE(data))

   } catch (error) {
      yield put(LiftActions.LIFTS_DRIVERS_REQUEST_ERROR_TYPE(error))

   }
}

export function* updateUserDetails(action) {
   try {
      const data = yield call(LiftService.editUserDetails, action.payload.requestType, action.payload.authToken, action.payload.userId, action.payload.username, action.payload.firstname, action.payload.lastname, action.payload.email, action.payload.active, action.payload.customerId, action.payload.password, action.payload.role, action.payload.locations)
      yield put(LiftActions.LOCATION_USERS_UPDATE_SUCCESS_TYPE(data))
      if (data.data === 'Success') {
         action.payload.getUpdatedUsers()
         action.payload.getUpdatedDrivers()
      }
   } catch (error) {
      yield put(LiftActions.LOCATION_USERS_UPDATE_FAILURE_TYPE(error))
   }
}

export function* updateLiftDetails(action) {
   try {
      const data = yield call(LiftService.editLiftDetails, action.payload.userToken, action.payload.liftId, action.payload.locationId, action.payload.driverId, action.payload.routeName, action.payload.truckName, action.payload.liftStatus)
      yield put(LiftActions.LIFTS_UPDATE_REQUEST_SUCCESS_TYPE(data))
      if (data.data === 'Success') {
         action.payload.updateLiftDetails()
      }
   } catch (error) {
      yield put(LiftActions.LIFTS_UPDATE_REQUEST_ERROR_TYPE(error))

   }

}

export function* clearServiceTask(action) {
   try {
      const data = yield call(LiftService.clearServiceItems, action.payload.userToken, action.payload.id, action.payload.sheduledIDs, action.payload.date, action.payload.keyonTime, action.payload.userId, action.payload.taskId)
      yield put(LiftActions.LIFT_CLEAR_SERVICE_TASK_SUCCESS(data))
      if (data.data === 'Success') {
         action.payload.updateResults()
      }
   } catch (error) {
      yield put(LiftActions.LIFT_CLEAR_SERVICE_TASK_FAILURE(error))

   }
}

export function* getFleetComparisionReport(action) {
   try {
      const data = yield call(LiftService.getAlertDetailFleet, action.payload.authToken, action.payload.locationid, action.payload.startdate, action.payload.enddate)
      yield put(LiftActions.USER_FLEET_REPORT_REQUEST_SUCCESS_TYPE(data))
   } catch (error) {
      yield put(LiftActions.USER_FLEET_REPORT_REQUEST_ERROR_TYPE(error))

   }
} 

export function* getAssetDetails(action) {
   try {
      const data = yield call(LiftService.getAssetDetails, action.payload.userToken,action.payload.customerId, action.payload.locationId)
      yield put(LiftActions.ASSET_DETAILS_SUCCESS_TYPE(data))
   } catch (error) {
      yield put(LiftActions.ASSET_DETAILS_ERROR_TYPE(error))
   }
}

export function* getTotalAssets(action) {
   try {
      const data = yield call(LiftService.getTotalAssets, action.payload.userToken,action.payload.customerId, action.payload.locationId)
      yield put(LiftActions.TOTAL_ASSETS_SUCCESS_TYPE(data))
   } catch (error) {
      yield put(LiftActions.TOTAL_ASSETS_ERROR_TYPE(error))
   }
}

export function* getLostScrap(action) {
   try {
      const data = yield call(LiftService.getLostScrap, action.payload.userToken,action.payload.customerId, action.payload.locationId)
      yield put(LiftActions.LOST_SCRAP_SUCCESS_TYPE(data))
   } catch (error) {
      yield put(LiftActions.LOST_SCRAP_ERROR_TYPE(error))
   }
}

export function* getDwellAndTurnTime(action) {
   try {
      const data = yield call(LiftService.getDwellAndTurnTime, action.payload.userToken,action.payload.customerId, action.payload.locationId)
      yield put(LiftActions.DWELL_AND_TURN_TIME_SUCCESS_TYPE(data))
   } catch (error) {
      yield put(LiftActions.DWELL_AND_TURN_TIME_ERROR_TYPE(error))
   }
}

export function* getAvgDwellAndTurnTime(action) {
   try {
      const data = yield call(LiftService.getAvgDwellAndTurnTime, action.payload.userToken,action.payload.customerId, action.payload.locationId)
      yield put(LiftActions.AVG_DWELL_AND_TURN_TIME_SUCCESS_TYPE(data))
   } catch (error) {
      yield put(LiftActions.AVG_DWELL_AND_TURN_TIME_ERROR_TYPE(error))
   }
}

export function* getHardwareDetails(action) {
   try {
      const data = yield call(LiftService.getHardwareDetails, action.payload.userToken,action.payload.customerId, action.payload.locationId)
      yield put(LiftActions.HARDWARE_DETAILS_SUCCESS_TYPE(data))
   } catch (error) {
      yield put(LiftActions.HARDWARE_DETAILS_ERROR_TYPE(error))
   }
}

export function* addLostScrap(action) {
   try {
      const data = yield call(LiftService.addLostScrap, action.payload.userToken,action.payload.customerId, action.payload.locationId, action.payload.lostScrapValue, action.payload.lostScrapUnit)
      yield put(LiftActions.LOSTSCRAP_ADD_SUCCESS_TYPE(data))
   } catch (error) {
      yield put(LiftActions.LOSTSCRAP_ADD_ERROR_TYPE(error))
   }
}

export function* storeDetails(action) {
   try {
      const data = yield call(LiftService.storeDetails, action.payload.userToken,action.payload.customerId, action.payload.locationId, action.payload.dashboardFlag)
      yield put(LiftActions.STORE_DETAILS_SUCCESS_TYPE(data))
   } catch (error) {
      yield put(LiftActions.STORE_DETAILS_ERROR_TYPE(error))
   }
}

export function* fetchUserRoleName(action) {
   try {
      const data = yield call(LiftService.fetchUserRoleName, action.payload.userToken,action.payload.roleId)
      yield put(LiftActions.FETCH_USER_ROLE_SUCCESS_TYPE(data))
   } catch (error) {
      yield put(LiftActions.FETCH_USER_ROLE_ERROR_TYPE(error))
   }
}

// export function* fetchUserAccessLevel(action) {
//    try {
//       const data = yield call(LiftService.fetchUserAccessLevel, action.payload.userToken,action.payload.userId)
//       yield put(LiftActions.FETCH_USER_ACCESS_SUCCESS_TYPE(data))
//    } catch (error) {
//       yield put(LiftActions.FETCH_USER_ACCESS_ERROR_TYPE(error))
//    }
// }

export function* EffectListener() {
   yield takeEvery(ActionTypes.LIFT_DASHBOARD_LOGIN_REQUEST_TYPE, loginUser)
   yield takeEvery(ActionTypes.VALIDATE_AUTHENTICATION_REQUEST_TYPE, authCheck)
   yield takeEvery(ActionTypes.LOAD_CUSTOMERS_AND_LOCATIONS, fetchCustomersAndLocations)
   yield takeEvery(ActionTypes.LOAD_LIFT_SUMMARY_TYPE, fetchLiftDevices)
   yield takeEvery(ActionTypes.LOAD_LIFT_STOP_ALERT_DATA, fetchLiftDevicesAndData)
   yield takeEvery(ActionTypes.LOAD_LIFT_DETAIL_TYPE, fetchLiftDeviceDetails)
   yield takeEvery(ActionTypes.LOAD_LIFT_ALERT_HISTORY_TYPE, fetchLiftDeviceAlertHistory)
   yield takeEvery(ActionTypes.LOAD_LIFT_ALERT_DETAIL_HISTORY_TYPE, fetchLiftDeviceAlertDetailHistory)
   yield takeEvery(ActionTypes.LOAD_LIFT_BREADCRUMB_HISTORY_TYPE, fetchLiftBreadcrumbHistory)
   yield takeEvery(ActionTypes.LOAD_LIFT_STOPS_HISTORY_TYPE, fetchLiftStopsHistory)
   yield takeEvery(ActionTypes.LOAD_LIFT_ALERT_TRAIL_TYPE, fecthLiftDeviceAlertTrail)
   yield takeEvery(ActionTypes.LOAD_ALERT_PARETO, fetchAlertPareto)
   yield takeEvery(ActionTypes.LOAD_LIFT_ALERT_PARETO, fetchLiftAlertPareto)
   yield takeEvery(ActionTypes.LOAD_LIFT_MAINTENANCE_MODAL,fetchLiftMaintenanceDetails)
   yield takeEvery(ActionTypes.LOAD_LIFT_STOP_BIOMETRICS,fetchLiftStopBiometrics)
   yield takeEvery(ActionTypes.LIFT_USERS_LOCATIONS_REQUEST_TYPE, fetchUsersAndLocations)
   yield takeEvery(ActionTypes.CUSTOMER_ADD_REQUEST_TYPE, addNewUser)
   yield takeEvery(ActionTypes.CUSTOMER_EDIT_REQUEST_TYPE, editUser)
   yield takeEvery(ActionTypes.LOCATION_ADD_REQUEST_TYPE, addNewLocation)
   yield takeEvery(ActionTypes.LOCATION_EDIT_REQUEST_TYPE, editLocation)
   yield takeEvery(ActionTypes.LOCATIONS_USERS_REQUEST_TYPE, fetchUsersForLocations)
   yield takeEvery(ActionTypes.LOCATIONS_LIFTS_REQUEST_TYPE, fetchLiftsForLocations)
   yield takeEvery(ActionTypes.LIFTS_DRIVERS_REQUEST_TYPE, getDiversInfo)
   yield takeEvery(ActionTypes.LIFTS_UPDATE_REQUEST_TYPE, updateLiftDetails)
   yield takeEvery(ActionTypes.LOCATION_USERS_UPDATE_REQUEST_TYPE, updateUserDetails)
   yield takeEvery(ActionTypes.LIFT_CLEAR_SERVICE_TASK, clearServiceTask)
   yield takeEvery(ActionTypes.USER_FLEET_REPORT_REQUEST_TYPE, getFleetComparisionReport)
   yield takeEvery(ActionTypes.ASSET_DETAILS_REQUEST_TYPE, getAssetDetails)
   yield takeEvery(ActionTypes.TOTAL_ASSETS_REQUEST_TYPE, getTotalAssets)
   yield takeEvery(ActionTypes.LOST_SCRAP_REQUEST_TYPE, getLostScrap)
   yield takeEvery(ActionTypes.DWELL_AND_TURN_TIME_REQUEST_TYPE, getDwellAndTurnTime)
   yield takeEvery(ActionTypes.AVG_DWELL_AND_TURN_TIME_REQUEST_TYPE, getAvgDwellAndTurnTime)
   yield takeEvery(ActionTypes.HARDWARE_DETAILS_REQUEST_TYPE, getHardwareDetails)
   yield takeEvery(ActionTypes.LOST_SCRAP_ADD_REQUEST_TYPE, addLostScrap)
   yield takeEvery(ActionTypes.STORE_DETAILS_REQUEST_TYPE, storeDetails)
   yield takeEvery(ActionTypes.FETCH_USER_ROLE_REQUEST_TYPE, fetchUserRoleName)
}
