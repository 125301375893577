import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import { InputLabel, FormControl, MenuItem, Typography, Select } from '@mui/material';
import * as LiftActions from '../../../store/lift/lift.actions'
import Paper from '@mui/material/Paper';
import {
    ArgumentAxis,
    ValueAxis,
    Chart,
    BarSeries,
} from '@devexpress/dx-react-chart-material-ui';
import { EventTracker } from '@devexpress/dx-react-chart';
import exclamationIcon from '../../../assets/triangle-exclamation-solid.svg';
import FleetComparisionChartDataTable from '../FleetComparisionChartDataTable/FleetComparisionChartDataTable.js';
import FleetDetailedReport from '../FleetDetailedReport/FleetDetailedReport.js'

const FleetAlertChart = (props) => {
    const [alertSelection, setAlertSelection] = useState(30);

    const getCurrentDate = () => {
        let newDate = new Date()
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        let day = newDate.getDate();
        let requireDate = year + '-' + month + '-' + day;
        return requireDate
    }

    const getBeforeDate = (daysBack) => {
        let newDate = new Date()
        newDate.setDate(newDate.getDate() - daysBack);
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        let day = newDate.getDate();
        let requireDate = year + '-' + month + '-' + day;
        return requireDate
    }

    const [startDate, setStartDate] = useState(getBeforeDate(30));
    const [endDate, setEndDate] = useState(getCurrentDate());

    const handleChange = (event) => {
        setAlertSelection(event.target.value);
        let locationId = props.selectedLocationListData.locationId
        var startDate = ""
        var endDate = ""
        const loggedInUserIDToken = localStorage.getItem("authToken");

        if (event.target.value === 1) {
            startDate = getCurrentDate()

        } else if (event.target.value === 7) {
            startDate = getBeforeDate(event.target.value)
            endDate = getCurrentDate()

        } else if (event.target.value === 30) {
            startDate = getBeforeDate(event.target.value)
            endDate = getCurrentDate()

        } else if (event.target.value === 365) {
            startDate = getBeforeDate(event.target.value)
            endDate = getCurrentDate()
        }
        props.updateUserAlertSelection(event.target.value)
        props.fetchAlertPareto(loggedInUserIDToken, locationId, startDate, endDate);
        setStartDate(startDate);
        setEndDate(endDate)
    };

    const showOverLayforChart = (event) => {
        const loggedInUserIDToken = localStorage.getItem("authToken");
        props.loadAlertDataModal(event.dataItem.alertID.replace('Alert', ''), loggedInUserIDToken);
    }

    const addAlertTexttoNumber = (alertParetos) => {
        alertParetos.map(alertPareto => {
            if (alertPareto.alertID !== null) {
                let textContains = `${alertPareto.alertID}`
                if (textContains.includes("Alert") == false) {
                    alertPareto.alertID = `Alert ${alertPareto.alertID}`;
                }
            }
        }
        )
        return alertParetos;
    }

    const userClickOnBar = (event) => {
        console.log(event)
    }

    let alertParetoRetrieved = props.alertPareto;
    let modifiedAlertPareto = addAlertTexttoNumber(alertParetoRetrieved)
    let alertParetoGraph;
    let userSelectionForDropDown;

    if (alertSelection !== props.userSelectionAlerts) {
        userSelectionForDropDown = props.userSelectionAlerts
    } else {
        userSelectionForDropDown = alertSelection
    }
    if (alertParetoRetrieved.length > 0) {
        alertParetoGraph =
            <Paper>
                <Chart
                    data={modifiedAlertPareto}
                >
                    <ArgumentAxis />
                    <ValueAxis />
                    <BarSeries valueField="alertCount" argumentField="alertID" barWidth="0.2" color="red" />
                    <EventTracker onClick={userClickOnBar}></EventTracker>
                </Chart>
            </Paper>
    } else {
        alertParetoGraph =
            <Paper>
                <Typography gutterBottom variant="h5" component="h2" className="app-name-font" style={{ marginRight: "20px" }}>
                    NO ALERT DATA AVAILABLE
                </Typography>
            </Paper>

    }

    useEffect(() => {
        setAlertSelection(30);
        props.updateUserAlertSelection(30);
      }, [props.selectedCustomerListData, props.selectedLocationListData, props.selectedBoard])

    return (
        <div className="fleetComparisonBarGraph">
            <div className="fleetComparisionTopDropDown">
                <FormControl fullWidth style={{ width: '50%' }}>
                    <InputLabel id="demo-simple-select-label">Alerts</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={alertSelection}
                        label="Age"
                        onChange={handleChange}
                        size='small'
                    >
                        <MenuItem value={1}>Day</MenuItem>
                        <MenuItem value={7}>Week</MenuItem>
                        <MenuItem value={30}>Month</MenuItem>
                        <MenuItem value={365}>Year</MenuItem>
                    </Select>
                </FormControl>
                <FleetDetailedReport startDate={startDate} endDate={endDate}></FleetDetailedReport>
            </div>
            <div className="fleetComparisionTopTitle">
                <img src={exclamationIcon} width="35px" length="35px" style={{ marginRight: "20px" }} />
                <Typography gutterBottom variant="h5" component="h3" className="app-name-font">
                    TOP 5 ALERTS
                </Typography>
            </div>
            <div>
                {alertParetoGraph}
                <div style={{ marginTop: "20px", marginBottom: "20px" }}>
                    <FleetComparisionChartDataTable liftAlertData={modifiedAlertPareto}></FleetComparisionChartDataTable>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        userSelectionAlerts: state.fleetAlertSelection,
        selectedLocationListData: state.selectedLocationListData,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loadAlertDataModal: (alertID, userToken) => dispatch(LiftActions.LOAD_LIFT_ALERT_PARETO(alertID, userToken)),
        fetchAlertPareto: (userToken, locationid, startdate, enddate) => dispatch(LiftActions.LOAD_ALERT_PARETO(userToken, locationid, startdate, enddate)),
        updateUserAlertSelection: (alertType) => dispatch(LiftActions.UPDATE_FLEET_COMPARISION_SELCTION(alertType))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FleetAlertChart);