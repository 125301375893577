import React from 'react';
import { connect } from 'react-redux'
import UserManagementBottomPanel from '../UserManagementBottomPanel/UserManagementBottomPanel.js'
import UserManagementAddEditModal from '../UserManagementAddEditModal/UserManagementAddEditModal.js'
class UserManagementPortal extends React.Component {
    render() {
        return(
            <div style={{width: "100%"}}>
            <UserManagementBottomPanel selectedUsersForLocation = {this.props.selectedUsersForLocation} isReadOnly={this.props.isReadOnlyUser}></UserManagementBottomPanel>
            <UserManagementAddEditModal></UserManagementAddEditModal>
            </div>
        )
    }
}

const mapStateToProps = (state) =>{
    return {
        usersForLocations: state.usersForLocations,
        selectedLocationForCustomer: state.selectedLocationForCustomer,
        selectedUsersForLocation: state.selectedUsersForLocation,
        isReadOnlyUser: state.isReadOnlyUser,
    }
 }

export default connect(mapStateToProps, null) (UserManagementPortal);
