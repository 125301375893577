import React, { useEffect, useContext, useMemo } from "react";
import { Box, Paper, Pagination, Table, TableContainer, TablePagination } from "@mui/material";
import AppTableHead from "./TableHead";
import AppTableBody from "./TableBody";
import { styles } from "./styles";
import DropdownIcon from "../../assets/dropdownIcon.svg"

const StationTable = ({ tableHeadItems, tableBodyData, tableName, editButtonArea, unassignButtonArea, handleEditLiftTapped, handleUnassignLiftTapped, customerName, locationName }) => {
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const totalPages = Math.ceil(tableBodyData.length / rowsPerPage);
  const [currentPage, setCurrentPage] = React.useState(1);
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * rowsPerPage;
    const lastPageIndex = firstPageIndex + rowsPerPage;
    return tableBodyData.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, rowsPerPage, tableBodyData]);

  const handleChangePage = (newPage) => {
    setPage(newPage);
    setCurrentPage(newPage)
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  return (
    <Paper sx={styles.paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <AppTableHead tableHeadItems={tableHeadItems} />
        <AppTableBody
          tableType={tableHeadItems.type}
          data={currentTableData}
          editButtonArea={editButtonArea}
          unassignButtonArea={unassignButtonArea}
          handleEditLiftTapped={handleEditLiftTapped}
          handleUnassignLiftTapped={handleUnassignLiftTapped}
          customerName={customerName}
          locationName={locationName}
        />
      </Table>
      {tableName !== "userProfile" && (
        <Box sx={styles.tableFooter}>
          {tableBodyData.length > 0 && (
            <TablePagination
              rowsPerPageOptions={[10, 20, 30, 40, 50]}
              component="div"
              count={10}
              page={0}
              rowsPerPage={rowsPerPage}
              onPageChange={() => { }}
              onRowsPerPageChange={handleChangeRowsPerPage}
              sx={styles.tablePagination}
              SelectProps={{
                IconComponent: () => {
                  return (
                    <img
                      src={DropdownIcon}
                      alt="v"
                      style={{
                        position: "absolute",
                        right: 8,
                      }}
                    />
                  );
                },
              }}
            />
          )}
          {tableBodyData.length > 0 && (
            <Pagination
              count={totalPages}
              shape="rounded"
              sx={styles.pagination}
              siblingCount={0}
              defaultPage={1}
              size={"small"}
              onChange={(e, v) => {
                handleChangePage(v);
              }}
              page={page}
            />
          )}
          <Box sx={styles.blocker}></Box>
        </Box>
      )}
    </Paper>
  );
};

export default StationTable;