import React from 'react';
import {connect} from 'react-redux'
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import * as LiftActions from '../../../store/lift/lift.actions'
import duoLiftImage from '../../../assets/DuoIOTLift.png'
class DuoLiftListTopSelection extends React.Component {

    state = {
       selectedLiftLocation: this.props.selectedLiftLocation,
       selectedLiftLocation: this.props.selectedLiftLocation
    }
    
    handleCustomerChange = (event) => {
        console.log(event.target.value)
        if (this.props.liftCustomersAndLocations.length !== 0) {
            this.props.liftCustomersAndLocations.map((customer) => {
                if (customer.customerId == event.target.value) {
                    this.props.updateSelectedCustomer(customer, customer.locations)
                }
            })
        }
    }


    handleLocationChange = (event) => {
        console.log(event.target.value)
        
        if (this.props.liftCustomersAndLocations.length !== 0) {
            this.props.liftCustomersAndLocations.map((customer) => {
                if (customer.customerId == this.props.selectedLiftCustomer.customerId) {
                    if (customer.locations.length !== 0) {
                        customer.locations.map((location) => {
                            if (location.locationId == event.target.value) {
                                this.props.updateSelectedLocation(location)
                            }
                        })
                    }
                }
            })
        }
        this.fetchDevices(event.target.value)
    }

    fetchDevices = (locationId) => {
        if (this.props.selectedLiftCustomer != undefined) {
            let userIDToken = localStorage.getItem("authToken");

            let customerId = this.props.selectedLiftCustomer.customerId
            // let locationId = this.props.selectedLiftLocation.locationId
            this.props.fetchDevices(userIDToken, customerId, locationId);
        }
    
        // if (props.selectedLiftCustomer !== undefined, props.selectedLiftLocation !== undefined)
        //  props.fetchDevices(userIDToken, props.selectedLiftCustomer.customerId, props.selectedLiftLocation.locationId);
    
      }
    
    render () {
        let customerData = []
        let locationData = []
        let customerName = ""
        let locationName = ""
        if (this.props.selectedLiftCustomer != undefined) {
            customerName = this.props.selectedLiftCustomer.customerId
            if  (this.props.selectedLiftLocation != undefined) {
                locationName = this.props.selectedLiftLocation.locationId

            }
        }
        if (this.props.liftCustomersAndLocations.length !== 0) {
            customerData = this.props.liftCustomersAndLocations.filter((customer) => customer.customerActive !== false);
            if (this.props.selectedCustomerLocationData.length !== 0) {
                locationData = this.props.selectedCustomerLocationData.filter((location) => location.locationActive !== false);
            }
        }
        if (this.props.isRPCAdmin) {
            return (
                <div className = "duoLiftlistTopSelection">
                    <div className = "duoLiftTitleContainer">
                    <img src= {duoLiftImage} style={{width: "80px", height: "80px", marginLeft: "25px", marginTop: "10px"}} alt="Lift" />
                    <div className="liftHeadingFont" style={{marginLeft: "15px"}}> LIFTS</div>
                    </div>
                    <div className="duoLiftDropDownContainer">
                    <Box sx={{ minWidth: 60, marginTop: "20px" }}>
                        <FormControl fullWidth variant="outlined" sx={{marginBottom: "20px", backgroundColor: "white", borderWidth: '0px'}}>
                        <InputLabel id="demo-simple-select-label">Customer</InputLabel>
                        <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={customerName}
                        label="Customer"
                        onChange={this.handleCustomerChange} 
                        key="Customer"
                        >
                        {customerData.map((customer, index) => (
                            <MenuItem key={index} value={customer.customerId}>{customer.customerName}</MenuItem>
                        ))}
                        </Select>
                        </FormControl>
                        <FormControl fullWidth sx={{backgroundColor: "white"}}>
                        <InputLabel id="demo-simple-select-label">Location</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={locationName}
                            label="Location"
                            onChange={this.handleLocationChange}  
                            key="Location" 
                        >
                        {locationData.map((location, index) => (
                            <MenuItem key={index} value={location.locationId}>{location.locationName}</MenuItem>
                        ))}
                        </Select>
                     </FormControl>
                    </Box>
                    </div>
                </div>
            )
        } else {
            return (
                <div className = "duoLiftlistTopSelection">
                    <div className = "duoLiftTitleContainer">
                    <img src= {duoLiftImage} style={{width: "80px", height: "80px", marginLeft: "25px", marginTop: "10px"}} alt="Lift" />
                    <div className="liftHeadingFont" style={{marginLeft: "15px"}}> LIFTS</div>
                    </div>
                    <div className="duoLiftDropDownContainer">
                    <Box sx={{ minWidth: 60, marginTop: "20px"}}>
                        <FormControl fullWidth sx={{backgroundColor: "white"}}>
                        <InputLabel id="demo-simple-select-label">Location</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={locationName}
                            label="Location"
                            onChange={this.handleLocationChange}  
                            key="Location" 
                        >
                        {locationData.map((location, index) => (
                            <MenuItem key={index} value={location.locationId}>{location.locationName}</MenuItem>
                        ))}
                        </Select>
                     </FormControl>
                    </Box>
                    </div>
                </div>
            )
        }
    }
}

const mapStateToProps = (state) =>{
    return {
       isRPCAdmin: state.isRPCAdmin,
       liftCustomersAndLocations: state.liftCustomersAndLocations,
       selectedCustomerLocationData: state.selectedCustomerLocationData,
       selectedLiftCustomer: state.selectedLiftCustomer,
       selectedLiftLocation: state.selectedLiftLocation
    }
 }
 const mapDispatchToProps = (dispatch) =>{
    return {
        fetchCustomersAndLocations: (userToken, loadLifts) => dispatch(LiftActions.LOAD_CUSTOMERS_AND_LOCATIONS(userToken, loadLifts)),
        fetchDevices: (userToken, customerId, locationId) => dispatch(LiftActions.LOAD_LIFT_SUMMARY(userToken, customerId, locationId)),
        updateSelectedCustomer: (selectedCustomer, locationData) => dispatch(LiftActions.UPDATE_SELECTED_LIFT_CUSTOMERS(selectedCustomer, locationData)),
        updateSelectedLocation: (selectedLocation) => dispatch(LiftActions.UPDATE_SELECTED_LIFT_LOCATIONS(selectedLocation)),    }
  }
 export default connect(mapStateToProps,mapDispatchToProps) (DuoLiftListTopSelection);

