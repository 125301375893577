import React, { useEffect, useState } from "react";
import { Box, Button, Divider, FormControl, InputLabel, Grid, MenuItem, Select, TextField, Typography } from "@mui/material";
import { connect } from 'react-redux'
import * as LiftActions from '../../store/lift/lift.actions'
import Snackbar from "../Snackbar/Snackbar";

const LostScrapManagementPage = (props) => {
    let customersData = props.customerLocationList || null;
    let selectedCustomer = props.selectedCustomerListData
    let selectedLocation = props.selectedLocationListData
    const customerId = props.selectedCustomerListData?.customerId;
    const locationId = props.selectedLocationListData?.locationId;
    const [open, setOpen] = React.useState(false);
    const [successMessage, setSuccessMessage] = useState(props?.successMessage)

    const updateSelectedCustomer = (value) => {
        props.updateSelectedCustomer(value)
    }

    const updateSelectedLocation = (value) => {
        props.updateSelectedLocation(value)
    }

    const getTotalAssetsAndLostScrap = () => {
        const loggedInUserIDToken = localStorage.getItem("authToken");
        props.fetchLostScrap(loggedInUserIDToken, customerId, locationId);
    }

    const addLostScrap = (loggedInUserIDToken, customerId, locationId, lostScrapValue, lostScrapUnit) => {
        setSuccessMessage("");
        props.addLostScrap(loggedInUserIDToken, customerId, locationId, lostScrapValue, lostScrapUnit);
    }

    useEffect(() => {
        if (customerId && locationId) {
            getTotalAssetsAndLostScrap();
        }
    }, [props.selectedCustomerListData, props.selectedLocationListData]);

    useEffect(() => {
        setOpen(true)
        setSuccessMessage(props?.successMessage)
    }, [successMessage, props?.successMessage])

    return (
        <>
            {successMessage && (
                <Snackbar open={open} setOpen={setOpen} message={`Lost/Scrap Successfully Configured`} />
            )}
            <div style={{ width: "100%" }}>
                <Typography sx={styles.heading}>Lost Asset Management</Typography>
                <ConfigureLostScrapForm customersData={customersData}
                    selectedCustomer={selectedCustomer}
                    selectedLocation={selectedLocation}
                    updateSelectedCustomer={updateSelectedCustomer}
                    updateSelectedLocation={updateSelectedLocation}
                    lostScarpData={props?.lostScrap}
                    addLostScrap={addLostScrap} />
            </div>
        </>
    );
};

const ConfigureLostScrapForm = (props) => {
    const [error, setError] = useState({});
    const [customerLostScrapValue, setCustomerLostScrapValue] = useState("");
    const [locationLostScrapValue, setLocationLostScrapValue] = useState("");
    const [customerLostScrapUnit, setCustomerLostScrapUnit] = useState("");
    const [locationLostScrapUnit, setLocationLostScrapUnit] = useState("");
    const dropdownItems = ["Days", "Hours", "Months", "Quarters", "Weeks", "Years"];
    const [customerEdit, setCustomerEdit] = useState(false);
    const [customerSave, setCustomerSave] = useState(false);
    const [disableCustomerValue, setDisableCustomerValue] = useState(true);
    const [disableCustomerUnit, setDisableCustomerUnit] = useState(true);
    const [locationEdit, setLocationEdit] = useState(false);
    const [locationSave, setLocationSave] = useState(false);
    const [disableLocationValue, setDisableLocationValue] = useState(true);
    const [disableLocationUnit, setDisableLocationUnit] = useState(true);

    const handleCustomerChange = (event) => {
        props.updateSelectedCustomer(event.target.value)
    }

    const handleLocationChange = (event) => {
        props.updateSelectedLocation(event.target.value)
    }

    const handleCustomerLostScrapValueChange = (value) => {
        const updateError = { ...error };
        delete updateError["customerLostScrapValue"];
        setError(updateError);
        setCustomerLostScrapValue(value);
    }

    const handleCustomerLostScrapUnitChange = (value) => {
        const updateError = { ...error };
        delete updateError["customerLostScrapUnit"];
        setError(updateError);
        setCustomerLostScrapUnit(value)
    }

    const handleLocationLostScrapValueChange = (value) => {
        const updateError = { ...error };
        delete updateError["locationLostScrapValue"];
        setError(updateError);
        setLocationLostScrapValue(value);
    }

    const handleLocationLostScrapUnitChange = (value) => {
        const updateError = { ...error };
        delete updateError["locationLostScrapUnit"];
        setError(updateError);
        setLocationLostScrapUnit(value)
    }

    const handleCustomerSubmit = () => {
        const hasErrors = handleFormValidation();
        if (Object.keys(hasErrors).length > 0) {
            console.log("ERROR!", hasErrors);
            setError(hasErrors);
            return;
        }
        const loggedInUserIDToken = localStorage.getItem("authToken");
        const customerId = props.selectedCustomer.customerId;
        const locationId = ""
        const lostScrapValue = customerLostScrapValue;
        const lostScrapUnit = customerLostScrapUnit.toLowerCase();
        props.addLostScrap(loggedInUserIDToken, customerId, locationId, lostScrapValue, lostScrapUnit);
        setDisableCustomerValue(true);
        setDisableCustomerUnit(true);
        setCustomerEdit(true);
        setCustomerSave(false);
    }

    const handleFormValidation = () => {
        const error = {};
        let isNum = /^\d+$/.test(customerLostScrapValue);
        if (!customerLostScrapValue || customerLostScrapValue === "") {
            error["customerLostScrapValue"] = "Lost value field is required.";
        } else if (!isNum) {
            error["customerLostScrapValue"] = "Lost value field must be a number";
        }
        if (!customerLostScrapUnit || customerLostScrapUnit === "") {
            error["customerLostScrapUnit"] = "Lost unit field is required.";
        }
        return error;
    };

    const handleLocationSubmit = () => {
        const hasErrors = handleLocationFormValidation();
        if (Object.keys(hasErrors).length > 0) {
            setError(hasErrors);
            return;
        }
        const loggedInUserIDToken = localStorage.getItem("authToken");
        const customerId = props.selectedCustomer.customerId;
        const locationId = props.selectedLocation.locationId
        const lostScrapValue = locationLostScrapValue;
        const lostScrapUnit = locationLostScrapUnit.toLowerCase();
        props.addLostScrap(loggedInUserIDToken, customerId, locationId, lostScrapValue, lostScrapUnit);
        setDisableLocationValue(true);
        setDisableLocationUnit(true);
        setLocationEdit(true);
        setLocationSave(false);
    }

    const handleLocationFormValidation = () => {
        const error = {};
        let isNum = /^\d+$/.test(locationLostScrapValue);
        if (!locationLostScrapValue || locationLostScrapValue === "") {
            error["locationLostScrapValue"] = "Lost value field is required.";
        } else if (!isNum) {
            error["locationLostScrapValue"] = "Lost value field must be a number";
        }
        if (!locationLostScrapUnit || locationLostScrapUnit === "") {
            error["locationLostScrapUnit"] = "Lost unit field is required.";
        }
        return error;
    };

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const handleCustomerEdit = () => {
        setDisableCustomerValue(false);
        setDisableCustomerUnit(false);
        setCustomerEdit(false);
        setCustomerSave(true);
    };

    const handleLocationEdit = () => {
        setDisableLocationValue(false);
        setDisableLocationUnit(false);
        setLocationEdit(false);
        setLocationSave(true);
    };

    useEffect(() => {
        if (props?.lostScarpData) {
            setCustomerLostScrapValue(props.lostScarpData?.cus_lost_scrap_threshold_value)
            setCustomerLostScrapUnit(capitalizeFirstLetter(props.lostScarpData?.cus_lost_scrap_threshold_unit))
            setLocationLostScrapValue(props.lostScarpData?.loc_lost_scrap_threshold_value)
            setLocationLostScrapUnit(capitalizeFirstLetter(props.lostScarpData?.loc_lost_scrap_threshold_unit))
            if (!props.lostScarpData?.cus_lost_scrap_threshold_value && !props.lostScarpData?.cus_lost_scrap_threshold_unit) {
                setCustomerSave(true);
                setCustomerEdit(false);
                setDisableCustomerValue(false);
                setDisableCustomerUnit(false);
            } else {
                setCustomerEdit(true);
                setCustomerSave(false);
                setDisableCustomerValue(true);
                setDisableCustomerUnit(true);
            }
            if (!props.lostScarpData?.loc_lost_scrap_threshold_value && !props.lostScarpData?.loc_lost_scrap_threshold_unit) {
                setLocationSave(true);
                setLocationEdit(false)
                setDisableLocationValue(false);
                setDisableLocationUnit(false);
            } else {
                setLocationEdit(true);
                setLocationSave(false)
                setDisableLocationValue(true);
                setDisableLocationUnit(true);
            }
        }
    }, [props.lostScarpData])

    return (
        <>
            <Box style={{ marginTop: "1rem", marginRight: "20px" }}>
                <Typography>Select Customer</Typography>
                <FormControl fullWidth size={"small"}
                    sx={{
                        borderRadius: "4px",
                        background: "#fff",
                        boxShadow: "0px 4px 8px rgba(16, 24, 64, 0.16)",
                        "& .MuiInputBase-root.Mui-disabled": {
                            background: "#E0E2EA",
                            color: "#A6A6A6",
                        },
                        width: "460px"
                    }}
                >
                    <Select
                        labelId="customer-name-label"
                        id="customer-name"
                        value={props?.selectedCustomer || ''}
                        onChange={handleCustomerChange}
                    >
                        {props?.customersData && props?.customersData.map((item) => (
                            <MenuItem key={item.customerId} value={item}>
                                {item.customerName}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
            <Box style={{ width: "460px" }}>
                <Grid container>
                    <Grid item xs={5}>
                        <Box pt={3} pb={3}>
                            <Typography
                                sx={{ color: "", display: "flex", alignItems: "center", justifyContent: "space-between" }}
                            >
                                Lost Value
                            </Typography>
                            <TextField
                                value={customerLostScrapValue}
                                onChange={(e) => {
                                    handleCustomerLostScrapValueChange(e.target.value);
                                }}
                                sx={{
                                    width: "216px",
                                    borderRadius: "4px",
                                    input: {
                                        color: "#033F53",
                                    },
                                    background: "#fff",
                                    boxShadow: "0px 4px 8px rgba(16, 24, 64, 0.16)",
                                    "& .MuiInputBase-root.Mui-disabled": {
                                        background: "#E0E2EA",
                                        color: "#A6A6A6",
                                    },
                                }}
                                size={"small"}
                                placeholder="Lost Value"
                                id="outlined-start-adornment"
                                disabled={disableCustomerValue}
                            />
                            {error && <Typography sx={{ color: "red" }}>{error.customerLostScrapValue}</Typography>}
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box pl={7} pt={3} pb={3}>
                            <Typography
                                sx={{ color: "", display: "flex", alignItems: "center", justifyContent: "space-between" }}
                            >
                                Lost Unit
                            </Typography>
                            <FormControl fullWidth size={"small"}
                                sx={{
                                    borderRadius: "4px",
                                    background: "#fff",
                                    boxShadow: "0px 4px 8px rgba(16, 24, 64, 0.16)",
                                    "& .MuiInputBase-root.Mui-disabled": {
                                        background: "#E0E2EA",
                                        color: "#A6A6A6",
                                    },
                                    width: "216px"
                                }}
                            >
                                {!customerLostScrapUnit &&
                                    <InputLabel
                                        shrink={false}
                                        id="select-label"
                                        sx={{
                                            fontFamily: "Montserrat, sans-serif !important",
                                            color: "#8F95B2"
                                        }}
                                    >
                                        Lost Unit
                                    </InputLabel>
                                }
                                <Select
                                    labelId="customer-name-label"
                                    id="customer-name"
                                    value={customerLostScrapUnit}
                                    onChange={(e) => {
                                        handleCustomerLostScrapUnitChange(e.target.value);
                                    }}
                                    disabled={disableCustomerUnit}
                                >
                                    {dropdownItems.map((item, index) => (
                                        <MenuItem key={index} value={item}>
                                            {item}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            {error && <Typography sx={{ color: "red" }}>{error.customerLostScrapUnit}</Typography>}
                        </Box>
                    </Grid>
                </Grid>
                {customerEdit && (
                    <Box sx={{ display: "flex", flexDirection: "row-reverse" }}>
                        <Button
                            onClick={handleCustomerEdit}
                            sx={{
                                height: "36px",
                                background: "#3b62a1",
                                color: "#fff",
                                fontWeight: 700,
                                textTransform: "capitalize",
                                borderRadius: "4px",
                                boxShadow: "0px 4px 8px rgba(16, 24, 64, 0.16)",
                                "&:hover": {
                                    background: "#3b62a1",
                                },
                                border: "none",
                            }}
                        >
                            Edit
                        </Button>
                    </Box>
                )}

                {customerSave && (
                    <Box sx={{ display: "flex", flexDirection: "row-reverse" }}>
                        <Button
                            onClick={handleCustomerSubmit}
                            sx={{
                                height: "36px",
                                background: "#3b62a1",
                                color: "#fff",
                                fontWeight: 700,
                                textTransform: "capitalize",
                                borderRadius: "4px",
                                boxShadow: "0px 4px 8px rgba(16, 24, 64, 0.16)",
                                "&:hover": {
                                    background: "#3b62a1",
                                },
                                border: "none",
                            }}
                        >
                            Save
                        </Button>
                    </Box>
                )}

            </Box>
            <span style={{ margin: "11px" }} />
            <Divider />
            <Box style={{ marginTop: "1rem", marginRight: "20px" }}>
                <Typography>Select Location</Typography>
                <FormControl fullWidth size={"small"}
                    sx={{
                        width: "460px",
                        borderRadius: "4px",
                        background: "#fff",
                        boxShadow: "0px 4px 8px rgba(16, 24, 64, 0.16)",
                        "& .MuiInputBase-root.Mui-disabled": {
                            background: "#E0E2EA",
                            color: "#A6A6A6",
                        },
                    }}
                >
                    <Select
                        labelId="customer-name-label"
                        id="location-name"
                        value={props?.selectedLocation || ''}
                        onChange={handleLocationChange}
                    >
                        {props?.selectedCustomer && props?.selectedCustomer.locations.map((item) => (
                            <MenuItem key={item.locationId} value={item}>
                                {item.locationName}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
            <Box style={{ width: "460px" }}>
                <Grid container>
                    <Grid item xs={5}>
                        <Box pt={3} pb={3}>
                            <Typography
                                sx={{ color: "", display: "flex", alignItems: "center", justifyContent: "space-between" }}
                            >
                                Lost Value
                            </Typography>
                            <TextField
                                value={locationLostScrapValue}
                                onChange={(e) => {
                                    handleLocationLostScrapValueChange(e.target.value);
                                }}
                                sx={{
                                    width: "216px",
                                    borderRadius: "4px",
                                    input: {
                                        color: "#033F53",
                                    },
                                    background: "#fff",
                                    boxShadow: "0px 4px 8px rgba(16, 24, 64, 0.16)",
                                    "& .MuiInputBase-root.Mui-disabled": {
                                        background: "#E0E2EA",
                                        color: "#A6A6A6",
                                    },
                                }}
                                size={"small"}
                                placeholder="Lost Value"
                                id="outlined-start-adornment"
                                disabled={disableLocationValue}
                            />
                            {error && <Typography sx={{ color: "red" }}>{error.locationLostScrapValue}</Typography>}
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box pl={7} pt={3} pb={3}>
                            <Typography
                                sx={{ color: "", display: "flex", alignItems: "center", justifyContent: "space-between" }}
                            >
                                Lost Unit
                            </Typography>
                            <FormControl fullWidth size={"small"}
                                sx={{
                                    borderRadius: "4px",
                                    background: "#fff",
                                    boxShadow: "0px 4px 8px rgba(16, 24, 64, 0.16)",
                                    "& .MuiInputBase-root.Mui-disabled": {
                                        background: "#E0E2EA",
                                        color: "#A6A6A6",
                                    },
                                    width: "216px"
                                }}
                            >
                                {!locationLostScrapUnit &&
                                    <InputLabel
                                        shrink={false}
                                        id="select-label"
                                        sx={{
                                            fontFamily: "Montserrat, sans-serif !important",
                                            color: "#8F95B2"
                                        }}
                                    >
                                        Lost Unit
                                    </InputLabel>
                                }
                                <Select
                                    labelId="customer-name-label"
                                    id="customer-name"
                                    value={locationLostScrapUnit}
                                    onChange={(e) => {
                                        handleLocationLostScrapUnitChange(e.target.value);
                                    }}
                                    disabled={disableLocationUnit}
                                >
                                    {dropdownItems.map((item, index) => (
                                        <MenuItem key={index} value={item}>
                                            {item}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            {error && <Typography sx={{ color: "red" }}>{error.locationLostScrapUnit}</Typography>}
                        </Box>
                    </Grid>
                </Grid>
                {locationEdit && (
                    <Box sx={{ display: "flex", flexDirection: "row-reverse" }}>
                        <Button
                            onClick={handleLocationEdit}
                            sx={{
                                height: "36px",
                                background: "#3b62a1",
                                color: "#fff",
                                fontWeight: 700,
                                textTransform: "capitalize",
                                borderRadius: "4px",
                                boxShadow: "0px 4px 8px rgba(16, 24, 64, 0.16)",
                                "&:hover": {
                                    background: "#3b62a1",
                                },
                                border: "none",
                            }}
                        >
                            Edit
                        </Button>
                    </Box>
                )}

                {locationSave && (
                    <Box sx={{ display: "flex", flexDirection: "row-reverse" }}>
                        <Button
                            onClick={handleLocationSubmit}
                            sx={{
                                height: "36px",
                                background: "#3b62a1",
                                color: "#fff",
                                fontWeight: 700,
                                textTransform: "capitalize",
                                borderRadius: "4px",
                                boxShadow: "0px 4px 8px rgba(16, 24, 64, 0.16)",
                                "&:hover": {
                                    background: "#3b62a1",
                                },
                                border: "none",
                            }}
                        >
                            Save
                        </Button>
                    </Box>
                )}
            </Box>
        </>
    );
};

const styles = {
    heading: {
        color: "#303030",
        fontSize: "24px",
        fontWeight: 700,
    }
};

const mapStateToProps = (state) => {
    return {
        customerLocationList: state.customerLocationList,
        selectedCustomerListData: state.selectedCustomerListData,
        selectedLocationListData: state.selectedLocationListData,
        lostScrap: state.lostScrap,
        successMessage: state.successMessage
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateSelectedCustomer: (customerInfo) => dispatch(LiftActions.CUSTOMER_UPDATE(customerInfo)),
        updateSelectedLocation: (locationInfo) => dispatch(LiftActions.LOCATION_UPDATE(locationInfo)),
        fetchLostScrap: (userToken, customerId, locationId) => dispatch(LiftActions.LOST_SCRAP_REQUEST_TYPE(userToken, customerId, locationId)),
        addLostScrap: (userToken, customerId, locationId, lostScrapValue, lostScrapUnit) => dispatch(LiftActions.LOST_SCRAP_ADD_REQUEST_TYPE(userToken, customerId, locationId, lostScrapValue, lostScrapUnit))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LostScrapManagementPage);