export const USER_MANAGEMENT_TABLE_HEAD = {
  type: "user-management",
  numColumns: 8,
  columns: [
    {
      column: "Last Name",
      field: ""
    },
    {
      column: "First Name",
      field: ""
    },
    {
      column: "Username",
      field: ""
    },
    {
      column: "Email",
      field: ""
    },
    {
      column: "Company",
      field: ""
    },
    {
      column: "Location(s)",
      field: ""
    },
    {
      column: "Status",
      field: ""
    },
    {
      column: "Access Level",
      field: ""
    }
  ],
};
export const LIFT_MANAGEMENT_TABLE_HEAD = {
  type: "lift-management",
  numColumns: 8,
  columns: [
    {
      column: "Lift SR #",
      field: ""
    },
    {
      column: "First Name",
      field: ""
    },
    {
      column: "Last Name",
      field: ""
    },
    {
      column: "Route",
      field: ""
    },
    {
      column: "Truck",
      field: ""
    },
    {
      column: "Status",
      field: ""
    },
    {
      column: "Edit Lift",
      field: ""
    },
    {
      column: "Edit Lift",
      field: ""
    }
  ],
};
export const USER_PROFILE_TABLE_HEAD = {
  type: "user-profile",
  numColumns: 4,
  columns: [
    {
      column: "Last Name",
      field: ""
    },
    {
      column: "First Name",
      field: ""
    },
    {
      column: "Username",
      field: ""
    },
    {
      column: "Email",
      field: ""
    },
    {
      column: "Access Level",
      field: ""
    }
  ],
};
export const CUSTOMER_DETAILS_TABLE_HEAD = {
  type: "customer-details",
  numColumns: 9,
  columns: [
    {
      column: "Customer Name",
      field: ""
    },
    {
      column: "Customer Address 1 (Line 1)",
      field: ""
    },
    {
      column: "Customer Address 2 (Line 2)",
      field: ""
    },
    {
      column: "Customer City (Line 3)",
      field: ""
    },
    {
      column: "Customer State (Line 4)",
      field: ""
    },
    {
      column: "Customer Zip (Line 5)",
      field: ""
    },
    {
      column: "Total No.of Locations",
      field: ""
    },
    {
      column: "Total No.of Lifts",
      field: ""
    },
    {
      column: "Total No.of Pallets",
      field: ""
    },
  ],
};
export const LOCATION_DETAILS_TABLE_HEAD = {
  type: "location-details",
  numColumns: 10,
  columns: [
    {
      column: "Customer Name",
      field: ""
    },
    {
      column: "Location Name",
      field: ""
    },
    {
      column: "Latitude",
      field: ""
    },
    {
      column: "Longitude",
      field: ""
    },
    {
      column: "Fence Radius",
      field: ""
    },
    {
      column: "Location Address 1(Line 1)",
      field: ""
    },
    {
      column: "Location Address 2 (Line 2)",
      field: ""
    },
    {
      column: "Location City (Line 3)",
      field: ""
    },
    {
      column: "Location State (Line 4)",
      field: ""
    },
    {
      column: "Location zip (Line 5)",
      field: ""
    },
  ],
};
export const ASSET_DETAILS_TABLE_HEAD = {
  type: "asset-details",
  numColumns: 6,
  columns: [
    {
      column: "RFID",
      field: "rfid"
    },
    {
      column: "SSCC",
      field: "sscc"
    },
    {
      column: "Asset Status",
      field: "asset_status"
    },
    {
      column: "Last Updated By",
      field: "last_updated_by"
    },
    {
      column: "Check-out Date/Time from DC",
      field: "checkout_date"
    },
    {
      column: "Check-in Date/Time into DC",
      field: "checkin_date"
    }
  ],
};
export const FLOAT_VOLUME_TABLE_HEAD = {
  type: "float-volume",
  numColumns: 6,
  columns: [
    {
      column: "RFID",
      field: ""
    },
    {
      column: "Check-out Date",
      field: ""
    },
    {
      column: "Check-in Date",
      field: ""
    },
    {
      column: "Location",
      field: ""
    }
  ],
};
export const LOST_SCRAP_TABLE_HEAD = {
  type: "lost-scrap",
  numColumns: 6,
  columns: [
    {
      column: "RFID",
      field: ""
    },
    {
      column: "Lost/Scrap Company",
      field: ""
    },
    {
      column: "Lost/Scrap Location",
      field: ""
    },
    {
      column: "Store Location",
      field: ""
    },
    {
      column: "Store Address",
      field: ""
    }
  ],
};
export const DWELL_TIME_TABLE_HEAD = {
  type: "dwell-time",
  numColumns: 6,
  columns: [
    {
      column: "RFID",
      field: ""
    },
    {
      column: "Check-out Date",
      field: ""
    },
    {
      column: "Check-in Date",
      field: ""
    },
    {
      column: "Dwell Time",
      field: ""
    },
    {
      column: "Turn Time",
      field: ""
    },
    {
      column: "Store Name",
      field: ""
    }
  ],
};
export const HARDWARE_MANAGEMENT_TABLE_HEAD = {
  type: "hardware-mgmt",
  numColumns: 5,
  columns: [
    {
      column: "Company Name",
      field: "rfid"
    },
    {
      column: "Location Name",
      field: "sscc"
    },
    {
      column: "Hardware Name",
      field: "asset_status"
    },
    {
      column: "Hardware Type",
      field: "checkout_date"
    },
    {
      column: "Hardware Status",
      field: "checkin_date"
    }
  ]
};