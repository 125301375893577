import React from 'react';
import { connect } from 'react-redux';
import Typography from '@mui/material/Typography';
import DuoLiftServiceTask from '../../Cards/DuoLiftServiceItems/DuoLiftServiceTask/DuoLiftServiceTask.js'

class DuoLiftStatusTab extends React.Component {

    SelectBatteryImage(batteryLevel) {
        
        if(batteryLevel === 100)
           return "fa-solid fa-battery-full fa-lg";
        else if(batteryLevel >= 65 && batteryLevel < 100)
            return  "fa-solid fa-battery-three-quarters fa-lg";
        else if(batteryLevel >= 35 && batteryLevel < 65)
            return "fa-solid fa-battery-half fa-lg";
        else if(this.batteryLevel > 0 && batteryLevel < 35)
            return  "fa-solid fa-battery-quarter fa-lg";
        else
           return "fa-solid fa-battery-empty fa-lg";
        
        }
        getServiceTasks = () => {

            var newServices = []
            if (this.props.selectedLift != null) {
                if (this.props.selectedLift.maintType != null) {
                    if (this.props.selectedLift.maintType.length > 0) {
                        this.props.selectedLift.maintType.forEach(mainTask => {
    
                            if (mainTask.maintTypeID !== null) {
                                if (mainTask.tasks !== undefined && mainTask.tasks.length > 0) {
                                    mainTask.tasks.forEach(task => {
                                        newServices.push(task)
                                    })
                                } else {
                                    if (newServices.length > 0) {
                                        newServices = newServices.filter((set => f => !set.has(f.maintTaskID) && set.add(f.maintTaskID))(new Set));
                                    }
                                    return newServices
                                }
                            } else {
                                if (newServices.length > 0) {
                                    newServices = newServices.filter((set => f => !set.has(f.maintTaskID) && set.add(f.maintTaskID))(new Set));
                                }
                                return newServices
                            }
                        });
                        if (newServices.length > 0) {
                            newServices = newServices.filter((set => f => !set.has(f.maintTaskID) && set.add(f.maintTaskID))(new Set));
                        }
                        return newServices
                    } else {
                        if (newServices.length > 0) {
                            newServices = newServices.filter((set => f => !set.has(f.maintTaskID) && set.add(f.maintTaskID))(new Set));
                        }
                        return newServices
                    }
                } else {
                    if (newServices.length > 0) {
                        newServices = newServices.filter((set => f => !set.has(f.maintTaskID) && set.add(f.maintTaskID))(new Set));
                    }
                    return newServices
                }
    
            }
            }
 
    render() {
        let selectedDevice = this.props.selectedLift
        let serviceItems = this.getServiceTasks();
        let selectedLiftLocation = this.props.selectedLiftLocation
        let lifeLiftDistance;
        let todayLiftDistance;
        let showMainItems

        if (selectedLiftLocation != null && selectedLiftLocation != undefined) {
            showMainItems = selectedLiftLocation.maintenanceDetails
        } else {
            showMainItems = false 
        }

        if (selectedDevice.distanceToday != null && selectedDevice.distanceToday != undefined && selectedDevice.distanceToday != 0) {
            todayLiftDistance = selectedDevice.distanceToday.toFixed(2) ? selectedDevice.distanceToday.toFixed(2): selectedDevice.distanceToday
        } else {
            todayLiftDistance = 0.00
        }

        if (selectedDevice.distanceLifetime != null && selectedDevice.distanceLifetime != undefined && selectedDevice.distanceLifetime != 0) {
            lifeLiftDistance = selectedDevice.distanceLifetime.toFixed(2) ? selectedDevice.distanceLifetime.toFixed(2): selectedDevice.distanceLifetime
        } else {
            lifeLiftDistance = 0.00
        }

        if (selectedDevice != null) {
            let serviceView;
            if (serviceItems.length > 0)
                serviceView = <div className="rightCardStatusColumnElements">
                    {
                        serviceItems.map(item => (
                             <DuoLiftServiceTask serviceTask={item} key={item.maintTaskID}serviceItems={serviceItems}></DuoLiftServiceTask>
                        ))
                    }
                 </div>
            else
                serviceView = <div className="rightCardStatusRowElements">
                <Typography variant="body1" gutterBottom>
                NONE
                </Typography>
                </div>

            return (
                <div className="rightCardStatusTab">
                    <div className="duoRightCardImageSection">
                    <div className="duoMapCardImgAndLabel">
                    <i className= {this.SelectBatteryImage(selectedDevice.batteryPercent)}></i>
                    <span style={{fontSize: '14px', fontWeight: 'regular'}}>{Math.trunc(selectedDevice.batteryPercent)}%</span>
                    </div>
                    <div className="duoMapCardImgAndLabel">
                    <i className= "fa-regular fa-triangle-exclamation fa-lg"></i>
                    <span style={{fontSize: '14px', fontWeight: 'regular'}}>{selectedDevice.alertCount} ALERTS</span>
                    </div>
                    <div className="duoMapCardImgAndLabel">
                    <i className="fa-regular fa-temperature-half"></i>
                    <span style={{fontSize: '14px', fontWeight: 'regular'}}>{selectedDevice.ambientTemp}</span>
                    </div>
                    </div>
                    <div className="rightCardStatusElements">
                    <Typography variant="subtitle2" gutterBottom component="div" style={{marginBottom: "0px"}}>
                     HOURS
                    </Typography>
                    <hr style={{backgroundColor: "black", height: 2, width: "100%", marginTop: "0px"}}></hr>
                    <div className="rightCardStatusRowElements">
                    <Typography variant="body1" gutterBottom>
                     KEY-ON TIME
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                    {selectedDevice.keyOnHours != null? selectedDevice.keyOnHours: 0} hrs
                    </Typography>
                    </div>
                    <div className="rightCardStatusRowElements">
                    <Typography variant="body1" gutterBottom>
                    USE TIME
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                    {selectedDevice.workingHours != null? selectedDevice.workingHours: 0} hrs
                    </Typography>
                    </div>
                    </div>
                    <div className="rightCardStatusElements">
                    <Typography variant="subtitle2" gutterBottom component="div" style={{marginBottom: "0px"}}>
                     LIFT DISTANCE
                    </Typography>
                    <hr style={{backgroundColor: "black", height: 2, width: "100%", marginTop: "0px"}}></hr>
                    {/* <div className="rightCardStatusRowElements">
                    <Typography variant="body1" gutterBottom>
                    TODAY
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                    {todayLiftDistance} M
                    </Typography>
                    </div> */}
                    <div className="rightCardStatusRowElements">
                    <Typography variant="body1" gutterBottom>
                    LIFETIME
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                    {lifeLiftDistance} M
                    </Typography>
                    </div>
                    </div>
                    <div className="rightCardStatusElements">
                    {/* <Typography variant="subtitle2" gutterBottom component="div" style={{marginBottom: "0px"}}>
                     SERVICE
                    </Typography>
                    <hr style={{backgroundColor: "black", height: 2, width: "100%", marginTop: "0px"}}></hr>
                    <div className="rightCardStatusRowElements">
                    <Typography variant="body1" gutterBottom>
                    LAST SERVICE KEY-ON TIME
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                    {selectedDevice.lastServiceHours} hrs
                    </Typography>
                    </div>
                    <div className="rightCardStatusRowElements">
                    <Typography variant="body1" gutterBottom>
                    NEXT SERVICE KEY-ON TIME
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                    {selectedDevice.nextServiceHours} hrs
                    </Typography>
                    </div> */}
                    <div className="serviceIndicatorFlexForPanel" style={{ backgroundColor: "gray" }}>
                        <span className="serviceIndicatorText">Service Coming Soon</span>
                    </div>
                    </div>
                    {showMainItems &&
                     <div className="rightCardStatusElements">
                     <Typography variant="subtitle2" gutterBottom component="div" style={{marginBottom: "0px"}}>
                      SERVICE ITEMS
                     </Typography>
                     <hr style={{backgroundColor: "black", height: 2, width: "100%", marginTop: "0px"}}></hr>
                     {serviceView}
                     </div>
                    }
                </div>
    
            )
        } else {
            return (
                <div></div>
            )
        }
    }
}

const mapStateToProps = (state) => {
    return {
       
    }
  }

  const mapDispatchToProps = (dispatch) =>{
    return {
    }
  }
export default connect (mapStateToProps, mapDispatchToProps) (DuoLiftStatusTab);