import React from "react";
import { Box, Grid, Paper, Tooltip, Typography } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

const KPICard = ({ icon, title, data, background, color, tooltip, tooltipText }) => (
  <Paper
    sx={{
      height: "93px",
      boxShadow: "0px 4px 8px rgba(16, 24, 64, 0.16)",
      padding: "15px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      background: background,
    }}
  >
    <img src={icon} alt="" style={{ marginLeft: "20px" }} />
    <Box
      sx={{
        textAlign: "right",
      }}
    >
      <Typography
        sx={{
          fontFamily: "Montserrat, sans-serif !important",
          fontWeight: 500,
          fontSize: "40px",
          color: color ? color : "#414242",
        }}
      >
        {data}
      </Typography>
      <Grid container>
        <Grid item xs={11}>
          <Typography
            sx={{
              fontFamily: "Montserrat, sans-serif !important",
              fontSize: "12px",
              color: color ? color : "#414242",
              marginBottom: "6px",
              marginTop: "6px"
            }}
          >
            {title}
          </Typography>
        </Grid>
        <Grid item xs={1} style={{ marginBottom: "5px" }}>
          {tooltip &&
            <Tooltip title={tooltipText} arrow>
              <HelpOutlineIcon style={{ paddingTop: "5px" }} />
            </Tooltip>
          }
        </Grid>
      </Grid>
    </Box>
  </Paper>
);
export default KPICard;