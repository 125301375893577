import React from 'react';
import DuoLiftCard from '../DuoLiftCard/DuoLiftCard.js'
import DuoLiftListTopSelection from '../DuoLiftTopSelection/DuoLiftListTopSelection.js'
import {connect} from 'react-redux'
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import * as LiftActions from '../../../store/lift/lift.actions'
import moment from 'moment'

class DuoLiftList extends React.Component{

   state = { selectedDeviceId: null,
      openOnlineMaintLifts: true,
      openOnlinetLifts: true,
      openOfflineLifts: true,
   }

   componentDidMount() {
      this.getUsersAndLocations()
      //this.getUsersForLocations()
      this.getLiftsForLocations()
      this.getDriversForLifts()
     // this.getCustomerLocationList()
   }

   componentDidUpdate(prevProps, prevState) {
      let devices= this.props.devices
      let sortedDevices = this.props.sortedDevices;
      // if(prevProps.devices !== this.props.devices)
      //     this.setState({devicesLoadedAt: moment(new Date()) })
      if(this.state.selectedDeviceId == null && devices != null && devices.length > 0) {
         if (this.props.selectedLiftLocation != null) {
            if(sortedDevices != undefined) {
               if (sortedDevices.onlineMainLifts.length > 0) {
                  this.selectDevice(sortedDevices.onlineMainLifts[0].liftID)
               } else if (sortedDevices.onlineLifts.length > 0){
                  this.selectDevice(sortedDevices.onlineLifts[0].liftID)
               } else if (sortedDevices.offLineLifts.length > 0){
                  this.selectDevice(sortedDevices.offLineLifts[0].liftID)
               }
            }
         }
       
      } else if(this.state.selectedDeviceId != null && devices != null && devices.length > 0) {
         if (this.props.selectedLiftLocation != null) {
            if (!this.containsObject(this.state.selectedDeviceId, devices)) {
               if(sortedDevices != undefined) {
                  if (sortedDevices.onlineMainLifts.length > 0) {
                     this.selectDevice(sortedDevices.onlineMainLifts[0].liftID)
                  } else if (sortedDevices.onlineLifts.length > 0){
                     this.selectDevice(sortedDevices.onlineLifts[0].liftID)
                  } else if (sortedDevices.offLineLifts.length > 0){
                     this.selectDevice(sortedDevices.offLineLifts[0].liftID)
                  }
               }
            } 
         }
      } else if (devices != null && devices.length == 0) {
         if (this.state.selectedDeviceId != null) {
            this.setState({ selectedDeviceId: null})
         }
      }  
   }

    handleClickForMaintLifts = () => {
      this.setState({openOnlineMaintLifts: !this.state.openOnlineMaintLifts})
    };


    handleClickForOnlineLifts = () => {
      this.setState({openOnlinetLifts: !this.state.openOnlinetLifts})
    };

    handleClickForOfflineLifts = () => {
      this.setState({openOfflineLifts: !this.state.openOfflineLifts})
    };

    containsObject(obj, list) {
      var i;
      for (i = 0; i < list.length; i++) {
          if (list[i].liftID === obj) {
              return true;
          }
      }
  
      return false;
  }
   // shouldComponentUpdate(nextProps, nextState) {
   //    if(this.state.selectedDeviceId === null && nextState.selectedDeviceId !== null)
   //       return false;
   //    else
   //       return true;
   //  }
render() {
   let devices= this.props.devices
   let selectedDevice = this.state.selectedDeviceId;
   let sortedDevices = this.props.sortedDevices;
   let selectedDeviceByUser = this.props.selectedDeviceByUser
   let onlineMaintLifts = []
   let onlineLifts = []
   let offlineLifts = []
   if(this.state.selectedDeviceId == null && devices != null && devices.length > 0) {
      if(sortedDevices !== undefined) {
         if (selectedDeviceByUser === null) {
            if (sortedDevices.onlineMainLifts.length > 0) {
               selectedDevice = sortedDevices.onlineMainLifts[0].liftID 
            } else if (sortedDevices.onlineLifts.length > 0){
               selectedDevice = sortedDevices.onlineLifts[0].liftID 
            } else if (sortedDevices.offLineLifts.length > 0){
               selectedDevice = sortedDevices.offLineLifts[0].liftID 
            }
         } else {
            selectedDevice = selectedDeviceByUser.liftID
         }
      }
   }

   if(this.state.selectedDeviceId != null && devices != null && devices.length > 0) {
      if (!this.containsObject(this.state.selectedDeviceId, devices)) {
         if(sortedDevices != undefined) {
            if (sortedDevices.onlineMainLifts.length > 0) {
               selectedDevice = sortedDevices.onlineMainLifts[0].liftID 
            } else if (sortedDevices.onlineLifts.length > 0){
               selectedDevice = sortedDevices.onlineLifts[0].liftID 
            } else if (sortedDevices.offLineLifts.length > 0){
               selectedDevice = sortedDevices.offLineLifts[0].liftID 
            }
         }
      }
   }

   if(sortedDevices != undefined) {
      onlineMaintLifts = sortedDevices.onlineMainLifts
      onlineLifts = sortedDevices.onlineLifts
      offlineLifts = sortedDevices.offLineLifts
   }
      return(
               <ul className="duoLiftList">
                  <DuoLiftListTopSelection></DuoLiftListTopSelection>
                  <List
                     sx={{ width: '100%', minWidth: 320 }}
                     // bgcolor: 'background.paper'
                     component="nav"
                     aria-labelledby="nested-list-subheader"
                  >
                      <ListItemButton onClick={this.handleClickForMaintLifts}>
                        <ListItemText secondary={`LIFTS NEEDING ATTENTION - ${onlineMaintLifts.length}`} />
                           {this.state.openOnlineMaintLifts ? <ExpandLess /> : <ExpandMore />}
                     </ListItemButton>
                     <Collapse in={this.state.openOnlineMaintLifts} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                           {  
                           onlineMaintLifts.map(device=>(
                           <li  onClick={this.selectDevice.bind(this,device.liftID)}  key={device.liftID} >
                              <DuoLiftCard device={device} selected={selectedDevice === device.liftID}>
                              </DuoLiftCard>
                           </li>
                           ))
                           }
                        </List>
                     </Collapse>
                     <ListItemButton onClick={this.handleClickForOnlineLifts}>
                        <ListItemText secondary={`CONNECTED LIFTS - ${onlineLifts.length}`} />
                           {this.state.openOnlinetLifts ? <ExpandLess /> : <ExpandMore />}
                     </ListItemButton>
                     <Collapse in={this.state.openOnlinetLifts} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                           {  
                           onlineLifts.map(device=>(
                           <li  onClick={this.selectDevice.bind(this,device.liftID)}  key={device.liftID} >
                              <DuoLiftCard device={device} selected={selectedDevice ===device.liftID}>
                              </DuoLiftCard>
                           </li>
                           ))
                           }
                        </List>
                     </Collapse>
                     <ListItemButton onClick={this.handleClickForOfflineLifts}>
                        <ListItemText secondary={`DISCONNECTED LIFTS - ${offlineLifts.length}`} />
                           {this.state.openOfflineLifts ? <ExpandLess /> : <ExpandMore />}
                     </ListItemButton>
                      <Collapse in={this.state.openOfflineLifts} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                           {  
                           offlineLifts.map(device=>(
                           <li  onClick={this.selectDevice.bind(this,device.liftID)}  key={device.liftID} >
                              <DuoLiftCard device={device} selected={selectedDevice ===device.liftID}>
                              </DuoLiftCard>
                           </li>
                           ))
                           }
                        </List>
                     </Collapse>
                  </List> 
            </ul>
            )
      }

getCurrentDate() {
   let newDate = new Date()
   let month = newDate.getMonth() + 1;
   let year = newDate.getFullYear();
   let day = newDate.getDate();
   let requireDate = year + '-' + month + '-' + day;
   return requireDate
}

selectDevice(id){
   const loggedInUserIDToken = localStorage.getItem("authToken");

   this.setState({ selectedDeviceId: id})
   this.props.updateStopSelectedFlag(false)
   this.props.updateAlertSelectedFlag(false)

   // this.props.updateSelectedMapType(["Alerts"])

   if (this.props.mapDateItemsValue.start !== undefined && this.props.mapDateItemsValue.start !== null) {
      let newStartDate = this.getUserSelectedDate(this.props.mapDateItemsValue.start)
        this.props.fetchDeviceDetails(id, loggedInUserIDToken, newStartDate);

      this.props.fetchDeviceAlertHistory(id, newStartDate, loggedInUserIDToken);
      this.props.fetchDeviceBreadcrumbHistory(id, newStartDate, loggedInUserIDToken);
      this.props.fetchDeviceStopHistory(id, newStartDate, loggedInUserIDToken);
      this.props.fetchDeviceAlertTrail(id, newStartDate, loggedInUserIDToken);
   } else {
      this.props.fetchDeviceDetails(id, loggedInUserIDToken, this.getCurrentDate());
      this.props.fetchDeviceAlertHistory(id, this.getCurrentDate(), loggedInUserIDToken);
      this.props.fetchDeviceBreadcrumbHistory(id, this.getCurrentDate(), loggedInUserIDToken);
      this.props.fetchDeviceStopHistory(id, this.getCurrentDate(), loggedInUserIDToken);
      this.props.fetchDeviceAlertTrail(id, this.getCurrentDate(), loggedInUserIDToken);
   }
   if (this.props.selectedLiftCustomer != undefined, this.props.selectedLiftCustomer != null, this.props.selectedLiftLocation != undefined, this.props.selectedLiftLocation != null) {
      if (this.props.selectedLiftCustomer.customerId !== undefined, this.props.selectedLiftCustomer.customerId !== null, this.props.selectedLiftLocation.locationId !== undefined, this.props.selectedLiftLocation.locationId !== null) {
         this.props.fetchDevices(loggedInUserIDToken, this.props.selectedLiftCustomer.customerId, this.props.selectedLiftLocation.locationId)
      }
   }
   
   this.props.updateRefreshDataFlag(false)
   this.props.fetchStopBiometrics(id, this.getCurrentDate(), loggedInUserIDToken)
   if (this.props.fleetAlertSelection !== null) {
      var startDate = ""
      var endDate = ""
      this.props.updateUserAlertSelection(this.props.fleetAlertSelection)
      if (this.props.fleetAlertSelection === 1) {
         startDate = this.getCurrentDate()
 
         } else if (this.props.fleetAlertSelection === 7) {
             startDate = this.getBeforeDate(this.props.fleetAlertSelection)
             endDate = this.getCurrentDate()
 
         } else if (this.props.fleetAlertSelection === 30) {
             startDate = this.getBeforeDate(this.props.fleetAlertSelection)
             endDate = this.getCurrentDate()
 
         } else if (this.props.fleetAlertSelection === 365) {
             startDate = this.getBeforeDate(this.props.fleetAlertSelection)
             endDate = this.getCurrentDate()
         }
      this.props.fetchAlertPareto(loggedInUserIDToken, this.props.selectedLiftLocation.locationId, startDate, endDate);
   } else {
      this.props.updateUserAlertSelection(30)
      this.props.fetchAlertPareto(loggedInUserIDToken, this.props.selectedLiftLocation.locationId, this.getThirtyDaysBeforeDate(), this.getCurrentDate());
   }
}

getCurrentDate() {
   let newDate = new Date()
   let month = newDate.getMonth() + 1;
   let year = newDate.getFullYear();
   let day = newDate.getDate();
   let requireDate = year + '-' + month + '-' + day;
   return requireDate
 }

 getBeforeDate(daysBack) {
   let newDate = new Date()
   newDate.setDate(newDate.getDate() - daysBack);
   let month = newDate.getMonth() + 1;
   let year = newDate.getFullYear();
   let day = newDate.getDate();
   let requireDate = year + '-' + month + '-' + day;
   return requireDate
 }
   
   getThirtyDaysBeforeDate() {
     let newDate = new Date()
     newDate.setDate(newDate.getDate() - 30);
     let month = newDate.getMonth() + 1;
     let year = newDate.getFullYear();
     let day = newDate.getDate();
     let requireDate = year + '-' + month + '-' + day;
     return requireDate
   }

getUserSelectedDate(selectedValue) {
   let newDate = selectedValue
   if (newDate != null) {
       let month = newDate.getMonth() + 1;
       let year = newDate.getFullYear();
       let day = newDate.getDate();
       let requireDate = year + '-' + month + '-' + day;
       console.log(requireDate)
       return requireDate
   } else {
       return null
   }
}

   getUsersAndLocations() {
      const loggedInUserIDToken = localStorage.getItem("authToken");
      this.props.fetchUsersAndLocation(loggedInUserIDToken)
   }

   getUsersForLocations() {
      const loggedInUserIDToken = localStorage.getItem("authToken");
      this.props.fetchUsersForLocation(loggedInUserIDToken)
   }

   getLiftsForLocations() {
      const loggedInUserIDToken = localStorage.getItem("authToken");
      this.props.fetchLiftsForLocation(loggedInUserIDToken)
   }

   getDriversForLifts() {
      const loggedInUserIDToken = localStorage.getItem("authToken");
      this.props.fetchDriversForLifts(loggedInUserIDToken)
   }

   getCustomerLocationList(){
      const loggedInUserIDToken = localStorage.getItem("authToken");
      this.props.fetchCustomersAndLocations(loggedInUserIDToken);
   }

}
const mapStateToProps = (state) =>{
   return {
      sortedDevices: state.sortedDevices,
      devices: state.devices,
      isCustomerFleetUser: state.isCustomerFleetUser,
      mapDateItemsValue: state.mapDateItemsValue,
      selectedLiftCustomer: state.selectedLiftCustomer,
      selectedLiftLocation: state.selectedLiftLocation,
      selectedDeviceByUser: state.selectedDevice,
      fleetAlertSelection: state.fleetAlertSelection,
   }
}
const mapDispatchToProps = (dispatch) =>{
   return {
      updateRefreshDataFlag: (refreshDataRequested) => dispatch(LiftActions.UPDATE_REFRESH_DATA_REQUESTED_FLAG(refreshDataRequested)),
      updateSelectedMapType: (selectedType) => dispatch(LiftActions.LOAD_LIFT_MAP_TYPE_UPDATE(selectedType)),
      fetchDevices: (userToken, customerId, locationId) => dispatch(LiftActions.LOAD_LIFT_SUMMARY(userToken, customerId, locationId)),
      fetchDeviceDetails: (id, userToken, startDate) => dispatch(LiftActions.LOAD_LIFT_DETAIL(id, userToken, startDate)),
      fetchDeviceAlertHistory: (id, startDate, userToken) => dispatch(LiftActions.LOAD_LIFT_ALERT_HISTORY(id, startDate, userToken)),
      fetchDeviceBreadcrumbHistory: (id, startDate, userToken) => dispatch(LiftActions.LOAD_LIFT_BREADCRUMB_HISTORY(id, startDate, userToken)),
      fetchDeviceStopHistory: (id, startDate, userToken) => dispatch(LiftActions.LOAD_LIFT_STOPS_HISTORY_TYPE(id, startDate, userToken)),
      fetchDeviceAlertTrail: (id, startDate, userToken) => dispatch(LiftActions.LOAD_LIFT_ALERT_TRAIL_TYPE(id, startDate, userToken)),
      fetchAlertPareto: (userToken, locationid, startdate, enddate) => dispatch(LiftActions.LOAD_ALERT_PARETO(userToken, locationid, startdate, enddate)),
      fetchStopBiometrics: (id, date, userToken) => dispatch(LiftActions.LOAD_LIFT_STOP_BIOMETRICS(id, date, userToken)),
      fetchUsersAndLocation: (userToken) => dispatch(LiftActions.LIFT_USERS_LOCATIONS_REQUEST_TYPE(userToken)),
      // fetchCustomersAndLocations: (userToken) => dispatch(LiftActions.LOAD_CUSTOMERS_AND_LOCATIONS(userToken)),
      fetchUsersForLocation: (userToken) => dispatch(LiftActions.LOCATIONS_USERS_REQUEST_TYPE(userToken)),
      fetchLiftsForLocation: (userToken) => dispatch(LiftActions.LOCATIONS_LIFTS_REQUEST_TYPE(userToken)),
      fetchDriversForLifts: (userToken) => dispatch(LiftActions.LIFTS_DRIVERS_REQUEST_TYPE(userToken, "", "")),
      updateUserAlertSelection: (alertType) => dispatch(LiftActions.UPDATE_FLEET_COMPARISION_SELCTION(alertType)),
      updateStopSelectedFlag: (stopSelectedFlag) => dispatch(LiftActions.UPDATE_USER_SELECTED_STOP_DASHBOARD_FLAG(stopSelectedFlag)),
      updateAlertSelectedFlag: (alertSelectedFlag) => dispatch(LiftActions.UPDATE_USER_SELECTED_ALERT_DASHBOARD_FLAG(alertSelectedFlag)),
   }
 }
export default connect(mapStateToProps,mapDispatchToProps) (DuoLiftList);
