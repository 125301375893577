import React from "react";
import { TableBody, TableCell, TableRow } from "@mui/material";
import { styles } from "./styles";

const AppTableBody = ({
  tableType,
  data,
  customerName,
  locationName,
  editButtonArea,
  unassignButtonArea,
  handleEditLiftTapped,
  handleUnassignLiftTapped
}) => {
  switch (tableType) {
    case "user-profile":
      return (
        <RenderUserProfile
          data={data}
        />
      );
    case "customer-details":
      return (
        <RenderCustomerDetails
          data={data}
        />
      );
    case "location-details":
      return (
        <RenderLocationDetails
          data={data}
        />
      );
    case "lift-management":
      return (
        <RenderLiftManagement
          data={data}
          editButtonArea={editButtonArea}
          unassignButtonArea={unassignButtonArea}
          handleEditLiftTapped={handleEditLiftTapped}
          handleUnassignLiftTapped={handleUnassignLiftTapped}
        />
      );
    case "user-management":
      return (
        <RenderUserManagement
          data={data}
        />
      );
    case "hardware-mgmt":
      return (
        <RenderHardwareManagement
          data={data}
          customerName={customerName}
          locationName={locationName}
        />
      );
    case "asset-details":
      return (
        <RenderAssetDetails
          data={data}
        />
      );
    case "float-volume":
      return (
        <RenderFloatVolume
          data={data}
        />
      );
    case "lost-scrap":
      return (
        <RenderLostScrap
          data={data}
        />
      );
    case "dwell-time":
      return (
        <RenderDwellTime
          data={data}
        />
      );
    //  default: return null;
  }
  return null;
};

const RenderUserProfile = ({ data }) => {
  return (
    <TableBody>
      {
        data && data.map((row, index) => {
          return (
            <TableRow hover role="checkbox" key={index}>
              <TableCell sx={styles.tableRow} align="left">
                {row.firstName}
              </TableCell>
              <TableCell sx={styles.tableRow} align="left">
                {row.lastName}
              </TableCell>
              <TableCell sx={styles.tableRow} align="left">
                {row.userName}
              </TableCell>
              <TableCell sx={styles.tableRow} align="left">
                {row.email}
              </TableCell>
              <TableCell sx={styles.tableRow} align="left">
                {row.status}
              </TableCell>
            </TableRow>
          );
        })
      }
    </TableBody>
  );
};

const RenderCustomerDetails = ({ data }) => {
  if (data.length === 0) {
    return (
      <TableBody>
        <TableRow>
          <TableCell sx={{ border: "none" }} colSpan={9} style={{ textAlign: "center" }}>No Customers Found</TableCell>
        </TableRow>
      </TableBody>
    );
  }
  return (
    <TableBody>
      {
        data && data.map((row, index) => {
          return (
            <TableRow hover role="checkbox" key={index}>
              <TableCell sx={styles.tableRow} align="left">
              </TableCell>
              <TableCell sx={styles.tableRow} align="left">
              </TableCell>
              <TableCell sx={styles.tableRow} align="left">
              </TableCell>
              <TableCell sx={styles.tableRow} align="left">
              </TableCell>
              <TableCell sx={styles.tableRow} align="left">
              </TableCell>
              <TableCell sx={styles.tableRow} align="left">
              </TableCell>
              <TableCell sx={styles.tableRow} align="left">
              </TableCell>
              <TableCell sx={styles.tableRow} align="left">
              </TableCell>
              <TableCell sx={styles.tableRow} align="left">
              </TableCell>
            </TableRow>
          );
        })
      }
    </TableBody>
  );
};

const RenderLocationDetails = ({ data }) => {
  if (data.length === 0) {
    return (
      <TableBody>
        <TableRow>
          <TableCell sx={{ border: "none" }} colSpan={10} style={{ textAlign: "center" }}>No Locations Found</TableCell>
        </TableRow>
      </TableBody>
    );
  }
  return (
    <TableBody>
      {
        data && data.map((row, index) => {
          return (
            <TableRow hover role="checkbox" key={index}>
              <TableCell sx={styles.tableRow} align="left">
              </TableCell>
              <TableCell sx={styles.tableRow} align="left">
              </TableCell>
              <TableCell sx={styles.tableRow} align="left">
              </TableCell>
              <TableCell sx={styles.tableRow} align="left">
              </TableCell>
              <TableCell sx={styles.tableRow} align="left">
              </TableCell>
              <TableCell sx={styles.tableRow} align="left">
              </TableCell>
              <TableCell sx={styles.tableRow} align="left">
              </TableCell>
              <TableCell sx={styles.tableRow} align="left">
              </TableCell>
              <TableCell sx={styles.tableRow} align="left">
              </TableCell>
              <TableCell sx={styles.tableRow} align="left">
              </TableCell>
              <TableCell sx={styles.tableRow} align="left">
              </TableCell>
            </TableRow>
          );
        })
      }
    </TableBody>
  );
};

const RenderLiftManagement = ({ data, editButtonArea, unassignButtonArea, handleEditLiftTapped, handleUnassignLiftTapped }) => {

  const handleEditLift = (data, editButtonArea) => {
    handleEditLiftTapped(data, editButtonArea)
  }
  const handleUnassignLift = (data) => {
    handleUnassignLiftTapped(data)
  }

  if (data.length === 0) {
    return (
      <TableBody>
        <TableRow>
          <TableCell sx={{ border: "none" }} colSpan={8} style={{ textAlign: "center" }}>There are no lifts for the selected location.</TableCell>
        </TableRow>
      </TableBody>
    );
  }

  return (
    <TableBody>
      {
        data && data.map((row, index) => {
          return (
            <TableRow hover role="checkbox" key={index}>
              <TableCell sx={styles.tableRow} align="left">
                {row.liftId}
              </TableCell>
              <TableCell sx={styles.tableRow} align="left">
                {row.firstName === null ? "N/A" : row.firstName}
              </TableCell>
              <TableCell sx={styles.tableRow} align="left">
                {row.lastName === null ? "N/A" : row.lastName}
              </TableCell>
              <TableCell sx={styles.tableRow} align="left">
                {row.route === null ? "N/A" : row.route}
              </TableCell>
              <TableCell sx={styles.tableRow} align="left">
                {row.truck === null ? "N/A" : row.truck}
              </TableCell>
              <TableCell sx={styles.tableRow} align="left">
                {row.active === true ? "Active" : "InActive"}
              </TableCell>
              <TableCell sx={styles.edit} align="left" onClick={() => handleEditLift(row, editButtonArea)}>
                {editButtonArea}
              </TableCell>
              <TableCell sx={styles.edit} align="left" onClick={() => handleUnassignLift(row)}>
                {unassignButtonArea}
              </TableCell>
            </TableRow>
          );
        })
      }
    </TableBody>
  );
};

const RenderUserManagement = ({ data }) => {
  if (data.length === 0) {
    return (
      <TableBody>
        <TableRow>
          <TableCell sx={{ border: "none" }} colSpan={8} style={{ textAlign: "center" }}>There are no users for the selected location.</TableCell>
        </TableRow>
      </TableBody>
    );
  }
  return (
    <TableBody>
      {
        data && data.map((row, index) => {
          return (
            <TableRow hover role="checkbox" key={index}>
              <TableCell sx={styles.tableRow} align="left">
                {row.lastName}
              </TableCell>
              <TableCell sx={styles.tableRow} align="left">
                {row.firstName}
              </TableCell>
              <TableCell sx={styles.tableRow} align="left">
                {row.userName}
              </TableCell>
              <TableCell sx={styles.tableRow} align="left">
                {row.email}
              </TableCell>
              <TableCell sx={styles.tableRow} align="left">
                {row.organization}
              </TableCell>
              <TableCell sx={styles.tableRow} align="left">
                {row.locations[0] && row.locations[0].locationName}
              </TableCell>
              <TableCell sx={styles.tableRow} align="left">
                {row.statusDesc}
              </TableCell>
              <TableCell sx={styles.tableRow} align="left">
                {row.role}
              </TableCell>
            </TableRow>
          );
        })
      }
    </TableBody>
  );
};

const RenderHardwareManagement = ({ data, customerName, locationName }) => {
  if (data.length === 0) {
    return (
      <TableBody>
        <TableRow>
          <TableCell sx={{ border: "none" }} colSpan={5} style={{ textAlign: "center" }}>No Data</TableCell>
        </TableRow>
      </TableBody>
    );
  }

  return (
    <TableBody>
      {
        data && data.map((row, index) => {
          const hardwareType = {
            "1": "Destacker",
            "2": "Robowrapper",
            "3": "Lantech - VOR",
            "4": "Lantech - Standalone",
            "5": "Load Validation",
            "6": "SLS",
          };
          return (
            <TableRow hover role="checkbox" key={index}>
              <TableCell sx={styles.tableRow} align="left">
                {customerName}
              </TableCell>
              <TableCell sx={styles.tableRow} align="left">
                {locationName}
              </TableCell>
              <TableCell sx={styles.tableRow} align="left">
                {row.short_name}
              </TableCell>
              <TableCell sx={styles.tableRow} align="left">
                {hardwareType[row.hardware_type_id]}
              </TableCell>
              <TableCell sx={styles.tableRow} align="left">
                {row.status}
              </TableCell>
            </TableRow>
          );
        })
      }
    </TableBody>
  );
};

const RenderAssetDetails = ({ data }) => {
  if (data.length === 0) {
    return (
      <TableBody>
        <TableRow>
          <TableCell sx={{ border: "none" }} colSpan={11} style={{ textAlign: "center" }}>No Data</TableCell>
        </TableRow>
      </TableBody>
    );
  }
  return (
    <TableBody>
      {
        data && data.map((row, index) => {
          return (
            <TableRow hover role="checkbox" key={index}>
              <TableCell sx={styles.tableRow} align="left" style={{ wordBreak: "break-all" }}>
                {row.rfid}
              </TableCell>
              <TableCell sx={styles.tableRow} align="left" style={{ wordBreak: "break-all" }}>
                {row.sscc}
              </TableCell>
              <TableCell sx={styles.tableRow} align="left">
                {row.asset_status}
              </TableCell>
              <TableCell sx={styles.tableRow} align="left">
                {row.last_updated_by}
              </TableCell>
              <TableCell sx={styles.tableRow} align="center">
                {row.checkout_date === 0 ? "-" : row.checkout_date}
              </TableCell>
              <TableCell sx={styles.tableRow} align="center">
                {row.checkin_date === 0 ? "-" : row.checkin_date}
              </TableCell>
            </TableRow>
          );
        })
      }
    </TableBody>
  );
};

const RenderFloatVolume = ({ data }) => {
  if (data.length === 0) {
    return (
      <TableBody>
        <TableRow>
          <TableCell sx={{ border: "none" }} colSpan={8} style={{ textAlign: "center" }}>No Data</TableCell>
        </TableRow>
      </TableBody>
    );
  }
  return (
    <TableBody>
      {
        data && data.map((row, index) => {
          return (
            <TableRow hover role="checkbox" key={index}>
              <TableCell sx={styles.tableRow} align="left">
                {row.lastName}
              </TableCell>
              <TableCell sx={styles.tableRow} align="left">
                {row.firstName}
              </TableCell>
              <TableCell sx={styles.tableRow} align="left">
                {row.userName}
              </TableCell>
              <TableCell sx={styles.tableRow} align="left">
                {row.email}
              </TableCell>
              <TableCell sx={styles.tableRow} align="left">
                {row.organization}
              </TableCell>
              <TableCell sx={styles.tableRow} align="left">
                {row.locations[0] && row.locations[0].locationName}
              </TableCell>
              <TableCell sx={styles.tableRow} align="left">
                {row.statusDesc}
              </TableCell>
              <TableCell sx={styles.tableRow} align="left">
                {row.role}
              </TableCell>
            </TableRow>
          );
        })
      }
    </TableBody>
  );
};

const RenderLostScrap = ({ data }) => {
  if (data.length === 0) {
    return (
      <TableBody>
        <TableRow>
          <TableCell sx={{ border: "none" }} colSpan={8} style={{ textAlign: "center" }}>No Data</TableCell>
        </TableRow>
      </TableBody>
    );
  }
  return (
    <TableBody>
      {
        data && data.map((row, index) => {
          return (
            <TableRow hover role="checkbox" key={index}>
              <TableCell sx={styles.tableRow} align="left">
                {row.rfid}
              </TableCell>
              <TableCell sx={styles.tableRow} align="left">
                {row.customer_name}
              </TableCell>
              <TableCell sx={styles.tableRow} align="left">
                {row.location_name}
              </TableCell>
              <TableCell sx={styles.tableRow} align="left">
                {row.store_name}
              </TableCell>
              {row.store_address ?
                <TableCell sx={styles.tableRow} align="left">
                  {row.store_address}
                </TableCell> :
                <TableCell sx={styles.tableRow} align="center">
                  {"-"}
                </TableCell>
              }
            </TableRow>
          );
        })
      }
    </TableBody>
  );
};

const RenderDwellTime = ({ data }) => {
  if (data.length === 0) {
    return (
      <TableBody>
        <TableRow>
          <TableCell sx={{ border: "none" }} colSpan={8} style={{ textAlign: "center" }}>No Data</TableCell>
        </TableRow>
      </TableBody>
    );
  }
  return (
    <TableBody>
      {
        data && data.map((row, index) => {
          return (
            <TableRow hover role="checkbox" key={index}>
              <TableCell sx={styles.tableRow} align="left">
                {row.lastName}
              </TableCell>
              <TableCell sx={styles.tableRow} align="left">
                {row.firstName}
              </TableCell>
              <TableCell sx={styles.tableRow} align="left">
                {row.userName}
              </TableCell>
              <TableCell sx={styles.tableRow} align="left">
                {row.email}
              </TableCell>
              <TableCell sx={styles.tableRow} align="left">
                {row.organization}
              </TableCell>
              <TableCell sx={styles.tableRow} align="left">
                {row.locations[0] && row.locations[0].locationName}
              </TableCell>
              <TableCell sx={styles.tableRow} align="left">
                {row.statusDesc}
              </TableCell>
              <TableCell sx={styles.tableRow} align="left">
                {row.role}
              </TableCell>
            </TableRow>
          );
        })
      }
    </TableBody>
  );
};

export default React.memo(AppTableBody);