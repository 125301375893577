import React, { useEffect, useState } from "react";
import { DataGrid } from '@mui/x-data-grid';
import { Box, CircularProgress, Grid, InputAdornment, TextField, Typography, Stack } from "@mui/material";
import { connect } from 'react-redux'
import * as LiftActions from '../../store/lift/lift.actions'
import SearchIcon from "../../assets/searchIcon.svg";

const HardwareManagementPage = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [tableData, setTableData] = useState([]);
    const [pageSize, setPageSize] = useState(10);
    const customerId = props.selectedCustomerListData && props.selectedCustomerListData.customerId;
    const locationId = props.selectedCustomerListData && props.selectedLocationListData.locationId;

    const getHardwareDetails = () => {
        const loggedInUserIDToken = localStorage.getItem("authToken");
        props.fetchHardwareDetails(loggedInUserIDToken, customerId, locationId);
        if(props.hardwareDetailsData){
            setIsLoading(false)
        }
    }

    const runSearchQuery = (searchInput) => {
        const filteredAssetData = props?.hardwareDetailsData.filter((row) => {
            return (
                (row.customer_name && row.customer_name.toUpperCase().includes(searchInput.toUpperCase())) ||
                (row.location_name && row.location_name.toUpperCase().includes(searchInput.toUpperCase())) ||
                (row.short_name && row.short_name.toUpperCase().includes(searchInput.toUpperCase())) ||
                (row.hardware_type_id && hardwareType[row.hardware_type_id].toUpperCase().includes(searchInput.toUpperCase())) ||
                (row.status && row.status.toUpperCase().includes(searchInput.toUpperCase()))
            );
        });
        setTableData(filteredAssetData)
    };

    const handleChange = (value) => {
        props.updateSearchInput(value);
    }

    useEffect(() => {
        if (props?.searchInput) {
            runSearchQuery(props.searchInput);
        } else if (props?.hardwareDetailsData) {
            setTableData(props?.hardwareDetailsData);
        }
    }, [props?.searchInput, props.hardwareDetailsData]);

    useEffect(() => {
        if (customerId && locationId) {
            getHardwareDetails();
            props.updateSearchInput("");
        }
    }, [props.selectedCustomerListData, props.selectedLocationListData]);

    // useEffect(() => {
    //     if (tableData.length > 0) {
    //         setIsLoading(false)
    //     }
    // }, [tableData]);

    const columns = [
        {
            headerName: "Company Name",
            field: "customer_name",
            flex: 1
        },
        {
            headerName: "Location Name",
            field: "location_name",
            flex: 1
        },
        {
            headerName: "Hardware Name",
            field: "short_name",
            flex: 1
        },
        {
            headerName: "Hardware Type",
            field: "hardware_type_id",
            flex: 1,
            valueGetter: (params) =>
                `${hardwareType[params.row.hardware_type_id]}`
        },
        {
            headerName: "Hardware Status",
            field: "status",
            flex: 1
        }
    ];

    const hardwareType = {
        "1": "Destacker",
        "2": "Robowrapper",
        "3": "Lantech - VOR",
        "4": "Lantech - Standalone",
        "5": "Load Validation",
        "6": "SLS",
    };

    return (
        <div style={{ width: "100%" }}>
            <Typography sx={styles.heading}>Hardware Management</Typography>
            {isLoading ?
                <Stack alignItems="center">
                    <CircularProgress color="inherit" />
                </Stack> :
                <>
                    <Grid container>
                        <Grid item xs={3}>
                            <Box sx={{ width: "100%" }} mt={4} mb={4}>
                                <TextField
                                    value={props?.searchInput}
                                    onChange={(e) => {
                                        handleChange(e.target.value);
                                    }}
                                    autoComplete="off"
                                    sx={{
                                        borderRadius: "4px",
                                        input: {
                                            color: "#033F53",
                                        },
                                        width: "100%",
                                        background: "#fff",
                                        boxShadow: "0px 4px 8px rgba(16, 24, 64, 0.16)",
                                        "& .MuiInputBase-root.Mui-disabled": {
                                            background: "#E0E2EA",
                                            color: "#A6A6A6",
                                        },
                                    }}
                                    size={"small"}
                                    placeholder="Search"
                                    id="outlined-start-adornment"
                                    InputProps={{
                                        startAdornment:
                                            <InputAdornment position="start">
                                                <img src={SearchIcon} alt="" />
                                            </InputAdornment>
                                    }}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                    {tableData && (
                        <DataGrid
                            rows={tableData}
                            columns={columns}
                            getRowId={(row) => row.hardware_id}
                            disableColumnMenu
                            autoHeight={true}
                            pageSize={pageSize}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            rowsPerPageOptions={[10, 20, 30, 40, 50]}
                            disableSelectionOnClick
                            hideFooterPagination={tableData.length > 0 ? false: true}
                            sx={{
                                ".MuiDataGrid-columnHeaders": {
                                    backgroundColor: "#FAFBFF",
                                },
                                '.MuiDataGrid-columnSeparator': {
                                    display: 'none',
                                },
                                '.MuiDataGrid-cell:focus': {
                                    outline: "none !important",
                                }
                            }}
                        />
                    )}
                </>
            }
        </div>
    );
};

export const styles = {
    heading: {
        color: "#303030",
        fontSize: "24px",
        fontWeight: 700,
    }
}

const mapStateToProps = (state) => {
    return {
        isReadOnlyUser: state.isReadOnlyUser,
        selectedCustomerListData: state.selectedCustomerListData,
        selectedLocationListData: state.selectedLocationListData,
        hardwareDetailsData: state.hardwareDetailsData,
        searchInput: state.searchInput
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchHardwareDetails: (userToken, customerId, locationId) => dispatch(LiftActions.HARDWARE_DETAILS_REQUEST_TYPE(userToken, customerId, locationId)),
        updateSearchInput: (searchInput) => dispatch(LiftActions.SEARCH_INPUT_UPDATE(searchInput))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HardwareManagementPage);