import React, { useEffect } from "react";
import { Auth } from "aws-amplify";
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import * as LiftActions from '../../store/lift/lift.actions';
import { Box, Button, Divider, Grid, Menu, MenuItem, Typography } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import LogoutIcon from '@mui/icons-material/Logout';
import UserIcon from '../../assets/userIcon.svg';

const DashboardHeader = (props) => {
  const history = useHistory();
  const userName = localStorage.getItem("userName");
  const userRole = localStorage.getItem("userRole");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    localStorage.clear();
    history.push('/');
    await Auth.signOut();
    props.signoutUser(false);
  };

  const handleSelectedBoardChange = (value) => {
    if (value === "userProfile") {
      props.updateSelectedBoardType("userProfile");
      setAnchorEl(null);
    } else {
      props.updateSelectedBoardType(value);
    }
  }

  let parentPageNameHome
  if (props.selectedCustomerListData !== null || props.selectedCustomerListData !== undefined) {
    const appIds = localStorage.getItem("applicationIds");
    const userRoleName = localStorage.getItem("userRole");
    if (props.selectedCustomerListData?.duo_iot_enabled === true && props.selectedCustomerListData?.am_enabled === true &&   (userRoleName === "RpcAdmin" || userRoleName === "RpcReadOnly")) {
      parentPageNameHome = 'Asset Management IOT';
    }else if(props.selectedCustomerListData?.duo_iot_enabled === true && props.selectedCustomerListData?.am_enabled === true &&  appIds?.includes(4) && appIds?.includes(6)) {
      parentPageNameHome = 'Asset Management IOT';
    }else if (props.selectedCustomerListData?.duo_iot_enabled === true && (appIds?.includes(4) || userRoleName === "RpcAdmin" || userRoleName === "RpcReadOnly")) {
      parentPageNameHome = 'Delivery IOT'
    } else if (props.selectedCustomerListData?.am_enabled === true && (appIds?.includes(6) || userRoleName === "RpcAdmin" || userRoleName === "RpcReadOnly")) {
      parentPageNameHome = 'Asset Management IOT'
    }
  }

  return (
    <>
      <Grid container sx={styles.flexContainer}>
        <Grid item xs={11}>
          <Typography
            sx={{
              color: "#fff",
              fontSize: "24px",
              fontWeight: 700,
            }}
          >
            {userRole !== "RpcAdmin" ? props.selectedCustomerListData?.customerName : ""}
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <Box style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              id="basic-button"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
            >
              <Box sx={styles.username}>
                <img src={UserIcon} alt={""} style={{ alignItems: "end" }} />
                <ArrowDropDownIcon />
              </Box>
            </Button>
            <Menu
              sx={
                {
                  mt: "1px", left: "25px", "& .MuiMenu-paper":
                    { backgroundColor: "#3b62a1", },
                }
              }
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 55,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                    backgroundColor: "#3b62a1"
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <MenuItem sx={styles.menuItem} style={{ color: "white" }} onClick={() => handleSelectedBoardChange("User Profile")}>
                <Typography>{userName}</Typography>
                <Typography align="center">({userRole})</Typography>
              </MenuItem>
              <Divider sx={{ my: 0.5 }} />
              <MenuItem onClick={handleLogout} style={{ color: "white" }}
                sx={{
                  "&:hover": {
                    backgroundColor: "#2DA7D1 !important"
                  }
                }}
              >
                <Box sx={styles.logout}>
                  <LogoutIcon fontSize="small" sx={{ mt: "3px", mr: "6px" }} />
                  <Typography>
                    Logout
                  </Typography>
                </Box></MenuItem>
            </Menu>
          </Box>
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ paddingBottom: "10px" }}>
        <Link to="/Dashboard" style={{ textDecoration: "none", color: props?.selectedBoard === parentPageNameHome ? "#000" : "#1976d2" }} onClick={() => parentPageNameHome !== "Delivery IOT" ? handleSelectedBoardChange(parentPageNameHome) : ""}>
          {parentPageNameHome}
        </Link>
        {props?.selectedBoard !== "Delivery IOT" && props?.selectedBoard !== "Asset Management IOT" &&
          <>
            <Typography component={"span"} p={2}>
              {">"}
            </Typography>
            <Link to="/Dashboard" style={{ textDecoration: "none", color: "#000" }} onClick={() => handleSelectedBoardChange(props?.selectedBoard)}>
              {props?.selectedBoard}
            </Link>
          </>
        }
      </Grid>
    </>
  );
};

const styles = {
  flexContainer: {
    border: "1px solid",
    backgroundColor: "#3b62a1",
    padding: "25px",
    borderRadius: "10px",
    position: "sticky",
    top: "0",
    marginBottom: "30px",
    zIndex: 999
  },
  username: {
    color: "#D8DAE5",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    marginTop: "5px",
    textTransform: 'none'
  },
  logout: {
    cursor: "pointer",
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "5px",
  },
  menuItem: {
    display: 'block',
    "&:hover": {
      backgroundColor: "#2DA7D1 !important"
    }
  },
};

const mapStateToProps = (state) => {
  return {
    selectedBoard: state.selectedBoard,
    showNavMenu: state.showNavMenu,
    showSignoutPopup: state.showSignoutPopup,
    selectedCustomerListData: state.selectedCustomerListData,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateSelectedBoardType: (selectedType) => dispatch(LiftActions.LOAD_DASHBOARD_TYPE_UPDATE(selectedType)),
    signoutUser: (showSignoutPopup) => dispatch(LiftActions.SIGNOUT_USER(showSignoutPopup))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardHeader);