import React from 'react'
import { GoogleMap, LoadScript, Marker, MarkerClusterer, InfoWindow } from '@react-google-maps/api'
import mapsAlerts from '../../../assets/mapsWarning.png'
import mapsStops from '../../../assets/mapStops.png'
import mapsBreadcrumbs from '../../../assets/map-pin-solid.svg'
import duoIOTLiftImage from '../../../assets/DuoIOTLift.png'
import duoLiftTruckImage from '../../../assets/truck-solid.svg'
import duoRFIDPalletImage from '../../../assets/mapsPalletImage1.png'
import * as LiftActions from '../../../store/lift/lift.actions'
import { connect } from 'react-redux'
import DuoLiftMapItems from '../DuoLiftMapItems/DuoLiftMapItems.js'
import { Polyline } from '@react-google-maps/api';
import moment from 'moment-timezone';
import CircularProgress from '@mui/material/CircularProgress';
import DuoLiftRightPanelCard from '../DuoLiftRightPanelCard/DuoLiftRightPanelCard.js'
class DuoLiftMap extends React.Component {
  state = {
    hasLoaded: false,
    selectedMapType: this.props.selectedMapType,
    userSelectedAlerts: null,
    userRequestedDataPoints: { alerts: [], stops: [], breadcrumbs: []},
    refreshDataRequested: this.props.refreshDataRequested,
    showPopUp: false,
    popUpData: null,
    liftLatitude: null,
    liftLongitude: null,
    showLoadingIndicator: false,
    currentLocation: "Unable to find geocoded address",
    showPalletInfo: false,
    palletDataToShow: []
  }

  newcenter = { lat: 0, lng: 0}
  mapRef;

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.selectedMapType != nextProps.selectedMapType) {
      return true
    }
    if (this.state.refreshDataRequested) {
      if(this.props.latitude === nextProps.latitude) {
        if (nextProps.selectedMapType != null, nextProps.selectedMapType.length > 0) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    }
    return true
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state,callback)=>{
        return;
    };
}

  selectedReturns() {
    let userSelectedMaptypes = this.state.selectedMapType
    let newUserDataPoints = { alerts: [], stops: [], breadcrumbs: []}
    if (userSelectedMaptypes != null || userSelectedMaptypes != undefined) {
      userSelectedMaptypes.map(mapType => { 
        if (mapType == 'Alerts') {
          newUserDataPoints.alerts = this.props.alerts;
        } else if (mapType == 'Breadcrumbs') {
          newUserDataPoints.breadcrumbs = this.props.breadcrumbs;
        } else if (mapType == 'Stops') {
          newUserDataPoints.stops = this.props.stops;
        }
      })
    }
    return newUserDataPoints
  }

  onLoad = (map) => {
    this.setState({ hasLoaded: true })
    this.mapRef = map //class scope
    if (this.props.geocoder === null || this.props.geocoder === undefined) {
      this.props.UpdateGeocoderState(new window.google.maps.Geocoder());
    }
    // this.render();
  }

  handleBoundsChange = (center) => {
    if (this.mapRef) {
      const newCenter = this.mapRef.getCenter();
      this.newcenter = newCenter
    }
  }

  showPalletPopupWithData = (palletData, palletLatitude) => {
    let palletFinalData = [];
    palletData.filter(item => {
      if (parseFloat(item.stop_latitude) === parseFloat(palletLatitude)) {
        palletFinalData.push(item)
      }
    })
    this.setState({
      showPalletInfo: true,
      palletDataToShow:palletFinalData
    }) 
  }

  showLiftPopupWithData = (event) => {
    if (this.state.showPopUp === true) {
      this.setState({
        showPopUp: false,
        popUpData: null
      })

    } else {
      this.setState({
        showLoadingIndicator: true,
        popUpData: event
      }) 
      let default_state = "Unable to find geocoded address" 
      if(this.props.geocoder != null) {    
        if((event.latitude== null
           || event.longitude == null)
         && this.state.currentLocation !== default_state){
          this.setState({ currentLocation: default_state});
  
        } else {
          if (this.state.liftLatitude !== event.latitude || this.state.liftLongitude !== event.longitude) {
            this.props.geocoder.geocode({ location: { lat: event.latitude !== null ? parseFloat(event.latitude) : 50.25, lng: event.longitude !== null ? parseFloat(event.longitude) : 50.25 } }, (results, status) => {
              if(status === "OK") {
                  this.setState({ currentLocation: results[0].formatted_address, showPopUp: true, 
                    showLoadingIndicator: false, popUpData: event, liftLatitude: event.latitude, liftLongitude: event.longitude});
                  }         
               })
          } else {
            this.setState({ showPopUp: true, 
              showLoadingIndicator: false, popUpData: event, liftLatitude: event.latitude, liftLongitude: event.longitude});
          }
          }
          }
    }
   
  }

  hideLiftPopUp = () => {
    this.setState({
      showPopUp: false,
      popUpData: null
    })
  }

  hidePalletPopUp = () => {
    this.setState({
      showPalletInfo: false,
      palletDataToShow: []
    })
  }

  SelectBatteryImage = (batteryLevel) => {
        
    if(batteryLevel === 100)
       return "fa-solid fa-battery-full fa-lg";
    else if(batteryLevel >= 65 && batteryLevel < 100)
        return  "fa-solid fa-battery-three-quarters fa-lg";
    else if(batteryLevel >= 35 && batteryLevel < 65)
        return "fa-solid fa-battery-half fa-lg";
    else if(this.batteryLevel > 0 && batteryLevel < 35)
        return  "fa-solid fa-battery-quarter fa-lg";
    else
       return "fa-solid fa-battery-empty fa-lg";
    
    }

    setConnectedImage = (clientLastUpdated, lastHeartbeat) => {
      let currentTime = moment(clientLastUpdated)
      let end = moment(lastHeartbeat +'Z'); 
      let duration = moment.duration(currentTime.diff(end));
      let connected = duration.asMinutes() <= 5;
      if (connected) {
          return "fa-solid fa-wifi"
      } else {
          return "fa-solid fa-wifi-slash"
      }
  }

  setLastUpdatedTime = (clientLastUpdated, lastHeartbeat) => {
    let currentTime = moment(clientLastUpdated)
      let end = moment(lastHeartbeat +'Z'); 
      let duration = moment.duration(currentTime.diff(end));
      return Math.trunc(duration.asMinutes()) ;
  }

  GenerateCode = (item, mapType) => {
    if (mapType == 'Alerts') {
      let code = item.alertID.toString(16).toUpperCase()
      if (code.length > 1) {
        code = code.substring(0, 1) + "." + code.substring(1, code.length)
      }
      return `Alert \n \n ${item.alertDesc} \n \n Date & Time: ${item.date}`;
    } else if (mapType == 'Breadcrumbs') {
      var m = item.dateTime
      // m.tz('America/Chicago');
      // var s = m.format("YYYY-MM-DD HH:mm:ss");
      return m
      // return `Breadcrumbs pins, will be based upon heartbeat of tracker one.`;
    } else if (mapType == 'Stops') {
      let stopId = item.stopOrdinal
      let timeDiff = item.stopMinutes
      var stopStart = item.stopStart.split(" ")
      var stopEnd = item.stopEnd.split(" ")
      var timeStart = new Date(item.stopStart);
      var timeEnd = new Date(item.stopEnd);
      var hours = parseInt(Math.abs(timeEnd - timeStart) / (1000 * 60 * 60) % 24);
      var minDiff = parseInt(Math.abs(timeEnd.getTime() - timeStart.getTime()) / (1000 * 60) % 60);   
      var caseCount 
      var timeText
      if (item.case_count !== undefined && item.case_count !== null) {
        caseCount = item.case_count
      } else {
        caseCount = "N/A"
      }
      if (hours > 0 && minDiff > 0) {
        timeText = `${hours} hours ${minDiff} minutes`
      } else if (minDiff > 0) {
        timeText = `${minDiff} minutes`
      }
      return ` Stop: ${stopId} \n ArrivalTime: ${stopStart[1]} \n DepartureTime: ${stopEnd[1]} \n \n Time at stop: ${timeDiff} minutes \n Case Count: ${caseCount}`;
    } else if (mapType == 'Pallets') {
      var rfidTag = item.tag
      return rfidTag
    }
  }

  render() {
    this.state.selectedMapType = this.props.selectedMapType
    this.state.userRequestedDataPoints = this.selectedReturns()
    this.state.refreshDataRequested = this.props.refreshDataRequested
    let device = this.props.selectedDevice;
    let deviceList = this.props.deviceList
    let popUpInfoWindow;
    let mapWidth;

    if (this.props.collapseRightPanel) {
      mapWidth = "95%"
    } else {
      mapWidth = "75%"
    }

    const renderInfoWindow = () => {
      if (this.state.showLoadingIndicator) {
        popUpInfoWindow = <InfoWindow
        onCloseClick={this.hideLiftPopUp}
        visible={this.state.showPopUp}
        >
        <div className = "duoMapPopupCardForLoading">
          <CircularProgress></CircularProgress>
        </div>
        </InfoWindow>
        return popUpInfoWindow
      } else {
        if (this.state.popUpData !== null) {
          popUpInfoWindow = <InfoWindow
          onCloseClick={this.hideLiftPopUp}
          visible={this.state.showPopUp}
          >
          <div className = "duoMapPopupCard">
          <div className = "duoMapPopupCardHeader">
            <span style={{fontSize: '16px', fontWeight: 'bold'}}>DUO LIFT: {this.state.popUpData.liftID}</span>
            <i className= {this.setConnectedImage(this.state.popUpData.clientLastUpdated, this.state.popUpData.lastHeartbeat)}></i>
          </div>
          <div className="liftCustomerNameFlexForMap"> {this.state.popUpData.customer_name} </div>
          <div className="liftLocationFlexForMap"> {this.state.currentLocation} </div>
          <div className="duoMapCardImageSection">
            <div className="duoMapCardImgAndLabel">
              <i className= {this.SelectBatteryImage(this.state.popUpData.batteryPercent)}></i>
              <span style={{fontSize: '14px', fontWeight: 'regular'}}>{Math.trunc(this.state.popUpData.batteryPercent)}%</span>
            </div>
            <div className="duoMapCardImgAndLabel">
              <i className= "fa-regular fa-triangle-exclamation fa-lg"></i>
              <span style={{fontSize: '14px', fontWeight: 'regular'}}>{this.state.popUpData.alertCount} ALERTS</span>
            </div>
            <div className="duoMapCardImgAndLabel">
            <i className="fa-regular fa-temperature-half"></i>
            <span style={{fontSize: '14px', fontWeight: 'regular'}}>{this.state.popUpData.ambientTemp}</span>
            </div>
          </div>
          <div className="duoMapCardLastUpdated">
            {}
             <span style={{fontSize: '14px', fontWeight: 'regular'}}>LAST UPDATED: {this.setLastUpdatedTime(this.state.popUpData.clientLastUpdated, this.state.popUpData.lastHeartbeat)} MIN AGO</span>
          </div>
          </div>
          </InfoWindow>
          return popUpInfoWindow
        }
      }
    }

    if (this.state.hasLoaded) {

      const alertsImage = {
        url:
        mapsAlerts,
        size: new window.google.maps.Size(30, 30),
        origin: new window.google.maps.Point(0, 0),
        anchor: new window.google.maps.Point(15, 15),
        scaledSize: new window.google.maps.Size(30, 30)
      };
    
      const stopsImage = {
        url:
        mapsStops,
        size: new window.google.maps.Size(30, 30),
        origin: new window.google.maps.Point(0, 0),
        anchor: new window.google.maps.Point(15, 15),
        scaledSize: new window.google.maps.Size(30, 30)
      };
    
      const breadcrumbsImage = {
        url:
        mapsBreadcrumbs,
        size: new window.google.maps.Size(30, 30),
        origin: new window.google.maps.Point(0, 0),
        anchor: new window.google.maps.Point(15, 15),
        scaledSize: new window.google.maps.Size(30, 30)
      };
    
      const liftImage = {
        url:
        duoIOTLiftImage,
        size: new window.google.maps.Size(80, 80),
        origin: new window.google.maps.Point(0, 0),
        anchor: new window.google.maps.Point(38, 38),
        scaledSize: new window.google.maps.Size(70, 70)
      };
    
      const truckImage = {
        url:
        duoLiftTruckImage,
        size: new window.google.maps.Size(50, 50),
        origin: new window.google.maps.Point(0, 0),
        anchor: new window.google.maps.Point(38, 38),
        scaledSize: new window.google.maps.Size(50, 50)
      }

      const palletImage = {
        url:
        duoRFIDPalletImage,
        size: new window.google.maps.Size(50, 50),
        origin: new window.google.maps.Point(0, 0),
        anchor: new window.google.maps.Point(55,30),
        scaledSize: new window.google.maps.Size(50, 50)
      }

      const generateLiftImage = (liftData) => {
        if (liftData != undefined) {
          if (liftData.is_lift_in_truck) {
            return truckImage
          } else {
            return liftImage
          }
        }
      }
      var initialcenter;
      if (this.state.refreshDataRequested) {
        if (this.props.userSelectedStopDashboardFlag) {
          initialcenter = {
            lat: this.props.userSelectedStopDashboard.latitude != null ? this.props.userSelectedStopDashboard.latitude : 50.25,
            lng: this.props.userSelectedStopDashboard.longitude != null ? this.props.userSelectedStopDashboard.longitude : 50.25
          }
        } else if (this.props.userSelectedAlertDashboardFlag) {
          initialcenter = {
            lat: this.props.userSelectedAlertDashboard.alertLatitude != null ? this.props.userSelectedAlertDashboard.alertLatitude : 50.25,
            lng: this.props.userSelectedAlertDashboard.alertLongitude != null ? this.props.userSelectedAlertDashboard.alertLongitude : 50.25
          }
        } else {
          initialcenter = this.newcenter
        }
      } else {
        if (this.props.userSelectedStopDashboardFlag) {
          initialcenter = {
            lat: this.props.userSelectedStopDashboard.latitude != null ? this.props.userSelectedStopDashboard.latitude : 50.25,
            lng: this.props.userSelectedStopDashboard.longitude != null ? this.props.userSelectedStopDashboard.longitude : 50.25
          }
        } else if (this.props.userSelectedAlertDashboardFlag) {
          initialcenter = {
            lat: this.props.userSelectedAlertDashboard.alertLatitude != null ? this.props.userSelectedAlertDashboard.alertLatitude : 50.25,
            lng: this.props.userSelectedAlertDashboard.alertLongitude != null ? this.props.userSelectedAlertDashboard.alertLongitude : 50.25
          }
        } else {
          initialcenter = {
            lat: this.props.latitude != null ? this.props.latitude : 50.25,
            lng: this.props.longitude != null ? this.props.longitude : 50.25
          }
        }
      }

      // const liftLocation = {
      //   lat: this.props.latitude != null ? this.props.latitude : 50.25,
      //   lng: this.props.longitude != null ? this.props.longitude : 50.25
      // }

      return (
        <div style={{display:"flex", flexDirection:"row", width: "100%"}}>
          <LoadScript
             googleMapsApiKey={process.env.REACT_APP_GMAP_API_KEY}>
          <GoogleMap
            center={initialcenter}
            zoom={15}
         
            onLoad={this.onLoad}
            mapContainerStyle={{ height: '800px', width: mapWidth, borderRadius: '1em' }}
            onCenterChanged = {this.handleBoundsChange}
          >
          <DuoLiftMapItems geocoder={this.props.geocoder} latitude={device.lat} longitude={device.long}> </DuoLiftMapItems>
            {/* <Marker 
            position={liftLocation} 
            icon={liftImage}
            /> */}
            {/* <Polyline
              path={breadcrum  bPath}
            /> */}
            
            <MarkerClusterer maxZoom={20}>
              {(clusterer) => {
                return deviceList != null &&
                deviceList.map((deviceE, index) => (
                  <Marker key={index} position={{ lat: parseFloat(deviceE.latitude) !== null ? parseFloat(deviceE.latitude) : 50.25, lng: parseFloat(deviceE.longitude) !== null ? parseFloat(deviceE.longitude): 50.25 }} clusterer={clusterer} icon={generateLiftImage(deviceE)} onClick={() => this.showLiftPopupWithData(deviceE)}>              
                    {this.state.popUpData !== null && this.state.popUpData.liftID === deviceE.liftID &&
                      renderInfoWindow()
                    } 
                  </Marker>  
                  )
                )
              }
              }
            </MarkerClusterer>
            <MarkerClusterer maxZoom={20}>
              {(clusterer) => {
                if (this.state.userRequestedDataPoints.stops != null && this.state.userRequestedDataPoints.stops.length === 0) {
                  clusterer.clearMarkers()
                }
                return this.state.userRequestedDataPoints.stops != null &&
                this.state.userRequestedDataPoints.stops.map((stop, index) => (
                  <Marker key={index} position={{ lat: parseFloat(stop.latitude), lng: parseFloat(stop.longitude) }} clusterer={clusterer} noClustererRedraw={true} icon={stopsImage} title={this.GenerateCode(stop, "Stops")} />
                )
                )
              }
              }
            </MarkerClusterer>
            <MarkerClusterer maxZoom={20}>
              {(clusterer) => {
                if (this.state.userRequestedDataPoints.stops != null && this.state.userRequestedDataPoints.stops.length === 0 && this.props.userSelectedStopPallets != null) {
                  clusterer.clearMarkers()
                }
                return this.state.userRequestedDataPoints.stops.length > 0 && this.props.userSelectedStopPallets != null &&
                  this.props.userSelectedStopPallets.map((pallet, index) => {
                    return (
                      <Marker key={index} position={{ lat: parseFloat(pallet.stop_latitude), lng: parseFloat(pallet.stop_longitude) }} clusterer={clusterer} noClustererRedraw={true} icon={palletImage} onClick={() => this.showPalletPopupWithData(this.props.userSelectedStopPallets, pallet.stop_latitude, pallet.stop_longitude)}>
                        {this.state.showPalletInfo &&
                          <InfoWindow
                            onCloseClick={this.hidePalletPopUp}
                            visible={this.state.showPalletInfo}
                          >
                            <div>
                              <div style={{ textAlign: "center", fontWeight: 600 }}>RFID's</div>
                              {this.state.palletDataToShow && this.state.palletDataToShow.map((row) => (
                                <div>{row.tag}</div>
                              ))}
                            </div>
                          </InfoWindow>
                        }
                      </Marker>
                    )
                  }
                  )
              }
              }
            </MarkerClusterer>
            <MarkerClusterer maxZoom={20}>
              {(clusterer) => {
                if (this.state.userRequestedDataPoints.breadcrumbs != null && this.state.userRequestedDataPoints.breadcrumbs.length === 0) {
                  clusterer.clearMarkers()
                }

                return this.state.userRequestedDataPoints.breadcrumbs != null &&
                 this.state.userRequestedDataPoints.breadcrumbs.map((breadcrumb, index) => (
                   <Marker key={index} position={{ lat: parseFloat(breadcrumb.latitude), lng: parseFloat(breadcrumb.longitude) }} clusterer={clusterer} noClustererRedraw={false} icon={breadcrumbsImage} title={this.GenerateCode(breadcrumb, "Breadcrumbs")} />
                 )
                 )
              }
            }
             </MarkerClusterer>
            <MarkerClusterer maxZoom={20}>
              {(clusterer) => {
                if (this.state.userRequestedDataPoints.alerts != null && this.state.userRequestedDataPoints.alerts.length === 0) {
                  clusterer.clearMarkers()
                }
                return this.state.userRequestedDataPoints.alerts != null &&
                  this.state.userRequestedDataPoints.alerts.map((alert, index) => {
                    if (alert.latitude !== 0 && alert.longitude !== 0) {
                      return <Marker key={index} position={{ lat: parseFloat(alert.latitude), lng: parseFloat(alert.longitude) }} clusterer={clusterer} noClustererRedraw={true} icon={alertsImage} title={this.GenerateCode(alert, "Alerts")} />
                    }
                  }
                  )
              }
              }
            </MarkerClusterer>
          </GoogleMap>
        </LoadScript>
        <DuoLiftRightPanelCard></DuoLiftRightPanelCard>
      </div>
      );
    } else {
      return (
      <div>
        <LoadScript
          googleMapsApiKey={process.env.REACT_APP_GMAP_API_KEY}>
        <GoogleMap onLoad={this.onLoad}>
        </GoogleMap>
        </LoadScript>
      </div>
      )}
  }

};

const mapStateToProps = (state) => {
  return {
    selectedDevice: state.selectedDevice,
    selectedMapType: state.selectedMapType,
    geocoder: state.geocoder,
    collapseRightPanel: state.collapseRightPanel,
    userSelectedStopDashboardFlag: state.userSelectedStopDashboardFlag,
    userSelectedStopDashboard: state.userSelectedStopDashboard,
    userSelectedAlertDashboardFlag: state.userSelectedAlertDashboardFlag,
    userSelectedAlertDashboard: state.userSelectedAlertDashboard,
    userSelectedStopPallets: state.selectedStopPallets,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    UpdateGeocoderState: (geocoder) => dispatch(LiftActions.LOAD_LIFT_MAP_GEOCODER(geocoder)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(React.memo(DuoLiftMap))