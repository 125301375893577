import React, {useEffect} from 'react';
import { connect } from 'react-redux'
import * as LiftActions from '../../../store/lift/lift.actions'
import LiftManagementBottomPanel from '../LiftManagementBottomPanel/LiftManagementBottomPanel.js'
import LiftManagementEditModal from '../LiftManagementEditModal/LiftManagementEditModal.js'

const LiftManagementPortal = (props) => {
    const customerId = props.selectedCustomerListData && props.selectedCustomerListData.customerId;
    const locationId = props.selectedCustomerListData && props.selectedLocationListData.locationId;
    const getLiftsData = () => {
        const loggedInUserIDToken = localStorage.getItem("authToken");
        props.fetchLiftsForLocation(loggedInUserIDToken,customerId,locationId);
      }
      
    const getDriversForLifts = () => {
        const loggedInUserIDToken = localStorage.getItem("authToken");
        props.fetchDriversForLifts(loggedInUserIDToken)
     }

    useEffect(()=>{
        if(customerId && locationId){
            getLiftsData();
            getDriversForLifts();
            props.updateSearchInput("");
        }
      },[props.selectedCustomerListData, props.selectedLocationListData])

    return (
        <div style={{width: "100%"}}>
        <LiftManagementBottomPanel isReadOnly={props.isReadOnlyUser}></LiftManagementBottomPanel>
        <LiftManagementEditModal></LiftManagementEditModal>
        </div>
    )
}

const mapStateToProps = (state) =>{
    return {
        customersForLifts: state.liftsForLocations,
        isReadOnlyUser: state.isReadOnlyUser,
        selectedCustomerListData: state.selectedCustomerListData,
        selectedLocationListData: state.selectedLocationListData
    }
 }

 const mapDispatchToProps = (dispatch) =>{
    return {
        fetchLiftsForLocation: (userToken,customerId,locationId) => dispatch(LiftActions.LOCATIONS_LIFTS_REQUEST_TYPE(userToken,customerId,locationId)),
        fetchDriversForLifts: (userToken) => dispatch(LiftActions.LIFTS_DRIVERS_REQUEST_TYPE(userToken, "", "")),
        updateSearchInput: (searchInput) => dispatch(LiftActions.SEARCH_INPUT_UPDATE(searchInput))
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (LiftManagementPortal);