import React from 'react';
import moment from 'moment'
class DuoLiftCard extends React.Component{

    SelectBatteryImage(batteryLevel){
        
        if(batteryLevel === 100)
           return "fa-solid fa-battery-full fa-lg";
        else if(batteryLevel >= 65 && batteryLevel < 100)
            return  "fa-solid fa-battery-three-quarters fa-lg";
        else if(batteryLevel >= 35 && batteryLevel < 65)
            return "fa-solid fa-battery-half fa-lg";
        else if(this.batteryLevel > 0 && batteryLevel < 35)
            return  "fa-solid fa-battery-quarter fa-lg";
        else
           return "fa-solid fa-battery-empty fa-lg";
        
        }
        SelectServiceImage(serviceNumber) {
            if (serviceNumber === 1) {
                return "fa-solid fa-wrench";
            } else if (serviceNumber === 2) {
                return "fa-solid fa-wrench";
            }
        }
    
        ServiceStatusText(serviceNumber) {
            if (serviceNumber === 1) {
                return 'UPCOMING SERVICE REQUIRED';
            } else if (serviceNumber === 2) {
                return 'SERVICE PAST DUE';
            }
        }

        serviceItemBackgroundColor(serviceNumber) {
            if (serviceNumber === 1) {
                return '#FB9700';
            } else if (serviceNumber === 2) {
                return '#DB2D2D';
            }
        }

        setConnectedImage(connected) {
            if (connected) {
                return "fa-solid fa-wifi"
            } else {
                return "fa-solid fa-wifi-slash"
            }
        }

        setAlertImage(alertCount) {
            if (alertCount != null) {
                if (alertCount > 0) {
                    return "fa-regular fa-triangle-exclamation fa-lg"
                } else {
                    return null
                }
            } else {
                return null
            }
        }
render(){
let currentTime = moment(this.props.device.clientLastUpdated)
let end = moment(this.props.device.lastHeartbeat +'Z'); 
let duration = moment.duration(currentTime.diff(end));
let connected = duration.asMinutes() <= 5;
let connectedStatusImage = this.setConnectedImage(connected)
let batteryImage = this.SelectBatteryImage(this.props.device.batteryPercent)
let serviceImage = this.SelectServiceImage(this.props.device.maintState)
let serviceStatus = this.ServiceStatusText(this.props.device.maintState)
let alertImage = this.setAlertImage(this.props.device.alertCount)
let serviceItemBackgroundColor = this.serviceItemBackgroundColor(this.props.device.maintState)
    return (
        <div className={`dashboard-card ${this.props.selected ? 'duolift-device-card-selected' : 'duolift-device-card-notselected'}`}>
            {this.props.device.routeName ?
                <div className="dashboard-card-header">
                    <div style={{ fontSize: '16px', fontWeight: 'bold' }}>ROUTE: {this.props.device.routeName}</div>
                </div> : null}
            <div className="dashboard-card-header">
                <div style={{ display: 'block' }}>
                    <div style={{ fontSize: '16px', fontWeight: this.props.device.routeName ? 'normal' : 'bold' }}>DUO LIFT: {this.props.device.liftID}</div>
                </div>
                <div>
                    <i className={alertImage} style={{ marginRight: "10px" }}></i>
                    <i className={batteryImage} style={{ marginRight: "10px" }}></i>
                    <i className={connectedStatusImage}></i>
                </div>
        </div> 
            <div className="liftCustomerNameFlex"> {this.props.device.customer_name} </div>
            {serviceImage ? 
            <div className="serviceIndicatorFlex" style={{backgroundColor: serviceItemBackgroundColor}}>
                 {serviceImage ? <i className={serviceImage} style={{marginLeft: "10px"}}></i> : null} 
                <span className="serviceIndicatorText" style={{color: 'white'}}>{serviceStatus}</span>
             </div> : null}
        </div>
);
        }
}
export default DuoLiftCard;
