import React from 'react';
import { connect } from 'react-redux'
import Button from '@mui/material/Button';
import MyCommandCell from '../../tools/GridColumnButton2/GridColumnButton.js'
import * as LiftActions from '../../../store/lift/lift.actions'
import { Typography } from '@mui/material';
import Table from "../../Table/Table.js"
import { USER_MANAGEMENT_TABLE_HEAD } from "../../../data/tableHeadData.js";
class UserManagementBottomPanel extends React.Component {

    state = {
        filter: null,
        searchResults: '',
        searchFlag: false
    };

    handleAddUserTapped = () => {
        this.props.showAddUserModal("Add User", true)
    }

    handleEditUserTapped = (event) => {
        this.props.showEditUserModal("Edit User", true, event)
    }

    editUserButton = (props) =>
        <MyCommandCell name="Edit User" onClick={this.handleEditUserTapped} style={{ margin: '5px', padding: '5px' }}></MyCommandCell>

    render() {

        let selectedUsersForLocation = this.props.selectedUsersForLocation;
        let userLocationArea;
        let editButtonArea;
        var editButtonAreaTitle = "";
        var editButtonWidth = "100px";
        var addUserButtonArea;
        let filteredData;
        let searchQuery = '';
        const setSearchQuery = (event) => {
            searchQuery = event.target.value ? event.target.value.toLowerCase() : '';
            searchFn(event);
        }
        const searchFn = (event) => {
            event.preventDefault();
            this.setState({ searchResults: '' });
            if (searchQuery) {
                this.setState({ searchFlag: true });
                filteredData = selectedUsersForLocation.users.filter(e =>
                    (e.lastName && e.lastName.toLowerCase().includes(searchQuery))
                    || (e.firstName && e.firstName.toLowerCase().includes(searchQuery))
                    || (e.userName && e.userName.toLowerCase().includes(searchQuery))
                    || (e.email && e.email.toLowerCase().includes(searchQuery))
                    || (e.role && e.role.toLowerCase().includes(searchQuery))
                    || (e.statusDesc && e.statusDesc.toLowerCase().includes(searchQuery)));
                this.setState({ searchResults: filteredData });
            } else {
                this.setState({ searchFlag: false });
                this.setState({ searchResults: this.props.selectedUsersForLocation.users });
            }
        }
        if (selectedUsersForLocation) {
            filteredData = (this.state.searchFlag) ? this.state.searchResults : selectedUsersForLocation.users;
        }

        // const columns = [
        //     { field: 'lastName', headerName: 'LAST NAME', width: 180 },
        //     { field: 'firstName', headerName: 'FIRST NAME', width: 180 },
        //     { field: 'userName', headerName: 'USER NAME', width: 180 },
        //     { field: 'email', headerName: 'EMAIL ID', width: 180 },
        //     { field: 'role', headerName: 'USER TYPE', width: 180 },
        //     { field: 'statusDesc', headerName: 'STATUS', width: 180 },
        //     { field: 'editUser', headerName: 'EDIT USER', width: 180, renderCell: this.editUserButton },
        // ];

        if (selectedUsersForLocation != undefined) {
            if (selectedUsersForLocation.displayStatus && !this.props.isReadOnly) {
                if (this.props.selectedUsersForLocation.locationName === "ShowAll") {
                    editButtonArea = null
                    editButtonAreaTitle = ""
                    editButtonWidth = "0px"
                    addUserButtonArea = null
                } else {
                    editButtonArea = this.editUserButton
                    editButtonAreaTitle = "EDIT USER"
                    addUserButtonArea = <Button variant="outlined" onClick={this.handleAddUserTapped}> Add User</Button>
                }
            } else {
                editButtonArea = null
                editButtonAreaTitle = ""
                editButtonWidth = "0px"
                addUserButtonArea = null
            }
        }

        if (selectedUsersForLocation != undefined && filteredData) {
            if (selectedUsersForLocation.users.length > 0) {
                let users = selectedUsersForLocation.users;
                users.map(userInfo => {
                    if (userInfo.displayStatus === true) {
                        userInfo['statusDesc'] = "Active"
                    } else {
                        userInfo['statusDesc'] = "InActive"
                    }
                })


                userLocationArea = (filteredData.length > 0 || this.state.searchFlag) && <Table
                    tableHeadItems={USER_MANAGEMENT_TABLE_HEAD}
                    tableBodyData={filteredData}
                    tableName="userManagement"
                />

            } else {
                userLocationArea = <p className="alert-no-alerts">There are no users for the selected location. </p>
            }
        } else {
            userLocationArea = <p className="alert-no-alerts">Select a customer and location to see the users </p>
        }

        return (
            <div style={{ width: "100%" }}>
                <Typography sx={styles.heading}>User Management</Typography>
                <Table
                        tableHeadItems={USER_MANAGEMENT_TABLE_HEAD}
                        tableBodyData={""}
                        tableName="userManagement"
                    />
                {filteredData &&
                    <Table
                        tableHeadItems={USER_MANAGEMENT_TABLE_HEAD}
                        tableBodyData={filteredData}
                        tableName="userManagement"
                    />
                }
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        showAddUserModal: (type, show) => dispatch(LiftActions.USERS_ADD_MODAL_UPDATE(type, show)),
        showEditUserModal: (type, show, userData) => dispatch(LiftActions.USERS_EDIT_MODAL_UPDATE(type, show, userData)),
    }
}

export const styles = {
    heading: {
        color: "#303030",
        fontSize: "24px",
        fontWeight: 700,
    }
}

export default connect(null, mapDispatchToProps)(UserManagementBottomPanel);