import React from 'react';
import { connect } from 'react-redux';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import * as LiftActions from '../../../../store/lift/lift.actions';
import { NightShelter } from '@mui/icons-material';
import Modal from '@mui/material/Modal';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

class DuoLiftStopsTab extends React.Component {

    state = {
        selectedStop: null,
        showStopRFID: false,
    };

    userClickStop(stop) {
        this.setState({ selectedStop: stop });
        this.props.updateUserSelectedStop(stop)
        this.props.updateStopSelectedFlag(true)
    }

    handleRFIDValuesTapped=() => {
        this.setState({ showStopRFID: true });
    }

    handleClose = () => {
        this.setState({ showStopRFID: false });
    };

    userSelectedColor(stop) {
        if (this.state.selectedStop !== null) {
            if (this.state.selectedStop.stopOrdinal === stop.stopOrdinal) {
                return 'lightblue'
            } else {
                return this.stopBackGroundColor(stop.avg_stop_time, stop.stopMinutes)
            }
        } else {
            return this.stopBackGroundColor(stop.avg_stop_time, stop.stopMinutes)
        }
    }

    userBorder(stop) {
        if (this.state.selectedStop !== null) {
            if (this.state.selectedStop.stopOrdinal === stop.stopOrdinal) {
                return '3px solid #00B0F0'
            } else {
                return 'white'
            }
        } else {
            return 'white'
        }
    }

    stopBackGroundColor(avgTime, stopTime) {
        if (avgTime !== null, stopTime !== null) {
            var diff = stopTime - avgTime
            if (diff > 0) {
                if (diff < 10) {
                    return 'green'
                } else if (diff >= 10 && diff < 15) {
                    return '#FB9700'
                } else if (diff >= 15) {
                    return '#DB2D2D'
                }
            } else {
                return 'green'
            }
        } 
    }

    checkForRFIDValueExists(stops) {
        if(stops.some(e => e.rfid_count !== 0)) {
            return true
        } else {
            return false
        }
    }

    render() {
        let selectedDevice = this.props.selectedLift
        let stopBio = this.props.selectedLiftStopBio
        let avgCostPerStep = 0.00
        let avgCostPerStop = 0.00
        let avgMinutesPerStop = 0.00
        let avgStepsPerStop = 0.00
    
        if (stopBio != null) {
            if (stopBio.avgCostPerStep != null) {
                avgCostPerStep = stopBio.avgCostPerStep
            }
        }
        if (stopBio != null) {
            if (stopBio.avgCostPerStop != null) {
                avgCostPerStop = stopBio.avgCostPerStop
            }
        }
    
        if (stopBio != null) {
            if (stopBio.avgMinutesPerStop != null) {
                avgMinutesPerStop = stopBio.avgMinutesPerStop
            }
        }
    
        if (stopBio != null) {
            if (stopBio.avgStepsPerStop != null) {
                avgStepsPerStop = stopBio.avgStepsPerStop
            }
        }


        if (selectedDevice != null) {
            let stopsLength  = selectedDevice.stops == null ? 0: selectedDevice.stops.length;
            if (stopsLength > 0) {
                let stopRFIDArea 

                if (this.state.selectedStop !== null) {
                    if (this.state.selectedStop.rfid_count > 0) {
                        stopRFIDArea = <Modal
                        open={this.state.showStopRFID}
                        onClose={this.handleClose}
                         aria-labelledby="parent-modal-title"
                        aria-describedby="parent-modal-description"
                        style={{display: "flex", alignItems:'center',justifyContent:'center', marginTop:'120px'}}
                        >
              
                        <TableContainer component={Paper} sx={{ minWidth: 400 , maxWidth: 400, maxHeight: 400, overflow: 'scroll'}}>
                        <Table sx={{ minWidth: 400 ,maxWidth: 400, maxHeight: 400, overflow: 'scroll', margin: 'auto'}} aria-label="simple table">
                            <TableHead>
                            <TableRow>
                                <TableCell>No</TableCell>
                                <TableCell align="center">RFID</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {this.state.selectedStop.rfid.map((row, index) => (
                            <TableRow
                            key={index}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                            <TableCell component="th" scope="row">
                            {index + 1}
                            </TableCell>
                            <TableCell align="center">{row.tag}</TableCell>
                            </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                        </TableContainer>
                        </Modal>
                    } else {
                        stopRFIDArea = <Modal
                        open={this.state.showStopRFID}
                        onClose={this.handleClose}
                         aria-labelledby="parent-modal-title"
                        aria-describedby="parent-modal-description"
                        style={{display: "flex", alignItems:'center',justifyContent:'center', marginTop:'120px'}}
                        >
              
                        <TableContainer component={Paper} sx={{ minWidth: 400 , maxWidth: 400, maxHeight: 400, overflow: 'scroll'}}>
                        <Table sx={{ minWidth: 400 , maxWidth: 400, maxHeight: 400, overflow: 'scroll', margin: 'auto'}} aria-label="simple table">
                            <TableHead>
                            <TableRow>
                                <TableCell>No</TableCell>
                                <TableCell align="center">RFID</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            <TableRow
                                key={1}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                <TableCell component="th" scope="row">
                                null
                                </TableCell>
                                <TableCell align="center">null</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                        </TableContainer>
                        </Modal>
                    }
                } 

                let stopArea = 
                selectedDevice.stops.map((item,index) => (
                    <div className="rightCardStatusRowStopElements" key={item.stopOrdinal} style={{border: this.userBorder(item)}}>
                    <div className="rightCardStatusRowElements" key={item.stopOrdinal} style={{background: "white"}} onClick={() => this.userClickStop(item)}> 
                    <Typography variant="body1" gutterBottom style={{width:"30px", marginLeft: "10px"}}>
                    {item.stopOrdinal}
                    </Typography>
                    <Typography variant="body1" gutterBottom style={{width:"30px"}}>
                    {item.avg_stop_time} MIN
                    </Typography>
                    <Typography variant="body1" gutterBottom style={{width:"30px"}}>
                    {item.stopMinutes} MIN
                    </Typography>
                    <Typography variant="body1" gutterBottom style={{width:"100px", textAlignVertical: "center",textAlign: "center"}}>
                    {item.stopAddress ? item.stopAddress : item.store_name} 
                    </Typography>
                    </div>
                     <div className="rightCardStatusRowElements" key={0} style={{background: "white"}} onClick={() => this.userClickStop(item)}> 
                         {this.checkForRFIDValueExists(selectedDevice.stops) &&
                         <Button variant={""} size="small" style={{alignItems: "flex-start", maxHeight: "30px", maxWidth: "30px", minWidth: "30px", minHeight: "30px"}} onClick={this.handleRFIDValuesTapped}>
                         <Typography variant="body1" gutterBottom style={{width:"30px", textAlignVertical: "center",textAlign: "center"}}>
                        {item.rfid_count} 
                        </Typography>
                        </Button>
                    }
                    </div>
                    </div>
                 ))  
                return (
                    <div className="rightCardAlertsTab">
                    <div className="rightCardStatusElements">
                    <Typography variant="subtitle2" gutterBottom component="div" style={{marginBottom: "0px"}}>
                     STOP ANALYTICS FOR FLEET
                    </Typography>
                    <hr style={{backgroundColor: "black", height: 2, width: "100%", marginTop: "0px"}}></hr>
                    <div className="rightCardStatusRowElements"> 
                        <Typography variant="body1" gutterBottom>
                        AVG MIN PER STOP
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                        {avgMinutesPerStop}
                        </Typography>
                    </div>
                    {/* <div className="rightCardStatusRowElements"> 
                        <Typography variant="body1" gutterBottom>
                        AVG STEPS PER STOP
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                        {avgStepsPerStop}
                        </Typography>
                    </div>
                    <div className="rightCardStatusRowElements"> 
                        <Typography variant="body1" gutterBottom>
                        AVG COST PER STOP
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                        {avgCostPerStop.toFixed(2)}
                        </Typography>
                    </div>
                    <div className="rightCardStatusRowElements"> 
                        <Typography variant="body1" gutterBottom>
                        AVG COST PER STEP
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                        {avgCostPerStep.toFixed(2)}
                        </Typography>
                    </div> */}
                    </div>
                    <div className="rightCardStatusElements">
                    <Typography variant="subtitle2" gutterBottom component="div" style={{marginBottom: "0px"}}>
                     STOPS
                    </Typography>
                    <hr style={{backgroundColor: "black", height: 2, width: "100%", marginTop: "0px"}}></hr>
                    <div className="rightCardStopElements">
                    <div className="rightCardStatusRowElements"> 
                   <Typography variant="body2" gutterBottom style={{width:"30px"}}>
                    STOP
                    </Typography>
                    <Typography variant="body2" gutterBottom style={{width:"30px"}}>
                    AVG TIME
                    </Typography>
                    <Typography variant="body2" gutterBottom style={{width:"30px"}}>
                    STOP MIN
                    </Typography>
                    <Typography variant="body2" gutterBottom style={{width:"100px", textAlignVertical: "center",textAlign: "center"}}>
                    ADDRESS
                    </Typography>
                    </div>
                    <div className="rightCardStatusRowElements"> 
                    {this.checkForRFIDValueExists(selectedDevice.stops) &&
                       <Typography variant="body2" gutterBottom style={{width:"30px", textAlignVertical: "center",textAlign: "center"}}>
                       PALLETS
                       </Typography>
                    }
                    </div>
                    </div>
                    {stopArea}
                    {stopRFIDArea}
                    </div>
                    </div>
                )
            } else {
                return (
                    <div className="rightCardAlertsTab">
                        <div className="rightCardStatusElements">
                        <Typography variant="subtitle2" gutterBottom component="div" style={{marginBottom: "0px"}}>
                        STOP ANALYTICS FOR FLEET
                        </Typography>
                        <hr style={{backgroundColor: "black", height: 2, width: "100%", marginTop: "0px"}}></hr>
                        <div className="rightCardStatusRowElements"> 
                            <Typography variant="body1" gutterBottom>
                            AVG MIN PER STOP
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                            {avgMinutesPerStop}
                            </Typography>
                        </div>
                        {/* <div className="rightCardStatusRowElements"> 
                             <Typography variant="body1" gutterBottom>
                            AVG STEPS PER STOP
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                            {avgStepsPerStop}
                             </Typography>
                        </div>
                        <div className="rightCardStatusRowElements"> 
                            <Typography variant="body1" gutterBottom>
                            AVG COST PER STOP
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                            {avgCostPerStop.toFixed(2)}
                            </Typography>
                         </div>
                        <div className="rightCardStatusRowElements"> 
                             <Typography variant="body1" gutterBottom>
                             AVG COST PER STEP
                             </Typography>
                             <Typography variant="body1" gutterBottom>
                             {avgCostPerStep.toFixed(2)}
                             </Typography>
                        </div> */}
                        </div>

                        <div className="rightCardStatusElements">
                        <Typography variant="subtitle2" gutterBottom component="div" style={{marginBottom: "0px"}}>
                        STOPS
                        </Typography>
                        <hr style={{backgroundColor: "black", height: 2, width: "100%", marginTop: "0px"}}></hr>
                        NO STOPS
                        </div>
                    </div>
                ) 
            }
        } else {
            return null;
        }
        return (
            <div className="rightCardStopsTab">
                
            </div>

        )
    }
}
const mapStateToProps = (state) => {
    return {
       
    }
  }
const mapDispatchToProps = (dispatch) =>{
    return {
        updateStopSelectedFlag: (stopSelectedFlag) => dispatch(LiftActions.UPDATE_USER_SELECTED_STOP_DASHBOARD_FLAG(stopSelectedFlag)),
        updateUserSelectedStop: (stopSelected) => dispatch(LiftActions.UPDATE_USER_SELECTED_STOP_DASHBOARD(stopSelected)),
    }
  }

export default connect (mapStateToProps, mapDispatchToProps) (DuoLiftStopsTab);