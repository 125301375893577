import React from 'react';
import { connect } from 'react-redux';
import Typography from '@mui/material/Typography';
import * as LiftActions from '../../../../store/lift/lift.actions';

class DuoLiftAlertsTab extends React.Component {

    state = {
        selectedAlert: null,
    };

    userClickAlert(alert) {      
        this.setState({ selectedAlert: alert });
        if(alert.alertLatitude !== 0 && alert.alertLongitude !== 0){
            this.props.updateUserSelectedAlert(alert)
            this.props.updateAlertSelectedFlag(true)
        }
    }

    userSelectedColor(alert) {
        if (this.state.selectedAlert !== null) {
            if (this.state.selectedAlert.alertOrdinal === alert.alertOrdinal) {
                return 'lightblue'
            } 
        } 
    }

    userBorder(alert) {
        if (this.state.selectedAlert !== null) {
            if (this.state.selectedAlert.alertOrdinal === alert.alertOrdinal) {
                return '3px solid #00B0F0'
            } else {
                return ''
            }
        } else {
            return ''
        }
    }

    returnTimeForAlert(alert) {
        if (alert != null) {
            if (alert.alertTime.includes("T")) {
                return alert.alertTime.split("T")[1]
            } else {
                return alert.alertTime.split(" ")[1]
            }
        }
    }

    render() {
        let selectedDevice = this.props.selectedLift
        let selectedDeviceAlerts = this.props.selectedLiftAlerts
        if (selectedDevice != null) {
            let alertsLength  = selectedDeviceAlerts == null ? 0: selectedDeviceAlerts.length;
        if (alertsLength > 0) {
            let alertArea = 
            selectedDeviceAlerts.map((item,index) => (
                    // <div className="rightCardStatusRowElements"> 
                    // <Typography variant="body1" gutterBottom style={{width:"200px"}}>
                    // {item.alertDesc}
                    // </Typography>
                    // <Typography variant="body1" gutterBottom style={{width:"30px"}}>
                    // {item.count} 
                    // </Typography>
                    // </div>


                <div className="rightCardStatusRowStopElements" key={item.alertOrdinal} style={{border: this.userBorder(item)}}>
                <div className="rightCardStatusRowElements" key={item.alertOrdinal} style={{background: this.userSelectedColor(item)}} onClick={() => this.userClickAlert(item)}> 
                <Typography variant="body1" gutterBottom style={{width:"40%", marginLeft:"10px"}}>
                {item.alertDesc}
                </Typography>
                <Typography variant="body1" gutterBottom style={{width:"30%", marginLeft:"30px"}}>
                {item.alertID} 
                </Typography>
                <Typography variant="body1" gutterBottom style={{width:"30%"}}>
                {this.returnTimeForAlert(item)}
                </Typography>
                </div>
                </div>
                 ))  

            return (
                <div className="rightCardAlertsTab">
                    <div className="rightCardStatusElements">
                    <Typography variant="subtitle2" gutterBottom component="div" style={{marginBottom: "0px"}}>
                    {selectedDevice.alertCount} ALERTS
                    </Typography>
                    <div className="rightCardStopElements">
                    <div className="rightCardStatusRowElements"> 
                    <Typography variant="body2" gutterBottom style={{width:"40%",  marginLeft:"10px"}}>
                    ALERT DESC
                    </Typography>
                    <Typography variant="body2" gutterBottom  style={{width:"25%",  marginRight:"30px", marginLeft:"20px"}}>
                    ALERT ID
                    </Typography>
                    <Typography variant="body2" gutterBottom style={{width:"35%", marginRight:"15px"}}>
                    ALERT TIME
                    </Typography>
                    </div>
                    </div>
                    <hr style={{backgroundColor: "black", height: 2, width: "100%", marginTop: "0px"}}></hr>
                    {alertArea}
                    </div>
                </div>
            )

        } else {
            return (
                <div className="rightCardAlertsTab">
                    <div className="rightCardStatusElements">
                    <Typography variant="subtitle2" gutterBottom component="div" style={{marginBottom: "0px"}}>
                     ALERTS
                    </Typography>
                    <hr style={{backgroundColor: "black", height: 2, width: "100%", marginTop: "0px"}}></hr>
                    <Typography variant="body1" gutterBottom>
                     NO ALERTS
                    </Typography>
                    </div>
                </div>
            ) 
        }
        } else {
            return null;
        }
    }
}

const mapStateToProps = (state) => {
    return {
       
    }
  }
const mapDispatchToProps = (dispatch) =>{
    return {
        updateAlertSelectedFlag: (alertSelectedFlag) => dispatch(LiftActions.UPDATE_USER_SELECTED_ALERT_DASHBOARD_FLAG(alertSelectedFlag)),
        updateUserSelectedAlert: (alertSelected) => dispatch(LiftActions.UPDATE_USER_SELECTED_ALERT_DASHBOARD(alertSelected)),
    }
  }

export default connect (mapStateToProps, mapDispatchToProps) (DuoLiftAlertsTab);