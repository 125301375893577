import React from 'react';
import FleetAlertChart from '../FleetComparisionAlertBarchart/FleetAlertChart.js'
import FleetComparisionPieChart from "../FleetComparisionMaintPie/FleetComparisionPieChart.js"
import { connect } from 'react-redux'

const FleetComparisionPortal = (props) => {
  return (
    <div className="fleetComparisionPanel">
      <FleetAlertChart alertPareto={props.alertPareto}></FleetAlertChart>
      <FleetComparisionPieChart></FleetComparisionPieChart>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    alertPareto: state.alertPareto,
  }
}

export default connect(mapStateToProps, null)(FleetComparisionPortal);