import React from 'react';
import {connect} from 'react-redux'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import * as LiftActions from '../../../store/lift/lift.actions'


class NotificationService extends React.Component{
  state= {errorState: false, errorMessage: null}

   componentDidUpdate(prevPros, prevState) {
    if(this.props.errorMessage !== null && this.props.selectedLocationListData !== null) {
      if (prevPros.errorMessage !== this.props.errorMessage) {
        this.setState({errorState: true, errorMessage: this.props.errorMessage  })
      }
    } else if (prevState.errorMessage !== null && this.props.errorMessage === null) {
      this.setState({ errorState: false,  errorMessage: null});
    }
      
    
    // setTimeout(()=>{
    //   this.setState({ errorState: false,  errorMessage: null});
    //   if (this.state.errorState == false) {
    //   }
    // },2000)
   }
  


handleClose = () => {
  if (this.state.errorMessage !== "Lift details not available for the user") {
    this.setState({ errorState: false,  errorMessage: null});
    this.props.defaultErrorState()
  } 
}
  
  render(){

    let errorMessage;
    if (this.props.errorMessage != undefined && this.props.errorMessage != null) {
      errorMessage = this.props.errorMessage.toString()
      return(
        <div>
            <Snackbar open={this.state.errorState} autoHideDuration={5000} onClose={() => {
                          this.handleClose()
                      }} anchorOrigin={{vertical: 'bottom',horizontal: 'right'}}>
              <MuiAlert elevation={6} variant="filled" severity="error" sx={{ width: '100%', backgroundColor:"#d32f2f !important", color:"#fff !important" }}>
              {errorMessage}
              </MuiAlert>
            </Snackbar>
         </div>
      )
    } else {
      return null
    }
    // else {
    //   errorMessage = "API Error"
    // }
  }
}
const mapStateToProps = (state) =>{
  return {
     errorMessage: state.error,
     selectedCustomerListData: state.selectedCustomerListData,
     selectedLocationListData: state.selectedLocationListData,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    defaultErrorState: () => dispatch(LiftActions.DEFAULT_ERROR_MESSAGE()),
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(NotificationService);

