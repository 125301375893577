import React, { useEffect, useState } from "react";
import { CsvBuilder } from 'filefy';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Button, CircularProgress, Grid, InputAdornment, Typography, TextField, Stack } from '@mui/material';
import { connect } from 'react-redux'
import * as LiftActions from '../../../store/lift/lift.actions'
import SearchIcon from "../../../assets/searchIcon.svg";
import FileDownloadIcon from '@mui/icons-material/FileDownload';

const AssetDetailsPage = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [tableData, setTableData] = useState(props.assetDetailsData?.items);
    const [pageSize, setPageSize] = useState(10);
    const customerId = props.selectedCustomerListData && props.selectedCustomerListData.customerId;
    const locationId = props.selectedCustomerListData && props.selectedLocationListData.locationId;

    const getAssetDetails = () => {
        const loggedInUserIDToken = localStorage.getItem("authToken");
        props.fetchAssetDetails(loggedInUserIDToken, customerId, locationId);
    }

    const runSearchQuery = (searchInput) => {
        const filteredAssetData = props.assetDetailsData?.items.filter((row) => {
            return (
                (row.rfid && row.rfid.toUpperCase().includes(searchInput.toUpperCase())) ||
                (row.sscc && row.sscc.toUpperCase().includes(searchInput.toUpperCase())) ||
                (row.asset_status && row.asset_status.toUpperCase().includes(searchInput.toUpperCase())) ||
                (row.last_updated_by && row.last_updated_by.toUpperCase().includes(searchInput.toUpperCase())) ||
                (row.checkout_date && row.checkout_date.toUpperCase().includes(searchInput.toUpperCase())) ||
                (row.checkin_date && row.checkin_date.toUpperCase().includes(searchInput.toUpperCase()))
            );
        });
        setTableData(filteredAssetData)
    };

    useEffect(() => {
        if (props.searchInput) {
            runSearchQuery(props.searchInput);
        } else if (props.assetDetailsData) {
            setTableData(props.assetDetailsData?.items);
        }
    }, [props.searchInput, props.assetDetailsData]);

    useEffect(() => {
        if (customerId && locationId) {
            getAssetDetails();
            props.updateSearchInput("");
        }
    }, [props.selectedCustomerListData, props.selectedLocationListData]);

    useEffect(() => {
        if (tableData) {
            setIsLoading(false)
        }
    }, [tableData]);

    const handleChange = (value) => {
        props.updateSearchInput(value);
    }

    const columns = [
        {
            headerName: "RFID",
            field: "rfid",
            flex: 1
        },
        {
            headerName: "SSCC",
            field: "sscc",
            flex: 1
        },
        {
            headerName: "Asset Status",
            field: "asset_status",
            flex: 0.6
        },
        {
            headerName: "Last Updated By",
            field: "last_updated_by",
            flex: 0.8
        },
        {
            headerName: "Check-out Date/Time from DC",
            field: "checkout_date",
            flex: 1
        },
        {
            headerName: "Check-in Date/Time into DC",
            field: "checkin_date",
            flex: 1
        }
    ]

    const handleAssetDetailsDownload = () => {
        const currTs = Date.now();
        const data = tableData.map(rowData =>
            columns.map(columnDef => rowData[columnDef.field]),
        );
        const builder = new CsvBuilder(`assetDetails_${currTs}.csv`);
        builder
            .setColumns(columns.map(columnDef => columnDef.headerName))
            .addRows(data)
            .exportFile();
    }

    return (
        <div style={{ width: "100%" }}>
            <Typography sx={styles.heading}>Asset Status Details</Typography>
            {isLoading ?
                <Stack alignItems="center">
                    <CircularProgress color="inherit" />
                </Stack> :
                <>
                    <Grid container>
                        <Grid item xs={3}>
                            <Box sx={{ width: "100%" }} mt={4} mb={4}>
                                <TextField
                                    value={props?.searchInput}
                                    onChange={(e) => {
                                        handleChange(e.target.value);
                                    }}
                                    autoComplete="off"
                                    sx={{
                                        borderRadius: "4px",
                                        input: {
                                            color: "#033F53",
                                        },
                                        width: "100%",
                                        background: "#fff",
                                        boxShadow: "0px 4px 8px rgba(16, 24, 64, 0.16)",
                                        "& .MuiInputBase-root.Mui-disabled": {
                                            background: "#E0E2EA",
                                            color: "#A6A6A6",
                                        },
                                    }}
                                    size={"small"}
                                    placeholder="Search"
                                    id="outlined-start-adornment"
                                    InputProps={{
                                        startAdornment:
                                            <InputAdornment position="start">
                                                <img src={SearchIcon} alt="" />
                                            </InputAdornment>
                                    }}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={9}>
                        <Box mt={4} mb={4} display={"flex"} justifyContent={"end"}>
                            <Button
                                onClick={handleAssetDetailsDownload}
                                startIcon={<FileDownloadIcon />}
                                variant="contained"
                                style={{
                                    backgroundColor: "#3b62a1"
                                }}
                                >
                                Export Data
                            </Button>
                            </Box>
                        </Grid>
                    </Grid>
                    {tableData && (
                        <DataGrid
                            rows={tableData}
                            columns={columns}
                            getRowId={(row) => row.rfid}
                            disableColumnMenu
                            autoHeight={true}
                            pageSize={pageSize}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            rowsPerPageOptions={[10, 20, 30, 40, 50]}
                            disableSelectionOnClick
                            hideFooterPagination={tableData.length > 0 ? false : true}
                            sx={{
                                ".MuiDataGrid-columnHeaders": {
                                    backgroundColor: "#FAFBFF",
                                },
                                '.MuiDataGrid-columnSeparator': {
                                    display: 'none',
                                },
                                '.MuiDataGrid-cell:focus': {
                                    outline: "none !important",
                                }
                            }}
                        />
                    )}
                </>
            }
        </div>
    );
};

export const styles = {
    heading: {
        color: "#303030",
        fontSize: "24px",
        fontWeight: 700,
    }
}

const mapStateToProps = (state) => {
    return {
        isReadOnlyUser: state.isReadOnlyUser,
        selectedCustomerListData: state.selectedCustomerListData,
        selectedLocationListData: state.selectedLocationListData,
        assetDetailsData: state.assetDetailsData,
        searchInput: state.searchInput
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchAssetDetails: (userToken, customerId, locationId) => dispatch(LiftActions.ASSET_DETAILS_REQUEST_TYPE(userToken, customerId, locationId)),
        updateSearchInput: (searchInput) => dispatch(LiftActions.SEARCH_INPUT_UPDATE(searchInput)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AssetDetailsPage);