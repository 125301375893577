import React, { useState } from 'react';
import { connect } from 'react-redux'
import * as LiftActions from '../../../store/lift/lift.actions';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Chart, PieSeries } from '@devexpress/dx-react-chart-material-ui';
import { Animation } from '@devexpress/dx-react-chart';
import { Palette } from '@devexpress/dx-react-chart';
import wrenchSolidIcon from '../../../assets/wrench-solid.svg';
import FleetComparisionPieChartTable from '../FleetComparisionPieChartTable/FleetComparisionPieChartTable.js';

const FleetComparisionPieChart = (props) => {
  let series = [];

  const setValuesForPieChart = (devices) => {
    let noMaintenanceLiftCount = 0
    let upComingMaintenanceLiftCount = 0
    let postDueMaintenanceLiftCount = 0
    devices.map(device => {
      if (device.maintState == 0) {
        noMaintenanceLiftCount = noMaintenanceLiftCount + 1
      } else if (device.maintState == 1) {
        upComingMaintenanceLiftCount = upComingMaintenanceLiftCount + 1
      } else if (device.maintState == 2) {
        postDueMaintenanceLiftCount = postDueMaintenanceLiftCount + 1
      }
    }
    )
    series = series.concat({ title: "Lifts no maintenance required", category: `0`, value: noMaintenanceLiftCount, color: "#2ECC71" })
    series = series.concat({ title: "Lifts with upcoming maintenance", category: `1`, value: upComingMaintenanceLiftCount, color: "#F4D03F" })
    series = series.concat({ title: "Lifts with maintenance past due", category: `2`, value: postDueMaintenanceLiftCount, color: "#FB2D01" })
    series = [...new Map(series.map(o => [o.category, o])).values()]

  }

  let devices = props.devices
  let newschema = ["#3B9C57", "#F19902", "#DB2D2E"]
  let ChartContainer
  if (devices.length > 0) {
    setValuesForPieChart(devices)
    ChartContainer = (
      <Paper>
        <Chart
          data={series}
        >
          <Palette scheme={newschema} />
          <PieSeries
            valueField="value"
            argumentField="title"
          // color="color"
          />
          {/* <Title
                text="Area of Countries"
              /> */}
          <Animation />
        </Chart>
      </Paper>
    )
  } else {
    series = []
    ChartContainer = (
      <Paper>
        <Typography gutterBottom variant="h5" component="h2" className="app-name-font" style={{ marginRight: "20px" }}>
          NO LIFT DATA AVAILABLE
        </Typography>
      </Paper>
    )
  }
  return (
    <div className="fleetComparisonPieChart">
      <div className="fleetComparisionTopTitle">
        <img src={wrenchSolidIcon} width="35px" length="35px" style={{ marginRight: "20px" }} />
        <Typography gutterBottom variant="h5" component="h2" className="app-name-font" style={{ marginRight: "20px" }}>
          LIFT MAINTENANCE STATUS
        </Typography>
      </div>
      <div>
        {ChartContainer}
        <div style={{ marginTop: "20px", marginBottom: "20px" }}>
          <FleetComparisionPieChartTable liftData={series}></FleetComparisionPieChartTable>
        </div>
      </div>
    </div>
  )

}

const mapStateToProps = (state) => {
  return {
    devices: state.devices,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadMaintenanceStatusModal: (statusCode, userToken) => dispatch(LiftActions.LOAD_LIFT_MAINTENANCE_MODAL(statusCode, userToken)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FleetComparisionPieChart);