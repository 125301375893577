export const LOAD_CUSTOMERS_AND_LOCATIONS = '[LIFT CUSTOMERS AND LOCATION] Load Customers and Location'
export const LOAD_CUSTOMERS_AND_LOCATIONS_SUCCESS_TYPE = '[LIFT CUSTOMERS AND LOCATION SUCCESS] Load Customers and Location Success'
export const LOAD_CUSTOMERS_AND_LOCATIONS_FAILURE_TYPE = '[LIFT CUSTOMERS AND LOCATION FAILURE] Load Customers and Location Failure'
export const VALIDATE_AUTHENTICATION_REQUEST_TYPE ='Authentication check'
export const VALIDATE_AUTHENTICATION_REQUEST_SUCCESS_TYPE = 'Auth check success'
export const VALIDATE_AUTHENTICATION_REQUEST_FAILURE_TYPE = 'Auth check failed'

export const UPDATE_SELECTED_LIFT_CUSTOMERS = '[UPDATE SELECCTED LIFT CCUSTOMERS] update selected lift customers'

export const UPDATE_SELECTED_LIFT_LOCATIONS = '[UPDATE SELECTED LIFT LOCATIONS] update selected lift locations'

export const LOAD_LIFT_SUMMARY_TYPE = '[LIFT LIST] Load Lift Summaries'
export const LOAD_LIFT_STOP_ALERT_DATA = '[LIFT LIST STOP ALERT DATA] Load Lift Stop Alert Data'
export const LOAD_LIFT_SUMMARY_SUCCESS_TYPE = '[LIFT LIST] Load Lift Loaded Success'
export const LOAD_LIFT_SUMMARY_FAILURE_TYPE = '[LIFT LIST] Load Lift Loaded Failure'

export const LOAD_LIFT_DETAIL_TYPE = '[LIFT DETAIL] Load Lift Detail'
export const LOAD_LIFT_DETAIL_SUCCESS_TYPE = '[LIFT DETAIL] Load Lift Detail Loaded Success'
export const LOAD_LIFT_DETAIL_FAILURE_TYPE = '[LIFT DETAIL] Load Lift Detail Loaded Failure'

export const LOAD_LIFT_ALERT_HISTORY_TYPE = '[LIFT ALERT HISTORY] Load Lift Alert History'
export const LOAD_LIFT_ALERT_HISTORY_SUCCESS_TYPE = '[LIFT ALERT HISTORY] Load Lift Alert History Success'
export const LOAD_LIFT_ALERT_HISTORY_FAILURE_TYPE = '[LIFT ALERT HISTORY] Load Lift Alert History Failure'

export const LOAD_LIFT_ALERT_TRAIL_TYPE = '[LIFT ALERT TRAIL] Load Lift Alert TRAIL'
export const LOAD_LIFT_ALERT_TRAIL_SUCCESS_TYPE = '[LIFT ALERT TRAIL] Load Lift Alert History TRAIL'
export const LOAD_LIFT_ALERT_TRAIL_FAILURE_TYPE = '[LIFT ALERT TRAIL] Load Lift Alert History TRAIL'

export const LOAD_LIFT_BREADCRUMB_HISTORY_TYPE = '[LIFT BREADCRUMB HISTORY] Load Lift Breadcrumb History'
export const LOAD_LIFT_BREADCRUMB_HISTORY_SUCCESS_TYPE = '[LIFT BREADCRUMB HISTORY] Load Lift Breadcrumb History Success'
export const LOAD_LIFT_BREADCRUMB_HISTORY_FAILURE_TYPE = '[LIFT BREADCRUMB HISTORY] Load Lift Breadcrumb History Failure'

export const LOAD_LIFT_STOPS_HISTORY_TYPE = '[LIFT STOPS HISTORY] Load Lift Stops History'
export const LOAD_LIFT_STOPS_HISTORY_SUCCESS_TYPE = '[LIFT STOPS HISTORY] Load Lift Stops History Success'
export const LOAD_LIFT_STOPS_HISTORY_FAILURE_TYPE = '[LIFT STOPS HISTORY] Load Lift Stops History Failure'

export const LOAD_LIFT_MAP_TYPE_UPDATE = '[LIFT MAP TYPE UPDATE] Load Lift Map Type'

export const LOAD_DASHBOARD_TYPE_UPDATE = '[LOAD DASHBOARD TYPE UPDATE] Load Dashboard Type'

export const SEARCH_INPUT_UPDATE = 'search input updated value'


export const LOAD_NAVMENU_TYPE_UPDATE = '[LOAD NAVMENU TYPE UPDATE] Load Nav menu type update'

export const LOAD_SIGNOUT_POPUP_TYPE_UPDATE = '[LOAD SIGNOUT TYPE UPDATE] Load Signout type update'

export const SIGNOUT_USER = '[SIGNOUT USER] Sign out user'

export const UPDATE_USER_SELECTED_MAP_DATES = '[UPDATE USER SELECTED MAP DATES] Update Map Dates'

export const UPDATE_REFRESH_DATA_REQUESTED_FLAG = '[UPDATE REFRESH DATA FLAG] Update refresh data flag'

export const LOAD_ALERT_PARETO = '[LOAD ALERT PARETO] Load Alert Pareto'
export const LOAD_ALERT_PARETO_SUCCESS = '[LOAD ALERT PARETO SUCCESS] Load Alert Pareto Success'
export const LOAD_ALERT_PARETO_FAILURE = '[LOAD ALERT PARETO FAILURE] Load Alert Pareto Failure'

export const UPDATE_FLEET_COMPARISION_SELCTION = '[UPDATE FLEET COMPARISION SELECTION] Update Fleet Comparision Selection'

export const LOAD_LIFT_ALERT_PARETO = '[LOAD ALERT PARETO] Load Lift Alert Pareto'
export const LOAD_LIFT_ALERT_PARETO_SUCCESS = '[LOAD ALERT PARETO SUCCESS] Load Lift Alert Pareto Success'
export const LOAD_LIFT_ALERT_PARETO_FAILURE = '[LOAD ALERT PARETO FAILURE] Load Lift Alert Pareto Failure'

export const LOAD_LIFT_ALERT_DETAIL_HISTORY_TYPE = '[LIFT ALERT DETAIL HISTORY] Load Lift Alert Detail History'
export const LOAD_LIFT_ALERT_DETAIL_HISTORY_SUCCESS_TYPE = '[LIFT ALERT DETAIL HISTORY] Load Lift Alert Detail History Success'
export const LOAD_LIFT_ALERT_DETAIL_HISTORY_FAILURE_TYPE = '[LIFT ALERT DETAIL HISTORY] Load Lift Alert Detail History Failure'

export const RESET_LOAD_LIFT_ALERT_PARETO_MODAL = '[RESET LIFT ALERT PARETO MODAL] Reset Lift Alert pareto modal'

export const LOAD_LIFT_MAINTENANCE_MODAL = '[LOAD LIFT MAINTENANCE MODAL] Load Lift Maintenance Modal'
export const LOAD_LIFT_MAINTENANCE_MODAL_SUCCESS = '[LOAD LIFT MAINTENANCE MODAL SUCCESS] Load Lift Maintenance Modal Success'
export const LOAD_LIFT_MAINTENANCE_MODAL_FAILURE = '[LOAD LIFT MAINTENANCE MODAL FAILURE] Load Lift Maintenance Modal Failure'

export const LOAD_LIFT_STOP_BIOMETRICS = '[LOAD LIFT STOP Analytics] Load Lift Stop Analytics'
export const LOAD_LIFT_STOP_BIOMETRICS_SUCCESS = '[LOAD LIFT STOP Analytics SUCCESS] Load Lift Stop Analytics Success'
export const LOAD_LIFT_STOP_BIOMETRICS_FAILURE = '[LOAD LIFT STOP Analytics FAILURE] Load Lift Stop Analytics Failure'

export const UPDATE_LOAD_LIFT_MAINTENANCE_MODAL = '[UPDATE LOAD LIFT MAINTENANCE MODAL] Update Load Lift maintenance Modal'

export const LIFT_MAP_GEOCODER = '[LIFT_MAP_GEOCODER] Load Lift Map Geocoder'
export const LIFT_ALERT_DETAIL_MODAL= '[LIFT_ALERT_DETAIL_MODAL] Set Alert Detail Modal'

export const LIFT_SERVICE_MODAL = '[LIFT SERVICE MODAL] Lift Service Modal'
export const LIFT_SERVICE_MODAL_UPDATE = '[LIFT SERVICE MODAL UPDATE] Lift Service Modal Update'

export const LIFT_CLEAR_SERVICE_TASK = '[LIFT CLEAR SERVICE TASK] Lift clear service requested'
export const LIFT_CLEAR_SERVICE_TASK_SUCCESS = '[LIFT CLEAR SERVICE TASK SUCESS] Lift clear service success'
export const LIFT_CLEAR_SERVICE_TASK_FAILURE = '[LIFT CLEAR SERVICE TASK FAILURE] Lift clear service failure'

export const LIFT_DASHBOARD_LOGIN_REQUEST_TYPE = '[LIFT DASHBOARD LOGIN REQUEST] Login User Request'
export const LIFT_DASHBOARD_LOGIN_SUCCESS_TYPE = '[LIFT DASHBOARD LOGIN SUCCESS] Login User Success'
export const LIFT_DASHBOARD_LOGIN_FAILURE_TYPE = '[LIFT DASHBOARD LOGIN FAILURE] Login User Failure'

export const LIFT_USERS_LOCATIONS_REQUEST_TYPE = '[LIFT LOCATIONS REQUEST TYPE] Lift locations request type'
export const LIFT_USERS_LOCATIONS_SUCCESS_TYPE = '[LIFT LOCATIONS SUCCESS TYPE] Lift locations success type'
export const LIFT_USERS_LOCATIONS_ERROR_TYPE = '[LIFT LOCATIONS ERROR TYPE] Lift locations error type'

export const LIFT_CUSTOMER_SELECTION_TYPE_UPDATE = '[LIFT CUSTOMER SELECTION TYPE UPDATE] Lift customer selection type update'

export const LIFT_ADD_EDIT_CUSTOMER_MODAL_UPDATE = '[LIFT ADD EDIT CUSTOMER MODAL UPDATE] Lift add/edit customer modal update'

export const LIFT_ADD_EDIT_LOCATION_MODAL_UPDATE = '[LIFT ADD EDIT LOCATION MODAL UPDATE] Lift add/edit location modal update'

export const CUSTOMER_ADD_REQUEST_TYPE = '[CUSTOMER ADD REQUEST TYPE] Customer add request type'
export const CUSTOMER_EDIT_REQUEST_TYPE = '[CUSTOMER EDIT REQUEST TYPE] Customer edit request type'
export const CUSTOMER_ADD_EDIT_SUCCESS_TYPE = '[CUSTOMER ADD EDIT SUCCESS TYPE] Customer add edit success type'
export const CUSTOMER_ADD_EDIT_ERROR_TYPE = '[CUSTOMER ADD EDIT ERROR TYPE] Customer add edit error type'

export const LOCATION_ADD_REQUEST_TYPE = '[LOCATION ADD REQUEST TYPE] Location add request type'
export const LOCATION_EDIT_REQUEST_TYPE = '[LOCATION EDIT REQUEST TYPE] Location edit request type'
export const LOCATION_ADD_EDIT_SUCCESS_TYPE = '[LOCATION ADD EDIT SUCCESS TYPE] Location add edit success type'
export const LOCATION_ADD_EDIT_ERROR_TYPE = '[LOCATION ADD EDIT ERROR TYPE] Location add edit error type'

export const LOCATIONS_USERS_REQUEST_TYPE = '[LOCATIONS USERS REQUEST TYPE] Locations users rquest type'
export const LOCATIONS_USERS_SUCCESS_TYPE = '[LOCATIONS USERS SUCCESS TYPE] Location users success type'
export const LOCATIONS_USERS_ERROR_TYPE = '[LOCATIONS USERS ERROR TYPE] Locations users error type'

export const LOCATIONS_USERS_CUSTOMER_UPDATE = '[LOCATIONS USERS CUSTOMER UPDATE] Locations users customer update' 

export const LOCATIONS_USER_LOCATION_UPDATE = '[LOCATIONS USER LOCATION UPDATE] Locations user location update'

export const USERS_ADD_MODAL_UPDATE = '[USERS ADD EDIT MODAL UPDATE] User add edit modal update'

export const USERS_EDIT_MODAL_UPDATE = '[USERS EDIT EDIT MODAL UPDATE] User edit edit modal update'

export const LOCATION_USERS_UPDATE_REQUEST_TYPE = '[LOCATIONS USERS UPDATE REQUEST TYPE] Locations users update request type'
export const LOCATION_USERS_UPDATE_SUCCESS_TYPE = '[LOCATIONS USERS UPDATE SUCCESS TYPE] Location users update success type'
export const LOCATION_USERS_UPDATE_FAILURE_TYPE = '[LOCATIONS USERS UPDATE FAILURE TYPE] Locations users update failure type'

export const SHOW_ERROR_USER_ROLE_UPDATE = '[UPDATE ERROR USER ROLE UPDATE] update error user role update'

export const LOCATIONS_LIFTS_CUSTOMER_UPDATE = '[LOCATIONS LIFTS CUSTOMER UPDATE] Locations lifts customer update'

export const LOCATIONS_LIFTS_LOCATION_UPDATE = '[LOCATIONS LIFTS LOCATION UPDATE] Locations lifts location update'

export const LIFTS_EDIT_MODAL_UPDATE = '[LIFTS EDIT EDIT MODAL UPDATE] Lift edit edit modal update'

export const LOCATIONS_LIFTS_REQUEST_TYPE = '[LOCATIONS LIFTS REQUEST TYPE] Locations lifts rquest type'
export const LOCATIONS_LIFTS_SUCCESS_TYPE = '[LOCATIONS LIFTS SUCCESS TYPE] Location lifts success type'
export const LOCATIONS_LIFTS_ERROR_TYPE = '[LOCATIONS LIFTS ERROR TYPE] Locations lifts error type'

export const LIFTS_UPDATE_REQUEST_TYPE = '[LIFTS UPDATE REQUEST TYPE] Lifts update request type'
export const LIFTS_UPDATE_REQUEST_SUCCESS_TYPE = '[LIFTS UPDATE REQUEST SUCCESS TYPE] Lifts update request success type'
export const LIFTS_UPDATE_REQUEST_ERROR_TYPE = '[LIFTS UPDATE REQUEST ERROR TYPE] Lifts update request error type'

export const LIFTS_DRIVERS_REQUEST_TYPE = '[LIFTS DRIVERS REQUEST TYPE] Lifts drivers update request type'
export const LIFTS_DRIVERS_REQUEST_SUCCESS_TYPE = '[LIFTS DRIVERS REQUEST SUCCESS TYPE] Lifts drivers update request success type'
export const LIFTS_DRIVERS_REQUEST_ERROR_TYPE = '[LIFTS DRIVERS REQUEST ERROR TYPE] Lifts drivers update request error type'

export const UPDATE_RIGHT_PANEL_EXPAND_AND_COLLAPSE = '[UPDATE RIGHT PANEL EXPAND AND COLLAPSE] Update right panel expand and collapse'
export const UPDATE_LEFT_PANEL_EXPAND_AND_COLLAPSE = '[UPDATE LEFT PANEL EXPAND AND COLLAPSE] Update left panel expand and collapse'

export const UPDATE_USER_SELECTED_STOP_DASHBOARD = '[UPDATE USER SELECTED STOP DASHBOARD] Update user selected stop dashboard'
export const UPDATE_USER_SELECTED_STOP_DASHBOARD_FLAG = '[UPDATE USER SELECTED STOP DASHBOARD FLAG] Update user selected stop dashboard flag'

export const UPDATE_USER_SELECTED_ALERT_DASHBOARD = '[UPDATE USER SELECTED ALERT DASHBOARD] Update user selected alert dashboard'
export const UPDATE_USER_SELECTED_ALERT_DASHBOARD_FLAG = '[UPDATE USER SELECTED ALERT DASHBOARD FLAG] Update user selected alert dashboard flag' 

export const USER_FLEET_REPORT_REQUEST_TYPE = '[USER FLEET REPORT REQUEST TYPE] User Fleet request type'
export const USER_FLEET_REPORT_REQUEST_SUCCESS_TYPE = '[USER FLEET REPORT REQUEST SUCCESS TYPE] User Fleet request success type'
export const USER_FLEET_REPORT_REQUEST_ERROR_TYPE = '[USER FLEET REPORT REQUEST ERROR TYPE] User Fleet request error type'

export const USER_FLEET_REPORT_MODAL_UPDATE = '[USER FLEET REPORT MODAL UPDATE] User Fleet moday type update'

export const CUSTOMER_UPDATE = 'customer values update' 

export const LOCATION_UPDATE = 'Locations values update'
export const DEFAULT_ERROR_MESSAGE = 'DEFAULT_ERROR_MESSAGE'

//Asset Details Page
export const ASSET_DETAILS_REQUEST_TYPE = "Fetch Asset Details"
export const ASSET_DETAILS_SUCCESS_TYPE = "Asset Details success"
export const ASSET_DETAILS_ERROR_TYPE = "Asset Details Error"

//Total Assets in Float section
export const TOTAL_ASSETS_REQUEST_TYPE = "Fetch Total Assets"
export const TOTAL_ASSETS_SUCCESS_TYPE = "Total Assets success"
export const TOTAL_ASSETS_ERROR_TYPE = "Total Assets Error"

//Lost Scrap section
export const LOST_SCRAP_REQUEST_TYPE = "Fetch Lost Scrap Values"
export const LOST_SCRAP_SUCCESS_TYPE = "Lost Scrap success"
export const LOST_SCRAP_ERROR_TYPE = "Lost Scrap Error"

//Store Details section 
export const STORE_DETAILS_REQUEST_TYPE = "Store details Values"
export const STORE_DETAILS_SUCCESS_TYPE = "Store details success"
export const STORE_DETAILS_ERROR_TYPE = "Store details Error"

//Dwell & Turn Time
export const DWELL_AND_TURN_TIME_REQUEST_TYPE = "Fetch Dwell & Turn Time"
export const DWELL_AND_TURN_TIME_SUCCESS_TYPE = "Dwell & Turn Time success"
export const DWELL_AND_TURN_TIME_ERROR_TYPE = "Dwell & Turn Time Error"

//Avg Dwell & Turn Time
export const AVG_DWELL_AND_TURN_TIME_REQUEST_TYPE = "Fetch Avg Dwell & Turn Time"
export const AVG_DWELL_AND_TURN_TIME_SUCCESS_TYPE = "Avg Dwell & Turn Time success"
export const AVG_DWELL_AND_TURN_TIME_ERROR_TYPE = "Avg Dwell & Turn Time Error"


//Hardware Management
export const HARDWARE_DETAILS_REQUEST_TYPE = "Fetch Hardware Details"
export const HARDWARE_DETAILS_SUCCESS_TYPE = "Hardware Details success"
export const HARDWARE_DETAILS_ERROR_TYPE = "Hardware Details Error"

export const LOST_SCRAP_ADD_REQUEST_TYPE = "Add Lost Scrap Value & unit"
export const LOSTSCRAP_ADD_SUCCESS_TYPE = "Add Lost Scrap success"
export const LOSTSCRAP_ADD_ERROR_TYPE = "Add Lost Scrap Error"


//To fetch user role name 
export const FETCH_USER_ROLE_REQUEST_TYPE = "Fetch User Role Name Request"
export const FETCH_USER_ROLE_SUCCESS_TYPE = "Fetch User Role Name success"
export const FETCH_USER_ROLE_ERROR_TYPE = "Fetch User Role Name Error"