import React, { useState } from 'react';
import { connect } from 'react-redux'
import { CsvBuilder } from 'filefy';
import * as LiftActions from '../../../store/lift/lift.actions'
import { Box, Button, CircularProgress, Modal, IconButton, Paper, TextField, Table, TableBody, TableCell, TableContainer, TableFooter, TablePagination, TableHead, TableRow } from '@mui/material';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { useTheme } from '@mui/material/styles';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

const FleetDetailedReport = (props) => {
  const [open, setOpen] = useState(false);
  const [searchResults, setSearchResults] = useState("");
  const [searchFlag, setSearchFlag] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [page, setPage] = useState(0);

  const handleOpen = () => {
    setOpen(true)
    let locationId = props.selectedLocationListData.locationId
    var startDate = props.startDate
    var endDate = props.endDate
    const loggedInUserIDToken = localStorage.getItem("authToken");
    props.getAlertReport(loggedInUserIDToken, locationId, startDate, endDate)
  };

  const handleClose = () => {
    setOpen(false)
    setSearchResults("")
    setSearchFlag(false)
    props.showTableUpdate()
  };

  const returnValueForNull = (value) => {
    if (value !== null) {
      return value
    } else {
      return 'null'
    }
  }

  let filteredData, popOverModal, userFleetComparisionReportDetails;
  let searchQuery = '';
  const setSearchQuery = (searchInput) => {
    searchQuery = searchInput ? searchInput.toLowerCase() : '';
  }

  const searchFn = (event) => {
    event.preventDefault();
    setSearchResults("");
    setSearchFlag(true)
    if (searchQuery) {
      filteredData = props.userFleetComparisionReportDetails.filter(e =>
        (e.lift_id && e.lift_id.toLowerCase().includes(searchQuery))
        || (e.alert_description && e.alert_description.toLowerCase().includes(searchQuery))
        || (e.first_name && e.first_name.toLowerCase().includes(searchQuery))
        || (e.last_name && e.last_name.toLowerCase().includes(searchQuery))
        || (e.route_name && e.route_name.toLowerCase().includes(searchQuery))
        || (e.truck_name && e.truck_name.toLowerCase().includes(searchQuery)));
      setSearchResults(filteredData)

    } else {
      setSearchFlag(false)
      setSearchResults(props.userFleetComparisionReportDetails)
    }
  }

  let showFleetComparisionTable = props.showFleetComparisionTable;
  userFleetComparisionReportDetails = (searchFlag) ? searchResults : props.userFleetComparisionReportDetails;

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0)
  };

  const TablePaginationActions = (props) => {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    const columns = [
      {
        field: 'lift_id',
        headerName: 'Lift ID',
        size: 40,
      },
      {
        field: 'alert_description',
        headerName: 'Alert Description',
        size: 120,
      },
      {
        field: 'timestamp',
        headerName: 'Time Stamp',
        size: 120,
      },
      {
        field: 'lat',
        headerName: 'Latitude',
        size: 300,
      },
      {
        field: 'long',
        headerName: 'Longitude',
      },
      {
        field: 'first_name',
        headerName: 'First Name',
        size: 220,
      },
      {
        field: 'last_name',
        headerName: 'Last Name',
        size: 220,
      },
      {
        field: 'route_name',
        headerName: 'Route',
        size: 220,
      },
      {
        field: 'truck_name',
        headerName: 'Truck',
        size: 220,
      },
    ];

    const handleExportData = () => {
      const currTs = Date.now();
      const data = userFleetComparisionReportDetails.map(rowData =>
        columns.map(columnDef => rowData[columnDef.field]),
      );
      const builder = new CsvBuilder(`fleet_report_${currTs}.csv`);
      builder
        .setColumns(columns.map(columnDef => columnDef.headerName))
        .addRows(data)
        .exportFile();
    }

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
          size="large">
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
          size="large">
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
          size="large">
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
          size="large">
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>

        <Button
          color="primary"
          //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
          onClick={handleExportData}
          startIcon={<FileDownloadIcon />}
          variant="contained"
        >
          Export All Data
        </Button>
      </Box>
    );
  }

  if (showFleetComparisionTable) {
    if (userFleetComparisionReportDetails.length > 0 || searchFlag) {
      popOverModal = <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        style={{ alignItems: 'center', justifyContent: 'center', marginTop: '120px', marginRight: '40px', marginLeft: '40px' }}
      >
        <TableContainer component={Paper} sx={{ minWidth: 650, maxHeight: '90%', overflow: 'scroll' }}>
          <form onSubmit={searchFn} style={{ position: 'fixed', marginTop: '-10px' }}>
            <Box sx={{ padding: '20px' }} >
              <TextField
                id="search-bar"
                className="text"
                label="Search.."
                variant="outlined"
                placeholder="Search..."
                size="small"
                sx={{ background: '#fff' }}
                onInput={(e) => {
                  setSearchQuery(e.target.value);
                }}
              />
              <Button
                type="submit" aria-label="search"
                variant="contained"
                sx={{ marginLeft: '10px' }}> Search
              </Button>
            </Box>
          </form>
          <Table sx={{ minWidth: 650, maxHeight: '90%', overflow: 'scroll', marginTop: '80px' }} aria-label="simple table" stickyHeader options={{ exportButton: true }}>
            <TableHead>
              <TableRow>
                <TableCell>Lift ID</TableCell>
                <TableCell align="center">Alert Description</TableCell>
                <TableCell align="center">Time Stamp&nbsp;</TableCell>
                <TableCell align="center">Latitude&nbsp;</TableCell>
                <TableCell align="center">Longitude&nbsp;</TableCell>
                <TableCell align="center">First Name&nbsp;</TableCell>
                <TableCell align="center">Last Name&nbsp;</TableCell>
                <TableCell align="center">Route&nbsp;</TableCell>
                <TableCell align="center">Truck&nbsp;</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userFleetComparisionReportDetails.length < 1 ? <TableRow><TableCell sx={{ fontSize: '22px', margin: '30px', display: 'block' }}>Search results not found</TableCell></TableRow> : <TableRow></TableRow>}
              {(rowsPerPage > 0
                ? userFleetComparisionReportDetails.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : userFleetComparisionReportDetails).map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.lift_id}
                    </TableCell>
                    <TableCell align="center">{returnValueForNull(row.alert_description)}</TableCell>
                    <TableCell align="center">{returnValueForNull(row.timestamp)}</TableCell>
                    <TableCell align="center">{returnValueForNull(row.lat)}</TableCell>
                    <TableCell align="center">{returnValueForNull(row.long)}</TableCell>
                    <TableCell align="center">{returnValueForNull(row.first_name)}</TableCell>
                    <TableCell align="center">{returnValueForNull(row.last_name)}</TableCell>
                    <TableCell align="center">{returnValueForNull(row.route_name)}</TableCell>
                    <TableCell align="center">{returnValueForNull(row.truck_name)}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
            <TableFooter sx={{ position: 'absolute', width: "100%", top: '89%', background: '#E7E9EB' }}>
              <TableRow>
                <TablePagination
                  colSpan={3}
                  count={userFleetComparisionReportDetails.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page',
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Modal>
    } else {
      popOverModal = <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        style={{ alignItems: 'center', justifyContent: 'center', marginTop: '120px', marginRight: '40px', marginLeft: '40px' }}
      >
        <TableContainer component={Paper} sx={{ minWidth: 650, maxHeight: 500, overflow: 'scroll' }}>
          <Table sx={{ minWidth: 650, maxHeight: 500, overflow: 'scroll' }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Lift ID</TableCell>
                <TableCell align="center">Alert Description</TableCell>
                <TableCell align="center">Time Stamp&nbsp;</TableCell>
                <TableCell align="center">Latitude&nbsp;</TableCell>
                <TableCell align="center">Longitude&nbsp;</TableCell>
                <TableCell align="center">First Name&nbsp;</TableCell>
                <TableCell align="center">Last Name&nbsp;</TableCell>
                <TableCell align="center">Route&nbsp;</TableCell>
                <TableCell align="center">Truck&nbsp;</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                key={1}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row" colSpan={9} align="center">
                  No Data
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Modal>
    }
  } else {
    popOverModal = <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      style={{ alignItems: 'center', justifyContent: 'center', marginTop: '120px', marginRight: '40px', marginLeft: '40px' }}
    >
      <div className='fleetComparisionReportLoading'>
        <CircularProgress style={{ color: 'white' }} />
      </div>
    </Modal>
  }

  return (
    <div style={{ width: '30%', marginRight: '20px', background: 'aliceblue' }}>
      <Button variant="contained" onClick={handleOpen} style={{ width: '100%' }}> View Report</Button>
      {popOverModal}
    </div>
  )

}

const mapStateToProps = (state) => {
  return {
    showFleetComparisionTable: state.showFleetComparisionTable,
    userFleetComparisionReportDetails: state.userFleetComparisionReportDetails,
    selectedLocationListData: state.selectedLocationListData,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAlertReport: (authToken, locationid, startdate, enddate) => dispatch(LiftActions.USER_FLEET_REPORT_REQUEST_TYPE(authToken, locationid, startdate, enddate)),
    showTableUpdate: () => dispatch(LiftActions.USER_FLEET_REPORT_MODAL_UPDATE())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FleetDetailedReport);