import React from "react";
import ReactDOM from 'react-dom/client';
import "./index.css";
import App from "./App";
import "./App.css";
import { Amplify } from "aws-amplify";
import { Provider } from "react-redux";
import { applyMiddleware } from "redux";
import { legacy_createStore as createStore } from 'redux';
import createSagaMiddleware from "redux-saga";
import reducer from "./store/lift/lift.reducer";
import { EffectListener } from "./store/lift/lift.effects";

const awsConfig = {
  // Auth: {
  //   region: "us-east-2",
  //   identityPoolRegion: "us-east-2",
  //   userPoolId: "us-east-2_TPJZp78kr",
  //   userPoolWebClientId: "17o37nvvulghv98m0hvp2namnb",
  //   oauth: {
  //     domain: "rpc-central-dev.auth.us-east-2.amazoncognito.com",
  //     scope: ["email", "openid", "aws.cognito.signin.user.admin", "profile"],
  //     redirectSignIn: "https://deliveryiot-dev.rehrig.com",
  //     redirectSignOut: "https://deliveryiot-dev.rehrig.com",
  //     responseType: "code"
  //   }
  Auth: {
    region: process.env.REACT_APP_REGION,
    identityPoolRegion: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_APP_CLIENT_ID,
    oauth: {
      domain: process.env.REACT_APP_AUTHORITY,
      scope: ["email", "openid", "aws.cognito.signin.user.admin", "profile"],
      redirectSignIn: process.env.REACT_APP_REDIRECT_URI,
      redirectSignOut: process.env.REACT_APP_REDIRECT_URI,
      responseType: "code"
    }
  }
}
Amplify.configure(awsConfig);

const root = ReactDOM.createRoot(document.getElementById('root'));
const sagaMiddleware = createSagaMiddleware();
const store = createStore(reducer, applyMiddleware(sagaMiddleware));
sagaMiddleware.run(EffectListener);

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);